import { CategoryTreeContext } from 'category-tree-provider';
import { CategoryId } from 'core.types';
import { memo, useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { activeSliceIdAtom, rootIDAtom } from 'store';
import '../category.scss';
import { CategoryDropzoneItem } from './category-dropzone-item';

export const CategoryDropzones = memo(() => {
  const activeSliceId = useRecoilValue(activeSliceIdAtom) as CategoryId;
  const rootId = useRecoilValue(rootIDAtom);
  const { getCategoryById } = useContext(CategoryTreeContext);
  const category = getCategoryById(activeSliceId);

  const dropzoneItems = [
    {
      id: rootId as CategoryId,
      label: <FormattedMessage id='move.home' />,
    },
    {
      id: category.parentId as CategoryId,
      label: <FormattedMessage id='move.parent' />,
    },
  ];

  const renderDropzoneItem = useCallback(
    (dropzone: { id: CategoryId; label: any }, index: number) => (
      <CategoryDropzoneItem {...dropzone} key={index} />
    ),
    [],
  );

  return (
    <div className='category-list__dropzone-container'>
      {dropzoneItems.map(renderDropzoneItem)}
    </div>
  );
});
