import c from 'classnames';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedCategoryIDsAtom } from 'store';
import type { CategoryId } from '../../core.types';
import { useCategoryDndLogic } from '../use-category-dnd-logic';
import '../category.scss';

type CategoryDropzoneItemProps = {
  id: CategoryId;
  label: any;
};

export const CategoryDropzoneItem = memo(
  ({ id, label }: CategoryDropzoneItemProps) => {
    const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
    const { drop, isOver } = useCategoryDndLogic(id);

    return (
      <div
        className={c('category-list__dropzone-item', {
          'category-list__dropzone-item--active': isOver,
          'is-over': isOver && !selectedCategoryIdsList.includes(id),
        })}
        ref={drop}
      >
        {label}
      </div>
    );
  },
);
