import { AppButton } from 'components/app-button';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { AppIcon } from '../../components/app-icon';
import './crop-step-image-action-control.scss';

type CropStepImageActionControlProps = {
  type: string;
  onClick(): void;
  disabled: boolean;
  id: string;
};

export const CropStepImageActionControl = memo(
  ({ type, onClick, disabled, id }: CropStepImageActionControlProps) => {
    const intl = useIntl();
    const label = intl.messages['style.' + type] as string;

    return (
      <AppButton
        className='image-action-control'
        onPress={onClick}
        disabled={disabled}
        id={id}
      >
        <AppIcon name={type} className='image-action-control__icon' />
        <span className='image-action-control__label'>{label}</span>
      </AppButton>
    );
  },
);
