import { AppType, ReceiptText } from 'core.types';
import { hasAppAccess } from './app-service';

const findTotal = (texts: string[]) => {
  let total = 0;
  texts.forEach((text: string) => {
    const numerics = text.match(/\d+\.\d{2}|\d+, \d{2}|\d+,\d{2}/g);
    if (numerics) {
      numerics.forEach((numeric) => {
        if (total < parseFloat(numeric)) {
          total = parseFloat(numeric);
        }
      });
    }
  });
  return total;
};

const compare = (a: ReceiptText, b: ReceiptText) => {
  if (a.y < b.y) {
    return -1;
  }
  if (a.y > b.y) {
    return 1;
  }
  return 0;
};

const prepareTexts = (textsObjects: ReceiptText[]) => {
  const result: string[] = [];
  textsObjects = [...textsObjects];
  textsObjects.sort(compare);
  textsObjects.forEach((textItem) => {
    result.push(textItem.text);
  });
  return result.reverse();
};

export const extractAmountAndName = (receiptTexts?: ReceiptText[] | null) => {
  if (
    receiptTexts === undefined ||
    receiptTexts === null ||
    receiptTexts.length < 2
  ) {
    return { amount: 0, name: '' };
  }
  const texts = prepareTexts(receiptTexts);
  return {
    amount: hasAppAccess(AppType.ireceipt) ? findTotal(texts) : 0,
    name: texts[0],
  };
};
