import { useGetReceiptName } from 'hooks/use-get-receipt-name';
import { PAGE_ROUTES } from 'page-routes';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { categoryListViewAtom, receiptToEditAtom } from 'store';
import {
  editModeEnabledATOM,
  selectedReceiptIDsAtom,
} from 'store/category-store';
import { useSelectedIDsAtomSetters } from 'store/category-store/category-store.callbacks';
import { currentUserCurrencySelector } from 'store/user-store';
import { AppType, LocalReceipt } from '../core.types';
import {
  detectLocalImagePath,
  HelperService,
  unlockBackSwipe,
} from '../services';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { hasAppAccess } from 'services/app-service';

export const useReceiptItemLogic = (item: LocalReceipt) => {
  const intl = useIntl();
  const { name } = item;
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const selectedReceiptIdsList = useRecoilValue(selectedReceiptIDsAtom);
  const [addSelectedReceiptId, removeSelectedReceiptId] =
    useSelectedIDsAtomSetters(selectedReceiptIDsAtom);
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const setReceiptToEdit = useSetRecoilState(receiptToEditAtom);
  const currency = useRecoilValue(currentUserCurrencySelector);
  const history = useHistory();
  const isItemActive = !!selectedReceiptIdsList.find((id) => id === item.id);
  const itemName = useGetReceiptName({ name, type: item.type });

  const onItemClick = useCallback(async () => {
    if (!detectLocalImagePath(item.imagePath) && window.cordova) {
      toast.warn(intl.messages['notifications.tryOpenReceiptLater'] as string, {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }

    if (!editModeEnabled) {
      await unlockBackSwipe();
      await setReceiptToEdit(item);

      history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.previewReceipt}`);
      return;
    }

    return isItemActive
      ? removeSelectedReceiptId(item.id)
      : addSelectedReceiptId(item.id);
  }, [
    addSelectedReceiptId,
    editModeEnabled,
    history,
    intl.messages,
    isItemActive,
    item,
    removeSelectedReceiptId,
    setReceiptToEdit,
  ]);

  const onItemDescriptionCLick = useCallback(async () => {
    if (!detectLocalImagePath(item.imagePath) && window.cordova) {
      toast.warn('Try to open receipt later', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }
    await unlockBackSwipe();
    await setReceiptToEdit(item);
    history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.updateReceipt}`);
  }, [history, item, setReceiptToEdit]);

  const date = HelperService.unixTimeToString(item.receiptDate);

  return {
    isReceiptItemEmpty:
      (hasAppAccess(AppType.ireceipt) && item?.amount === 0) || !item.name,
    onItemDescriptionCLick,
    isItemActive,
    onItemClick,
    date,
    itemName,
    categoryListView,
    currency,
  };
};
