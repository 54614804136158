import { ServerErrorResponse } from 'core.types';
import {
  createValidationResolver,
  VALIDATION_STRATEGIES,
} from 'services/validation-service';

export const setFormServerErrors = (
  errorResponse: ServerErrorResponse,
  setError: any,
) => {
  if (!errorResponse.graphQLErrors) {
    return;
  }

  for (const graphQlError of errorResponse?.graphQLErrors) {
    if (graphQlError.field) {
      setError(graphQlError.field, {
        type: 'server',
        message: graphQlError.message,
      });
    }
  }
};

export const FORGOT_PASSWORD_FORM_MESSAGES = {
  email: 'Invalid email',
  password: 'Password is required',
  otpCode: 'Invalid OTP Code',
  phone: 'Invalid phone',
};

export const FORM_VALIDATION_RESOLVER = createValidationResolver(
  {
    email: VALIDATION_STRATEGIES.email,
    password: VALIDATION_STRATEGIES.required,
    otpCode: VALIDATION_STRATEGIES.otpCode,
    phone: VALIDATION_STRATEGIES.minLength(8),
  },
  FORGOT_PASSWORD_FORM_MESSAGES,
);
