import React, { memo } from 'react';

export const CreditCardPaymentIcon = memo(
  ({ size = { width: 21, height: 21 }, color = 'white' }) => {
    const { width, height } = size;

    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20.4602 6.82264L14.1776 0.539981C13.4579 -0.179709 12.2868 -0.179627 11.5672 0.539981L3.68364 8.42359H1.84578C0.828016 8.42359 0 9.25161 0 10.2694V19.1544C0 20.1722 0.828016 21.0002 1.84578 21.0002H15.9812C16.9989 21.0002 17.8269 20.1722 17.8269 19.1544V12.0662L20.4602 9.43294C21.1799 8.71329 21.1799 7.54233 20.4602 6.82264ZM12.4374 1.41008C12.5573 1.29015 12.7149 1.23014 12.8724 1.23014C13.03 1.23014 13.1875 1.29015 13.3075 1.41008L16.5447 4.64737L12.7685 8.42359H5.4238L12.4374 1.41008ZM16.5964 19.1544C16.5964 19.4937 16.3204 19.7697 15.9812 19.7697H1.84578C1.50652 19.7697 1.23052 19.4937 1.23052 19.1544V10.2694C1.23052 9.93011 1.50652 9.65411 1.84578 9.65411H15.9812C16.3204 9.65411 16.5964 9.93011 16.5964 10.2694V19.1544ZM16.2315 8.44102C16.1496 8.42986 16.0661 8.42359 15.9812 8.42359H14.5087L17.4148 5.51743L18.285 6.38757L16.2315 8.44102ZM19.5901 8.56288L17.8269 10.3261V10.2694C17.8269 9.80169 17.6518 9.37433 17.364 9.04873L19.1551 7.25767L19.5901 7.6927C19.83 7.93261 19.83 8.32297 19.5901 8.56288Z'
          fill={color}
        />
        <path
          d='M2.46106 16.6765H4.7506V17.907H2.46106V16.6765Z'
          fill={color}
        />
        <path
          d='M5.99951 16.6765H8.28906V17.907H5.99951V16.6765Z'
          fill={color}
        />
        <path
          d='M9.53796 16.6765H11.8275V17.907H9.53796V16.6765Z'
          fill={color}
        />
        <path
          d='M13.0764 16.6765H15.366V17.907H13.0764V16.6765Z'
          fill={color}
        />
        <path
          d='M6.61476 11.5164H2.46106V15.2079H6.61476V11.5164ZM5.38424 13.9774H3.69158V12.7469H5.38424V13.9774Z'
          fill={color}
        />
      </svg>
    );
  },
);
