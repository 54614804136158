import React, { memo, useRef, useEffect, useCallback } from 'react';
import { AnimatedProgressProvider } from './animated-progress-bar-provider';
import c from 'classnames';
import { easeQuadInOut } from 'd3-ease';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const ProgressBar = memo(
  ({ value = 0, isUpload = false, isMobile = false }) => {
    const currentValueRef = useRef();

    useEffect(() => {
      currentValueRef.current = value;
    }, [value]);

    const getStartValue = useCallback((value) => {
      const { current: prevValue } = currentValueRef;
      if (!prevValue && value === 1) {
        return 0;
      } else if (prevValue === 2 && value === 1) {
        return 100;
      } else {
        return 100 / value;
      }
    }, []);

    return (
      <>
        <div
          className={c('app-sync-counter__progress-bar', {
            'app-sync-counter__progress-bar--mobile': isMobile,
          })}
        >
          <AnimatedProgressProvider
            valueStart={getStartValue(value)}
            valueEnd={100}
            duration={0.25}
            easingFunction={easeQuadInOut}
          >
            {(progressValue) => {
              return (
                <CircularProgressbarWithChildren
                  value={Math.round(progressValue)}
                  styles={buildStyles({
                    pathColor: isUpload ? '#8a97e7' : '#76c488',
                  })}
                >
                  <div
                    className={c('app-sync-counter__count', {
                      'app-sync-counter__count--mobile': isMobile,
                    })}
                  >
                    {value}
                  </div>
                </CircularProgressbarWithChildren>
              );
            }}
          </AnimatedProgressProvider>
        </div>
      </>
    );
  },
);
