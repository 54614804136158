import { memo } from 'react';
import { AppIconProps } from '../app-icon';

export const AddIcon = memo(
  ({
    size = { width: 24, height: 24 },
    color = 'black',
    opacity = '1',
  }: AppIconProps) => {
    const { width, height } = size;

    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <circle cx='12' cy='12' r='12' fill={color} />
          <path
            d='M12.4687 17.5313L12.4688 12.4688L17.5312 12.4688C17.7901 12.4688 18 12.2589 18 12C18 11.7411 17.7901 11.5313 17.5312 11.5313L12.4688 11.5313L12.4688 6.46875C12.4688 6.20986 12.2589 6 12 6C11.7411 6 11.5313 6.20986 11.5313 6.46875L11.5313 11.5313L6.46875 11.5312C6.20986 11.5312 6 11.7411 6 12C6 12.2589 6.20986 12.4687 6.46875 12.4687L11.5313 12.4688L11.5312 17.5313C11.5312 17.7901 11.7411 18 12 18C12.2589 18 12.4687 17.7901 12.4687 17.5313Z'
            fill='white'
          />
        </g>
      </svg>
    );
  },
);
