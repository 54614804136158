import { AppType, Maybe } from 'core.types';
import { memo } from 'react';
import Tappable from 'react-tappable';
import '../../category.scss';
import { CategoryTileName } from './category-tile-name';
import { currencyFormatter } from '../../../services';
import { hasAppAccess } from 'services/app-service';

type CategoryGridTileDescriptionProps = {
  name: string;
  isEmpty: boolean;
  amount: number;
  currency: string;
  onClick(): void;
  createdBy: string;
  date: Maybe<string>;
};

export const CategoryGridTileDescription = memo(
  ({
    name,
    isEmpty,
    amount,
    currency,
    onClick,
    createdBy,
    date,
  }: CategoryGridTileDescriptionProps) => {
    return (
      <Tappable onTap={onClick}>
        <div>
          <CategoryTileName {...{ isEmpty, name }} />
          {hasAppAccess(AppType.ireceipt) && (
            <div className='category-grid__tile-currency'>
              {currencyFormatter(currency, amount)}
            </div>
          )}
          <div className='category-grid__tile-info'>
            <div className='category-grid__tile-info-author'>
              <span className='category-grid__tile-info-author-label'>BY</span>{' '}
              {createdBy}
            </div>
            {date && (
              <div className='category-grid__tile-info-date'>{date}</div>
            )}
          </div>
        </div>
      </Tappable>
    );
  },
);
