import { AppButton } from 'components/app-button';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { filterMadeByAtom } from 'store';
import { madeByModalOpenedATOM } from 'store/category-store';
import { useSetBooleanAtomState } from 'store/category-store/category-store.callbacks';
import { AppIcon } from '../../../components';
import { useDetectMobileResolution } from '../../../hooks';
import './category-header-users-content.scss';

const MOBILE_USER_ICON = { width: 22, height: 22 };
const DESKTOP_USER_ICON = { width: 30, height: 30 };

export const CategoryHeaderUsersContent = memo(() => {
  const intl = useIntl();
  const [openMadeByModal] = useSetBooleanAtomState(madeByModalOpenedATOM);
  const filterMadeBy = useRecoilValue(filterMadeByAtom);
  const isMobileResolution = useDetectMobileResolution();

  const userName =
    filterMadeBy.id === 'all'
      ? (intl.messages['all.profiles'] as string)
      : filterMadeBy.name;

  return (
    <AppButton className='header-users__button' onPress={openMadeByModal}>
      <AppIcon
        name='users-icon'
        size={isMobileResolution ? MOBILE_USER_ICON : DESKTOP_USER_ICON}
      />
      <span className='header-users__label'>{userName}</span>
    </AppButton>
  );
});
