import { memo, useCallback } from 'react';
import { AppIcon } from '../../../components';
import { useDetectMobileResolution } from '../../../hooks';
import {
  SETTINGS_ICON_RESOLUTION_TYPES,
  SETTINGS_ICON_SIZE_MAP,
  SETTINGS_ICON_SIZE_TYPES,
} from './settings-icon.constants';

export const SettingsIcon = memo(
  ({
    className,
    onClick,
    name,
    color,
    opacity,
    sizeType = SETTINGS_ICON_SIZE_TYPES.default,
  }: any) => {
    const isMobile = useDetectMobileResolution();

    const resolutionType = isMobile
      ? SETTINGS_ICON_RESOLUTION_TYPES.mobile
      : SETTINGS_ICON_RESOLUTION_TYPES.desktop;

    const getSize = useCallback(
      (sizeType) => {
        switch (sizeType) {
          case SETTINGS_ICON_SIZE_TYPES.default:
            return SETTINGS_ICON_SIZE_MAP[sizeType][resolutionType];
          case SETTINGS_ICON_SIZE_TYPES.small:
            return SETTINGS_ICON_SIZE_MAP[sizeType][resolutionType];
          case SETTINGS_ICON_SIZE_TYPES.custom:
            return SETTINGS_ICON_SIZE_MAP[sizeType][name][resolutionType];
          default:
            return SETTINGS_ICON_SIZE_MAP[SETTINGS_ICON_SIZE_TYPES.default][
              resolutionType
            ];
        }
      },
      [name, resolutionType],
    );

    const size = getSize(sizeType);

    return <AppIcon {...{ className, name, size, color, opacity, onClick }} />;
  },
);
