import { useMutation } from '@apollo/client';
import { CategoryTreeContext } from 'category-tree-provider';
import { LocalReceipt, Maybe } from 'core.types';
import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import pick from 'lodash/pick';
import { useContext } from 'react';

const mutationDeleteReceipts = loader('./mutation-delete-receipts.graphql');
const mutationUpdateReceipt = loader('./mutation-update-receipt.graphql');
const mutationSetReadDocumentPermission = loader(
  './mutation-set-read-document-permission.graphql',
);

export const useDeleteReceipts = () => {
  const [deleteReceipts] = useMutation(mutationDeleteReceipts);

  return {
    deleteReceipts,
  };
};

export const useCreateReceiptWithRequestQueue = () => {
  const [createReceipt] = useMutation(mutationUpdateReceipt);

  return {
    createReceipt,
  };
};

export const useCreateReceipt = () => {
  const [createReceipt] = useCustomMutation(mutationUpdateReceipt);
  return {
    createReceipt,
  };
};

export const useUpdateReceipt = () => {
  const [updateReceipt] = useMutation(mutationUpdateReceipt);
  return {
    updateReceipt,
  };
};

type UploadFileDTO = {
  url: string;
  file: Maybe<Blob | File>;
};

export const uploadFile = async ({ url, file }: UploadFileDTO) => {
  try {
    await fetch(url, {
      method: 'PUT',
      body: file,
    });
  } catch (error) {
    console.error('IReceipt - file Upload to GC error:', error);
    throw error;
  }
};

export const useSetReadDocumentPermission = (postUpdate = false) => {
  const { createNewReceiptList } = useContext(CategoryTreeContext);
  const [mutation] = useCustomMutation(mutationSetReadDocumentPermission, {
    // @ts-ignore
    update: (_, { data: { createOrUpdateReceipt: receiptResponse } }) => {
      if (!postUpdate) {
        return;
      }

      createNewReceiptList([
        {
          ...pick(receiptResponse, [
            'id',
            'imagePath',
            'thumbPath',
            'amount',
            'name',
            'receiptDate',
            'createdBy',
            'type',
            'deleted',
            'updatedAt',
          ]),
          parentId: receiptResponse.category.id,
        } as LocalReceipt,
      ]);
    },
  });

  return mutation;
};
