import React, { memo } from 'react';

export const PdfIcon = memo(
  ({ size = { width: 38, height: 38 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.23938 8.57227H3.9375C3.77401 8.57227 3.61721 8.63736 3.50175 8.75314C3.38629 8.86893 3.32174 9.02594 3.32227 9.18947L3.33133 13.863C3.33133 14.2028 3.60679 14.4782 3.94657 14.4782C4.28634 14.4782 4.5618 14.2028 4.5618 13.863V12.444C4.81335 12.4427 5.08209 12.4416 5.23938 12.4416C6.32039 12.4416 7.19989 11.5737 7.19989 10.5069C7.19989 9.44016 6.32039 8.57227 5.23938 8.57227ZM5.23938 11.2111C5.08069 11.2111 4.81097 11.2122 4.55844 11.2135C4.55713 10.9516 4.55598 10.6696 4.55598 10.5069C4.55598 10.3678 4.55528 10.0765 4.55454 9.80273H5.23934C5.63506 9.80273 5.96938 10.1252 5.96938 10.5069C5.96938 10.8887 5.6351 11.2111 5.23938 11.2111Z'
          fill={color}
        />
        <path
          d='M10.4725 8.57227H9.1875C9.02414 8.57227 8.86746 8.63723 8.75204 8.75286C8.63662 8.86848 8.57194 9.02528 8.57227 9.18865C8.57227 9.18869 8.58141 13.7105 8.58145 13.7269C8.58207 13.8901 8.64745 14.0463 8.76328 14.1613C8.87857 14.2757 9.03435 14.3399 9.19669 14.3399H9.19903C9.23787 14.3397 10.1544 14.3362 10.5209 14.3298C11.9111 14.3056 12.92 13.0969 12.92 11.4561C12.92 9.7312 11.9365 8.57227 10.4725 8.57227ZM10.4994 13.0996C10.34 13.1024 10.0659 13.1046 9.80987 13.1063C9.80815 12.5719 9.80479 10.3581 9.80384 9.80273H10.4725C11.6018 9.80273 11.6895 11.0682 11.6895 11.4561C11.6895 12.2638 11.3215 13.0852 10.4994 13.0996Z'
          fill={color}
        />
        <path
          d='M16.9748 9.75537C17.3146 9.75537 17.5901 9.47991 17.5901 9.14014C17.5901 8.80036 17.3146 8.5249 16.9748 8.5249H15.0937C14.754 8.5249 14.4785 8.80036 14.4785 9.14014V13.7814C14.4785 14.1211 14.754 14.3966 15.0937 14.3966C15.4335 14.3966 15.709 14.1211 15.709 13.7814V12.0321H16.8254C17.1652 12.0321 17.4407 11.7566 17.4407 11.4168C17.4407 11.0771 17.1652 10.8016 16.8254 10.8016H15.709V9.75537H16.9748Z'
          fill={color}
        />
        <path
          d='M18.7852 5.94727H18.334V5.77176C18.334 4.9809 18.0343 4.22929 17.4902 3.65531L14.9347 0.959725C14.3565 0.349822 13.5428 0 12.7023 0H4.51172C3.494 0 2.66602 0.827982 2.66602 1.8457V5.94727H2.21484C1.19712 5.94727 0.369141 6.77525 0.369141 7.79297V15.1758C0.369141 16.1935 1.19712 17.0215 2.21484 17.0215H2.66602V19.1543C2.66602 20.172 3.494 21 4.51172 21H16.4883C17.506 21 18.334 20.172 18.334 19.1543V17.0215H18.7852C19.8029 17.0215 20.6309 16.1935 20.6309 15.1758V7.79297C20.6309 6.77525 19.8029 5.94727 18.7852 5.94727ZM3.89648 1.8457C3.89648 1.50646 4.17248 1.23047 4.51172 1.23047H12.7023C13.2066 1.23047 13.6948 1.44035 14.0418 1.80633L16.5973 4.50192C16.9237 4.84628 17.1035 5.29725 17.1035 5.77176V5.94727H3.89648V1.8457ZM17.1035 19.1543C17.1035 19.4935 16.8275 19.7695 16.4883 19.7695H4.51172C4.17248 19.7695 3.89648 19.4935 3.89648 19.1543V17.0215H17.1035V19.1543ZM19.4004 15.1758C19.4004 15.515 19.1244 15.791 18.7852 15.791H2.21484C1.8756 15.791 1.59961 15.515 1.59961 15.1758V7.79297C1.59961 7.45373 1.8756 7.17773 2.21484 7.17773H18.7852C19.1244 7.17773 19.4004 7.45373 19.4004 7.79297V15.1758Z'
          fill={color}
        />
      </svg>
    );
  },
);
