import { memo } from 'react';
import type { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

type AuthRouteProps = {
  hasAccess: boolean;
  path: string;
  component: FC;
  redirect?: string;
};

export const AuthRoute = memo(
  ({ path, component, hasAccess, redirect = '/auth' }: AuthRouteProps) => {
    return hasAccess ? (
      <Route {...{ path, component }} />
    ) : (
      <Redirect to={redirect} />
    );
  },
);
