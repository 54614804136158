import { CategoryTreeProvider } from 'category-tree-provider/category-tree-provider';
import { AuthRoute } from 'components/auth-route';
import { PAGE_ROUTES } from 'page-routes';
import { memo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import useDocumentTitle from 'use-document-title';
import {
  activeSliceIdAtom,
  currentUserSharedAccountSelector,
  dateFromFilterAtom,
  dateToFilterAtom,
  filterMadeByAtom,
  isAuthorizedAtom,
  isRootSelector,
  receiptToEditAtom,
  receiptTypeAtom,
  rootIDAtom,
  searchFilterAtom,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
  sortFilterAtom,
  tempIdMapAtom,
  userIdSelector,
} from 'store';
import { Category } from '../category';
import {
  Auth,
  ForgotPasswordPage,
  LoginPage,
  MailConfirmPage,
  OtpLogin,
  ResetPassword,
  SignUp,
} from '../scenes';
import { Settings } from '../scenes/Settings';
import { RootWrapper } from './root-wrapper';
// import { useCheckLanguage } from './use-check-language';
import { useRootInit } from './use-root-init';
import { getAppName } from 'services/app-service';
import { useSetFavicon } from 'hooks/use-set-favicon';

export const Root = memo(() => {
  // useCheckLanguage();
  const isAuthorized = useRecoilValue(isAuthorizedAtom);
  useRootInit(isAuthorized);

  useDocumentTitle(getAppName());
  useSetFavicon();

  // !TODO refactor provider to FC -> and move recoil to provider
  const searchFilter = useRecoilValue(searchFilterAtom);
  const receiptType = useRecoilValue(receiptTypeAtom);
  const filterMadeBy = useRecoilValue(filterMadeByAtom);
  const dateFrom = useRecoilValue(dateFromFilterAtom);
  const dateTo = useRecoilValue(dateToFilterAtom);
  const currentUserSharedAccount = useRecoilValue(
    currentUserSharedAccountSelector,
  );
  const userId = useRecoilValue(userIdSelector);
  const [activeSliceId, setActiveSliceId] = useRecoilState(activeSliceIdAtom);
  const isRoot = useRecoilValue(isRootSelector);
  const rootId = useRecoilValue(rootIDAtom);
  const { sortField, sortType } = useRecoilValue(sortFilterAtom);
  const [receiptToEdit, setReceiptToEdit] = useRecoilState(receiptToEditAtom);
  const [tempIdMap, setTempIdMap] = useRecoilState(tempIdMapAtom);
  const [selectedCategoryIdsList, setSelectedCategoryIdsList] = useRecoilState(
    selectedCategoryIDsAtom,
  );
  const [selectedReceiptIdsList, setSelectedReceiptIdsList] = useRecoilState(
    selectedReceiptIDsAtom,
  );

  return (
    <CategoryTreeProvider
      {...{
        searchFilter,
        receiptType,
        filterMadeBy,
        dateFrom,
        dateTo,
        currentUserSharedAccount,
        userId,
        activeSliceId,
        setActiveSliceId,
        isRoot,
        rootId,
        sortField,
        sortType,
        receiptToEdit,
        setReceiptToEdit,
        tempIdMap,
        setTempIdMap,
        selectedCategoryIdsList,
        setSelectedCategoryIdsList,
        selectedReceiptIdsList,
        setSelectedReceiptIdsList,
      }}
    >
      <RootWrapper>
        <Switch>
          <AuthRoute
            path='/auth'
            component={Auth}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path={PAGE_ROUTES.login}
            component={LoginPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/otp-login'
            component={OtpLogin}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/forgot-password'
            component={ForgotPasswordPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/reset-password'
            component={ResetPassword}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/sign-up'
            component={SignUp}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path={PAGE_ROUTES.mailConfirm}
            component={MailConfirmPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path={PAGE_ROUTES.category}
            component={Category}
            hasAccess={isAuthorized}
          />
          <AuthRoute
            path={PAGE_ROUTES.settings}
            component={Settings}
            hasAccess={isAuthorized}
          />
          <Redirect to={PAGE_ROUTES.category} />
        </Switch>
      </RootWrapper>
    </CategoryTreeProvider>
  );
});
