export class OnlineDispatcher {
  constructor() {
    this.pingTimeout = 1500;
    this.pingInterval = 5000;
    this.initiaized = false;
    this.url = process.env.REACT_APP_WEBSITE_URL + 'healthcheck';
    this.xhr = new XMLHttpRequest();
    this.xhr.timeout = this.pingTimeout;
    this.isOnline = window.navigator.onLine;
    this.onlineEvent = new Event('serverOnline');
    this.offlineEvent = new Event('serverOffline');
    this.startMonitoring().then(() => {});
  }

  async startMonitoring() {
    while (this.xhr) {
      let online =
        window.navigator.onLine === false
          ? false
          : await this.isAlive('GET', this.url);

      if (online !== this.isOnline) {
        this.isOnline = online;
        this.dispatchEvent();
      }
      this.initiaized = true;
      await new Promise((resolve) => setTimeout(resolve, this.pingInterval));
    }
  }

  waitForReady() {
    const initialized = this.initiaized;
    return new Promise(async function (resolve) {
      while (!initialized) {
        await new Promise((resolve) => setTimeout(resolve, 10));
      }
      resolve();
    });
  }

  dispatchEvent() {
    console.log(this.isOnline);
    if (this.isOnline) {
      window.dispatchEvent(this.onlineEvent);
    } else {
      window.dispatchEvent(this.offlineEvent);
    }
  }

  isAlive(method, url) {
    let xhr = this.xhr;
    return new Promise(function (resolve) {
      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      xhr.onerror = function () {
        resolve(false);
      };
      xhr.ontimeout = function () {
        resolve(false);
      };
      xhr.send();
    });
  }
}
