import { RequestItem, REQUEST_TYPE, ReceiptId } from '../core.types';
import { delay } from '../services/HelperService';
import { requestTable } from '../services/indexed-db-service';

type ChangeIDPayload = { id: number; tempId: string };

const getUniqRequestId = async () => {
  await delay(1);
  return String(new Date().getTime());
};

export const addRequestItem = async ({
  payload,
  requestType,
  rollbackPayload,
}: Omit<RequestItem, 'headers' | 'id'>) => {
  try {
    const accountId = localStorage.getItem('accountId');
    await requestTable.put({
      id: await getUniqRequestId(),
      payload,
      rollbackPayload,
      headers: {
        'X-CURRENT-ACCOUNT-ID': accountId,
      },
      requestType,
    });
  } catch (error) {
    console.log('IReceipt: error in addRequestItem function', error);
  }
};

export const updateCategoryRequestTempIdsOnRealIds = async ({
  id,
  tempId,
}: ChangeIDPayload) => {
  const updatePromiseList: Promise<any>[] = [];

  await requestTable.toArray().then(async (requestItems: RequestItem[]) => {
    for (const item of requestItems) {
      if ([REQUEST_TYPE.deleteReceipt].includes(item.requestType)) {
        return;
      }

      if (
        (item.requestType === REQUEST_TYPE.createReceipt ||
          item.requestType === REQUEST_TYPE.updateReceipt) &&
        item.payload.category_id === tempId
      ) {
        updatePromiseList.push(
          requestTable.put({
            id: item.id,
            requestType: item.requestType,
            payload: { ...item.payload, category_id: id },
          }),
        );
      }

      if (
        (item.requestType === REQUEST_TYPE.createCategory ||
          item.requestType === REQUEST_TYPE.updateCategory) &&
        item.payload.parent_id === tempId
      ) {
        updatePromiseList.push(
          requestTable.put({
            id: item.id,
            requestType: item.requestType,
            payload: { ...item.payload, parent_id: id },
          }),
        );
      }

      if (
        item.requestType === REQUEST_TYPE.updateCategory &&
        item.payload.id === tempId
      ) {
        updatePromiseList.push(
          requestTable.put({
            id: item.id,
            requestType: item.requestType,
            payload: { ...item.payload, id },
          }),
        );
      }

      if (
        item.requestType === REQUEST_TYPE.deleteCategory &&
        item.payload.includes(tempId)
      ) {
        updatePromiseList.push(
          requestTable.put({
            id: item.id,
            requestType: item.requestType,
            payload: [...item.payload.filter((id) => id !== tempId), id],
          }),
        );
      }
    }
  });

  return Promise.all(updatePromiseList);
};

export const updateReceiptRequestTempIdsOnRealIds = async ({
  id,
  tempId,
}: ChangeIDPayload) => {
  const updatePromiseList: Promise<any>[] = [];

  //TODO: Maybe bulk put is better
  await requestTable.toArray().then(async (requestItems: RequestItem[]) => {
    for (const item of requestItems) {
      if (
        [
          REQUEST_TYPE.updateCategory,
          REQUEST_TYPE.createCategory,
          REQUEST_TYPE.deleteCategory,
        ].includes(item.requestType)
      ) {
        return;
      }

      if (
        (item.requestType === REQUEST_TYPE.updateReceipt ||
          item.requestType === REQUEST_TYPE.createReceipt) &&
        item.payload.id === tempId
      ) {
        updatePromiseList.push(
          requestTable.put({
            id: item.id,
            requestType: item.requestType,
            payload: { ...item.payload, id },
          }),
        );
      }

      if (
        item.requestType === REQUEST_TYPE.deleteReceipt &&
        item.payload.includes(tempId)
      ) {
        updatePromiseList.push(
          requestTable.put({
            id: item.id,
            requestType: item.requestType,
            payload: [...item.payload.filter((id) => id !== tempId), id],
          }),
        );
      }
    }
  });

  await Promise.all(updatePromiseList);
};

export const deleteRelatedRequests = async (tempId: ReceiptId) => {
  await requestTable.where('payload.id').equals(tempId).delete();
};
