import { ACCEPT_FILE_TYPE } from 'app.constants';
import { AppIcon } from 'components/app-icon';
import {
  AppType,
  Maybe,
  RECOGNITION_TYPES,
  ScannedReceipt,
  VisionKitGalleryImageFile,
  VisionKitPDFResult,
  VisionKitScanResult,
} from 'core.types';
import { useSelectReceiptFile } from 'hooks/use-select-receipt-file';
import noop from 'lodash/noop';
import { PAGE_ROUTES } from 'page-routes';
import { memo, SyntheticEvent, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { generatePdfFromReceiptList } from 'services/pdf-service';
import {
  activeSliceIdAtom,
  newReceiptFileAtom,
  recognitionConfigAtom,
  recognizedImagePointsAtom,
  recognizedImageTextsAtom,
} from 'store';
import { useCreateReceiptList, useDetectMobileResolution } from '../../hooks';
import { getBlobFromUrl, getVisionKit, HelperService } from '../../services';
import { AddReceiptButtonSignature } from './add-receipt-button-signature';
import { hasAppAccess } from '../../services/app-service';

const PLUS_ICON_DESKTOP_SIZE = {
  width: 54,
  height: 54,
};

const PLUS_ICON_MOBILE_SIZE = {
  width: 32,
  height: 32,
};

export const AddReceiptButton = memo(() => {
  const createReceiptList = useCreateReceiptList();
  const isMobile = useDetectMobileResolution();
  const fileInputRef = useRef<Maybe<HTMLInputElement>>(null);
  const onChangeInput = useSelectReceiptFile(fileInputRef);
  const setNewReceiptFile = useSetRecoilState(newReceiptFileAtom);
  const history = useHistory();
  const setRecognizedImageTextsAtom = useSetRecoilState(
    recognizedImageTextsAtom,
  );
  const setRecognizedImagePoints = useSetRecoilState(recognizedImagePointsAtom);

  const onScanSuccess = useCallback(
    async (scannedResult: VisionKitScanResult) => {
      // @ts-ignore
      const scannedResultItem = scannedResult[0];

      // !If from camera
      if (!!(scannedResultItem as ScannedReceipt)) {
        // @ts-ignore
        if (hasAppAccess(AppType.idocument) && scannedResult.length > 1) {
          // @ts-ignore
          const combinedPDF = await generatePdfFromReceiptList(scannedResult);
          setNewReceiptFile(combinedPDF as File);
          history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.createReceipt}`);
          return;
        }

        createReceiptList(scannedResult as ScannedReceipt[]);
        return;
      }

      // !Detect from gallery or files
      const pdfFileLink = (scannedResult as VisionKitPDFResult).pdfTempUrl;
      const imageFileLink = (scannedResult as VisionKitGalleryImageFile)
        .imagePath;

      if (!pdfFileLink && !imageFileLink) {
        return;
      }

      const fileUrl = HelperService.getTempPath(pdfFileLink || imageFileLink);
      let points = (scannedResult as VisionKitGalleryImageFile).points;
      if (points) {
        points = [
          points['topLeft'],
          points['bottomLeft'],
          points['topRight'],
          points['bottomRight'],
        ];
      }

      if (imageFileLink) {
        setRecognizedImageTextsAtom(
          (scannedResult as VisionKitGalleryImageFile).text,
        );
        setRecognizedImagePoints(points);
      }

      const file = await getBlobFromUrl(fileUrl);
      setNewReceiptFile(file as File);

      history.push(
        `${PAGE_ROUTES.category}${
          pdfFileLink ? PAGE_ROUTES.createReceipt : PAGE_ROUTES.cropStep
        }`,
      );
    },
    [
      createReceiptList,
      history,
      setNewReceiptFile,
      setRecognizedImagePoints,
      setRecognizedImageTextsAtom,
    ],
  );

  const startVisionKit = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const recognitionConfig = snapshot.getLoadable(
          recognitionConfigAtom,
        ).contents;

        getVisionKit().scan(onScanSuccess, noop, {
          languages: recognitionConfig.languages,
          isFastTextRecognition:
            recognitionConfig.recognitionType === RECOGNITION_TYPES.fast,
        });
      },
    [onScanSuccess],
  );

  const onImageButtonClick = useRecoilCallback(
    ({ snapshot }) =>
      (event: SyntheticEvent<HTMLDivElement>) => {
        const activeSliceId = snapshot.getLoadable(activeSliceIdAtom).contents;

        if (!activeSliceId) {
          toast.error(
            'Error during sync, try to do pull to refresh when online',
            {
              autoClose: 3000,
              position: 'bottom-center',
            },
          );
          event.preventDefault();
          return;
        }

        if (
          window.cordova ||
          HelperService.parceBoolEnv(process.env.REACT_APP_EMULATE_VISIONKIT)
        ) {
          startVisionKit();
          event.preventDefault();
        }
      },
    [startVisionKit],
  );

  return (
    <div
      className='app-footer__add-receipt-block'
      onClick={onImageButtonClick}
      onTouchEnd={onImageButtonClick}
    >
      <label
        role='button'
        className='app-footer__center-button'
        htmlFor='add-receipt-main-button'
      >
        <input
          id='add-receipt-main-button'
          type='file'
          ref={fileInputRef}
          onChange={onChangeInput}
          accept={ACCEPT_FILE_TYPE}
          className='app-footer__input'
        />
        <AppIcon
          name='plus-icon'
          size={isMobile ? PLUS_ICON_MOBILE_SIZE : PLUS_ICON_DESKTOP_SIZE}
        />
      </label>
      <AddReceiptButtonSignature />
    </div>
  );
});
