import { HTMLAttributes, memo } from 'react';

export type AppButtonProps = {
  onPress?(...args: any[]): void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit';
} & HTMLAttributes<HTMLButtonElement>;

export const AppButton = memo((props: AppButtonProps) => {
  const {
    children,
    className = '',
    disabled,
    onPress,
    type = 'button',
  } = props;

  return (
    <button
      onClick={onPress}
      onTouchEnd={(e) => {
        if (onPress) {
          e.stopPropagation();
          e.preventDefault();
          onPress();
        }
      }}
      className={className}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
});
