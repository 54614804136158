export const PAGE_ROUTES = {
  login: '/login',
  category: '/category',
  settings: '/settings',
  cropStep: '/crop-step',
  createReceipt: '/create-receipt',
  updateReceipt: '/update-receipt',
  previewReceipt: '/preview-receipt',
  profile: '/settings/profile',
  auth: '/auth',
  otpLogin: '/otp-login',
  forgotPassword: '/forgot-password',
  mailConfirm: '/mail-confirm',
};
