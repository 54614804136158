import React, { memo } from 'react';

export const ShareIcon = memo(
  ({ size = { width: 30, height: 30 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M24.666 9.49992C21.9102 9.49992 19.666 7.44413 19.666 4.91658C19.666 2.38904 21.9102 0.333252 24.666 0.333252C27.4221 0.333252 29.6661 2.38904 29.6661 4.91658C29.6661 7.44413 27.4221 9.49992 24.666 9.49992ZM24.666 2.16659C23.0127 2.16659 21.666 3.3997 21.666 4.91658C21.666 6.43347 23.0127 7.66658 24.666 7.66658C26.3194 7.66658 27.666 6.43347 27.666 4.91658C27.666 3.3997 26.3194 2.16659 24.666 2.16659Z'
            fill={color}
          />
          <path
            d='M24.666 29.6667C21.9102 29.6667 19.666 27.6109 19.666 25.0833C19.666 22.5558 21.9102 20.5 24.666 20.5C27.4221 20.5 29.6661 22.5558 29.6661 25.0833C29.6661 27.6109 27.4221 29.6667 24.666 29.6667ZM24.666 22.3333C23.0127 22.3333 21.666 23.5664 21.666 25.0833C21.666 26.6002 23.0127 27.8333 24.666 27.8333C26.3194 27.8333 27.666 26.6002 27.666 25.0833C27.666 23.5664 26.3194 22.3333 24.666 22.3333Z'
            fill={color}
          />
          <path
            d='M5.33303 19.5832C2.57691 19.5832 0.333008 17.5274 0.333008 14.9998C0.333008 12.4723 2.57691 10.4165 5.33303 10.4165C8.08914 10.4165 10.333 12.4723 10.333 14.9998C10.333 17.5274 8.08914 19.5832 5.33303 19.5832ZM5.33303 12.2498C3.6797 12.2498 2.33302 13.483 2.33302 14.9998C2.33302 16.5167 3.6797 17.7498 5.33303 17.7498C6.98635 17.7498 8.33304 16.5167 8.33304 14.9998C8.33304 13.483 6.98635 12.2498 5.33303 12.2498Z'
            fill={color}
          />
          <path
            d='M8.81434 14.1078C8.4662 14.1078 8.12757 13.9415 7.94495 13.6459C7.67152 13.2059 7.83826 12.646 8.31825 12.3955L20.6903 5.92985C21.1689 5.67696 21.7809 5.83094 22.0529 6.27226C22.3263 6.71224 22.1596 7.27196 21.6796 7.52261L9.30751 13.9881C9.1515 14.0686 8.98231 14.1078 8.81434 14.1078Z'
            fill={color}
          />
          <path
            d='M21.1856 24.1907C21.0176 24.1907 20.8482 24.1515 20.6922 24.0698L8.32009 17.6042C7.84011 17.3537 7.6736 16.7938 7.9468 16.3538C8.21877 15.9127 8.82961 15.7599 9.3096 16.0116L21.6815 22.4771C22.1615 22.7277 22.3282 23.2875 22.055 23.7274C21.8709 24.0244 21.5323 24.1907 21.1856 24.1907Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
