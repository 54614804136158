import { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { dateFromFilterAtom, dateToFilterAtom } from 'store/filters-store';
import './category-filters.scss';
import { SearchInput } from './category-search-input';
import { Datepicker, locale } from '@mobiscroll/react';
import { calendarIcon } from './calendar-icon';
import { ClientService } from '../../services';
import { APP_CSS_THEME_VARIABLES } from '../../color-theme.constants';

export const CategoryFilters = memo(() => {
  const iconColor = `var(${APP_CSS_THEME_VARIABLES.icons})`;
  const [dateFrom, setDateFrom] = useRecoilState(dateFromFilterAtom);
  const [dateTo, setDateTo] = useRecoilState(dateToFilterAtom);
  const appLocale = ClientService.getLocale();
  const onApplyClick = useCallback(
    async ({ value }) => {
      await Promise.all([setDateFrom(value[0]), setDateTo(value[1])]);
    },
    [setDateFrom, setDateTo],
  );

  return (
    <section className='top-filter'>
      <SearchInput />
      <Datepicker
        locale={locale[appLocale]}
        theme='ios'
        themeVariant='light'
        controls={['calendar']}
        touchUi
        select='range'
        dateFormat='DD.MM.YYYY'
        value={[dateFrom, dateTo]}
        onChange={onApplyClick}
        display={'anchored'}
        inputProps={{
          className: 'top-filter__input',
        }}
        startIconSvg={calendarIcon(iconColor)}
      />
    </section>
  );
});
