import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSettingsLanguageLogic } from './use-settings-language-logic';
import { AppPseudoRadio } from '../../../../components/app-pseudo-radio';
import './settings-language.scss';

export const SettingsLanguage = memo(() => {
  const intl = useIntl();
  const { settingsLanguageOptions } = useSettingsLanguageLogic();

  const renderSettingsLanguageOptions = useCallback(
    () =>
      settingsLanguageOptions.map(({ label, onClick, isActive }, index) => (
        <li
          key={index}
          className='language-edit__language-list-item'
          onClick={onClick}
          onTouchEnd={onClick}
        >
          <AppPseudoRadio isItemActive={isActive} />
          <span className='language-edit__language-list-item-label'>
            {label}
          </span>
        </li>
      )),
    [settingsLanguageOptions],
  );

  return (
    <div className='language-edit__wrapper'>
      <div className='language-edit__title'>
        {intl.messages['menu.lang.choose'] as string}
      </div>

      <ul className='language-edit__language-list'>
        {renderSettingsLanguageOptions()}
      </ul>
    </div>
  );
});
