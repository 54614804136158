import {
  useGetLocalReceiptList,
  useSetCategoryTreeInRecoil,
  useSyncLastUpdatedValuesFromServer,
  useSyncServerCategoryTree,
} from 'category-tree-hooks/category-service';
import { useGetCategory } from 'gql/api-category';
import { useRecoilCallback } from 'recoil';
import { getAccountId } from 'services/auth-service';
import { getLocalCategoryTreeFromIndexedDB } from '../services/indexed-db-service';
import {
  currentUserSharedAccountSelector,
  getSnapshotValue,
  lastCategoryUpdateAtom,
  lastReceiptUpdateAtom,
  userIdSelector,
} from '../store';

export const useSyncCategoryTree = () => {
  const { fetchCategoryTree } = useGetCategory();
  const setCategoryTreeInRecoil = useSetCategoryTreeInRecoil();
  const syncServerCategoryTree = useSyncServerCategoryTree();
  const syncLastUpdatedValues = useSyncLastUpdatedValuesFromServer();
  const getLocalReceiptList = useGetLocalReceiptList();

  return useRecoilCallback(({ snapshot, set }) => async () => {
    // TODO fix here recoil problem
    window.syncProgress = true;
    const currentAccountId = getAccountId();

    const userId = getSnapshotValue(snapshot, userIdSelector);
    const currentUserSharedAccount = getSnapshotValue(
      snapshot,
      currentUserSharedAccountSelector,
    );

    const {
      categoriesFromCache,
      receiptsFromCache,
      lastCategoryUpdatedAtFromCache,
      lastReceiptUpdatedAtFromCache,
    } = await getLocalCategoryTreeFromIndexedDB(
      currentAccountId,
      currentUserSharedAccount,
      userId,
      getLocalReceiptList,
    );

    setCategoryTreeInRecoil({
      categories: categoriesFromCache,
      receipts: receiptsFromCache,
    });

    set(lastReceiptUpdateAtom, lastCategoryUpdatedAtFromCache);
    set(lastCategoryUpdateAtom, lastReceiptUpdatedAtFromCache);

    const { receipts, categories, accountId } = await fetchCategoryTree({
      lastReceiptUpdatedAt: lastReceiptUpdatedAtFromCache,
      lastCategoryUpdatedAt: lastCategoryUpdatedAtFromCache,
    });

    syncLastUpdatedValues(receipts, categories);
    syncServerCategoryTree({ receipts, categories, accountId });
    window.syncProgress = false;
  });
};
