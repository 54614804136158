import React, { memo } from 'react';

export const EditPencilIcon = memo(
  ({ size = { width: 10, height: 10 }, color = '#1A1C29' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.15132 0.4493C8.5522 -0.149767 7.58088 -0.149767 6.98176 0.4493L0.899735 6.53079C0.858054 6.57246 0.827958 6.62413 0.812244 6.68085L0.0124401 9.56807C-0.0204523 9.68644 0.0129727 9.81321 0.0997979 9.90016C0.186756 9.98697 0.313532 10.0204 0.431918 9.98764L3.31939 9.18777C3.37612 9.17206 3.42779 9.14197 3.46947 9.10029L9.55136 3.01867C10.1495 2.41921 10.1495 1.44877 9.55136 0.8493L9.15132 0.4493ZM1.64254 6.75236L6.62021 1.775L8.22554 3.38019L3.24774 8.35755L1.64254 6.75236ZM1.32188 7.39576L2.60441 8.67832L0.830355 9.1698L1.32188 7.39576ZM9.06929 2.53665L8.70774 2.89817L7.10228 1.29284L7.46396 0.931324C7.79674 0.598568 8.33634 0.598568 8.66912 0.931324L9.06929 1.33132C9.40155 1.66448 9.40155 2.20363 9.06929 2.53665Z'
          fill={color}
        />
      </svg>
    );
  },
);
