import { CategoryTreeContext } from 'category-tree-provider';
import { useLogout } from 'hooks/use-logout';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { createFileFromSrc } from '../components/idcrop/id-crop-helpers';
import {
  RequestCreateCategoryItem,
  RequestCreateReceiptItem,
  RequestDeleteCategoryItem,
  RequestDeleteReceiptItem,
  RequestUpdateCategoryItem,
  RequestUpdateReceiptItem,
  REQUEST_TYPE,
  ServerError,
  ServerErrorResponse,
  ServerErrorType,
} from '../core.types';
import {
  useCreateOrUpdateCategoryRequest,
  useDeleteCategoryList,
} from '../gql/api-category';
import {
  uploadFile,
  useCreateReceiptWithRequestQueue,
  useDeleteReceipts,
  useSetReadDocumentPermission,
  useUpdateReceipt,
} from '../gql/api-receipt';
import { delay, HelperService } from '../services';
import {
  checkIfTableIsEmpty,
  localReceiptFilesTable,
  replaceTempIdInLocalFilesTable,
  requestTable,
  waitUntilAppWillBeOnline,
  waitUntilDBWillBeNotEmpty,
} from '../services/indexed-db-service';
import { queryQueueItemsCountAtom } from '../store';
import {
  deleteRelatedRequests,
  updateCategoryRequestTempIdsOnRealIds,
  updateReceiptRequestTempIdsOnRealIds,
} from './use-query-queue';

type RequestErrorMiddlewareDTO = {
  errorResponse: ServerErrorResponse;
  rollbackStrategy(): Promise<void>;
};

export const useRequestSync = () => {
  const setQueryQueueItemsCount = useSetRecoilState(queryQueueItemsCountAtom);

  const { createReceipt } = useCreateReceiptWithRequestQueue();
  const { updateReceipt } = useUpdateReceipt();
  const { deleteReceipts } = useDeleteReceipts();

  const { createOrUpdateCategoryRequest } = useCreateOrUpdateCategoryRequest();
  const { deleteCategories } = useDeleteCategoryList();
  const {
    updateCategoryList,
    updateReceiptList,
    updateReceiptTempIdOnRealId,
    updateCategoryTempIdOnRealId,
    deleteCategoryList,
    deleteReceiptList,
  } = useContext(CategoryTreeContext);
  const setReadDocumentPermission = useSetReadDocumentPermission();

  const logout = useLogout();

  const postProcessAllRequestQueueErrors = useCallback(
    async (errors: ServerError[]) => {
      for (const error of errors) {
        if (
          [
            ServerErrorType.TYPE_CODE_TOKEN_ISSUE,
            ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER,
          ].includes(error.type)
        ) {
          await logout();
          break;
        }
      }
    },
    [logout],
  );

  const requestErrorMiddleware = useCallback(
    async ({ errorResponse, rollbackStrategy }: RequestErrorMiddlewareDTO) => {
      const errors: ServerError[] =
        errorResponse.graphQLErrors as ServerError[];

      for (const error of errors) {
        const toastCaller =
          error.type === ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE
            ? toast.info
            : toast.error;

        toastCaller(error.message, {
          autoClose: 3000,
          position: 'bottom-center',
        });

        if (
          [
            ServerErrorType.TYPE_CODE_TOKEN_ISSUE,
            ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER,
            ServerErrorType.TYPE_CODE_OFFLINE_ERROR,
          ].includes(error.type)
        ) {
          await rollbackStrategy();
        }
      }

      // ! Need delay to prevent server from Ddos
      await delay(3000);
      return errors;
    },
    [],
  );

  const removeRequestItem = useCallback(
    async (queryKey: string) => {
      await requestTable.where('id').equals(queryKey).delete();
      setQueryQueueItemsCount(await requestTable.count());
    },
    [setQueryQueueItemsCount],
  );

  const createReceiptStrategy = useCallback(
    async (
      requestKey: string,
      { payload, headers, rollbackPayload }: RequestCreateReceiptItem,
    ) => {
      try {
        const {
          imagePath,
          thumbPath,
          updatedAt: mockUpdatedAt,
          id: tempId,
          ...receiptData
        } = payload;

        const mainFile = (await createFileFromSrc(
          HelperService.resolveFilePath(imagePath),
        )) as File;

        const imageThumbFile = (await createFileFromSrc(
          HelperService.resolveFilePath(thumbPath),
        )) as File;

        const {
          data: {
            createOrUpdateReceipt: {
              id,
              updatedAt,
              uploadFileLink,
              uploadFileThumbLink,
            },
          },
        } = await createReceipt({
          variables: {
            ...receiptData,
            fileExtension: mainFile?.type.split('/').pop(),
          },
          context: {
            headers,
          },
        });

        await Promise.all([
          uploadFile({ url: uploadFileLink, file: mainFile }),
          uploadFile({
            url: uploadFileThumbLink,
            file: imageThumbFile,
          }),
        ]);

        await setReadDocumentPermission({
          variables: { id, fileUploaded: true },
        });
        // Update receipt ids in request queue table
        await updateReceiptRequestTempIdsOnRealIds({
          tempId: tempId as string,
          id,
        });
        // Update receipt ids in local files table
        await replaceTempIdInLocalFilesTable({
          table: localReceiptFilesTable,
          tempId: String(tempId),
          id: id,
        });
        // Update receipt ids in storage + local receipt and categories tables
        updateReceiptTempIdOnRealId({
          tempId: tempId as string,
          id,
          updatedAt,
        });
        await removeRequestItem(requestKey);
      } catch (errorResponse) {
        return requestErrorMiddleware({
          errorResponse: errorResponse as ServerErrorResponse,
          rollbackStrategy: async () => {
            await deleteRelatedRequests(payload.id);
            await removeRequestItem(requestKey);
            deleteReceiptList(rollbackPayload);
          },
        });
      }
    },
    [
      createReceipt,
      deleteReceiptList,
      removeRequestItem,
      requestErrorMiddleware,
      setReadDocumentPermission,
      updateReceiptTempIdOnRealId,
    ],
  );

  const updateReceiptStrategy = useCallback(
    async (
      requestKey: string,
      { payload, headers, rollbackPayload }: RequestUpdateReceiptItem,
    ) => {
      try {
        await updateReceipt({
          variables: {
            ...payload,
          },
          context: {
            headers,
          },
        });
        await removeRequestItem(requestKey);
      } catch (errorResponse) {
        return requestErrorMiddleware({
          errorResponse: errorResponse as ServerErrorResponse,
          rollbackStrategy: async () => {
            await removeRequestItem(requestKey);
            updateReceiptList([rollbackPayload]);
          },
        });
      }
    },
    [
      removeRequestItem,
      requestErrorMiddleware,
      updateReceipt,
      updateReceiptList,
    ],
  );

  const createCategoryStrategy = useCallback(
    async (
      requestKey: string,
      { payload, headers, rollbackPayload }: RequestCreateCategoryItem,
    ) => {
      try {
        const { id: tempId, ...restCategory } = payload;
        const {
          data: {
            // @ts-ignore
            createOrUpdateCategory: { id, updatedAt },
          },
        } = await createOrUpdateCategoryRequest({
          variables: {
            ...restCategory,
            id: null,
          },
          context: {
            headers,
          },
        });

        await updateCategoryRequestTempIdsOnRealIds({
          tempId: tempId as string,
          id,
        });

        await updateCategoryTempIdOnRealId({
          tempId: tempId as string,
          id,
          updatedAt,
        });
        await removeRequestItem(requestKey);
      } catch (errorResponse) {
        return requestErrorMiddleware({
          errorResponse: errorResponse as ServerErrorResponse,
          rollbackStrategy: async () => {
            await removeRequestItem(requestKey);
            deleteCategoryList([rollbackPayload]);
          },
        });
      }
    },
    [
      createOrUpdateCategoryRequest,
      deleteCategoryList,
      removeRequestItem,
      requestErrorMiddleware,
      updateCategoryTempIdOnRealId,
    ],
  );

  const deleteReceiptStrategy = useCallback(
    async (
      requestKey: string,
      { payload, headers, rollbackPayload }: RequestDeleteReceiptItem,
    ) => {
      try {
        await deleteReceipts({
          variables: { ids: payload },
          context: { headers },
        });
        // todo later use only on ios
        // todo later remove this items by id from images store, and delete them from vision kit
        await removeRequestItem(requestKey);
      } catch (errorResponse) {
        return requestErrorMiddleware({
          errorResponse: errorResponse as ServerErrorResponse,
          rollbackStrategy: async () => {
            await removeRequestItem(requestKey);
            updateReceiptList(rollbackPayload);
          },
        });
      }
    },
    [
      deleteReceipts,
      removeRequestItem,
      requestErrorMiddleware,
      updateReceiptList,
    ],
  );

  const deleteCategoryStrategy = useCallback(
    async (
      requestKey: string,
      { payload, headers, rollbackPayload }: RequestDeleteCategoryItem,
    ) => {
      try {
        await deleteCategories({
          variables: { ids: payload },
          context: { headers },
        });
        await removeRequestItem(requestKey);
      } catch (errorResponse) {
        return requestErrorMiddleware({
          errorResponse: errorResponse as ServerErrorResponse,
          rollbackStrategy: async () => {
            await removeRequestItem(requestKey);
            updateCategoryList(rollbackPayload);
          },
        });
      }
    },
    [
      deleteCategories,
      removeRequestItem,
      requestErrorMiddleware,
      updateCategoryList,
    ],
  );

  const updateCategoryStrategy = useCallback(
    async (
      requestKey: string,
      { payload, headers, rollbackPayload }: RequestUpdateCategoryItem,
    ) => {
      try {
        await createOrUpdateCategoryRequest({
          variables: payload,
          context: {
            headers,
          },
        });
        await removeRequestItem(requestKey);
      } catch (errorResponse) {
        return requestErrorMiddleware({
          errorResponse: errorResponse as ServerErrorResponse,
          rollbackStrategy: async () => {
            await removeRequestItem(requestKey);
            updateCategoryList([rollbackPayload]);
          },
        });
      }
    },
    [
      createOrUpdateCategoryRequest,
      removeRequestItem,
      requestErrorMiddleware,
      updateCategoryList,
    ],
  );

  const REQUEST_TYPE_STRATEGY_MAP = useMemo(
    () => ({
      [REQUEST_TYPE.createReceipt]: createReceiptStrategy,
      [REQUEST_TYPE.updateReceipt]: updateReceiptStrategy,
      [REQUEST_TYPE.deleteReceipt]: deleteReceiptStrategy,
      [REQUEST_TYPE.createCategory]: createCategoryStrategy,
      [REQUEST_TYPE.updateCategory]: updateCategoryStrategy,
      [REQUEST_TYPE.deleteCategory]: deleteCategoryStrategy,
    }),
    [
      createCategoryStrategy,
      createReceiptStrategy,
      deleteCategoryStrategy,
      deleteReceiptStrategy,
      updateCategoryStrategy,
      updateReceiptStrategy,
    ],
  );

  const handleCategoryTreeRequestList = useCallback(async () => {
    let completedRequestErrors: ServerError[] = [];
    while (true) {
      try {
        await Promise.all([
          waitUntilAppWillBeOnline(),
          waitUntilDBWillBeNotEmpty(requestTable),
        ]);

        const requestKeysList = await requestTable.toCollection().keys();
        // TODO remove counter
        setQueryQueueItemsCount(requestKeysList.length);

        for await (const requestKey of requestKeysList) {
          const request = await requestTable.get(requestKey);

          if (!request) {
            continue;
          }
          // @ts-ignore
          const errors = await REQUEST_TYPE_STRATEGY_MAP[request.requestType](
            request.id,
            // @ts-ignore
            request,
          );
          if (errors) {
            completedRequestErrors.push(...errors);
          }
        }

        if (await checkIfTableIsEmpty(requestTable)) {
          postProcessAllRequestQueueErrors(completedRequestErrors);
          completedRequestErrors = [];
        }
      } catch (error) {
        console.log('handleCategoryTreeRequestList - error', error);
      }
    }
  }, [
    REQUEST_TYPE_STRATEGY_MAP,
    postProcessAllRequestQueueErrors,
    setQueryQueueItemsCount,
  ]);

  useEffect(() => {
    handleCategoryTreeRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
