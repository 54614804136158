import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { useCustomMutation } from 'hooks/use-server-logic';

const mutationProfileEdit = loader('./mutationProfileEdit.graphql');

export const useLangEdit = () => {
  const [_mutation, { loading }] = useCustomMutation(mutationProfileEdit);

  const changeLocale = useCallback(
    ({ locale, recognizeLocale }) => {
      return (async () => {
        try {
          return await _mutation({
            variables: {
              locale,
              recognizeLocale,
            },
          });
        } catch (error) {
          console.warn(error);
        }
      })();
    },
    [_mutation],
  );

  return {
    changeLocale,
    loading,
  };
};
