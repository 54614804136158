import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import { useRecoilValue } from 'recoil';
import { currentUserAtom, currentUserIconSelector } from 'store/user-store';
import { useGetUser } from '../../../../../gql/api-user';

const mutationProfileEdit = loader('./mutationProfileEdit.graphql');

export const useProfileEdit = () => {
  const [_mutation, { loading: loadProfile }] =
    useCustomMutation(mutationProfileEdit);

  const { fetchUser } = useGetUser();
  const user = useRecoilValue(currentUserAtom);
  const userIcon = useRecoilValue(currentUserIconSelector);

  const changeProfile = ({
    currency,
    firstName,
    lastName,
    birthday,
    gender,
    customPicture,
  }: any) => {
    return (() => {
      try {
        return _mutation({
          variables: {
            currency,
            firstName,
            lastName,
            birthday,
            gender,
            ...(customPicture ? { customPicture } : {}),
          },
        }).then(() => fetchUser());
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  return {
    loading: loadProfile,
    data: user,
    userIcon,
    changeProfile,
  };
};
