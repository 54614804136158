import { FC, ReactNode, useCallback, memo } from 'react';
import c from 'classnames';
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail,
} from '@ionic/react';
import { StringsOption } from '../../core.types';
import { AppIonLabel } from '../app-ion-label';

import './app-ion-switch.scss';

type AppIonSwitchProps = {
  className?: string;
  options: StringsOption[];
  value: string;
  onChange: (event: CustomEvent<SegmentChangeEventDetail>) => void;
  label?: string;
};

export const AppIonSwitch: FC<AppIonSwitchProps> = memo(
  ({ className, options, value, onChange, label }) => {
    const renderAppSwitchOptions = useCallback(
      (): ReactNode[] =>
        options.map(({ value, label }: StringsOption, index) => (
          <IonSegmentButton
            className='app-ion-switch__button'
            key={index}
            value={value}
          >
            <IonLabel>{label}</IonLabel>
          </IonSegmentButton>
        )),
      [options],
    );

    const renderAppIonSwitchLabel = useCallback(
      (): ReactNode =>
        label && (
          <AppIonLabel className='app-ion-switch__label' label={label} />
        ),
      [label],
    );

    return (
      <div className={c('app-ion-switch', { [`${className}`]: className })}>
        {renderAppIonSwitchLabel()}
        <IonSegment
          className='app-ion-switch__switch'
          mode='ios'
          value={value}
          onIonChange={onChange}
        >
          {renderAppSwitchOptions()}
        </IonSegment>
      </div>
    );
  },
);
