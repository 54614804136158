import { CategoryTreeContext } from 'category-tree-provider';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { categoryToEditAtom, receiptTypeAtom } from 'store';
import { v4 as uuidv4 } from 'uuid';
import { DefaultInput } from '../../../components';
import { AppModal } from '../../../components/app-modal';
import {
  useCreateCategory,
  useDetectMobileResolution,
  useUpdateCategoryList,
} from '../../../hooks';

type CreateCategoryModalProps = {
  open: boolean;
  onClose(): void;
};

export const CreateCategoryModal = ({
  open,
  onClose,
}: CreateCategoryModalProps) => {
  const { activeSliceSelector } = useContext(CategoryTreeContext);
  const activeSlice = activeSliceSelector();
  const createCategory = useCreateCategory();
  const updateCategoryList = useUpdateCategoryList();
  const [categoryToEdit, setCategoryToEdit] =
    useRecoilState(categoryToEditAtom);
  const [folderName, setFolderName] = useState(categoryToEdit?.name || '');
  const intl = useIntl();
  const isMobile = useDetectMobileResolution();
  const isEditMode = categoryToEdit !== null;

  const onCloseHandler = useCallback(() => {
    setFolderName('');
    setCategoryToEdit(null);
    onClose();
  }, [onClose, setCategoryToEdit]);

  const onSaveClick = useRecoilCallback(({ snapshot }) => () => {
    if (!activeSlice) {
      return;
    }

    const receiptType = snapshot.getLoadable(receiptTypeAtom).contents;

    !isEditMode
      ? createCategory({
          id: uuidv4(),
          name: folderName,
          parent_id: activeSlice.id,
          type: receiptType,
        })
      : updateCategoryList([{ ...categoryToEdit, name: folderName }]);
    onCloseHandler();
  });

  const onFolderNameChange = useCallback(({ currentTarget: { value } }) => {
    setFolderName(value);
  }, []);

  const onKeyPress = useCallback(
    ({ charCode }: any) => {
      if (charCode === 13 && folderName !== '') {
        onSaveClick();
      }
    },
    [folderName, onSaveClick],
  );

  const createModalContent = useMemo(
    () => (
      <DefaultInput
        // @ts-ignore
        withIcon
        onKeyPress={onKeyPress}
        autofocus
        iconSize={{ width: 22, height: 22 }}
        iconName='folder-open'
        placeholder={intl.messages['name'] as string}
        value={folderName}
        onChange={onFolderNameChange}
        isMobile={isMobile}
      />
    ),
    [folderName, intl.messages, isMobile, onFolderNameChange, onKeyPress],
  );

  if (!activeSlice) {
    return null;
  }

  return (
    <AppModal
      content={createModalContent}
      saveDisabled={!folderName}
      onSaveClick={onSaveClick}
      saveEnabled
      // @ts-ignore
      title={
        !isEditMode ? (
          (intl.messages['new.folder'] as string)
        ) : (
          <FormattedMessage id='save' />
        )
      }
      {...{ open, onClose: onCloseHandler }}
    />
  );
};
