import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { Dictionary } from 'lodash';

export const getRequestHTTPHeaders = (headers = {} as Dictionary<string>) => {
  const token = localStorage.getItem('token');
  const accountId = localStorage.getItem('accountId');

  const extraHeaders: Dictionary<string> = {
    'X-APP-TYPE': process.env.REACT_APP_PROJECT_TYPE as string, // ireceipt || idocument
  };

  if (token) {
    extraHeaders['X-AUTH-TOKEN'] = token;
  }
  if (accountId) {
    extraHeaders['X-CURRENT-ACCOUNT-ID'] = accountId;
  }

  return {
    headers: {
      ...extraHeaders,
      ...headers,
      Accept: 'application/json',
    },
  };
};

const authLink = setContext((_, { headers }) => {
  return getRequestHTTPHeaders(headers);
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SINGLE_QL_LINK,
});

export const client = new ApolloClient({
  // @ts-ignore
  link: from([authLink, httpLink]),
  assumeImmutableResults: true,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
});
