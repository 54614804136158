import { AppForm, AppFormField } from 'components/app-form';
import { AppFormSubmitButton } from 'components/app-form/app-form-submit-button';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-phone-input-2/lib/style.css';
import { FORM_VALIDATION_RESOLVER } from 'scenes/common-auth-logic';

type OtpLoginPhoneFormProps = {
  onPhoneSubmit(values: { phone: string }, ...rest: any[]): void;
};

export const OtpLoginPhoneForm = memo(
  ({ onPhoneSubmit }: OtpLoginPhoneFormProps) => {
    return (
      <>
        <h1 className='auth-page__title'>
          <FormattedMessage id='otp.h1' />
        </h1>
        <p className='auth-page__title'>
          <FormattedMessage id='otp.p' />
        </p>
        <AppForm
          onSubmit={onPhoneSubmit}
          className='auth__form'
          formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
        >
          {/* @ts-ignore */}
          <AppFormField type={'phone' as const} name='phone' />
          <AppFormSubmitButton>
            <FormattedMessage id='btn.continue' />
          </AppFormSubmitButton>
        </AppForm>
      </>
    );
  },
);
