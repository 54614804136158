import React, { memo } from 'react';

export const SubscriptionIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M17.8125 3.125H2.1875C0.981328 3.125 0 4.10633 0 5.3125V14.6875C0 15.8937 0.981328 16.875 2.1875 16.875H17.8125C19.0187 16.875 20 15.8937 20 14.6875V5.3125C20 4.10633 19.0187 3.125 17.8125 3.125ZM2.1875 4.375H17.8125C18.3294 4.375 18.75 4.79559 18.75 5.3125V6.5625H1.25V5.3125C1.25 4.79559 1.67059 4.375 2.1875 4.375ZM17.8125 15.625H2.1875C1.67059 15.625 1.25 15.2044 1.25 14.6875V7.8125H18.75V14.6875C18.75 15.2044 18.3294 15.625 17.8125 15.625Z'
            fill={color}
          />
          <path
            d='M4.375 13.75H3.75C3.40484 13.75 3.125 13.4702 3.125 13.125V12.5C3.125 12.1548 3.40484 11.875 3.75 11.875H4.375C4.72016 11.875 5 12.1548 5 12.5V13.125C5 13.4702 4.72016 13.75 4.375 13.75Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
