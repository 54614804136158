import {
  useSyncLastUpdatedValuesFromServer,
  useSyncServerCategoryTree,
} from 'category-tree-hooks/category-service';
import { useGetCategory } from 'gql/api-category';
import { useEffect } from 'react';
import { useRecoilCallback } from 'recoil';
import {
  activeSliceIdAtom,
  lastCategoryUpdateAtom,
  lastReceiptUpdateAtom,
} from 'store/category-store';
import { getSnapshotValue, isApplicationOnlineAtom } from 'store/root-store';

export const useDetectOnlineApplication = () => {
  const { fetchCategoryTree } = useGetCategory();
  const syncServerCategoryTreeFromServer = useSyncServerCategoryTree();
  const syncLastUpdatedValues = useSyncLastUpdatedValuesFromServer();

  const onLineHandler = useRecoilCallback(({ snapshot, set }) => async () => {
    set(isApplicationOnlineAtom, true);
    const activeSliceId = getSnapshotValue(snapshot, activeSliceIdAtom);

    if (!activeSliceId) {
      const { categories, receipts, accountId } = await fetchCategoryTree({
        lastReceiptUpdatedAt: getSnapshotValue(snapshot, lastReceiptUpdateAtom),
        lastCategoryUpdatedAt: getSnapshotValue(
          snapshot,
          lastCategoryUpdateAtom,
        ),
      });
      syncLastUpdatedValues(receipts, categories);

      syncServerCategoryTreeFromServer({
        categories,
        receipts,
        accountId,
      });
    }
  });

  const offlineHandler = useRecoilCallback(({ set }) => () => {
    set(isApplicationOnlineAtom, false);
  });

  useEffect(() => {
    window.addEventListener('serverOnline', onLineHandler);
    window.addEventListener('serverOffline', offlineHandler);
  }, [offlineHandler, onLineHandler]);
};
