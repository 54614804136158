import { AppButton } from 'components/app-button';
import { memo } from 'react';
import { AppIcon } from '../../../components';
import { APP_CSS_THEME_VARIABLES } from '../../../color-theme.constants';

type HeaderControlItemProps = {
  onClick(): void;
  icon: string;
  isMobile: boolean;
};

export const HEADER_ICON_DESKTOP_SIZE = {
  width: 30,
  height: 30,
};

export const HEADER_ICON_MOBILE_SIZE = {
  width: 23,
  height: 23,
};

export const HeaderControlItem = memo(
  ({ onClick, icon, isMobile }: HeaderControlItemProps) => {
    return (
      <AppButton className='app-header-right-control' onPress={onClick}>
        <AppIcon
          color={`var(${APP_CSS_THEME_VARIABLES.icons})`}
          name={icon}
          className='app-header-icon'
          size={isMobile ? HEADER_ICON_MOBILE_SIZE : HEADER_ICON_DESKTOP_SIZE}
        />
      </AppButton>
    );
  },
);
