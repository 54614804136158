import { memo } from 'react';
import { Animate } from 'react-move';

export const AnimatedProgressProvider = memo(
  ({ valueStart = 0, duration, easingFunction, children }: any) => {
    return (
      <Animate
        start={() => ({
          value: valueStart,
        })}
        update={() => ({
          value: [valueStart],
          timing: {
            duration: duration * 1000,
            ease: easingFunction,
          },
        })}
      >
        {({ value }) => children(value)}
      </Animate>
    );
  },
);
