import React, { memo, useCallback } from 'react';
import Slider from '@material-ui/core/Slider';
import './app-slider.scss';

export const AppSlider = memo(
  ({ min, max, step = 1, value, onRangeChange, caption }) => {
    const onChangeRange = useCallback(
      (_, value) => {
        onRangeChange(value);
      },
      [onRangeChange],
    );

    const defaultProps = {
      classes: {
        root: 'app-slider-root',
        track: 'app-slider-track',
        valueLabel: 'app-slider-value-label',
      },
      defaultValue: [min, max],
      valueLabelDisplay: 'on',
    };
    return (
      <div>
        <Slider
          {...{
            value,
            min,
            max,
            step,
          }}
          onChange={onChangeRange}
          {...defaultProps}
        />
        {caption && <span className='app-slider-caption'>{caption}</span>}
      </div>
    );
  },
);
