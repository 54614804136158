import { LocalCategory } from 'core.types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { activeSliceIdAtom, receiptTypeAtom } from 'store';
import { v4 as uuidv4 } from 'uuid';
import { AppIcon } from '../../components';
import { useCreateCategory, useUpdateCategoryList } from '../../hooks';
import { MOBILE_FOLDER_ICON_SIZE } from './create-receipt-modal-tree-folder';
import './create-receipt-modal-tree.scss';

type CreateReceiptModalTreeFolderInputProps = {
  closeInput(): void;
  category?: LocalCategory;
  scrollDownListContainer?(): void;
};

export const CreateReceiptModalTreeFolderInput = ({
  category,
  closeInput,
}: CreateReceiptModalTreeFolderInputProps) => {
  const createCategory = useCreateCategory();
  const updateCategoryList = useUpdateCategoryList();
  const [folderName, setFolderName] = useState(category?.name);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const activeSliceId = useRecoilValue(activeSliceIdAtom);

  const folderIconSize = MOBILE_FOLDER_ICON_SIZE;

  const onInputChange = useCallback(({ target: { value } }) => {
    setFolderName(value);
  }, []);

  const onBlurInput = useRecoilCallback(({ snapshot }) => () => {
    const receiptType = snapshot.getLoadable(receiptTypeAtom).contents;

    if (folderName && activeSliceId) {
      category
        ? updateCategoryList([{ ...category, name: folderName }])
        : createCategory({
            id: uuidv4(),
            name: folderName,
            type: receiptType,
            parent_id: activeSliceId,
          });
    }
    closeInput();
  });

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className='tree-folder-edit-input-wrapper'>
      <AppIcon
        name='green-folder-non-empty'
        size={folderIconSize}
        id={category ? category.id : 'new-folder'}
      />
      <input
        type='text'
        ref={inputRef}
        className='tree-folder-edit-input'
        onChange={onInputChange}
        onBlur={onBlurInput}
      />
    </div>
  );
};
