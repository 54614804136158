import { APP_COLOR_THEME_TYPE } from 'color-theme.constants';
import { memo } from 'react';
import { AppPseudoRadio } from '../../../../components/app-pseudo-radio';

export type ThemePaletteProps = {
  label: string;
  themeType: APP_COLOR_THEME_TYPE;
  onThemeChange(themeType: APP_COLOR_THEME_TYPE): void;
  selectedAppColorType: APP_COLOR_THEME_TYPE;
};

const IRECEIPT_COLOR_TILES = ['#252F6F', '#141B50', '#A3ACE2'];
const LIGHT_COLOR_TILES = ['#E2E2E2', '#EAEAEA', '#59A4AE'];
const DARK_COLOR_TILES = ['#181818', '#101010', '#3079E8'];
const IDOCUMENT_COLOR_TILES = ['#043D36', '#0A4D45', '#59A4AE'];

export const COLOR_TILES_MAP = {
  [APP_COLOR_THEME_TYPE.DARK]: DARK_COLOR_TILES,
  [APP_COLOR_THEME_TYPE.LIGHT]: LIGHT_COLOR_TILES,
  [APP_COLOR_THEME_TYPE.IRECEIPT]: IRECEIPT_COLOR_TILES,
  [APP_COLOR_THEME_TYPE.IDOCUMENT]: IDOCUMENT_COLOR_TILES,
};

export const ThemePalette = memo(
  ({
    label,
    themeType,
    onThemeChange,
    selectedAppColorType,
  }: ThemePaletteProps) => {
    const colorTiles = COLOR_TILES_MAP[themeType];

    const onThemePaletteClick = () => {
      onThemeChange(themeType);
    };

    const renderColorTile = (color: string, index: number) => (
      <div
        className='theme-palette__tile'
        style={{ backgroundColor: color }}
        key={index}
      />
    );

    return (
      <div
        className='theme-palette'
        onClick={onThemePaletteClick}
        onTouchEnd={onThemePaletteClick}
      >
        <div className='theme-palette__header'>
          <span>{label}</span>
          <AppPseudoRadio isItemActive={selectedAppColorType === themeType} />
        </div>
        <div className='theme-palette__tiles'>
          {colorTiles.map(renderColorTile)}
        </div>
      </div>
    );
  },
);
