import { memo, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactCodeInput from 'react-verification-code-input';
import { hasError } from 'services/form-service';
import { AppFormFieldError } from '../app-form-field-error';
import '../app-form.scss';

type AppFormOtpCodeFieldProps = {
  name: string;
};

export const AppFormOtpCodeField = memo(
  ({ name }: AppFormOtpCodeFieldProps) => {
    const context = useFormContext();

    const {
      register,
      control,
      setValue,
      formState: { isDirty, errors },
    } = context;
    const { showError, error } = hasError({ errors: errors, name, isDirty });
    register(name);
    const value: string = useWatch({ name, control });

    const onChangeCodeField = useCallback(
      (value: string) => {
        setValue(name, value);
      },
      [name, setValue],
    );

    useEffect(() => {
      if (value === '') {
        Array.from(
          document.querySelectorAll('.app-form__control input[type=tel]'),
        ).forEach((item) => {
          // @ts-ignore
          item.value = '';
        });
      }
    }, [value]);

    return (
      <div className='app-form__control'>
        <ReactCodeInput
          fields={4}
          className='verification-field__group'
          onChange={onChangeCodeField}
          autoFocus
        />
        <AppFormFieldError {...{ name, showError, error }} />
      </div>
    );
  },
);
