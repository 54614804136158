import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { isRootSelector } from 'store';
import { CategoryHeaderUsersContent } from '../category-header-users-content';
import { CategoryHeaderBack } from '../category-header-back';

export const CategoryHeaderLeftContent = memo(() => {
  const isRoot = useRecoilValue(isRootSelector);
  return isRoot ? <CategoryHeaderUsersContent /> : <CategoryHeaderBack />;
});
