import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';

const createPdfMutation = loader('./createPdf.graphql');
const notifyPdfSavedMutation = loader('./notifyPdfSaved.graphql');

export const useGetServerPdfLink = () => {
  const [createPdfMethod] = useCustomMutation(createPdfMutation);
  const [notifyPdfSavedMethod] = useCustomMutation(notifyPdfSavedMutation);

  const getServerPDFLink = async (name) => {
    return createPdfMethod({ variables: { name } });
  };
  const notifyPdfSaved = async (id) => {
    return notifyPdfSavedMethod({ variables: { id } });
  };

  return {
    getServerPDFLink,
    notifyPdfSaved,
  };
};
