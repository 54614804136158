import { AppButton } from 'components/app-button';
import { memo } from 'react';
import { SettingsIcon } from '../../settings-icon';
import { APP_CSS_THEME_VARIABLES } from '../../../../color-theme.constants';

type PhoneItemProps = {
  id: number;
  phone: string;
  setSelectedPhoneId(id: number): void;
  deleteShown?: boolean | null;
  openDeleteModal(): void;
};

export const PhoneItem = memo(
  ({
    id,
    phone,
    setSelectedPhoneId,
    openDeleteModal,
    deleteShown = true,
  }: PhoneItemProps) => {
    const onDeleteItemClick = () => {
      setSelectedPhoneId(id);
      openDeleteModal();
    };
    const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;

    return (
      <div className='phone-edit__phone-item'>
        <span>
          <SettingsIcon name='phone' color={colorTextMain20} />
          <span className='phone-edit__phone-item-text'>{phone}</span>
        </span>
        {deleteShown && (
          <AppButton className='phone-edit__delete' onPress={onDeleteItemClick}>
            <SettingsIcon
              name='close'
              sizeType='small'
              color={colorTextMain20}
            />
          </AppButton>
        )}
      </div>
    );
  },
);
