import { FC, memo, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from '@ionic/react';
import c from 'classnames';
import { StringsOption } from '../../core.types';
import { AppIonLabel } from '../app-ion-label';

import './app-ion-select.scss';

type AppIonSelectProps = {
  className?: string;
  options: StringsOption[];
  value: string[];
  onChange: (event: CustomEvent<SelectChangeEventDetail>) => void;
  isMultiple?: boolean;
  placeholder?: string;
  cancelText: string;
  confirmText: string;
  interfaceOptions?: any;
  label?: string;
  disabled?: boolean;
};

export const AppIonSelect: FC<AppIonSelectProps> = memo(
  ({
    disabled = false,
    label,
    className,
    options,
    onChange,
    value,
    isMultiple = false,
    placeholder,
    interfaceOptions,
    cancelText,
    confirmText,
  }) => {
    const intl = useIntl();
    const renderAppIonSelectOptions = useCallback(
      (): ReactNode[] =>
        options.map(({ value, label }: StringsOption, index) => (
          <IonSelectOption
            className='app-ion-select__option'
            key={index}
            value={value}
          >
            <IonLabel>{label}</IonLabel>
          </IonSelectOption>
        )),
      [options],
    );

    const renderAppIonSelectLabel = useCallback(
      () =>
        label && (
          <AppIonLabel className='app-ion-select__label' label={label} />
        ),
      [label],
    );

    return (
      <div className={c('app-ion-select', { [`${className}`]: className })}>
        {renderAppIonSelectLabel()}
        <IonSelect
          className='app-ion-select__select'
          disabled={disabled}
          interfaceOptions={{
            ...interfaceOptions,
            cssClass: 'app-ion-select__alert',
          }}
          mode='ios'
          cancelText={cancelText}
          okText={confirmText}
          multiple={isMultiple}
          value={value}
          onIonChange={onChange}
          placeholder={
            placeholder ? placeholder : `${intl.messages.select as string}...`
          }
        >
          {renderAppIonSelectOptions()}
        </IonSelect>
      </div>
    );
  },
);
