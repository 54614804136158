import React, { memo } from 'react';

export const IntensityIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M13.5451 6.45487C12.6456 5.5554 11.3758 4.97339 10.0001 4.97339C8.62443 4.97339 7.35459 5.52894 6.45511 6.45487C5.55564 7.35434 4.97363 8.62418 4.97363 9.99984C4.97363 11.3755 5.55564 12.6453 6.45511 13.5448C7.35459 14.4443 8.62443 15.0263 10.0001 15.0263C11.3758 15.0263 12.6456 14.4707 13.5451 13.5448C14.4445 12.6453 15.0265 11.3755 15.0265 9.99984C15.0265 8.62418 14.471 7.35434 13.5451 6.45487ZM12.5927 12.5924C11.9313 13.2538 11.0054 13.6506 10.0001 13.6506C8.9948 13.6506 8.06887 13.2538 7.4075 12.5924C6.74612 11.9311 6.34929 11.0051 6.34929 9.99984C6.34929 8.99455 6.74612 8.06863 7.4075 7.40725C8.06887 6.74588 8.9948 6.34905 10.0001 6.34905C11.0054 6.34905 11.9313 6.74588 12.5927 7.40725C13.2541 8.06863 13.6509 8.99455 13.6509 9.99984C13.6509 11.0051 13.2541 11.9311 12.5927 12.5924Z'
            fill={color}
          />
          <path
            d='M19.3118 9.31226H17.2747C16.9044 9.31226 16.5869 9.62972 16.5869 10.0001C16.5869 10.3705 16.9044 10.6879 17.2747 10.6879H19.3118C19.6822 10.6879 19.9996 10.3705 19.9996 10.0001C19.9996 9.62972 19.6822 9.31226 19.3118 9.31226Z'
            fill={color}
          />
          <path
            d='M10.0003 16.5874C9.62996 16.5874 9.3125 16.9049 9.3125 17.2752V19.3123C9.3125 19.6826 9.62996 20.0001 10.0003 20.0001C10.3707 20.0001 10.6882 19.6826 10.6882 19.3123V17.2752C10.6882 16.9049 10.3707 16.5874 10.0003 16.5874Z'
            fill={color}
          />
          <path
            d='M17.0638 16.1114L15.6088 14.6564C15.3707 14.3919 14.921 14.3919 14.6564 14.6564C14.3919 14.921 14.3919 15.3443 14.6564 15.6088L16.1114 17.0638C16.376 17.3284 16.7993 17.3284 17.0638 17.0638C17.3284 16.7993 17.3284 16.376 17.0638 16.1114Z'
            fill={color}
          />
          <path
            d='M10.0003 0C9.62996 0 9.3125 0.31746 9.3125 0.68783V2.72486C9.3125 3.09523 9.62996 3.41269 10.0003 3.41269C10.3707 3.41269 10.6882 3.09523 10.6882 2.72486V0.68783C10.6882 0.31746 10.3707 0 10.0003 0Z'
            fill={color}
          />
          <path
            d='M17.0902 2.93621C16.8256 2.67166 16.4024 2.67166 16.1378 2.93621L14.6828 4.39123C14.4182 4.65578 14.4182 5.07906 14.6828 5.34361C14.9209 5.60816 15.3706 5.60816 15.6352 5.34361L17.0902 3.88859C17.3547 3.62404 17.3547 3.20076 17.0902 2.93621Z'
            fill={color}
          />
          <path
            d='M2.72486 9.31226H0.68783C0.31746 9.31226 0 9.62972 0 10.0001C0 10.3705 0.291005 10.6879 0.68783 10.6879H2.72486C3.09523 10.6879 3.41269 10.3705 3.41269 10.0001C3.41269 9.62972 3.09523 9.31226 2.72486 9.31226Z'
            fill={color}
          />
          <path
            d='M5.31773 14.6564C5.07964 14.3919 4.6299 14.3919 4.36535 14.6564L2.91033 16.1114C2.64578 16.376 2.64578 16.7993 2.91033 17.0638C3.17488 17.3284 3.59816 17.3284 3.86271 17.0638L5.31773 15.6088C5.58228 15.3443 5.58228 14.921 5.31773 14.6564Z'
            fill={color}
          />
          <path
            d='M5.31773 4.39123L3.86271 2.93621C3.59816 2.67166 3.17488 2.67166 2.91033 2.93621C2.64578 3.20076 2.64578 3.62404 2.91033 3.88859L4.36535 5.34361C4.6299 5.60816 5.05318 5.60816 5.31773 5.34361C5.58228 5.07906 5.58228 4.65578 5.31773 4.39123Z'
            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width={width} height={height} fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
