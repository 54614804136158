import c from 'classnames';
import { AppButton } from 'components/app-button';
import { memo } from 'react';
import { AppIcon } from '..';
import './share-modal.scss';

type ShareModalButtonProps = {
  onClick(): void;
  icon: string;
  label: string;
  type: string;
};

export const ShareModalButton = memo(
  ({ onClick, icon, label, type }: ShareModalButtonProps) => {
    return (
      <AppButton
        className={c('app-share-button', `app-share-button-${type}`)}
        onPress={onClick}
      >
        <AppIcon
          name={icon}
          size={{ width: 20, height: 20 }}
          className='app-share-button-icon'
        />
        {label}
      </AppButton>
    );
  },
);
