import { AppSubscriptionId } from 'core.types';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { getCurrency } from '../../../../currencySymbols';
import { addClickHandle } from '../../../../services';
import { SubscriptionPeriod } from './app-plan.types';
import { Promo } from '../Promo/Promo';

export type PlanTileProps = {
  onTabTileClick(id: AppSubscriptionId): void;
  id: AppSubscriptionId;
  price: number;
  currency: string;
  discount?: number;
  isYearlyPlan?: boolean;
  period: SubscriptionPeriod;
};

export const PlanTile = memo(
  ({
    price,
    currency,
    discount,
    onTabTileClick,
    id,
    isYearlyPlan,
    period,
  }: PlanTileProps) => {
    const onClick = useCallback(() => {
      onTabTileClick(id);
    }, [onTabTileClick, id]);

    return (
      <div className='plan__proposals-item' {...addClickHandle(onClick)}>
        <div className='plan__proposals-item-wrapper'>
          <div className='plan__proposals-text'>
            <span className='plan__proposals-period'>
              <FormattedMessage
                id={`menu.profile.plan.proposals.${
                  period === SubscriptionPeriod.monthly ? '1' : '2'
                }.period`}
              />
            </span>

            <span className='plan__proposals-price'>
              {`${price} ${getCurrency(currency)}`}
            </span>

            <span className='plan__proposals-per-period'>
              <FormattedMessage
                id={`menu.profile.plan.proposals.${
                  period === SubscriptionPeriod.monthly ? '1' : '2'
                }.per-period`}
              />
            </span>

            {discount && (
              <>
                <div className='plan__discount'>
                  <span>{`- ${discount}%`}</span>
                </div>

                <div className='plan__best-value'>
                  <div />
                  <span>
                    <FormattedMessage id='menu.profile.plan.proposals.best-value' />
                  </span>
                </div>
              </>
            )}

            <button className='plan__proposals-buy-button'>
              <FormattedMessage id='app.buy' />
            </button>

            <span className='trial-three-days'>
              <FormattedMessage id='app.trial-tree-days' />
            </span>

            <Promo appSubscriptionId={id} />
          </div>
        </div>

        {isYearlyPlan && (
          <div className='plan__info'>
            <span>*</span>
            <FormattedMessage id='menu.profile.plan.proposals.info-1' />
            <span>{(price / 12).toFixed(2)}</span>
            <span className='plan__info-price'>{getCurrency(currency)}</span>
            <FormattedMessage id='menu.profile.plan.proposals.info-2' />
          </div>
        )}
      </div>
    );
  },
);
