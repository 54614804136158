import React, { memo } from 'react';

export const BusinessAccountIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.009 4.53559H13.6995V2.79906C13.6995 1.97221 13.0268 1.29956 12.1999 1.29956H7.8C6.97315 1.29956 6.30046 1.97221 6.30046 2.79906V4.53559H1.99098C0.893118 4.53559 -1.52588e-05 5.42876 -1.52588e-05 6.52658V16.7099C-1.52588e-05 17.8077 0.893118 18.7009 1.99098 18.7009H18.009C19.1068 18.7009 20 17.8077 20 16.7099V6.52658C20 5.42876 19.1068 4.53559 18.009 4.53559ZM7.57058 2.79906C7.57058 2.67256 7.6735 2.56968 7.8 2.56968H12.1999C12.3264 2.56968 12.4293 2.6726 12.4293 2.79906V4.53559H7.57058V2.79906ZM1.27011 6.52658C1.27011 6.12908 1.59347 5.80571 1.99098 5.80571H18.009C18.4065 5.80571 18.7299 6.12908 18.7299 6.52658V7.46651C18.7299 8.34814 18.1387 9.13597 17.2922 9.38241L10.6501 11.3163C10.2248 11.4402 9.77519 11.4402 9.34982 11.3163L2.70772 9.38237C1.86127 9.13593 1.27011 8.34814 1.27011 7.46651V6.52658ZM18.009 17.4308H1.99098C1.59351 17.4308 1.27011 17.1074 1.27011 16.7099V10.0524C1.59014 10.2997 1.95494 10.4861 2.35265 10.6019C9.68994 12.7382 9.31283 12.6794 9.99996 12.6794C10.6962 12.6794 10.3014 12.7407 17.6473 10.6019C18.045 10.4861 18.4098 10.2997 18.7298 10.0524V16.7099C18.7299 17.1074 18.4065 17.4308 18.009 17.4308Z'
          fill={color}
        />
      </svg>
    );
  },
);
