import { ALL_PROFILES } from 'app.constants';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  currentUserPersonalAccountSelector,
  currentUserSharedAccountsSelector,
} from 'store/user-store';
import { HelperService } from '../../../services';
import GroupIcon from '../../images/group-icon.svg';

export const useCategoryMadeByModalLogic = () => {
  const intl = useIntl();
  const personalAccount = useRecoilValue(currentUserPersonalAccountSelector);
  const sharedAccounts = useRecoilValue(currentUserSharedAccountsSelector);
  const accountList = useMemo(() => {
    if (!personalAccount) {
      return [];
    }

    const list = [
      {
        ownerFullName: intl.messages['all.profiles'] as string,
        id: ALL_PROFILES.id,
        icon: HelperService.resolvePathSvg(GroupIcon),
      },
      personalAccount,
      ...sharedAccounts,
    ];
    return list;
  }, [intl.messages, personalAccount, sharedAccounts]);

  return accountList;
};
