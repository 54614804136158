import React, { memo } from 'react';

export const TileIcon = memo(
  ({ size = { width: 22, height: 22 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.79604 0H0.62323C0.280453 0 0 0.275766 0 0.612813V5.69916C0 6.0362 0.280453 6.31197 0.62323 6.31197H5.79604C6.13881 6.31197 6.41927 6.0362 6.41927 5.69916V0.612813C6.41927 0.275766 6.13881 0 5.79604 0ZM5.23513 5.14763H1.24646V1.22563H5.23513V5.14763Z'
          fill={color}
        />
        <path
          d='M13.5865 0H8.41364C8.07086 0 7.79041 0.275766 7.79041 0.612813V5.69916C7.79041 6.0362 8.07086 6.31197 8.41364 6.31197H13.5865C13.9292 6.31197 14.2097 6.0362 14.2097 5.69916V0.612813C14.2097 0.275766 13.9292 0 13.5865 0ZM13.0255 5.14763H9.03687V1.22563H13.0255V5.14763Z'
          fill={color}
        />
        <path
          d='M5.79604 7.72144H0.62323C0.280453 7.72144 0 7.9972 0 8.33425V13.4206C0 13.7576 0.280453 14.0334 0.62323 14.0334H5.79604C6.13881 14.0334 6.41927 13.7576 6.41927 13.4206V8.33425C6.41927 7.9972 6.13881 7.72144 5.79604 7.72144ZM5.23513 12.8078H1.24646V8.94706H5.23513V12.8078Z'
          fill={color}
        />
        <path
          d='M13.5865 7.72144H8.41364C8.07086 7.72144 7.79041 7.9972 7.79041 8.33425V13.4206C7.79041 13.7576 8.07086 14.0334 8.41364 14.0334H13.5865C13.9292 14.0334 14.2097 13.7576 14.2097 13.4206V8.33425C14.2097 7.9972 13.9292 7.72144 13.5865 7.72144ZM13.0255 12.8078H9.03687V8.94706H13.0255V12.8078Z'
          fill={color}
        />
        <path
          d='M21.3767 0H16.2039C15.8611 0 15.5807 0.275766 15.5807 0.612813V5.69916C15.5807 6.0362 15.8611 6.31197 16.2039 6.31197H21.3767C21.7195 6.31197 22 6.0362 22 5.69916V0.612813C22 0.275766 21.7195 0 21.3767 0ZM20.8158 5.14763H16.8272V1.22563H20.8158V5.14763Z'
          fill={color}
        />
        <path
          d='M21.3767 7.72144H16.2039C15.8611 7.72144 15.5807 7.9972 15.5807 8.33425V13.4206C15.5807 13.7576 15.8611 14.0334 16.2039 14.0334H21.3767C21.7195 14.0334 22 13.7576 22 13.4206V8.33425C22 7.9972 21.7195 7.72144 21.3767 7.72144ZM20.8158 12.8078H16.8272V8.94706H20.8158V12.8078Z'
          fill={color}
        />
        <path
          d='M5.79604 15.688H0.62323C0.280453 15.688 0 15.9638 0 16.3008V21.3872C0 21.7242 0.280453 22 0.62323 22H5.79604C6.13881 22 6.41927 21.7242 6.41927 21.3872V16.3008C6.41927 15.9638 6.13881 15.688 5.79604 15.688ZM5.23513 20.8356H1.24646V16.9136H5.23513V20.8356Z'
          fill={color}
        />
        <path
          d='M13.5865 15.688H8.41364C8.07086 15.688 7.79041 15.9638 7.79041 16.3008V21.3872C7.79041 21.7242 8.07086 22 8.41364 22H13.5865C13.9292 22 14.2097 21.7242 14.2097 21.3872V16.3008C14.2097 15.9638 13.9292 15.688 13.5865 15.688ZM13.0255 20.8356H9.03687V16.9136H13.0255V20.8356Z'
          fill={color}
        />
        <path
          d='M21.3767 15.688H16.2039C15.8611 15.688 15.5807 15.9638 15.5807 16.3008V21.3872C15.5807 21.7242 15.8611 22 16.2039 22H21.3767C21.7195 22 22 21.7242 22 21.3872V16.3008C22 15.9638 21.7195 15.688 21.3767 15.688ZM20.8158 20.8356H16.8272V16.9136H20.8158V20.8356Z'
          fill={color}
        />
      </svg>
    );
  },
);
