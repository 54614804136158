import { useMutation } from '@apollo/client';
import { useResetRecoilLastUpdatedBasedOnSharedAccounts } from 'gql/api-user';
import { loader } from 'graphql.macro';
import { useErrorMiddleware } from 'hooks/use-server-logic';
import { useRecoilCallback } from 'recoil';
import { categoryLoadingAtom } from 'store/category-store';
import { getSnapshotValue } from 'store/root-store';
import { getUserSharedAccount } from 'store/user-store';
import type {
  CreateCategoryPayload,
  LocalCategory,
  LocalReceipt,
  Maybe,
  ServerErrorResponse,
  UpdateCategoryPayload,
} from '../../core.types';
import { getCategoryList } from './get-category-list';

const mutationCreateOrUpdateCategory = loader(
  './mutation-create-or-update-category.graphql',
);
const mutationDeleteCategories = loader('./mutation-delete-categories.graphql');

const EMPTY_LISTS = {
  receipts: [] as LocalReceipt[],
  categories: [] as LocalCategory[],
  accountId: null,
};

type FetchCategoryTreeDTO = {
  lastCategoryUpdatedAt: Maybe<number>;
  lastReceiptUpdatedAt: Maybe<number>;
};

export const useGetCategory = () => {
  const errorMiddleware = useErrorMiddleware();
  const resetRecoilLastUpdatedBasedOnSharedAccounts =
    useResetRecoilLastUpdatedBasedOnSharedAccounts();

  const fetchCategoryTree = useRecoilCallback(
    ({ set, snapshot }) =>
      async (lastUpdatedDTO: FetchCategoryTreeDTO) => {
        try {
          const categoryLoading = getSnapshotValue(
            snapshot,
            categoryLoadingAtom,
          );
          //@ts-ignore
          await window.onlineDispatcher.waitForReady();
          //@ts-ignore
          if (!window.onlineDispatcher.isOnline || categoryLoading) {
            return EMPTY_LISTS;
          }
          set(categoryLoadingAtom, true);
          let data: any = null;
          try {
            data = (await getCategoryList(lastUpdatedDTO))['data'];
          } catch (e) {
            console.log('Error on fetch Category List: ');
            console.log(e);
            return EMPTY_LISTS;
          } finally {
            set(categoryLoadingAtom, false);
          }

          const receipts: LocalReceipt[] = data?.user?.currentAccount?.receipts;
          const categories: LocalCategory[] =
            data?.user?.currentAccount?.categories;
          const accountId = data?.user?.currentAccount?.id;
          const newCurrentSharedAccount = getUserSharedAccount(data);
          resetRecoilLastUpdatedBasedOnSharedAccounts(newCurrentSharedAccount);

          return { receipts, categories, accountId };
        } catch (errorResponse) {
          set(categoryLoadingAtom, false);
          await errorMiddleware(
            (errorResponse as any)?.response as ServerErrorResponse,
          );
          return EMPTY_LISTS;
        }
      },
  );

  return {
    fetchCategoryTree,
  } as const;
};

export const useCreateOrUpdateCategoryRequest = () => {
  const [createOrUpdateCategoryRequest] = useMutation(
    mutationCreateOrUpdateCategory,
  );

  return {
    createOrUpdateCategoryRequest,
  };
};

export const useCreateCategory = () => {
  const { createOrUpdateCategoryRequest } = useCreateOrUpdateCategoryRequest();

  return {
    createCategory: (variables: CreateCategoryPayload) => {
      return createOrUpdateCategoryRequest({
        variables: variables,
      });
    },
  };
};

export const useUpdateCategory = () => {
  const { createOrUpdateCategoryRequest } = useCreateOrUpdateCategoryRequest();

  return {
    updateCategory: (variables: UpdateCategoryPayload) => {
      return createOrUpdateCategoryRequest({
        variables,
      });
    },
  };
};

export const useDeleteCategoryList = () => {
  const [deleteCategories] = useMutation(mutationDeleteCategories);

  return {
    deleteCategories,
  };
};
