import { memo, useCallback, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { detectDesktopApplication } from '../../services';

export const CategoryDndProvider = memo(({ children }: { children: any }) => {
  const isNativeApp = !!window.cordova;

  const getDropTargetElementsAtPoint = useCallback(
    (x: number, y: number, dropTargets: any) => {
      return dropTargets.filter((target: any) => {
        const rect = target.getBoundingClientRect();
        return (
          x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
        );
      });
    },
    [],
  );

  const DropContainerOptions = useMemo(
    () => ({
      delay: 150,
      enableMouseEvents: detectDesktopApplication(),
      getDropTargetElementsAtPoint:
        !isNativeApp && getDropTargetElementsAtPoint,
    }),
    [getDropTargetElementsAtPoint, isNativeApp],
  );

  return (
    // @ts-ignore
    <DndProvider backend={TouchBackend} options={DropContainerOptions}>
      {children}
    </DndProvider>
  );
});
