import { useMemo } from 'react';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { HelperService } from '../../services';

type SettingsDTO = {
  isApplicationOnline: boolean;
  recognitionShown: boolean;
};

export const useSettingsMenu = ({
  isApplicationOnline,
  recognitionShown,
}: SettingsDTO) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;
  const colorTextMain = `var(${APP_CSS_THEME_VARIABLES.textMain})`;

  const menuRoute = useMemo(
    () =>
      [
        {
          id: 'invite',
          to: '/invite',
          iconName: 'invite-friend',
          iconColor: colorTextMain,
          hidden: true,
        },
        {
          id: 'plan',
          to: '/plan',
          iconName: 'subscription',
          iconColor: colorTextMain,
          hidden:
            !window.cordova ||
            !isApplicationOnline ||
            HelperService.parceBoolEnv(process.env.REACT_APP_SHOW_SUBSCRIPTION),
        },
        {
          id: 'password',
          to: '/password',
          iconName: 'lock',
          iconColor: colorTextMain,
          hidden: !isApplicationOnline,
        },
        {
          id: 'email',
          to: '/email',
          iconName: 'mail',
          iconColor: colorTextMain,
          hidden: !isApplicationOnline,
        },
        {
          id: 'phone',
          to: '/phone',
          iconName: 'phone',
          iconColor: colorTextMain,
          hidden: !isApplicationOnline,
        },
        {
          id: 'lang',
          to: '/language',
          iconName: 'language',
          iconColor: colorTextMain,
        },
        {
          id: 'theme',
          to: '/theme',
          iconName: 'theme',
          iconColor: colorTextMain,
        },
        {
          id: 'quality',
          to: '/quality',
          iconName: 'quality',
          iconColor: colorTextMain,
          hidden: true,
        },
        {
          id: 'recognition',
          to: '/recognition',
          iconName: 'quality',
          hidden: !recognitionShown,
          iconColor: colorTextMain,
        },
        {
          id: 'terms',
          to: '/terms',
          iconName: 'terms',
          iconColor: colorTextMain,
        },
        { type: 'line' },
        {
          id: 'account',
          to: '/account',
          iconName: 'change-account',
          iconColor: colorEmphasized,
          hidden: !isApplicationOnline,
        },
      ].filter(({ hidden }) => !hidden),
    [colorEmphasized, isApplicationOnline, colorTextMain],
  );

  return { menuRoute };
};
