import { CategoryTreeContext } from 'category-tree-provider/category-tree-provider';
import { memo, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBackButton } from '../../../components/app-back-button';

export const CategoryHeaderBack = memo(() => {
  const { activeSliceSelector } = useContext(CategoryTreeContext);
  const activeSlice = activeSliceSelector();
  const { goBack } = useHistory();

  const onBackClick = useCallback(() => {
    if (activeSlice) {
      goBack();
    }
  }, [activeSlice, goBack]);
  // @ts-ignore
  return <AppBackButton onBackClick={onBackClick} label={activeSlice?.name} />;
});
