import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Tappable from 'react-tappable';
import { useGetUser } from '../../gql/api-user';
import {
  AppWrap,
  SettingsMenu,
  PrivateRoute,
  ProfileInfo,
} from '../../components';
import { LogoHeader } from './components/LogoHeader';
import {
  Account,
  Invite,
  SettingsLanguage,
  LogOut,
  Manage,
  ManageAccount,
  Password,
  Phone,
  Plan,
  Profile,
  Recognition,
  SettingsEmail,
  Terms,
} from './components';
import { unlockBackSwipe } from '../../services';
import { useProfileEdit } from './components/Profile/gql';
import { SettingsIcon } from './settings-icon';
import { useSettingsMenu } from './use-settings-menu';
import { AppBackButton } from '../../components/app-back-button';
import { useRecoilValue } from 'recoil';
import { isApplicationOnlineAtom } from '../../store';
import { AppTheme } from './components/app-theme';
import { APP_CSS_THEME_VARIABLES } from '../../color-theme.constants';

import './Settings.scss';
import { AppType } from 'core.types';
import { hasAppAccess } from 'services/app-service';

const MARGIN_WIDTH = 5;

export const Settings = () => {
  const isDesktop = window.matchMedia('(min-width: 1022px)').matches;
  const isMobile = !isDesktop;
  const isApplicationOnline = useRecoilValue(isApplicationOnlineAtom);
  const recognitionShown = !hasAppAccess(AppType.idocument) || isMobile;
  const { menuRoute } = useSettingsMenu({
    isApplicationOnline,
    recognitionShown,
  });
  const editProfileInfoRef = useRef();
  const match = useRouteMatch();
  const history = useHistory();
  const intl = useIntl();
  const { fetchUser } = useGetUser();
  const { data, userIcon: profileUserIcon } = useProfileEdit();

  const pathArray = history.location.pathname.split('/');
  const isSettingsTab = pathArray.length === 2;
  const [editProfileInfoWidth, setEditProfileInfoWidth] = useState(0);
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  useEffect(() => {
    if (isDesktop) return;
    const main = document.querySelector('.main-section');
    if (main) main.scrollTop = 0;
  }, [history.location.pathname, isDesktop]);

  const showMenu = () => {
    const arrPath = history.location.pathname
      .split('/')
      .filter((el) => el !== '');
    return arrPath.length !== 1;
  };
  useEffect(() => {
    fetchUser();
    window.newPoly.reset();
  }, [fetchUser]);

  useLayoutEffect(() => {
    editProfileInfoRef.current &&
      isSettingsTab &&
      setEditProfileInfoWidth(
        // @ts-ignore
        editProfileInfoRef.current.clientWidth + MARGIN_WIDTH,
      );
  }, [history.location.pathname, isSettingsTab]);

  const accountSettingsIconPropsMap = {
    family: {
      name: 'family-account',
      color: colorEmphasized,
    },
    personal: {
      name: 'personal-account',
      color: colorEmphasized,
    },
    business: {
      name: 'business-account',
      color: colorEmphasized,
    },
  };

  const getRoute = () => (
    <Switch>
      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/profile`}
          component={Profile}
          mobile
        />
      )}
      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/manage/:id`}
          component={ManageAccount}
          mobile
        />
      )}
      {isApplicationOnline && (
        <PrivateRoute path={`${match.path}/manage`} component={Manage} mobile />
      )}

      {isApplicationOnline && (
        <PrivateRoute path={`${match.path}/invite`} component={Invite} mobile />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/plan`}
          component={Plan}
          mobile={isMobile}
        />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/password`}
          component={Password}
          mobile={isMobile}
        />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/email`}
          component={SettingsEmail}
          mobile={isMobile}
        />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/phone`}
          component={Phone}
          mobile={isMobile}
        />
      )}

      <PrivateRoute
        path={`${match.path}/language`}
        component={SettingsLanguage}
        mobile={isMobile}
      />

      <PrivateRoute
        path={`${match.path}/theme`}
        component={AppTheme}
        mobile={isMobile}
      />

      {recognitionShown && (
        <PrivateRoute
          path={`${match.path}/recognition`}
          component={Recognition}
          mobile={isMobile}
        />
      )}

      <PrivateRoute
        path={`${match.path}/terms`}
        component={Terms}
        mobile={isMobile}
      />

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/account`}
          component={Account}
          mobile={isMobile}
        />
      )}
      {!isMobile && isSettingsTab && <Redirect to={`${match.path}/profile`} />}
    </Switch>
  );

  const withMenu = () => {
    const isProfileActive = pathArray.includes('profile') && isDesktop;
    const isManageActive = pathArray.includes('manage') && isDesktop;
    const accountType = data?.user.account.accountType;

    return (
      <div className='profile-menu'>
        <ul className='menu-list'>
          <Tappable
            onTap={async () => {
              await unlockBackSwipe();
              history.push('/settings/profile');
            }}
            component='li'
            className={cx('menu-item menu-item--profile', {
              'menu-item--active': isProfileActive,
            })}
          >
            {data?.user && (
              <ProfileInfo
                containerStyles={
                  editProfileInfoWidth
                    ? {
                        width: `calc(100% - ${editProfileInfoWidth}px)`,
                      }
                    : null
                }
                userIcon={profileUserIcon}
                ownerFullName={data.user.account.ownerFullName}
                userCurrency={data.user.currency}
              />
            )}
            {isApplicationOnline && (
              // @ts-ignore
              <div ref={editProfileInfoRef} className='edit-profile-info'>
                <SettingsIcon
                  name='edit'
                  color={colorEmphasized}
                  sizeType='small'
                />
                <span className='edit-profile'>
                  {intl.messages[`menu.profile`] as string}
                </span>
              </div>
            )}
          </Tappable>

          <li
            className={cx(
              'menu-line',
              { 'menu-line--margin-top': !isDesktop },
              { 'menu-line--active': isProfileActive || isManageActive },
            )}
          />
          {isApplicationOnline && (
            <Tappable
              component='li'
              onTap={async () => {
                await unlockBackSwipe();
                history.push('/settings/manage');
              }}
              className={cx('menu-item menu-item--account-type', {
                'menu-item--active': isManageActive,
              })}
            >
              <div>
                <SettingsIcon
                  // @ts-ignore
                  {...accountSettingsIconPropsMap[accountType || 'personal']}
                />

                <span className='profile-type'>
                  <FormattedMessage
                    id={`menu.settings.account.${accountType || 'personal'}`}
                  />
                </span>
              </div>
              <div>
                <SettingsIcon
                  name='gear'
                  color={colorEmphasized}
                  sizeType='small'
                />
                <span className='profile-manage'>
                  {intl.messages[`menu.profile.manage`] as string}
                </span>
              </div>
            </Tappable>
          )}

          <li
            className={cx(
              'menu-line',
              { 'menu-line--desktop-margin-bottom': isDesktop },
              { 'menu-line--active': isManageActive },
            )}
          />

          <SettingsMenu
            isDesktop={isDesktop}
            location={history.location}
            menuRoute={menuRoute}
            path={match.path}
          />

          {isApplicationOnline && <LogOut />}
        </ul>
        {getRouteWrapper()}
      </div>
    );
  };

  const onBackClick = useCallback(() => history.goBack(), [history]);
  const withoutMenu = () => <>{getRouteWrapper()}</>;
  const getRouteWrapper = () => (isMobile ? getRoute() : getRoute());
  const isShowMenu = isMobile && showMenu();

  return (
    <AppWrap>
      <header
        className={cx(`app-settings-header`, {
          'app-settings-header--settings-tab': isSettingsTab,
        })}
      >
        {!isSettingsTab && !isDesktop && (
          <AppBackButton
            onBackClick={onBackClick}
            label={intl.messages['back'] as string}
          />
        )}
        <LogoHeader
          customName={intl.messages[`menu${history.location.pathname}`]}
          className='app-settings-title'
        />
      </header>
      <main
        className={cx('main-section', 'main-section-settings', {
          'main-section__withoutMenu': isShowMenu,
          'main-section--desktop': isDesktop,
        })}
      >
        {isShowMenu ? withoutMenu() : withMenu()}
      </main>
    </AppWrap>
  );
};
