import { AppForm, AppFormField } from 'components/app-form';
import { AppFormSubmitButton } from 'components/app-form/app-form-submit-button';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FORM_VALIDATION_RESOLVER } from 'scenes/common-auth-logic';

type OtpLoginVerifyProps = {
  phone: string;
  countdown: string;
  onCodeSubmit(...rest: any[]): void;
};

export const OtpLoginVerify = memo(
  ({ phone, countdown, onCodeSubmit }: OtpLoginVerifyProps) => {
    return (
      <>
        <h2 className='auth-page__title'>
          <FormattedMessage id='verify.h1' />
        </h2>
        <p>
          <FormattedMessage id='verify.p' />
        </p>
        <p className='auth-page__title'> {phone} </p>

        <AppForm
          onSubmit={onCodeSubmit}
          className='auth__form'
          formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
        >
          {/* @ts-ignore */}
          <AppFormField type={'otpCode' as const} name='otpCode' />
          <p className='auth-page__title'>
            <FormattedMessage id='verify.receive' />
            <span>
              {' '}
              <FormattedMessage id='verify.reSend' /> {countdown}{' '}
            </span>
          </p>
          <AppFormSubmitButton>
            <FormattedMessage id='btn.verify' />
          </AppFormSubmitButton>
        </AppForm>
      </>
    );
  },
);
