import React, { memo } from 'react';

export const MailIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M17.8301 3H2.16992C1.25028 3 0.5 3.72498 0.5 4.61538V15.3846C0.5 16.272 1.24694 17 2.16992 17H17.8301C18.7474 17 19.5 16.2775 19.5 15.3846V4.61538C19.5 3.728 18.7531 3 17.8301 3ZM17.5995 4.07692L10.0354 11.394L2.4059 4.07692H17.5995ZM1.61328 15.1616V4.83325L6.97481 9.9752L1.61328 15.1616ZM2.40048 15.9231L7.76539 10.7334L9.64523 12.5362C9.86284 12.7449 10.214 12.7443 10.4307 12.5346L12.2637 10.7615L17.5995 15.9231H2.40048ZM18.3867 15.1616L13.0509 10L18.3867 4.83838V15.1616Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
