import { FormattedMessage } from 'react-intl';
import { SettingsIcon } from '../../settings-icon';
import { addClickHandle } from '../../../../services/HelperService';

export const ComingSoon = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <SettingsIcon
        className='coming-soon-icon'
        name='coming-soon'
        sizeType='custom'
      />

      <div className={'coming-soon-text'}>
        <FormattedMessage id='menu.profile.plan.coming.soon' />
      </div>

      <div {...addClickHandle(onClick)} className={'coming-soon-notify-button'}>
        <SettingsIcon name='notify' sizeType='custom' />
        <span className='coming-soon-notify-button__text'>
          <FormattedMessage id={'menu.profile.plan.notify'} />
        </span>
      </div>
      <div className='plan__coming-soon'>
        <span className='coming-soon-bottom-message__text'>
          <FormattedMessage id='menu.profile.plan.soon' />
        </span>
      </div>
    </>
  );
};
