import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { filterMadeByAtom } from 'store';
import { AppPseudoRadio } from '../../../components/app-pseudo-radio';
import { getCurrency } from '../../../currencySymbols';
import './made-by-filter-modal.scss';

type MadeByModalAccountItemProps = {
  id: number | string;
  ownerFullName: string;
  ownerPictureUrl: string;
  icon: string;
  ownerCurrency?: string;
};

export const MadeByModalAccountItem = memo(
  ({
    ownerFullName,
    ownerCurrency = 'USD',
    ownerPictureUrl,
    id,
    icon,
  }: MadeByModalAccountItemProps) => {
    const intl = useIntl();
    const [filterMadeBy, setMadeByFilter] = useRecoilState(filterMadeByAtom);
    const isItemActive = filterMadeBy.id === id;

    const onItemClick = useCallback(() => {
      setMadeByFilter({ id, name: ownerFullName });
    }, [id, ownerFullName, setMadeByFilter]);

    const image = useMemo(
      () =>
        icon ? (
          <div className='made-by-modal__account-item-icon'>
            <img src={icon} alt='' />
          </div>
        ) : (
          <img
            src={ownerPictureUrl}
            alt=''
            className='made-by-modal__account-item-image'
          />
        ),
      [icon, ownerPictureUrl],
    );

    return (
      <div className='made-by-modal__account-item' onClick={onItemClick}>
        <div className='made-by-modal__account-item-info'>
          {image}
          <div className='made-by-modal__account-item-description'>
            <div className='made-by-modal__account-item-name'>
              {ownerFullName}
            </div>
            <div className='made-by-modal__account-item-currency-row'>
              {intl.messages['menu.profile.info.currency'] as string}{' '}
              <span className='made-by-modal__account-item-currency-sign'>
                {getCurrency(ownerCurrency)} ({ownerCurrency})
              </span>
            </div>
          </div>
        </div>
        <AppPseudoRadio {...{ isItemActive }} />
      </div>
    );
  },
);
