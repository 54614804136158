// @ts-nocheck
import { toast } from 'react-toastify';
import { NAVIGATOR_LANG_MAP } from '../../app.constants';
import { IMAGE_PREFIX } from '../../core.types';
import { SwipeBack } from 'capacitor-plugin-ios-swipe-back';
import CurrencyList from '../../currencies.json';

export const createObjectURL = (file) => {
  const blob = new Blob([file], { type: file.type });
  if (window.webkitURL) {
    return window.webkitURL.createObjectURL(blob);
  } else if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(blob);
  } else {
    return null;
  }
};

export class HelperService {
  static userAgent = (function () {
    const ua = window.navigator.userAgent;
    // @ts-ignore
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      // @ts-ignore
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
      if (tem != null)
        return tem
          .slice(1)
          .join(' ')
          .replace('OPR', 'Opera')
          .replace('Edg ', 'Edge ');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

    return M[0];
  })();

  static pathForCordova = (() => {
    return HelperService.userAgent === 'Chrome'
      ? './android_asset/www/'
      : `${window.origin}/`;
  })();

  static resolvePathSvg(iconPath) {
    if (!iconPath) {
      return iconPath;
    }
    return window.cordova
      ? iconPath.replace('./', HelperService.pathForCordova)
      : iconPath;
  }

  static parceBoolEnv(env: any) {
    return env === 'true';
  }

  static getTempPath(path) {
    if (!path) {
      return null;
    }
    return this.parceBoolEnv(process.env.REACT_APP_EMULATE_VISIONKIT)
      ? path
      : window.Ionic.WebView.convertFileSrc(
          window.cordova.file.tempDirectory + path,
        );
  }

  static resolveFilePath(path) {
    if (!path) {
      return null;
    }

    if (
      path.includes('ireceipt-file-bucket') &&
      !path.includes('https://storage.googleapis.com')
    ) {
      return `https://storage.googleapis.com/${path}`;
    }

    if (
      path.includes('http') ||
      path.includes('/var/mobile') ||
      path.includes('blob:app') ||
      path.includes('capacitor') ||
      path.includes('https://storage.googleapis.com')
    ) {
      return path;
    }

    if (path.includes(IMAGE_PREFIX.local_stub) && !path.includes('http')) {
      return `https://localhost:3000/${path}`;
    }

    if (path.includes(IMAGE_PREFIX.rl_scan)) {
      return window.Ionic.WebView.convertFileSrc(
        window.cordova.file.documentsDirectory + path,
      );
    }

    const prefixPath = path.startsWith('/') ? path : `/${path}`;
    const finalPath = `${process.env.REACT_APP_WEBSITE_URL}${prefixPath}`;

    return finalPath;
  }

  static isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
  }

  static validationError(error) {
    //TODO: Get rid return sting, and add code and type
    if (error && error.graphQLErrors[0]) {
      const errorObj = error.graphQLErrors[0];
      if (errorObj && errorObj.field) {
        return { [errorObj.field]: errorObj.message };
      } else {
        return errorObj.message;
      }
    }

    return '';
  }

  static unixTimeToString(date) {
    return new Date(date * 1000).toLocaleDateString().replace(/\//gi, '.');
  }

  static getBlob(canvas) {
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve({ url: createObjectURL(file), file });
      }, 'image/jpeg');
    });
  }

  static getImageWidthAndHeight(image, max_size) {
    let width = image.width;
    let height = image.height;

    if (max_size && width > height) {
      if (width > max_size) {
        height *= max_size / width;
        width = max_size;
      }
    } else {
      if (max_size && height > max_size) {
        width *= max_size / height;
        height = max_size;
      }
    }

    return { width, height };
  }

  static HTMLImageElementToCanvas(image, max_size) {
    const canvas = document.createElement('canvas');

    const { width, height } = HelperService.getImageWidthAndHeight(
      image,
      max_size,
    );

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    return canvas;
  }

  static async urlToCanvas(url) {
    const image = await HelperService.createImage(url);

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  static createImage(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });
  }

  static daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  static getMonthPeriod() {
    const date = new Date();
    const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);

    const dateTo = new Date(
      date.getFullYear(),
      date.getMonth(),
      HelperService.daysInMonth(date.getMonth() + 1, date.getFullYear()),
    );

    return {
      dateFrom,
      dateTo,
    };
  }

  static getPrevMonthPeriod(prevMonthCounter) {
    const date = new Date();
    const dateFrom = new Date(
      date.getFullYear(),
      date.getMonth() - prevMonthCounter,
      1,
    );

    const dateTo = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      HelperService.daysInMonth(date.getMonth(), date.getFullYear()),
    );

    return {
      dateFrom,
      dateTo,
    };
  }
}

export const isFileUrlPdf = (url) => String(url).includes('pdf');

export const detectDesktopApplication = () =>
  !(
    window.cordova ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  );

export const copyToClipboard = async ({ link, message }) => {
  await navigator.clipboard.writeText(link);
  toast(message);
};

export const getArraySum = (array) => array.reduce((a, b) => a + b, 0);

export const detectAndroid = () =>
  navigator.userAgent.match(/Android/i) !== 'Android' && window.cordova;

export const getBase64FromBlob = (blob: Blob | File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(this.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getBase64FromUrl = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();

  return getBase64FromBlob(blob);
};

export const getBlobFromUrl = async (url) => {
  const response = await fetch(url);
  return response.blob();
};

export function getBase64FromImageOrPdfFiles(url: string) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    image.onload = async () => {
      const canvas = HelperService.HTMLImageElementToCanvas(image);

      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const base64String = canvas.toDataURL();

      resolve(base64String);
    };
  });
}

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const addClickHandle = (onClick) =>
  window.cordova ? { onTouchEnd: onClick } : { onClick };

export const detectUserLocale = () => {
  return NAVIGATOR_LANG_MAP[navigator.language] || 'en';
};

export const lockScreenOrientation = (orientation = 'portrait') =>
  window.cordova && window.screen?.orientation?.lock(orientation);

export const detectLocalImagePath = (imagePath?: string) =>
  !!imagePath &&
  (imagePath.includes(IMAGE_PREFIX.local_stub) ||
    imagePath.includes(IMAGE_PREFIX.rl_scan));

export const getLocalImage = (src: string) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const image = new Image();
    image.src = src;
    image.onload = function () {
      resolve(image);
    };
  });
};

export const preloadLocalImage = (src: string) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const img = document.createElement('img');
    img.style.cssText = 'position:absolute;opacity:0.01;top:0';
    img.src = src;
    document.body.appendChild(img);
    img.onload = () => {
      const resolution = img.height * img.width;
      const timeout = resolution / 70000;
      setTimeout(() => {
        resolve({ width: img.width, height: img.height });
        img.remove();
      }, timeout);
    };
  });
};

export const getUnixTimestampFromDate = (date: Date) =>
  Math.floor(date.getTime() / 1000);

export const getDateFromUnixTimestamp = (stamp: number) =>
  new Date(stamp * 1000);

export const unlockBackSwipe = async () => {
  if (window?.cordova) {
    await SwipeBack.enable({ error: 0 });
  }
};

export const currencyFormatter = (
  currency: string | null = null,
  amount: number,
) => {
  const currencyItem = CurrencyList.find(
    ({ abbreviation }) => abbreviation === currency,
  );

  return `${currencyItem?.symbol} ${amount.toFixed(2)}`;
};

export const isMobileApp = () => Capacitor.getPlatform() !== 'web';
