import React, { memo } from 'react';

export const InviteFriendIcon = memo(
  ({ size = { width: 21, height: 21 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M3.55069 4.82607C3.55069 2.71637 5.26706 1 7.37674 1C9.48643 1 11.2028 2.71634 11.2028 4.82604L10.2463 4.9911C10.2463 3.40883 8.959 1.95652 7.37674 1.95652C5.79449 1.95652 4.5072 3.2438 4.5072 4.82607C4.5072 6.40833 5.79449 7.69562 7.37674 7.69562V8.65211C5.26706 8.65211 3.55069 6.93577 3.55069 4.82607Z'
            fill={color}
          />
          <path
            d='M7.37676 8.63837C4.8264 8.63837 2.68004 10.4128 2.09234 12.7993C1.96104 13.3325 2.16493 13.8828 2.63413 14.1681C3.34211 14.5985 4.64586 15.154 6.74643 15.2484L6.66575 16.1892C4.37306 16.1086 2.90225 15.6456 2.03537 15.2379C1.32282 14.9028 0.973828 14.1423 1.06908 13.3606C1.17222 12.5142 1.40336 11.7002 1.84397 10.9287C2.94522 9.00048 5.01284 7.69916 7.37676 7.69916C7.668 7.69916 7.95475 7.71891 8.23574 7.75716L8.12696 8.69021C7.88168 8.65604 7.63123 8.63837 7.37676 8.63837Z'
            fill={color}
          />
          <path
            d='M12.1281 4.80078C10.0184 4.80078 8.302 6.51715 8.302 8.62685C8.302 10.7365 10.0184 12.4529 12.1281 12.4529C14.2377 12.4529 15.9541 10.7365 15.9541 8.62682C15.9541 6.51712 14.2377 4.80078 12.1281 4.80078ZM12.1281 11.4964C10.5458 11.4964 9.25852 10.2091 9.25852 8.62685C9.25852 7.04459 10.5458 5.7573 12.1281 5.7573C13.7103 5.7573 14.9976 7.04459 14.9976 8.62685C14.9976 10.2091 13.7103 11.4964 12.1281 11.4964Z'
            fill={color}
          />
          <path
            d='M12.128 11.6025C9.76413 11.6025 7.69651 12.9039 6.59525 14.8321C6.15464 15.6036 5.9235 16.4176 5.82036 17.264C5.72511 18.0457 6.07481 18.8066 6.7874 19.1416C7.74091 19.5899 9.42457 20.1049 12.128 20.1049C14.8315 20.1049 16.5152 19.5899 17.4687 19.1416C18.1813 18.8066 18.531 18.0457 18.4357 17.264C18.3326 16.4176 18.1014 15.6036 17.6608 14.8321C16.5596 12.9039 14.4919 11.6025 12.128 11.6025ZM7.38544 18.0715C6.91623 17.7862 6.71232 17.2359 6.84362 16.7027C7.43132 14.3162 9.57768 12.5418 12.128 12.5418C14.6784 12.5418 16.8248 14.3162 17.4125 16.7027C17.5438 17.2359 17.3398 17.7862 16.8706 18.0715C16.0944 18.5434 14.6018 19.1657 12.128 19.1657C9.65425 19.1657 8.16172 18.5434 7.38544 18.0715Z'
            fill={color}
          />
          <path
            d='M18.386 2.59067V0H17.0907V2.59067H14.5V3.88601H17.0907V6.47668H18.386V3.88601H20.9767V2.59067H18.386Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
