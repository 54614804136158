import React, { memo } from 'react';

export const ListIcon = memo(
  ({ size = { width: 22, height: 22 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.3139 10.3628H8.78363C8.40621 10.3628 8.09741 10.651 8.09741 11.0033C8.09741 11.3555 8.40621 11.6437 8.78363 11.6437H21.3139C21.6913 11.6437 22.0001 11.3555 22.0001 11.0033C22.0001 10.651 21.6982 10.3628 21.3139 10.3628Z'
          fill={color}
        />
        <path
          d='M3.19089 8.02515C1.42732 8.02515 0 9.35732 0 11.0033C0 12.6493 1.42732 13.9815 3.19089 13.9815C4.95446 13.9815 6.38179 12.6493 6.38179 11.0033C6.38179 9.35732 4.95446 8.02515 3.19089 8.02515ZM3.19089 12.7005C2.18216 12.7005 1.37243 11.9384 1.37243 11.0033C1.37243 10.0618 2.18902 9.30608 3.19089 9.30608C4.19277 9.30608 5.00936 10.0682 5.00936 11.0033C5.00936 11.9384 4.19277 12.7005 3.19089 12.7005Z'
          fill={color}
        />
        <path
          d='M21.3139 2.35693H8.78363C8.40621 2.35693 8.09741 2.64514 8.09741 2.9974C8.09741 3.34966 8.40621 3.63787 8.78363 3.63787H21.3139C21.6913 3.63787 22.0001 3.34966 22.0001 2.9974C22.0001 2.64514 21.6982 2.35693 21.3139 2.35693Z'
          fill={color}
        />
        <path
          d='M3.19089 0C1.42732 0 0 1.33217 0 2.97817C0 4.62416 1.42732 5.95633 3.19089 5.95633C4.95446 5.95633 6.38179 4.62416 6.38179 2.97817C6.38179 1.33857 4.95446 0.00640466 3.19089 0ZM3.19089 4.6818C2.18216 4.6818 1.37243 3.91965 1.37243 2.98457C1.37243 2.04949 2.18902 1.28734 3.19089 1.28734C4.19963 1.28734 5.00936 2.04949 5.00936 2.98457C5.00936 3.91965 4.19277 4.6818 3.19089 4.6818Z'
          fill={color}
        />
        <path
          d='M21.3139 18.3687H8.78363C8.40621 18.3687 8.09741 18.6569 8.09741 19.0091C8.09741 19.3614 8.40621 19.6496 8.78363 19.6496H21.3139C21.6913 19.6496 22.0001 19.3614 22.0001 19.0091C22.0001 18.6569 21.6982 18.3687 21.3139 18.3687Z'
          fill={color}
        />
        <path
          d='M3.19089 16.0437C1.42732 16.0437 0 17.3759 0 19.0219C0 20.6679 1.42732 22 3.19089 22C4.95446 22 6.38179 20.6679 6.38179 19.0219C6.38179 17.3823 4.95446 16.0437 3.19089 16.0437ZM3.19089 20.7255C2.18216 20.7255 1.37243 19.9634 1.37243 19.0283C1.37243 18.0868 2.18902 17.331 3.19089 17.331C4.19963 17.331 5.00936 18.0932 5.00936 19.0283C5.00936 19.9634 4.19277 20.7255 3.19089 20.7255Z'
          fill={color}
        />
      </svg>
    );
  },
);
