import c from 'classnames';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { Maybe } from 'core.types';
import { memo, SyntheticEvent, useLayoutEffect, useMemo, useRef } from 'react';
import { AppIcon } from '../app-icon';
import {
  DEFAULT_INPUT_CLASSES,
  DEFAULT_INPUT_MODE_TYPE,
} from './default-input.constants';
import './default-input.scss';
import { InputClearableButton } from './input-clearable-button';

export const DEFAULT_ICON_SIZE = { width: 22, height: 22 };
export const DEFAULT_CLEAR_ICON_SIZE = { width: 12, height: 12 };

type DefaultInputProps = {
  value: string | number;
  placeholder: string;
  iconName: Maybe<string>;
  iconSize: typeof DEFAULT_ICON_SIZE;
  clearIconSize: typeof DEFAULT_CLEAR_ICON_SIZE;
  type: string;
  inputMode?: string;
  onChange(event: SyntheticEvent<HTMLInputElement>): void;
  inputClassName?: string;
  isMobile?: boolean;
  autofocus?: boolean;
  clearable?: boolean;
  onKeyPress?(event: SyntheticEvent<HTMLInputElement>): void;
  errorMessage?: string;
  hasError?: boolean;
};

export const DefaultInput = memo(
  ({
    value,
    placeholder,
    iconName = null,
    iconSize = DEFAULT_ICON_SIZE,
    type,
    inputMode = DEFAULT_INPUT_MODE_TYPE,
    onChange,
    inputClassName = '',
    autofocus,
    clearable = true,
    onKeyPress,
    errorMessage,
    hasError = false,
  }: DefaultInputProps) => {
    const inputRef = useRef<Maybe<HTMLInputElement>>(null);

    useLayoutEffect(() => {
      if (autofocus && inputRef?.current) {
        // now we can focus on the target input
        inputRef?.current?.focus();
      }
    }, [autofocus]);

    const extraStyle = useMemo(() => {
      if (!clearable && !iconName) {
        return {};
      }

      return { paddingRight: iconName && clearable ? 70 : 40 };
    }, [clearable, iconName]);

    return (
      <>
        <div className={DEFAULT_INPUT_CLASSES.wrapper}>
          {/* @ts-ignore */}
          <input
            ref={inputRef}
            onKeyPress={onKeyPress}
            className={c(
              DEFAULT_INPUT_CLASSES.input,
              {
                [DEFAULT_INPUT_CLASSES.inputPadding]: !!iconName,
              },
              inputClassName,
            )}
            {...{
              disabled: iconName === 'calendar',
              inputMode,
              value,
              onChange,
              type,
              placeholder: placeholder as string,
              readOnly: !onChange,
            }}
            style={extraStyle}
          />

          <div className='default-input__right-part'>
            <InputClearableButton
              {...{
                clearable,
                value,
                onChange,
                isSecondIcon: !!iconName,
              }}
            />
            {iconName && (
              <AppIcon
                className={DEFAULT_INPUT_CLASSES.icon}
                name={iconName}
                size={iconSize}
                color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
              />
            )}
          </div>
        </div>
        {hasError && (
          <div className={DEFAULT_INPUT_CLASSES.wrapperError}>
            <span className='default__error-message'>{errorMessage}</span>
          </div>
        )}
      </>
    );
  },
);
