import { AppFilePreview } from 'components/app-file-preview';
import { useGetSaveAreaPaddings } from 'hooks/use-get-safe-area-paddings';
import { usePreloadImage } from 'hooks/use-preload-image';
import { PAGE_ROUTES } from 'page-routes';
import { memo } from 'react';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { receiptTypeAtom } from 'store/category-store';
import { AppLoader } from '../components/app-loader';
import { CreateReceiptInputSection } from './create-receipt-input-section';
import './create-receipt.scss';
import { UpdateReceiptFooter } from './update-receipt-footer';
import { useCreateReceiptLogic } from './use-create-receipt-logic';
import { usePageLayoutSizeStyle } from './use-page-layout-style';
import { useUpdateReceiptLogic } from './use-update-receipt-logic';

export const CreateReceipt = memo(() => {
  const receiptType = useRecoilValue(receiptTypeAtom);
  const { pathname } = useLocation();
  const pageLogic = pathname.includes(PAGE_ROUTES.createReceipt)
    ? useCreateReceiptLogic
    : useUpdateReceiptLogic;
  const {
    activeSliceId,
    formState,
    setField,
    // @ts-ignore
    onBackClick,
    onSaveCloseClick,
    imagePath,
    loading,
    isPDFFile,
  } = pageLogic();

  const { safeAreaTop, safeAreaBottom } = useGetSaveAreaPaddings();
  const { keyboardShown, imageContainerHeight, gridTemplateRows, rowGap } =
    usePageLayoutSizeStyle({
      safeAreaTop,
      safeAreaBottom,
      receiptType,
    });

  const preloadImage = usePreloadImage(imagePath, isPDFFile);

  if (!activeSliceId || !imagePath) {
    return null;
  }

  if (!preloadImage) {
    return null;
  }

  return (
    <div className='create-receipt' style={{ gridTemplateRows, rowGap }}>
      {!keyboardShown && (
        <div className='create-receipt__image-container'>
          <AppFilePreview
            {...{
              isPDFFile,
              imageContainerHeight,
              preloadImage,
              name: formState.name,
            }}
          />
        </div>
      )}
      <CreateReceiptInputSection
        {...{
          receiptType,
          formState,
          setField,
        }}
      />
      <UpdateReceiptFooter
        {...{
          onBackClick,
          onSaveCloseClick,
        }}
      />
      {loading && <AppLoader />}
    </div>
  );
});
