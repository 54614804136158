import { memo } from 'react';
import { MadeByFilterModal } from './category-made-by-modal';
import './category-header.scss';

import { HeaderRightControls } from './header-right-controls';
import { CategoryHeaderLeftContent } from './category-header-left-content';
import { hasAppAccess } from 'services/app-service';
import { AppType } from 'core.types';

export const CategoryPageHeader = memo(() => {
  return (
    <header className='app-header'>
      <CategoryHeaderLeftContent />
      <div className='app-header-logo'>
        {hasAppAccess(AppType.ireceipt) ? 'iReceipt' : 'iDocument'}
      </div>
      <HeaderRightControls />
      <MadeByFilterModal />
    </header>
  );
});
