import c from 'classnames';
import { AppButton } from 'components/app-button';
import { Portal } from 'components/app-portal';
import { memo, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import './app-modal.scss';

type AppModalProps = {
  open: boolean;
  onClose(event: any, reason: string): void;
  content: any;
  title: string;
  className?: string;
  onSaveClick?(): void;
  saveButtonLabel?: string | ReactNode;
  saveDisabled?: boolean;
};

export const AppModal = memo(
  ({
    open,
    onClose,
    content,
    onSaveClick,
    saveDisabled,
    title,
    className = '',
    saveButtonLabel = <FormattedMessage id='save' />,
  }: AppModalProps) => {
    if (!open) {
      return null;
    }

    return (
      <Portal>
        <div className='app-modal-backdrop'>
          <div className={c('app-modal', className)}>
            <div className='app-modal__header-title'>{title}</div>
            <div className='app-modal__body'>{content}</div>
            <div className='app-modal__buttons'>
              <AppButton
                className={c('app-modal__button', 'app-modal__button-cancel')}
                onPress={onClose}
              >
                <FormattedMessage id='cancel' />
              </AppButton>
              {onSaveClick && (
                <AppButton
                  disabled={saveDisabled}
                  className={c(
                    'app-modal__button',
                    'app-modal__button-confirm',
                  )}
                  onPress={onSaveClick}
                >
                  {saveButtonLabel}
                </AppButton>
              )}
            </div>
          </div>
        </div>
      </Portal>
    );
  },
);
