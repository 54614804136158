import c from 'classnames';
import { memo, ReactNode } from 'react';
import '../../category.scss';

type CategoryItemNameProps = {
  isEmpty: boolean;
  name: ReactNode;
};

export const CategoryTileName = memo(
  ({ isEmpty, name }: CategoryItemNameProps) => {
    return (
      <div
        className={c('category-grid__tile-title', {
          'category-grid__tile-title--empty': isEmpty,
        })}
      >
        {name}
      </div>
    );
  },
);
