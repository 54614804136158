import { HelperService } from 'services/HelperService';

export const dateFilterEffect =
  (key: 'dateFrom' | 'dateTo') =>
  ({ setSelf, onSet, trigger }: any) => {
    if (trigger === 'get') {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(new Date(savedValue));
      } else {
        const { dateFrom, dateTo } = HelperService.getMonthPeriod();
        const finalDate = key === 'dateFrom' ? dateFrom : dateTo;
        setSelf(finalDate);
      }
    }

    onSet((newDate: Date, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, newDate.toString());
    });
  };
