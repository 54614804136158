import noop from 'lodash/noop';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReceiptId, CategoryId } from '../../core.types';
import { AppLoader } from '../app-loader';
import { AppModal } from '../app-modal';
import { ShareModalButton } from './share-modal-button';
import './share-modal.scss';
import { useShareModalLogic } from './use-share-modal-logic';

export type ShareModalProps = {
  selectedCategoryIdsList?: CategoryId[];
  selectedReceiptIdsList?: ReceiptId[];
  closeShareModal(): void;
  shareModalOpened: boolean;
  onShareEnd?(): void;
};

export const ShareModal = memo(
  ({
    shareModalOpened,
    closeShareModal,
    selectedCategoryIdsList = [],
    selectedReceiptIdsList = [],
    onShareEnd = noop,
  }: ShareModalProps) => {
    const intl = useIntl();
    const { buttons, loading } = useShareModalLogic({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
      closeShareModal,
      onShareEnd,
    });
    const renderShareModalButton = useCallback(
      (button, index) => <ShareModalButton {...button} key={index} />,
      [],
    );

    const modalContent = useMemo(
      () => (
        <div className='app-modal-buttons-wrapper'>
          {loading && <AppLoader />}
          {buttons.map(renderShareModalButton)}
        </div>
      ),
      [buttons, loading, renderShareModalButton],
    );

    return (
      <AppModal
        className='app-share-modal'
        open={shareModalOpened}
        onClose={closeShareModal}
        content={modalContent}
        title={intl.messages['modal.share'] as string}
      />
    );
  },
);
