import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import c from 'classnames';
import { AppIcon } from 'components';
import { AppButton } from 'components/app-button';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilState } from 'recoil';
import { madeByModalOpenedATOM } from 'store/category-store';
import { useDetectMobileResolution } from '../../../hooks';
import './made-by-filter-modal.scss';
import { MadeByModalAccountItem } from './made-by-modal-account-item';
import { useCategoryMadeByModalLogic } from './use-category-made-by-modal-logic';

export const MadeByFilterModal = () => {
  const isMobile = useDetectMobileResolution();
  const accountList = useCategoryMadeByModalLogic();
  const [madeByModalOpened, setMadeByModalOpenStatus] = useRecoilState(
    madeByModalOpenedATOM,
  );

  const renderAccountItem = useCallback(
    (item, index) => <MadeByModalAccountItem {...item} key={index} />,
    [],
  );

  const closeMadeByModal = useCallback(() => {
    setMadeByModalOpenStatus(false);
  }, [setMadeByModalOpenStatus]);

  const onClickAway = useCallback(() => {
    if (madeByModalOpened) {
      closeMadeByModal();
    }
  }, [closeMadeByModal, madeByModalOpened]);

  const iconSize = useMemo(
    () => (isMobile ? { width: 23, height: 23 } : { width: 30, height: 30 }),
    [isMobile],
  );

  if (!madeByModalOpened) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={c('made-by-modal__wrapper', {
          'made-by-modal__wrapper--active': madeByModalOpened,
        })}
      >
        <div className='made-by-modal__header'>
          <AppButton
            onPress={closeMadeByModal}
            className='made-by-modal__close-button'
          >
            <AppIcon size={iconSize} name='close' />
          </AppButton>
          <div className='made-by-modal__title'>
            <FormattedMessage id='sort.madeBy' />
          </div>
        </div>

        <div className='made-by-modal__list'>
          {accountList.map(renderAccountItem)}
        </div>
      </div>
    </ClickAwayListener>
  );
};
