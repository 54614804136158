import appLogo from '../../images/auth/app-logo.png';
import appIdocumentLogo from '../../images/auth/idocument-logo.png';
import { memo } from 'react';
import { HelperService } from 'services/HelperService';
import { AppType } from 'core.types';
import { hasAppAccess } from 'services/app-service';
import noop from 'lodash/noop';
import c from 'classnames';
import { useRecoilValue } from 'recoil';
import { appThemeTypeAtom } from 'store/category-store';
import { APP_COLOR_THEME_TYPE } from 'color-theme.constants';
import './app-logo.scss';

type AppLogoProps = {
  className?: string;
  onClick?(): void;
};

export const AppLogo = memo(
  ({ className = '', onClick = noop }: AppLogoProps) => {
    const finalLogo = hasAppAccess(AppType.ireceipt)
      ? appLogo
      : appIdocumentLogo;

    const appColorType = useRecoilValue(appThemeTypeAtom);

    return (
      <img
        src={HelperService.resolvePathSvg(finalLogo)}
        alt='logo'
        className={c(className, {
          'logo-light': appColorType === APP_COLOR_THEME_TYPE.LIGHT,
        })}
        onClick={onClick}
      />
    );
  },
);
