const findValidIntersections = (lines, maxX, maxY) => {
  let intersections = [];
  combinations(lines).forEach((combination) => {
    let line1 = combination[0];
    let line2 = combination[1];
    let angle = findAngle(line1, line2);
    if (angle > 80 && angle < 100) {
      let point = findIntersection(
        line1.startPoint.x,
        line1.startPoint.y,
        line1.endPoint.x,
        line1.endPoint.y,
        line2.startPoint.x,
        line2.startPoint.y,
        line2.endPoint.x,
        line2.endPoint.y,
      );
      if (point.x >= 0 && point.x <= maxX && point.y >= 0 && point.y <= maxY) {
        intersections.push(point);
      }
    }
  });

  return intersections;
};

export const findAngle = (line1, line2) => {
  //find vector components
  let dAx = line1.endPoint.x - line1.startPoint.x;
  let dAy = line1.endPoint.y - line1.startPoint.y;
  let dBx = line2.endPoint.x - line2.startPoint.x;
  let dBy = line2.endPoint.y - line2.startPoint.y;
  let angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
  if (angle < 0) {
    angle = angle * -1;
  }
  return angle * (180 / Math.PI);
};

const between = (a, b, c) => {
  const eps = 0.0000001;
  return a - eps <= b && b <= c + eps;
};

const detectAnglePoints = (points, maxX, maxY) => {
  let res = { leftTop: [], rightTop: [], leftBottom: [], rightBottom: [] };
  points.forEach((point) => {
    if (
      point.x > 0 &&
      point.y > 0 &&
      point.x < maxX / 2 &&
      point.y < maxY / 2
    ) {
      res.leftTop.push(point);
    } else if (
      point.x > maxX / 2 &&
      point.y > 0 &&
      point.x < maxX &&
      point.y < maxY / 2
    ) {
      res.rightTop.push(point);
    } else if (
      point.x > 0 &&
      point.y > maxY / 2 &&
      point.x < maxX / 2 &&
      point.y < maxY
    ) {
      res.leftBottom.push(point);
    } else if (
      point.x > maxX / 2 &&
      point.y > maxY / 2 &&
      point.x < maxX &&
      point.y < maxY
    ) {
      res.rightBottom.push(point);
    }
  });

  return Object.values(res);
};

const getCentroids = (points, maxX, maxY) => {
  let res = [];
  detectAnglePoints(points, maxX, maxY).forEach((group) => {
    res.push(findCentroid(group));
  });
  return res;
};

const houghTransform = (matrix) => {
  // Hough
  let lines = new window.cv.Mat();
  window.cv.HoughLines(matrix, lines, 1, Math.PI / 180, 70, 0, 0, 0, Math.PI);

  let allLines = [];
  // draw lines
  for (let i = 0; i < lines.rows; ++i) {
    let rho = lines.data32F[i * 2];
    let theta = lines.data32F[i * 2 + 1];
    let a = Math.cos(theta);
    let b = Math.sin(theta);
    let x0 = a * rho;
    let y0 = b * rho;
    let startPoint = { x: x0 - 1000 * b, y: y0 + 1000 * a };
    let endPoint = { x: x0 + 1000 * b, y: y0 - 1000 * a };
    let line = { startPoint: startPoint, endPoint: endPoint };
    if (isTrueLine(line, 20)) {
      allLines.push(line);
    }
  }
  lines.delete();

  return allLines;
};

const isTrueLine = (line, threshold) => {
  let m =
    (line.endPoint.y - line.startPoint.y) /
    (line.endPoint.x - line.startPoint.x); // tan(A)
  let angleWithXAxis = (Math.atan(m) * 180) / Math.PI;
  let initialLimit = 45 - threshold;
  let finalLimit = 45 + threshold;
  if (
    Math.abs(angleWithXAxis) < initialLimit ||
    Math.abs(angleWithXAxis) > finalLimit
  ) {
    return true;
  }

  return false;
};

const findIntersection = (x1, y1, x2, y2, x3, y3, x4, y4) => {
  var x =
    ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) /
    ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
  var y =
    ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) /
    ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
  if (isNaN(x) || isNaN(y)) {
    return false;
  } else {
    if (x1 >= x2) {
      if (!between(x2, x, x1)) {
        return false;
      }
    } else {
      if (!between(x1, x, x2)) {
        return false;
      }
    }
    if (y1 >= y2) {
      if (!between(y2, y, y1)) {
        return false;
      }
    } else {
      if (!between(y1, y, y2)) {
        return false;
      }
    }
    if (x3 >= x4) {
      if (!between(x4, x, x3)) {
        return false;
      }
    } else {
      if (!between(x3, x, x4)) {
        return false;
      }
    }
    if (y3 >= y4) {
      if (!between(y4, y, y3)) {
        return false;
      }
    } else {
      if (!between(y3, y, y4)) {
        return false;
      }
    }
  }
  return { x: x, y: y };
};

const findCentroid = (points) => {
  let sumX = 0;
  let sumY = 0;
  points.forEach((point) => {
    sumX += point.x;
    sumY += point.y;
  });

  return { x: sumX / points.length, y: sumY / points.length };
};

const combinations = ([head, ...tail]) =>
  tail.length > 0
    ? [...tail.map((tailValue) => [head, tailValue]), ...combinations(tail)]
    : [];
