import React, { memo } from 'react';

export const CheckIcon = memo(
  ({ size = { width: 14, height: 14 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.61392 7.15829L4.92328 10.0249C7.40245 6.90622 12.4677 0.634424 12.7344 0.334705C13.0647 -0.0365317 13.5477 -0.0719141 13.8211 0.096707C14.037 0.229838 14.0812 0.763009 13.8211 1.16127L6.29871 13.3488C6.08162 13.6889 5.54894 14.0149 5.14403 13.9995C4.67493 13.9817 4.26955 13.7777 4.00437 13.4668L0.287579 9.02248C-0.105922 8.58073 -0.170897 7.6683 0.576249 7.03518C1.3234 6.40207 2.19232 6.67072 2.61392 7.15829Z'
          fill={color}
        />
      </svg>
    );
  },
);
