import { AppFormOtpCodeField } from './app-form-otp-code-field';
import { AppFormPhoneField } from './app-form-phone-field';
import { AppFormTextField } from './app-form-text-field';

export type AppFormFieldType =
  | 'text'
  | 'email'
  | 'password'
  | 'otpCode'
  | 'phone';

export const AppFormFields = {
  text: AppFormTextField,
  email: AppFormTextField,
  password: AppFormTextField,
  otpCode: AppFormOtpCodeField,
  phone: AppFormPhoneField,
};
