import { AppBackButton } from '../components/app-back-button';
import { AppForwardButton } from '../components/app-forward-button';
import { useCropStepContext } from './crop-step-context';

export const CropStepFooter = () => {
  const { onBackClick, onNextClick, intl } = useCropStepContext();
  return (
    <div className='crop-step__footer'>
      <AppBackButton
        {...{ onBackClick, label: intl.messages.back as string }}
      />
      <AppForwardButton
        {...{ onNextClick, label: intl.messages.next as string }}
      />
    </div>
  );
};
