import { AppButton } from 'components/app-button';
import { AppConfirmModal } from 'components/app-confirm-modal';
import { useBooleanState } from 'hooks/use-boolean-state';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { ProfileInfo } from '../../../../components';
import { useQuerySharedAccounts } from '../Manage/gql';
import { PermissionItem } from '../PermissionItem';
import './ManageAccount.scss';

export const ManageAccount = () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();
  const { sharedAccounts, updateAccount, removeAccount } =
    useQuerySharedAccounts();
  const intl = useIntl();
  const account = sharedAccounts.find(
    (sharedAccount) => sharedAccount.id === +Number(id),
  );
  const [canViewGroup, _, __, toggleViewGroup] = useBooleanState(
    !!account?.canViewGroup,
  );
  const [canEditGroup, _1, __1, toggleEditGroup] = useBooleanState(
    !!account?.canEditGroup,
  );
  const [canDeleteGroup, _2, __2, toggleDeleteGroup] = useBooleanState(
    !!account?.canDeleteGroup,
  );
  const [
    deleteAccountModalOpened,
    openDeleteAccountModal,
    closeDeleteAccountModal,
  ] = useBooleanState(false);

  const onSaveClick = async () => {
    if (account) {
      await updateAccount({
        email: account?.ownerEmail,
        canViewGroup,
        canEditGroup,
        canDeleteGroup,
      });
      history.goBack();
    }
  };

  const onRemoveAccount = async () => {
    if (account) {
      await removeAccount(account.id);
      history.goBack();
    }
    closeDeleteAccountModal();
  };

  return (
    <div className='account-manage__wrapper'>
      {/* @ts-ignore */}
      <ProfileInfo
        userIcon={account?.ownerPictureUrl || ''}
        ownerFullName={account?.ownerFullName}
        userCurrency={account?.ownerCurrency}
      />
      <div className='account-manage__separation-line' />
      <ul className='account-manage__permission-list'>
        <PermissionItem
          permissionLabel={
            <FormattedMessage id='menu.profile.permission.viewing' />
          }
          permissionInfo={
            <FormattedMessage id={'menu.profile.permission.admin.view.info'} />
          }
          onClick={toggleViewGroup}
          isActive={canViewGroup}
        />
        <PermissionItem
          permissionLabel={
            <FormattedMessage id='menu.profile.permission.editing' />
          }
          permissionInfo={
            <FormattedMessage id='menu.profile.permission.admin.edit.info' />
          }
          onClick={toggleEditGroup}
          isActive={canEditGroup}
        />
        <PermissionItem
          permissionLabel={
            <FormattedMessage id='menu.profile.permission.deletion' />
          }
          permissionInfo={
            <FormattedMessage id='menu.profile.permission.admin.delete.info' />
          }
          onClick={toggleDeleteGroup}
          isActive={canDeleteGroup}
        />
      </ul>

      <div className='account-manage__buttons'>
        <AppButton className='account-manage__button' onPress={onSaveClick}>
          <FormattedMessage id='save' />
        </AppButton>

        <AppButton
          className='account-manage__button account-manage__button--delete'
          onPress={openDeleteAccountModal}
        >
          <FormattedMessage id='menu.profile.delete.profile' />
        </AppButton>
      </div>

      <AppConfirmModal
        key='confirm-modal'
        open={deleteAccountModalOpened}
        onConfirmClick={onRemoveAccount}
        title={intl.messages['menu.profile.delete.profile'] as string}
        onClose={closeDeleteAccountModal}
      />
    </div>
  );
};
