import { memo, useCallback } from 'react';
import { SettingsIcon } from '../../settings-icon';
import { useIntl } from 'react-intl';
import { AppButton } from 'components/app-button';
import { APP_CSS_THEME_VARIABLES } from '../../../../color-theme.constants';

export const SettingsEmailsList = memo(
  ({ userEmailsList, onDeleteEmailClick }: any) => {
    const intl = useIntl();
    const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;

    const renderNoEmailMessageItem = useCallback(
      () => (
        <li className='email__mail-item'>
          <div className={'email__mail-item email__mail-item--add-email'}>
            {intl.messages['menu.profile.add.mail'] as any}
          </div>
        </li>
      ),
      [intl.messages],
    );

    const renderUserEmailsItems = useCallback(
      () =>
        userEmailsList.map((email, index, emailsList) => (
          <li
            className={`email__mail-item email__mail-item--${index}`}
            key={index}
          >
            <span className='email__mail-item-wrapper'>
              <SettingsIcon name='mail' color={colorTextMain20} />
              <span className='email__mail-address'>{email.email}</span>
            </span>
            <AppButton
              onPress={onDeleteEmailClick(email.id)}
              className='email__delete'
            >
              <SettingsIcon
                name='close'
                sizeType='small'
                color={colorTextMain20}
              />
            </AppButton>
          </li>
        )),
      [colorTextMain20, onDeleteEmailClick, userEmailsList],
    );
    return (
      <ul className='email__mail-list'>
        {userEmailsList?.length
          ? renderUserEmailsItems()
          : renderNoEmailMessageItem()}
      </ul>
    );
  },
);
