import { memo } from 'react';

export const PasswordIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }: any) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M29 18H27V14C27 10.1 23.9 7 20 7C16.1 7 13 10.1 13 14V18H11V14C11 9 15 5 20 5C25 5 29 9 29 14V18Z'
          fill={color}
        />
        <path
          d='M28 35H12C10.3 35 9 33.7 9 32V20C9 18.3 10.3 17 12 17H28C29.7 17 31 18.3 31 20V32C31 33.7 29.7 35 28 35ZM12 19C11.4 19 11 19.4 11 20V32C11 32.6 11.4 33 12 33H28C28.6 33 29 32.6 29 32V20C29 19.4 28.6 19 28 19H12Z'
          fill={color}
        />
        <path
          d='M20 25C21.1046 25 22 24.1046 22 23C22 21.8954 21.1046 21 20 21C18.8954 21 18 21.8954 18 23C18 24.1046 18.8954 25 20 25Z'
          fill={color}
        />
        <path d='M20.5 23H19.5L18.5 29H21.5L20.5 23Z' fill={color} />
      </svg>
    );
  },
);
