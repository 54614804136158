export const DEFAULT_INPUT_TYPE = 'text';

export const DEFAULT_INPUT_MODE_TYPE = 'text';

export const DEFAULT_INPUT_CLASSES = {
  wrapper: 'default-input__wrapper',
  input: 'default-input__input',
  inputPadding: 'default-input__input--padding',
  icon: 'default-input__icon',
  clearIcon: 'default-input__clear-icon',
  clearIconLeftSide: 'default-input__clear-icon-left-side',
  button: 'default-input__button',
  wrapperError: 'default-input__wrapper-error',
};
