import { LocalReceipt } from 'core.types';
import { useUpdateReceiptList } from 'hooks';
import { useFormState } from 'hooks/use-form-state';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { getUnixTimestampFromDate } from 'services/HelperService';
import { activeSliceIdAtom, receiptToEditAtom } from 'store/category-store';
import { UpdateReceiptDTO } from './use-create-receipt-logic';

export const useUpdateReceiptLogic = () => {
  const history = useHistory();
  const [receiptToEdit, setReceiptToEdit] = useRecoilState(receiptToEditAtom);
  const updateReceiptList = useUpdateReceiptList();
  // TODO move recoil value -> useRecoilCallback
  const [activeSliceId, setActiveSliceId] = useRecoilState(activeSliceIdAtom);

  const { formState, setField } = useFormState<UpdateReceiptDTO>({
    defaultValues: {
      name: receiptToEdit?.name || '',
      amount: receiptToEdit?.amount || 0,
      remindedAt: receiptToEdit?.remindedAt || null,
      receiptDate:
        receiptToEdit?.receiptDate || getUnixTimestampFromDate(new Date()),
    },
  });

  const receiptUpdatedFields = useMemo(() => {
    return {
      ...formState,
      amount: Number(formState.amount),
      parentId: activeSliceId,
    };
  }, [activeSliceId, formState]);

  const isReceiptUpdated = useMemo(() => {
    if (!receiptToEdit) {
      return true;
    }
    return !isEqual(
      pick(receiptToEdit, ['amount', 'name', 'receiptDate', 'parentId']),
      receiptUpdatedFields,
    );
  }, [receiptUpdatedFields, receiptToEdit]);

  const isPDFFile = receiptToEdit?.imagePath?.includes('pdf');

  const onSaveCloseClick = useCallback(async () => {
    if (!activeSliceId || !receiptToEdit) {
      return;
    }

    if (isReceiptUpdated) {
      updateReceiptList([
        {
          ...receiptToEdit,

          ...receiptUpdatedFields,
          parentId: activeSliceId,
        } as LocalReceipt,
      ]);
    }
    setReceiptToEdit(null);
    window.newPoly.reset();
  }, [
    activeSliceId,
    isReceiptUpdated,
    receiptToEdit,
    receiptUpdatedFields,
    setReceiptToEdit,
    updateReceiptList,
  ]);

  useEffect(() => {
    if (!receiptToEdit || !activeSliceId) {
      history.goBack();
    }
  }, [activeSliceId, history, receiptToEdit]);

  useEffect(() => {
    if (receiptToEdit?.parentId) {
      setActiveSliceId(receiptToEdit.parentId);
    }
  }, [receiptToEdit?.parentId, setActiveSliceId]);

  return {
    isPDFFile,
    imagePath: receiptToEdit?.imagePath,
    onSaveCloseClick,
    formState,
    setField,
    activeSliceId,
    loading: false,
  };
};
