import { loader } from 'graphql.macro';
import { toast } from 'react-toastify';
import { useCustomMutation } from '../../../../../hooks/use-server-logic';
const mutationInvite = loader('./mutationInvite.graphql');

export const useInvite = () => {
  const [_mutation, { loading }] = useCustomMutation(mutationInvite);

  const invite = (email: string) => {
    return (async () => {
      try {
        await _mutation({
          variables: {
            email,
          },
        });

        toast.info(`success`, {
          autoClose: 3000,
          position: 'bottom-center',
        });

        return 'success';
      } catch (error: any) {
        toast.error(`${error.message}`, {
          autoClose: 3000,
          position: 'bottom-center',
        });

        return 'error';
      }
    })();
  };

  return {
    invite,
    loading,
  };
};
