import { AppType } from 'core.types';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilState } from 'recoil';
import { hasAppAccess } from 'services/app-service';
import { appThemeTypeAtom } from 'store/category-store';
import {
  applyColorTheme,
  APP_COLOR_THEME_TYPE,
} from '../../../../color-theme.constants';
import { ThemePalette, ThemePaletteProps } from './theme-palette';
import './app-theme.scss';

export const AppTheme = () => {
  const [appColorType, setAppThemeType] = useRecoilState(appThemeTypeAtom);

  const onThemeChange = useCallback(
    (theme: APP_COLOR_THEME_TYPE) => {
      setAppThemeType(theme);
      applyColorTheme(theme);
    },
    [setAppThemeType],
  );

  const themePalette = useMemo(() => {
    const defaultPalette = hasAppAccess(AppType.ireceipt)
      ? {
          label: 'IReceipt',
          themeType: APP_COLOR_THEME_TYPE.IRECEIPT,
          onThemeChange,
        }
      : {
          label: 'IDocument',
          themeType: APP_COLOR_THEME_TYPE.IDOCUMENT,
          onThemeChange,
        };

    return [
      defaultPalette,
      {
        label: 'Dark',
        themeType: APP_COLOR_THEME_TYPE.DARK,
        onThemeChange,
      },
      {
        label: 'Light',
        themeType: APP_COLOR_THEME_TYPE.LIGHT,
        onThemeChange,
      },
    ] as ThemePaletteProps[];
  }, [onThemeChange]);

  const renderThemePalette = (props: ThemePaletteProps, index: number) => {
    return (
      <ThemePalette
        {...props}
        key={index}
        selectedAppColorType={appColorType}
      />
    );
  };

  return (
    <div className={'plan__upper-wrapper'}>
      <div className={'plan__wrapper'}>
        <div className={'plan__title'}>
          <FormattedMessage id='menu.theme.title' />
        </div>

        <div className='plan__description'>
          <FormattedMessage id='menu.theme.subtitle' />
        </div>
        {themePalette.map(renderThemePalette)}
      </div>
    </div>
  );
};
