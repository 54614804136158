import { memo } from 'react';
import c from 'classnames';
import { useBooleanState } from '../../../hooks';
import { AppImagePlaceholder } from '../app-image-placeholder';
import { ReceiptCloudTile } from './receipt-cloud-tile';
import {
  detectDesktopApplication,
  detectLocalImagePath,
} from 'services/HelperService';

type CategoryGridTileImageProps = {
  imagePath: string;
};

export const CategoryGridTileImage = memo(
  ({ imagePath }: CategoryGridTileImageProps) => {
    const [imageLoaded, setImageIsLoaded] = useBooleanState(false);

    return (
      <>
        {!imageLoaded && <AppImagePlaceholder />}
        {!detectLocalImagePath(imagePath) && !detectDesktopApplication() && (
          <ReceiptCloudTile />
        )}
        <img
          src={imagePath}
          draggable='false'
          className={c('category-grid__tile-image', {
            'category-grid__tile-image--zero-height': !imageLoaded,
          })}
          width='100%'
          height='90'
          onLoad={setImageIsLoaded}
          alt=''
        />
      </>
    );
  },
);
