import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { setFormServerErrors } from 'scenes/common-auth-logic';
import { useCustomMutation } from '../../hooks/use-server-logic';

const mutationForgotPassword = loader('./mutationForgotPassword.graphql');

type ForgotPasswordValues = {
  email: string;
};

export const useForgotPasswordLogic = () => {
  const [triggerForgotPasswordMutation, { data, loading }] = useCustomMutation(
    mutationForgotPassword,
  );

  const onSubmit = useCallback(
    async ({ email }: ForgotPasswordValues, _: any, methods: any) => {
      const { setError } = methods;
      try {
        await triggerForgotPasswordMutation({
          variables: {
            email,
          },
        });
      } catch (errorResponse: any) {
        setFormServerErrors(errorResponse, setError);
      }
    },
    [triggerForgotPasswordMutation],
  );

  return {
    onSubmit,
    loading,
    isReset: !!(data as any)?.resetPasswordRequest,
  };
};
