import { CategoryTreeContext } from 'category-tree-provider';
import { AppButton } from 'components/app-button';
import { CategoryId } from 'core.types';
import { memo, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { activeSliceIdAtom } from '../../store';

type CreateReceiptModalTreeBreadcrumbsItemProps = {
  id: CategoryId;
};

export const CreateReceiptModalTreeBreadcrumbsItem = memo(
  ({ id }: CreateReceiptModalTreeBreadcrumbsItemProps) => {
    const setActiveSliceId = useSetRecoilState(activeSliceIdAtom);
    const { getCategoryById } = useContext(CategoryTreeContext);
    const category = getCategoryById(id);

    const onItemClick = () => {
      setActiveSliceId(id);
    };

    if (!category) {
      return null;
    }

    return (
      <AppButton className='tree-breadcrumbs-item' onPress={onItemClick}>
        {category.name}
        {' / '}
      </AppButton>
    );
  },
);
