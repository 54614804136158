import { AppLoader } from 'components/app-loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActivePlanTab } from './active-plan-tab';
import { SubscriptionGroup } from './app-plan.types';
import { PlanInfo } from './plan-info';
import './Plan.scss';
import { usePlanLogic } from './use-plan-logic';
import { ComingSoon } from './coming-soon';

const maxDocsForFree = 7;

export const Plan = () => {
  const {
    onTabTileClick,
    onTabClick,
    activePlanTab,
    plansList,
    loader,
    planBenefits,
  } = usePlanLogic();
  const intl = useIntl();
  const isBusiness = activePlanTab === SubscriptionGroup.business;
  if (!isBusiness && !plansList) {
    return <AppLoader />;
  }

  return (
    <div className='plan__upper-wrapper'>
      <div className='plan__wrapper'>
        <div className='plan__title'>
          <FormattedMessage id='menu.profile.plan.title' />
        </div>

        <div className='plan__description'>
          <FormattedMessage id='menu.profile.plan.description' />
        </div>

        <ul className='plan__tabs'>
          <ActivePlanTab
            label={<FormattedMessage id='menu.profile.plan.personal' />}
            onClick={onTabClick}
            active={activePlanTab === SubscriptionGroup.personal}
            subscriptionGroup={SubscriptionGroup.personal}
          />
          <ActivePlanTab
            label={<FormattedMessage id='menu.profile.plan.family' />}
            onClick={onTabClick}
            active={activePlanTab === SubscriptionGroup.family}
            subscriptionGroup={SubscriptionGroup.family}
          />
          <ActivePlanTab
            label={<FormattedMessage id='menu.profile.plan.business' />}
            onClick={onTabClick}
            active={activePlanTab === SubscriptionGroup.business}
            subscriptionGroup={SubscriptionGroup.business}
          />
        </ul>
        {activePlanTab !== SubscriptionGroup.business ? (
          <PlanInfo
            {...{
              planBenefits,
              onTabTileClick,
              planInfo: plansList,
              subscriptionGroup: activePlanTab,
            }}
          />
        ) : (
          <ComingSoon
            onClick={() => {
              alert(
                intl.messages['menu.profile.plan.coming.soon.alert'] as string,
              );
            }}
          />
        )}

        {activePlanTab === SubscriptionGroup.personal && (
          <div className='plan__subtitle'>
            <FormattedMessage id='menu.profile.plan.subtitle.1' />
            {maxDocsForFree}
            <FormattedMessage id='menu.profile.plan.subtitle.2' />
          </div>
        )}
      </div>
      {activePlanTab !== SubscriptionGroup.business && (
        <div className='plan__bottom-info'>
          <FormattedMessage id='menu.profile.plan.bottom-info' />
        </div>
      )}
      {loader && <AppLoader />}
    </div>
  );
};
