import {
  AppType,
  CategoryId,
  CategoryView,
  LocalCategory,
  LocalReceipt,
  Maybe,
  ReceiptId,
  ReceiptType,
  VisionKitGalleryImagePoint,
} from 'core.types';
import { atom, selector } from 'recoil';
import { APP_COLOR_THEME_TYPE } from 'color-theme.constants';
import { hasAppAccess } from 'services/app-service';
import { localStorageEffect } from 'store/store.effects';

export const pullToRefreshInProgressATOM = atom({
  key: 'pullToRefreshInProgressATOM',
  default: false,
});

export const createFolderModalOpenedATOM = atom({
  key: 'createFolderModalOpenedATOM',
  default: false,
});

export const editModeEnabledATOM = atom({
  key: 'editModeEnabledATOM',
  default: false,
});

export const madeByModalOpenedATOM = atom({
  key: 'madeByModalOpenedATOM',
  default: false,
});

export const dragEnabledATOM = atom({
  key: 'dragEnabledATOM',
  default: false,
});

export const selectedReceiptIDsAtom = atom<ReceiptId[]>({
  key: 'selectedReceiptIDsAtom',
  default: [],
});

export const selectedCategoryIDsAtom = atom<CategoryId[]>({
  key: 'selectedCategoryIDsAtom',
  default: [],
});

export const rootIDAtom = atom<Maybe<CategoryId>>({
  key: 'rootIDAtom',
  default: null,
  effects_UNSTABLE: [localStorageEffect('root-id', true)],
});

export const croppedImageFileAtom = atom<Maybe<File>>({
  key: 'croppedImageFileAtom',
  default: null,
});

export const newReceiptFileAtom = atom<Maybe<File>>({
  key: 'newReceiptFileAtom',
  default: null,
});

export const receiptToEditAtom = atom<Maybe<LocalReceipt>>({
  key: 'receiptToEditAtom',
  default: null,
});

export const categoryToEditAtom = atom<Maybe<LocalCategory>>({
  key: 'categoryToEditAtom',
  default: null,
});

export const categoryListViewAtom = atom<CategoryView>({
  key: 'categoryListView',
  default: CategoryView.tile,
  effects_UNSTABLE: [localStorageEffect('category-list-view')],
});

export const appThemeTypeAtom = atom<APP_COLOR_THEME_TYPE>({
  key: 'appThemeTypeAtom',
  default: hasAppAccess(AppType.ireceipt)
    ? APP_COLOR_THEME_TYPE.IRECEIPT
    : APP_COLOR_THEME_TYPE.IDOCUMENT,
  effects_UNSTABLE: [localStorageEffect('app-theme-type')],
});

export const activeSliceIdAtom = atom<Maybe<CategoryId>>({
  key: 'activeSliceIdAtom',
  default: localStorage.getItem('root-id')
    ? Number(localStorage.getItem('root-id'))
    : null,
});

const receiptTypeAtomEffects = hasAppAccess(AppType.ireceipt)
  ? [localStorageEffect('receipt-type')]
  : [];

export const receiptTypeAtom = atom<ReceiptType>({
  key: 'receiptTypeAtom',
  default: hasAppAccess(AppType.ireceipt)
    ? ReceiptType.receipt
    : ReceiptType.document,
  effects_UNSTABLE: receiptTypeAtomEffects,
});

export const lastReceiptUpdateAtom = atom<Maybe<number>>({
  key: 'lastReceiptUpdateAtom',
  default: null,
});

export const lastCategoryUpdateAtom = atom<Maybe<number>>({
  key: 'lastCategoryUpdateAtom',
  default: null,
});

export const categoryLoadingAtom = atom<boolean>({
  key: 'categoryLoadingAtom',
  default: false,
});

export const isRootSelector = selector<boolean>({
  key: 'isRootSelector',
  get: ({ get }) => {
    const activeSliceId = get(activeSliceIdAtom);
    const rootId = get(rootIDAtom);

    return activeSliceId === rootId;
  },
});

export const recognizedImageTextsAtom = atom<Maybe<string[]>>({
  key: 'recognizedImageTextsAtom',
  default: null,
});

export const recognizedImagePointsAtom = atom<
  Maybe<VisionKitGalleryImagePoint[]>
>({
  key: 'recognizedImagePointsAtom',
  default: null,
});
