import { AppConfirmModal } from 'components/app-confirm-modal';
import { Maybe } from 'core.types';
import { useBooleanState } from 'hooks';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppLoader } from '../../../../components/app-loader';
import { AddButton } from '../AddButton';
import { AddPhoneModal } from './add-phone-modal';
import { PhoneItem } from './phone-item';
import './Phone.scss';
import { usePhoneLogic } from './use-phone-logic';

export const Phone = () => {
  const intl = useIntl();
  const [selectedPhoneId, setSelectedPhoneId] = useState<Maybe<number>>(null);
  const [deleteModalOpened, openDeleteModal, closeDeleteModal] =
    useBooleanState(false);
  const [addPhoneModalOpened, openAddPhoneModal, closeAddPhoneModal] =
    useBooleanState(false);
  const { loading, userPhones, deletePhone, addPhone } = usePhoneLogic();

  const handleDelete = async () => {
    if (selectedPhoneId) {
      await deletePhone(selectedPhoneId);
      closeDeleteModal();
    }
  };

  return (
    <>
      <div className='phone-edit__wrapper'>
        {loading && <AppLoader />}

        <div className='phone-edit__phone-text'>
          <FormattedMessage id='menu.profile.phone.your' />
        </div>

        {userPhones.length ? (
          userPhones.map((phone) => (
            <PhoneItem
              key={phone.id}
              {...phone}
              {...{
                setSelectedPhoneId,
                openDeleteModal,
              }}
            />
          ))
        ) : (
          <div className='phone-edit__phone-item phone-edit__phone-item--add-phone'>
            <FormattedMessage id='menu.profile.phone.add' />
          </div>
        )}
        <div className='phone-edit__separation-line' />
        <AddButton
          label={<FormattedMessage id='menu.profile.phone.add' />}
          onClick={openAddPhoneModal}
        />
      </div>
      <AppConfirmModal
        key='confirm-modal'
        open={deleteModalOpened}
        onConfirmClick={handleDelete}
        title={intl.messages.alert as string}
        onClose={closeDeleteModal}
      />
      <AddPhoneModal
        open={addPhoneModalOpened}
        {...{ addPhone, closeAddPhoneModal }}
      />
    </>
  );
};
