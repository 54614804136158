import { AppLoader } from 'components/app-loader';
import { AppLogo } from 'components/app-logo/app-logo';
import { useGetIOSKeyboardHeight } from 'hooks/use-get-ios-keyboard-height';
import { FormattedMessage } from 'react-intl';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router';
import { OtpLoginPhoneForm } from './otp-login-phone-form';
import { OtpLoginVerify } from './otp-login-verify';
import { useLoginBySMS } from './use-otp-logic';

export const OtpLogin = () => {
  const keyboardHeight = useGetIOSKeyboardHeight();
  const history = useHistory();

  const {
    codeSectionShown,
    onLogoClick,
    countdown,
    loginBySMSLoading,
    onPhoneSubmit,
    onCodeSubmit,
    phone,
  } = useLoginBySMS();

  return (
    <div
      className='auth-page__wrapper'
      style={{ paddingBottom: keyboardHeight }}
    >
      <AppLogo onClick={onLogoClick} className='auth-page__image' />
      {codeSectionShown ? (
        <OtpLoginVerify
          {...{
            countdown,
            phone,
            onCodeSubmit,
          }}
        />
      ) : (
        <OtpLoginPhoneForm {...{ onPhoneSubmit }} />
      )}
      <span className='auth-page__link' onClick={history.goBack}>
        <FormattedMessage id='back' />{' '}
      </span>
      {loginBySMSLoading && <AppLoader />}
    </div>
  );
};
