import { CategoryTreeContext } from 'category-tree-provider';
import { CategoryTabs } from 'category/category-tabs';
import { AppHasAccess } from 'components/app-has-access';
import { AppType } from 'core.types';
import { memo, useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { categoryListViewAtom, isRootSelector } from 'store';
import {
  dragEnabledATOM,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
} from 'store/category-store';
import { useSelectedIDsAtomSetters } from 'store/category-store/category-store.callbacks';
import { CategoryDropzones } from '../category-dropzones';
import { CategoryGrid } from '../category-grid';
import { CategoryListNoChild } from '../category-no-child';
import { CategorySortHeader } from '../category-sort-header';
import { CategoryItemTable } from '../category-table';

const viewMap = {
  tile: CategoryGrid,
  list: CategoryItemTable,
};

export const CategoryContent = memo(() => {
  const isRoot = useRecoilValue(isRootSelector);
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const [_0, _1, clearSelectedCategories] = useSelectedIDsAtomSetters(
    selectedCategoryIDsAtom,
  );
  const [_2, _3, clearSelectedReceipts] = useSelectedIDsAtomSetters(
    selectedReceiptIDsAtom,
  );
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const View = viewMap[categoryListView];

  const {
    isEmptyActiveSliceSelector,
    activeSliceCategoryListSelectorFamily,
    activeSliceReceiptListSelectorFamily,
  } = useContext(CategoryTreeContext);

  const isSliceEmpty = isEmptyActiveSliceSelector();

  const foldersList = activeSliceCategoryListSelectorFamily();
  const emptyReceiptList = activeSliceReceiptListSelectorFamily('empty');
  const nonEmptyReceiptList = activeSliceReceiptListSelectorFamily('full');

  useEffect(() => {
    clearSelectedCategories();
    clearSelectedReceipts();
  }, [clearSelectedCategories, clearSelectedReceipts]);

  return (
    <div className='category-content'>
      <AppHasAccess appType={AppType.ireceipt}>
        <CategoryTabs />
      </AppHasAccess>
      <CategorySortHeader />
      {isSliceEmpty && <CategoryListNoChild />}
      {!isRoot && dragEnabled && <CategoryDropzones />}
      {!isSliceEmpty && (
        <View {...{ foldersList, emptyReceiptList, nonEmptyReceiptList }} />
      )}
    </div>
  );
});
