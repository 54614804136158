import { memo } from 'react';
import {
  PreviewReceiptControl,
  PreviewReceiptControlProps,
} from './preview-receipt-control';

type PreviewReceiptControlsProps = {
  previewBottomControls: any[];
};

export const PreviewReceiptControls = memo(
  ({ previewBottomControls }: PreviewReceiptControlsProps) => {
    const renderPreviewImageIcon = (
      previewIcon: PreviewReceiptControlProps,
    ) => <PreviewReceiptControl {...previewIcon} key={previewIcon.title} />;

    return (
      <div className='preview-receipt__controls'>
        {previewBottomControls.map(renderPreviewImageIcon)}
      </div>
    );
  },
);
