import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import { useRecoilValue } from 'recoil';
import {
  currentUserPersonalAccountSelector,
  currentUserSharedAccountsSelector,
} from 'store/user-store';
import { useGetUser } from '../../../../../gql/api-user';

const mutationAddAccount = loader('./mutationAddAccount.graphql');
const mutationRemoveAccount = loader('./mutationRemoveAccount.graphql');

export type UpdateAccountPayload = {
  email: string;
  canViewGroup: boolean;
  canEditGroup: boolean;
  canDeleteGroup: boolean;
};

export const useQuerySharedAccounts = () => {
  const { fetchUser } = useGetUser();
  const [_mutationAddAccount, { loading: processingAddEmail }] =
    useCustomMutation(mutationAddAccount);
  const [_mutationRemoveAccount, { loading: processingRemoveAccount }] =
    useCustomMutation(mutationRemoveAccount);
  const personalAccount = useRecoilValue(currentUserPersonalAccountSelector);
  const sharedAccounts = useRecoilValue(currentUserSharedAccountsSelector);

  const addAccount = async (email: string) => {
    try {
      await _mutationAddAccount({
        variables: {
          email,
          canViewGroup: false,
          canEditGroup: false,
          canDeleteGroup: false,
        },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  const updateAccount = async (payload: UpdateAccountPayload) => {
    try {
      await _mutationAddAccount({
        variables: payload,
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  const removeAccount = async (id: number) => {
    try {
      await _mutationRemoveAccount({
        variables: {
          sharing_id: id,
        },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    personalAccount,
    sharedAccounts,
    loading: processingAddEmail || processingRemoveAccount,
    addAccount,
    updateAccount,
    removeAccount,
  } as const;
};
