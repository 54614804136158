import { memo, ReactNode } from 'react';
import './app-typography.scss';
import c from 'classnames';

type AppTypographyProps = {
  children: ReactNode;
  variant: 'label';
  className?: string;
};

export const AppTypography = memo(
  ({ children, variant, className = '' }: AppTypographyProps) => {
    return (
      <span className={c(`app-typography-${variant}`, className)}>
        {children}
      </span>
    );
  },
);
