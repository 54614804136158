import { AppSubscriptionId } from 'core.types';
import { FormattedMessage } from 'react-intl';

export enum SubscriptionGroup {
  personal = 'personal',
  family = 'family',
  business = 'business',
}

export type PlanTileDTO = {
  type: SubscriptionGroup;
};

export type PlanInformation = {
  monthlyPrice: number;
  yearlyPrice: number;
  currency: string;
  discount?: number;
  monthlyId: AppSubscriptionId;
  yearlyId: AppSubscriptionId;
  yearSubscriptionMonthlyPrice?: number;
};

export enum SubscriptionPeriod {
  monthly = 'monthly',
  yearly = 'yearly',
}

export type SubscriptionPlansList = Record<SubscriptionGroup, PlanInformation>;

export const PlansInfoMap = {
  [SubscriptionGroup.personal]: {
    maxAccounts: 1,
    text: (
      <FormattedMessage id='menu.profile.plan.argument.2.personal.description' />
    ),
    type: SubscriptionGroup.personal,
  },
  [SubscriptionGroup.family]: {
    maxAccounts: 3,
    text: (
      <FormattedMessage id='menu.profile.plan.argument.2.family.description' />
    ),
    type: SubscriptionGroup.family,
  },
  [SubscriptionGroup.business]: {
    maxAccounts: 25,
    text: (
      <FormattedMessage id='menu.profile.plan.argument.2.business.description' />
    ),
    type: SubscriptionGroup.business,
  },
};

export const mockedPlansInfo = {
  [SubscriptionGroup.personal]: {
    currency: 'USD',
    monthlyPrice: 100,
    yearlyPrice: 200,
    yearSubscriptionMonthlyPrice: 33,
    discount: 50,
    monthlyId: 'monthlyId' as AppSubscriptionId,
    yearlyId: 'yearlyId' as AppSubscriptionId,
    period: SubscriptionPeriod.monthly,
  },
  [SubscriptionGroup.family]: {
    currency: 'USD',
    monthlyPrice: 80,
    yearlyPrice: 190,
    yearSubscriptionMonthlyPrice: 56,
    discount: 70,
    monthlyId: 'monthlyId' as AppSubscriptionId,
    yearlyId: 'yearlyId' as AppSubscriptionId,
    period: SubscriptionPeriod.monthly,
  },
  [SubscriptionGroup.business]: {
    currency: 'USD',
    monthlyPrice: 100,
    yearlyPrice: 200,
    yearSubscriptionMonthlyPrice: 56,
    discount: 70,
    monthlyId: 'monthlyId' as AppSubscriptionId,
    yearlyId: 'yearlyId' as AppSubscriptionId,
    period: SubscriptionPeriod.monthly,
  } as PlanInformation,
};

const pt = process.env.REACT_APP_PROJECT_TYPE;

// !Note Order -is important
export const activePlanSubscriptionGroups: Record<
  SubscriptionGroup,
  AppSubscriptionId[]
> = {
  [SubscriptionGroup.personal]: [
    (pt + AppSubscriptionId.personalMonthly) as AppSubscriptionId,
    (pt + AppSubscriptionId.personalYear) as AppSubscriptionId,
  ],
  [SubscriptionGroup.family]: [
    (pt + AppSubscriptionId.familyMonthly) as AppSubscriptionId,
    (pt + AppSubscriptionId.familyYear) as AppSubscriptionId,
  ],
  [SubscriptionGroup.business]: [
    (pt + AppSubscriptionId.businessMonthly) as AppSubscriptionId,
    (pt + AppSubscriptionId.businessYear) as AppSubscriptionId,
  ],
};
