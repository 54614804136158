import { useReceiptDNDLogic } from 'category/use-receipt-dnd-logic';
import c from 'classnames';
import { memo, useMemo } from 'react';
import Tappable from 'react-tappable';
import { useRecoilValue } from 'recoil';
import { editModeEnabledATOM } from 'store/category-store';
import { AppIcon } from '../../../components';
import { AppPseudoRadio } from '../../../components/app-pseudo-radio';
import { AppType, LocalReceipt } from '../../../core.types';
import { useDetectMobileResolution } from '../../../hooks';
import '../../category.scss';
import { useReceiptItemLogic } from '../../use-receipt-item-logic';
import { currencyFormatter } from '../../../services';
import { hasAppAccess } from 'services/app-service';

const RECEIPT_MOBILE_ICON_SIZE = {
  width: 24,
  height: 29,
};

const RECEIPT_DESKTOP_ICON_SIZE = {
  width: 29,
  height: 35,
};

export const CategoryItemTableRowReceipt = memo(
  (localReceipt: LocalReceipt) => {
    const editModeEnabled = useRecoilValue(editModeEnabledATOM);
    const {
      onItemClick,
      date,
      currency,
      isItemActive,
      itemName,
      isReceiptItemEmpty,
    } = useReceiptItemLogic(localReceipt);
    const { createdBy } = localReceipt;
    const { drag } = useReceiptDNDLogic(localReceipt.id);
    const isMobileResolution = useDetectMobileResolution();

    const image = useMemo(
      () => (
        <div className='category-table__icon-wrapper'>
          <AppIcon
            size={
              isMobileResolution
                ? RECEIPT_MOBILE_ICON_SIZE
                : RECEIPT_DESKTOP_ICON_SIZE
            }
            name='receipt'
          />
        </div>
      ),
      [isMobileResolution],
    );

    const createdByBlock = useMemo(
      () => (
        <div className='category-table__row-created-by'>
          <span className='category-table__row-created-by-label'>BY </span>
          {createdBy}
        </div>
      ),
      [createdBy],
    );

    return (
      <div className='category-table__row-block' ref={drag}>
        <Tappable
          onTap={onItemClick}
          className='category-table__row drop-container'
        >
          <div className='category-table__row-name-wrapper'>
            {editModeEnabled && (
              <AppPseudoRadio
                {...{
                  isItemActive,
                  className: 'category-table__row-indicator',
                }}
              />
            )}
            {image}
            <div
              className={c('category-table__row-name-wrapper-title', {
                'category-table__row--empty-item': isReceiptItemEmpty,
              })}
            >
              {itemName}
              {isMobileResolution && createdByBlock}
            </div>
          </div>
          {!isMobileResolution && createdByBlock}
          {hasAppAccess(AppType.ireceipt) && (
            <div className='category-table__row-currency'>
              {currencyFormatter(currency, localReceipt?.amount)}
            </div>
          )}
          <div
            className={c('category-table__row-date', {
              'category-table__row--empty-item': isReceiptItemEmpty,
            })}
          >
            {date}
          </div>
        </Tappable>
      </div>
    );
  },
);
