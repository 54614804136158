import { FormattedMessage } from 'react-intl';

export enum NAVIGATION_TYPE {
  back = 'back',
  next = 'next',
}

export enum CONTROL_TYPE {
  rotate = 'rotate',
  correction = 'correction',
  style = 'style',
  intensity = 'intensity',
}

export const CONTROL_TYPE_LABEL_MAP = {
  [CONTROL_TYPE.rotate]: 'Rotate',
  [CONTROL_TYPE.correction]: 'Correction',
  [CONTROL_TYPE.style]: 'Style',
  [CONTROL_TYPE.intensity]: 'Intensity',
};

export enum RECEIPT_STYLE {
  original = 'original',
  gray = 'gray',
  bw = 'bw',
}

export const RECEIPT_STYLE_TEXT_MAP = {
  [RECEIPT_STYLE.original]: <FormattedMessage id='filter.original' />,
  [RECEIPT_STYLE.gray]: <FormattedMessage id='filter.grey' />,
  [RECEIPT_STYLE.bw]: <FormattedMessage id='filter.bw' />,
};

export enum SLIDER_RANGE_TYPE {
  negative = 'negative',
  positive = 'positive',
}

export const CONTRAST_RANGE_MAP = {
  [SLIDER_RANGE_TYPE.negative]: [0.8, 1],
  [SLIDER_RANGE_TYPE.positive]: [1, 1.5],
};

export const INTENSITY_SLIDER_RANGE_MAP = {
  all: [-100, 100],
  [SLIDER_RANGE_TYPE.negative]: [-100, 0],
  [SLIDER_RANGE_TYPE.positive]: [0, 100],
};

export const SLIDER_STEP = 1;

export const CREATE_RECEIPT_INPUT_FILE_TYPE_PDF = 'pdf';
