import { AppButton } from 'components/app-button';
import { memo, useMemo } from 'react';
import { AppIcon } from '../components';
import { useDetectMobileResolution } from '../hooks';
import './preview-receipt.scss';

export type PreviewReceiptControlProps = {
  title: string;
  onClick(): void;
  icon: string;
};

const MOBILE_ICON_RESOLUTION = { width: 20, height: 20 };
const DESKTOP_ICON_RESOLUTION = { width: 30, height: 30 };

export const PreviewReceiptControl = memo(
  ({ title, onClick, icon }: PreviewReceiptControlProps) => {
    const isMobile = useDetectMobileResolution();
    const iconSize = useMemo(
      () => (isMobile ? MOBILE_ICON_RESOLUTION : DESKTOP_ICON_RESOLUTION),
      [isMobile],
    );

    return (
      <AppButton className='preview-receipt__control' onPress={onClick}>
        <AppIcon name={icon} size={iconSize} />
        <span className='preview-receipt__control-title'>{title}</span>
      </AppButton>
    );
  },
);
