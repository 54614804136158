import { Maybe } from 'core.types';
import {
  AvailableAccountShare,
  UserPayload,
  UserPhone,
} from 'gql/api-user/api-user.types';
import { atom, selector } from 'recoil';
import { HelperService } from 'services/HelperService';

const CURRENT_USER_KEY = 'current-user';

const currentUserAtomEffect = ({ setSelf, onSet, trigger }: any) => {
  if (trigger === 'get') {
    const savedValue = localStorage.getItem(CURRENT_USER_KEY);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
  }

  onSet((userTopSet: UserPayload, _: UserPayload, isReset: boolean) => {
    isReset
      ? localStorage.removeItem(CURRENT_USER_KEY)
      : localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(userTopSet));
  });
};

export const currentUserAtom = atom<Maybe<UserPayload>>({
  key: 'currentUserAtom',
  default: null,
  effects_UNSTABLE: [currentUserAtomEffect],
});

export const currentUserNameSelector = selector<string>({
  key: 'currentUserNameSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);
    return currentUserPayload?.user?.account?.ownerFullName || '';
  },
});

export const currentUserCurrencySelector = selector<string>({
  key: 'currentUserCurrencySelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);
    return currentUserPayload?.user?.currentAccount.ownerCurrency || '';
  },
});

export const currentUserIconSelector = selector<string>({
  key: 'currentUserIconSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);
    const userIconUrl = HelperService.resolveFilePath(
      currentUserPayload?.user?.currentAccount?.ownerPictureUrl || '',
    );
    //@ts-ignore
    return window.onlineDispatcher.isOnline ? userIconUrl : '';
  },
});

export const currentUserPhonesSelector = selector<UserPhone[]>({
  key: 'currentUserPhonesSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);

    return currentUserPayload?.user.phones || [];
  },
});

export type UserAccount = {
  id: number;
  ownerFullName: string;
  ownerCurrency: string;
  ownerPictureUrl: string;
  accountType: string;
};

export const currentUserPersonalAccountSelector = selector<Maybe<UserAccount>>({
  key: 'currentUserPersonalAccountSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);
    if (!currentUserPayload) {
      return null;
    }

    const account = currentUserPayload.user.account;

    return {
      id: account.id,
      ownerFullName: account.ownerFullName,
      ownerCurrency: account.ownerCurrency,
      ownerPictureUrl: HelperService.resolveFilePath(account.ownerPictureUrl),
      accountType: account.accountType,
    };
  },
});

export type SharedAccount = UserAccount & {
  canViewGroup: boolean;
  canEditGroup: boolean;
  canDeleteGroup: boolean;
  ownerEmail: string;
};

export const currentUserSharedAccountsSelector = selector<SharedAccount[]>({
  key: 'currentUserSharedAccountsSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);

    if (!currentUserPayload) {
      return [] as any[];
    }

    const account = currentUserPayload.user.account;

    return account.ownAccountShares.map(
      ({ id, accountTo, canViewGroup, canEditGroup, canDeleteGroup }) => ({
        ...accountTo,
        id,
        ownerPictureUrl: HelperService.resolveFilePath(
          accountTo.ownerPictureUrl,
        ),
        canViewGroup,
        canEditGroup,
        canDeleteGroup,
      }),
    );
  },
});

export const currentAccountIdSelector = selector<Maybe<number>>({
  key: 'currentAccountIdSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);

    if (!currentUserPayload) {
      return null;
    }

    return currentUserPayload.user.currentAccount.id;
  },
});

export const userIdSelector = selector<Maybe<number>>({
  key: 'userIdSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);

    if (!currentUserPayload) {
      return null;
    }

    return currentUserPayload.user.id;
  },
});

export const getUserSharedAccount = (
  currentUserPayload: UserPayload,
): Maybe<AvailableAccountShare> => {
  const availableAccountShares: AvailableAccountShare[] =
    currentUserPayload.user.account.availableAccountShares;
  const currentAccountId = currentUserPayload.user.currentAccount.id;
  const ownAccountId = currentUserPayload.user.account.id;

  return (
    availableAccountShares.find(
      ({ accountTo, accountFrom }) =>
        accountTo.id === ownAccountId && accountFrom.id === currentAccountId,
    ) || null
  );
};

export const currentUserSharedAccountSelector = selector<
  Maybe<AvailableAccountShare>
>({
  key: 'currentAccountAvailableAccountSharesSelector',
  get: ({ get }) => {
    const currentUserPayload = get(currentUserAtom);

    if (!currentUserPayload) {
      return null;
    }

    return getUserSharedAccount(currentUserPayload);
  },
});
