import { AppButton } from 'components/app-button';
import type { LocalCategory } from 'core.types';
import { memo, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeSliceIdAtom, receiptTypeAtom } from 'store';
import { AppIcon } from '../../components';
import { useBooleanState } from '../../hooks';
import { addClickHandle } from '../../services/HelperService';
import { CreateReceiptModalTreeFolderInput } from './create-receipt-modal-tree-folder-input';
import './create-receipt-modal-tree.scss';

type CreateReceiptModalTreeFolderProps = {
  category: LocalCategory;
};

export const MOBILE_FOLDER_ICON_SIZE = { width: 27, height: 20 };
const MOBILE_PENCIL_ICON_SIZE = { width: 10, height: 10 };

export const CreateReceiptModalTreeFolder = memo(
  ({ category }: CreateReceiptModalTreeFolderProps) => {
    const receiptType = useRecoilValue(receiptTypeAtom);
    const setActiveSliceId = useSetRecoilState(activeSliceIdAtom);
    const [editModeEnabled, enableEditMode, closeEditMode] =
      useBooleanState(false);

    const treeFolderIconSize = MOBILE_FOLDER_ICON_SIZE;
    const pencilIconSize = MOBILE_PENCIL_ICON_SIZE;

    const onFolderIconClick = useCallback(() => {
      setActiveSliceId(category.id);
    }, [category.id, setActiveSliceId]);

    if (category.type !== receiptType) {
      return null;
    }

    return (
      <div className='tree-folder'>
        {!editModeEnabled && (
          <div
            className='tree-folder-left-content'
            {...addClickHandle(onFolderIconClick)}
          >
            <AppIcon
              name='green-folder-non-empty'
              size={treeFolderIconSize}
              id={category.id}
            />
            <div className='tree-folder-name'>{category.name}</div>
          </div>
        )}
        {editModeEnabled && (
          <CreateReceiptModalTreeFolderInput
            {...{ category, closeInput: closeEditMode }}
          />
        )}
        {!editModeEnabled && (
          <AppButton
            onPress={enableEditMode}
            className='tree-folder__edit-button'
          >
            <AppIcon name='edit-pencil' size={pencilIconSize} color='#777' />
          </AppButton>
        )}
      </div>
    );
  },
);
