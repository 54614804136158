import { memo } from 'react';

export const ClockIcon = memo(
  ({ size = { width: 18, height: 18 }, color = 'white' }: any) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fillRule='evenodd'
        clipRule='evenodd'
        fill={color}
      >
        <path d='M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 11h6v1h-7v-9h1v8z' />
      </svg>
    );
  },
);
