import React, { memo } from 'react';

export const QualityIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M11.4844 7.73438H2.34375C1.05141 7.73438 0 8.78578 0 10.0781V16.0938C0 17.3861 1.05141 18.4375 2.34375 18.4375H11.4844C12.7767 18.4375 13.8281 17.3861 13.8281 16.0938V10.0781C13.8281 8.78578 12.7767 7.73438 11.4844 7.73438ZM12.2656 16.0938C12.2656 16.5245 11.9152 16.875 11.4844 16.875H2.34375C1.91297 16.875 1.5625 16.5245 1.5625 16.0938V10.0781C1.5625 9.64734 1.91297 9.29688 2.34375 9.29688H11.4844C11.9152 9.29688 12.2656 9.64734 12.2656 10.0781V16.0938Z'
            fill={color}
          />
          <path d='M1.5625 4.6875H0V6.25H1.5625V4.6875Z' fill={color} />
          <path
            d='M1.5625 1.5625C0.69957 1.5625 0 2.26207 0 3.125H1.5625V1.5625Z'
            fill={color}
          />
          <path
            d='M10.7812 1.5625H9.21875V3.125H10.7812V1.5625Z'
            fill={color}
          />
          <path
            d='M7.73438 1.5625H6.17188V3.125H7.73438V1.5625Z'
            fill={color}
          />
          <path
            d='M4.64844 1.5625H3.08594V3.125H4.64844V1.5625Z'
            fill={color}
          />
          <path
            d='M18.4375 1.5625V3.125H20C20 2.26207 19.3004 1.5625 18.4375 1.5625Z'
            fill={color}
          />
          <path d='M20 13.8281H18.4375V15.3906H20V13.8281Z' fill={color} />
          <path d='M20 10.7812H18.4375V12.3438H20V10.7812Z' fill={color} />
          <path d='M20 7.73438H18.4375V9.29688H20V7.73438Z' fill={color} />
          <path
            d='M13.8281 1.5625H12.2656V3.125H13.8281V1.5625Z'
            fill={color}
          />
          <path d='M20 4.6875H18.4375V6.25H20V4.6875Z' fill={color} />
          <path
            d='M16.9141 16.875H15.3516V18.4375H16.9141V16.875Z'
            fill={color}
          />
          <path
            d='M18.4375 18.4375C19.3004 18.4375 20 17.7379 20 16.875H18.4375V18.4375Z'
            fill={color}
          />
          <path
            d='M16.9141 1.5625H15.3516V3.125H16.9141V1.5625Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
