import { AppType } from 'core.types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { hasAppAccess } from 'services/app-service';
import { receiptTypeAtom } from 'store/category-store';

export const CategoryListNoChild = memo(() => {
  const receiptType = useRecoilValue(receiptTypeAtom);

  return (
    <p className='empty-category-list-message'>
      {hasAppAccess(AppType.idocument) ? (
        <FormattedMessage id='categoryList.no-documents' />
      ) : (
        <>
          <FormattedMessage id='categoryList.dont.1' /> {receiptType}
          <FormattedMessage id='categoryList.dont.2' />
        </>
      )}
    </p>
  );
});
