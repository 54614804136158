import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

export const Terms = memo(() => {
  return (
    <div style={{ color: 'var(--textMain);', padding: '30px' }}>
      <a href='https://ireceipt.io/privacy_policy.html'>
        <FormattedMessage id='app.privacyPolicy' />
      </a>
      <br />
      <br />
      <a href='https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'>
        <FormattedMessage id='app.licenseUserAgreement' />
      </a>
      <br />
      <br />
      <a href='https://ireceipt.io/gdpr.html'>
        <FormattedMessage id='app.gdpr' />
      </a>
    </div>
  );
});
