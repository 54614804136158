import { LocalCategory, LocalReceipt } from 'core.types';
import { memo, useCallback } from 'react';
import { CategoryFolderTile } from './category-grid-tile/category-folder-tile';
import { CategoryReceiptTile } from './category-grid-tile/category-receipt-tile';
import './category-grid.scss';

export const CategoryGrid = memo(
  ({ foldersList, emptyReceiptList, nonEmptyReceiptList }: any) => {
    const renderCategory = useCallback(
      (category: LocalCategory & { amount: number }) => {
        return <CategoryFolderTile {...category} key={category.id} />;
      },
      [],
    );
    const renderReceipt = useCallback((receipt: LocalReceipt) => {
      return <CategoryReceiptTile {...receipt} key={receipt.id} />;
    }, []);

    const renderGridSection = useCallback(
      (items: LocalReceipt[] | LocalCategory[], renderer) =>
        !!items.length && (
          <div className='category-grid__block'>{items.map(renderer)}</div>
        ),
      [],
    );

    return (
      <div className='category-grid'>
        {renderGridSection(emptyReceiptList, renderReceipt)}
        {!!emptyReceiptList.length && (
          <div className='category-grid__divider' />
        )}
        {renderGridSection(foldersList, renderCategory)}
        {renderGridSection(nonEmptyReceiptList, renderReceipt)}
      </div>
    );
  },
);
