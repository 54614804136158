import React, { memo } from 'react';

export const CorrectionIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M9.54538 2.72727C10.1784 2.72727 10.5166 2.7961 10.6782 2.95764C10.8398 3.11966 10.909 3.45835 10.909 4.09091C10.909 4.34216 11.1123 4.54547 11.3636 4.54547C11.6148 4.54547 11.8181 4.34216 11.8181 4.09091C11.8181 3.45835 11.8874 3.11966 12.049 2.95764C12.2105 2.7961 12.5488 2.72727 13.1817 2.72727C13.433 2.72727 13.6363 2.52396 13.6363 2.27271C13.6363 2.02146 13.433 1.81815 13.1817 1.81815C12.5488 1.81815 12.2105 1.74933 12.0489 1.58779C11.8874 1.42577 11.8181 1.08707 11.8181 0.454517C11.8181 0.20331 11.6148 0 11.3636 0C11.1124 0 10.909 0.20331 10.909 0.45456C10.909 1.08712 10.8398 1.42581 10.6782 1.58783C10.5166 1.74942 10.1784 1.8182 9.54538 1.8182C9.29413 1.8182 9.09082 2.02151 9.09082 2.27276C9.09082 2.52396 9.29413 2.72727 9.54538 2.72727ZM11.321 2.23057C11.3352 2.21591 11.3494 2.20125 11.3636 2.18617C11.3769 2.20125 11.3911 2.21591 11.4062 2.23057C11.4204 2.24476 11.4355 2.25899 11.4497 2.27276C11.4355 2.28652 11.4204 2.30029 11.4062 2.31494C11.392 2.3296 11.3778 2.34426 11.3636 2.35935C11.3502 2.34426 11.3361 2.3296 11.321 2.31494C11.3067 2.30075 11.2917 2.28652 11.2775 2.27276C11.2917 2.25895 11.3067 2.24519 11.321 2.23057Z'
            fill={color}
          />
          <path
            d='M19.4673 16.8963L8.09566 5.52418C8.50569 5.4785 8.97802 5.45455 9.54546 5.45455C9.79671 5.45455 10 5.25124 10 4.99999C10 4.74874 9.79671 4.54543 9.54546 4.54543C7.7184 4.54543 6.78887 4.32793 6.23046 3.76952C5.67205 3.21068 5.45455 2.28115 5.45455 0.45456C5.45455 0.20331 5.25124 0 4.99999 0C4.74874 0 4.54543 0.20331 4.54543 0.45456C4.54543 2.28119 4.32793 3.21068 3.76952 3.76956C3.21111 4.32797 2.28158 4.54547 0.454517 4.54547C0.20331 4.54547 0 4.74874 0 4.99999C0 5.25124 0.20331 5.45455 0.45456 5.45455C2.28162 5.45455 3.21115 5.67205 3.76956 6.23046C4.32797 6.78934 4.54547 7.71883 4.54547 9.54546C4.54547 9.79671 4.74878 10 5.00003 10C5.25128 10 5.45459 9.79671 5.45459 9.54546C5.45459 8.97785 5.47858 8.50543 5.52426 8.09527L16.8963 19.4673C17.2399 19.8109 17.6962 20 18.1818 20C19.1842 20 20 19.1846 20 18.1818C20 17.6962 19.8109 17.2399 19.4673 16.8963ZM4.99999 6.45064C4.85263 6.12482 4.66087 5.83675 4.41226 5.58772C4.1637 5.33915 3.87605 5.14692 3.54933 4.99999C3.87605 4.85306 4.1637 4.66087 4.41226 4.41226C4.66083 4.16323 4.85259 3.87516 4.99999 3.54934C5.14735 3.87516 5.33911 4.16323 5.58772 4.41226C5.83628 4.66083 6.12392 4.85306 6.45064 4.99999C6.12392 5.14692 5.83628 5.33911 5.58772 5.58772C5.33915 5.83675 5.14739 6.12482 4.99999 6.45064ZM5.745 7.03053C5.86453 6.69567 6.02387 6.43722 6.23046 6.23046C6.43713 6.02378 6.69567 5.86437 7.03074 5.74479L11.6709 10.3852L10.3853 11.6708L5.745 7.03053ZM18.1818 19.0909C17.9395 19.0909 17.7113 18.9964 17.5391 18.8246L11.0281 12.3136L12.3137 11.0279L18.8246 17.5391C18.9959 17.7108 19.0909 17.939 19.0909 18.1818C19.0909 18.683 18.6834 19.0909 18.1818 19.0909Z'
            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width={width} height={height} fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
