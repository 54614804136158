import c from 'classnames';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { useDetectMobileResolution } from 'hooks/use-detect-mobile-resolution';
import { memo } from 'react';
import { SettingsIcon } from 'scenes/Settings/settings-icon';

type PlanBenefitProps = {
  title: any;
  description: any;
};

export const PlanBenefit = memo(({ title, description }: PlanBenefitProps) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;
  const isMobile = useDetectMobileResolution();

  return (
    <div className='plan__list-item'>
      <SettingsIcon
        className={c({ 'app-icon__wrapper--margin-top': isMobile })}
        name='check'
        color={colorEmphasized}
        sizeType='custom'
      />
      <p className='plan_argument'>
        <span className='plan_argument-title'>{title}</span>
        <span className='plan_argument-description'>{description}</span>
      </p>
    </div>
  );
});
