import { IMAGE_PREFIX } from '../core.types';
import { HelperService } from './HelperService';

export const getVisionKit = () => {
  if (window.cordova) {
    return window.VisionKit;
  }

  if (HelperService.parceBoolEnv(process.env.REACT_APP_EMULATE_VISIONKIT)) {
    const pointsAndText = {
      text: [
        { y: 1, text: 'Some Invoice text.' },
        { y: 10, text: 'Total: 100.00' },
      ],
      points: {
        topLeft: { x: 10, y: 10 },
        topRight: { x: 700, y: 10 },
        bottomLeft: { x: 10, y: 700 },
        bottomRight: { x: 700, y: 700 },
      },
    };
    const imagesFromVisionKit = [
      {
        imagePath: `${IMAGE_PREFIX.local_stub}_1.png`,
        thumbPath: `${IMAGE_PREFIX.local_stub}_thumb_1.jpg`,
        ...pointsAndText,
      },
      {
        imagePath: `${IMAGE_PREFIX.local_stub}_2.jpg`,
        thumbPath: `${IMAGE_PREFIX.local_stub}_thumb_2.jpg`,
        ...pointsAndText,
      },
    ];

    const imageFromGallery = {
      imagePath: `${IMAGE_PREFIX.local_stub}_1.png`,
      ...pointsAndText,
    };

    const pdfFromFiles = {
      pdfTempUrl: `${IMAGE_PREFIX.local_stub}.pdf`,
    };

    const pdfBase64Result = {
      filePath: `${IMAGE_PREFIX.local_stub}.pdf`,
      thumbPath: 'pdf_thump.jpg',
    };

    const imageBase64Result = {
      filePath: `${IMAGE_PREFIX.local_stub}_1.png`,
      thumbPath: `${IMAGE_PREFIX.local_stub}_thumb_1.jpg`,
    };

    let data = imagesFromVisionKit;
    let base64Result = pdfBase64Result;

    if (process.env.REACT_APP_EMULATE_VISIONKIT_SOURCE === 'image') {
      // @ts-ignore
      data = imageFromGallery;
      base64Result = imageBase64Result;
    }
    if (process.env.REACT_APP_EMULATE_VISIONKIT_SOURCE === 'pdf') {
      // @ts-ignore
      data = pdfFromFiles;
    }

    const imagesFromDownload = [
      {
        filePath: `${IMAGE_PREFIX.local_stub}_1.jpg`,
        thumbPath: `${IMAGE_PREFIX.local_stub}_thumb_1.jpg`,
      },
      {
        filePath: `${IMAGE_PREFIX.local_stub}_2.jpg`,
        thumbPath: `${IMAGE_PREFIX.local_stub}_thumb_2.jpg`,
      },
    ];
    return {
      // @ts-ignore
      scan: (success, failure, options) => {
        setTimeout(() => {
          success(data);
        }, 1000);
      },
      // @ts-ignore
      download: (success, failure, urls) => {
        setTimeout(() => {
          success(imagesFromDownload);
        }, 1000);
      }, // @ts-ignore
      base64String: (success, failure, url) => {
        setTimeout(() => {
          success(base64Result);
        }, 1000);
      },
    };
  }
};
