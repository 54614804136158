import { CategoryTreeContext } from 'category-tree-provider';
import c from 'classnames';
import { memo, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { categoryListViewAtom } from 'store';
import { AppIcon } from '../../components';
import { CategoryId, CategoryView } from '../../core.types';

const TILE_FOLDER_ICON_SIZE = {
  width: 130,
  height: 90,
};

const LIST_FOLDER_ICON_SIZE = {
  width: 35,
  height: 28,
};

export const DndPreviewFolderItem = memo(({ id }: { id: CategoryId }) => {
  const { getCategoryById } = useContext(CategoryTreeContext);
  const localCategory = getCategoryById(id);
  const listView = useRecoilValue(categoryListViewAtom);
  const isTableView = listView === CategoryView.list;

  if (!localCategory) {
    return null;
  }

  return (
    <div
      className={c('dnd-preview-item', {
        'dnd-preview-item--table': isTableView,
      })}
    >
      <AppIcon
        name={
          localCategory.children.length || localCategory.receipts.length
            ? 'green-folder-non-empty'
            : 'green-folder-empty'
        }
        size={isTableView ? LIST_FOLDER_ICON_SIZE : TILE_FOLDER_ICON_SIZE}
      />
      <div className='dnd-preview-item__name'>{localCategory.name}</div>
    </div>
  );
});
