import { CategoryTreeContext } from 'category-tree-provider';
import c from 'classnames';
import { AppButton } from 'components/app-button';
import { CategoryId, LocalCategory } from 'core.types';
import { memo, useCallback, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AppIcon } from '../../components';
import { AppModal } from '../../components/app-modal';
import { useBooleanState } from '../../hooks';
import { CreateReceiptModalTreeBreadcrumbsItem } from './create-receipt-modal-tree-breadcrumbs-item';
import { CreateReceiptModalTreeFolder } from './create-receipt-modal-tree-folder';
import { CreateReceiptModalTreeFolderInput } from './create-receipt-modal-tree-folder-input';
import './create-receipt-modal-tree.scss';

type CreateReceiptModalTreeProps = {
  treeModalOpened: boolean;
  closeTreeModal(): void;
};

export const CreateReceiptModalTree = memo(
  ({ treeModalOpened, closeTreeModal }: CreateReceiptModalTreeProps) => {
    // todo rewrite
    const intl = useIntl();
    const treeFoldersRef = useRef<null | HTMLDivElement>(null);
    const { folderPathSelector, activeSliceCategoryListSelectorFamily } =
      useContext(CategoryTreeContext);
    const foldersPath = folderPathSelector();
    const [
      createFolderModeEnabled,
      enableCreateFolderMode,
      disableCreateFolderMode,
    ] = useBooleanState(false);

    const foldersList = activeSliceCategoryListSelectorFamily();

    const folderIconSize = { width: 22, height: 16 };

    const scrollDownListContainer = useCallback(() => {
      if (treeFoldersRef.current) {
        treeFoldersRef.current.scrollTo({
          top: treeFoldersRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, []);

    const renderBreadcrumb = useCallback(
      (id: CategoryId) => (
        <CreateReceiptModalTreeBreadcrumbsItem key={id} id={id} />
      ),
      [],
    );

    const renderTreeFolder = useCallback((category: LocalCategory, index) => {
      return <CreateReceiptModalTreeFolder {...{ category }} key={index} />;
    }, []);

    const onCloseClick = () => {
      disableCreateFolderMode();
      closeTreeModal();
    };

    const modalContent = (
      <>
        <div className='breadcrumbs-container'>
          {foldersPath.map(renderBreadcrumb)}
        </div>
        <div ref={treeFoldersRef} className='tree-folders'>
          {foldersList?.map(renderTreeFolder)}
          {createFolderModeEnabled && (
            <CreateReceiptModalTreeFolderInput
              {...{
                closeInput: disableCreateFolderMode,
                scrollDownListContainer,
              }}
            />
          )}
        </div>
        <AppButton
          className={c('recursive-tree__create-folder-button', {
            'recursive-tree__create-folder-button--margin':
              createFolderModeEnabled,
          })}
          onPress={enableCreateFolderMode}
        >
          <AppIcon
            name='folder-open'
            size={folderIconSize}
            className='recursive-tree__create-folder-button-icon'
          />
          {intl.messages['create.new.folder'] as string}
        </AppButton>
      </>
    );

    return (
      <AppModal
        className='recursive-tree-modal'
        onSaveClick={onCloseClick}
        open={treeModalOpened}
        onClose={onCloseClick}
        content={modalContent}
        title={intl.messages.saveTo as string}
      />
    );
  },
);
