import React, { memo } from 'react';

export const EditIcon = memo(
  ({ size = { width: 32, height: 32 }, color = 'white' }) => {
    const { width, height } = size;

    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M29.2175 19.887C28.7876 19.887 28.4392 20.2354 28.4392 20.6653V27.5756C28.4377 28.8647 27.3934 29.9093 26.1044 29.9105H4.26643C2.9774 29.9093 1.93309 28.8647 1.93157 27.5756V7.29427C1.93309 6.00553 2.9774 4.96093 4.26643 4.95941H11.1768C11.6067 4.95941 11.9551 4.611 11.9551 4.18112C11.9551 3.75154 11.6067 3.40283 11.1768 3.40283H4.26643C2.11824 3.40526 0.377432 5.14607 0.375 7.29427V27.5759C0.377432 29.7241 2.11824 31.4649 4.26643 31.4674H26.1044C28.2526 31.4649 29.9934 29.7241 29.9958 27.5759V20.6653C29.9958 20.2354 29.6474 19.887 29.2175 19.887Z'
          fill={color}
        />
        <path
          d='M29.6874 1.39986C28.3197 0.0320788 26.1022 0.0320788 24.7344 1.39986L10.8492 15.285C10.7541 15.3801 10.6854 15.4981 10.6495 15.6276L8.82357 22.2196C8.74847 22.4899 8.82478 22.7793 9.023 22.9779C9.22153 23.1761 9.51095 23.2524 9.78122 23.1776L16.3733 21.3514C16.5028 21.3155 16.6207 21.2468 16.7159 21.1516L30.6007 7.2662C31.9664 5.8975 31.9664 3.68182 30.6007 2.31313L29.6874 1.39986ZM12.5451 15.7909L23.909 4.42666L27.5739 8.09161L16.2097 19.4558L12.5451 15.7909ZM11.813 17.2599L14.741 20.1882L10.6908 21.3103L11.813 17.2599ZM29.5002 6.16565L28.6748 6.99106L25.0095 3.32581L25.8352 2.5004C26.595 1.74066 27.8269 1.74066 28.5866 2.5004L29.5002 3.41367C30.2587 4.17433 30.2587 5.4053 29.5002 6.16565Z'
          fill={color}
        />
      </svg>
    );
  },
);
