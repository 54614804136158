import { useDetectMobileResolution } from 'hooks/use-detect-mobile-resolution';
import { useGetSaveAreaPaddings } from 'hooks/use-get-safe-area-paddings';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { hasAppAccess } from 'services/app-service';
import {
  useDeleteReceiptList,
  useUpdateReceiptList,
} from '../category-tree-hooks/use-receipts-sync-logic';
import { AppType, CategoryId, ReceiptType } from '../core.types';
import { useBooleanState, usePrint } from '../hooks';
import { useGetReceiptName } from '../hooks/use-get-receipt-name';
import { currencyFormatter, HelperService } from '../services';
import {
  currentUserCurrencySelector,
  isApplicationOnlineAtom,
  receiptToEditAtom,
} from '../store';
import { PAGE_ROUTES } from './../page-routes';
import {
  activeSliceIdAtom,
  rootIDAtom,
} from './../store/category-store/category-store.atoms';

export const usePreviewReceiptLogic = () => {
  const [shareModalOpened, openShareModal, closeShareModal] =
    useBooleanState(false);
  const [deleteModalOpened, openDeleteModal, closeDeleteModal] =
    useBooleanState(false);
  const [
    moveBillingDocumentModalOpened,
    moveBillingDocumentModal,
    closeBillingDocumentModal,
  ] = useBooleanState(false);
  const receiptToEdit = useRecoilValue(receiptToEditAtom);
  const activeSliceID = useRecoilValue(activeSliceIdAtom);
  const isApplicationOnline = useRecoilValue(isApplicationOnlineAtom);
  const updateReceiptList = useUpdateReceiptList();
  const deleteReceiptList = useDeleteReceiptList();
  const activeReceiptName = useGetReceiptName({
    name: receiptToEdit?.name,
    type: receiptToEdit?.type,
  });
  const isMobileResolution = useDetectMobileResolution();
  const { print } = usePrint();
  const intl = useIntl();
  const history = useHistory();
  const currency = useRecoilValue(currentUserCurrencySelector);

  const moveBillingDocument = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        if (!activeSliceID || !receiptToEdit) {
          return;
        }
        const rootId = snapshot.getLoadable(rootIDAtom).contents;

        updateReceiptList([
          {
            ...receiptToEdit,
            parentId: rootId,
            type:
              receiptToEdit.type == ReceiptType.invoice
                ? ReceiptType.receipt
                : ReceiptType.invoice,
          },
        ]);
        history.goBack();
      },
    [activeSliceID, receiptToEdit, updateReceiptList],
  );

  const onPrintClick = useCallback(() => {
    if (!receiptToEdit) {
      return;
    }
    print({ receiptIds: [receiptToEdit.id] });
  }, [print, receiptToEdit]);

  const onEditClick = useCallback(() => {
    history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.updateReceipt}`);
  }, [history]);
  const isPDFFile = receiptToEdit?.imagePath?.includes('pdf');

  const onShareEnd = useCallback(() => {
    history.goBack();
  }, [history]);

  const previewBottomControls = useMemo(
    () =>
      [
        {
          onClick: onEditClick,
          icon: 'edit',
          title: intl.messages['preview.edit'] as string,
        },
        {
          onClick: openShareModal,
          icon: 'share',
          hidden: !isApplicationOnline,
          title: intl.messages['preview.share'] as string,
        },
        {
          onClick: openDeleteModal,
          icon: 'trash',
          title: intl.messages['preview.delete'] as string,
        },
        {
          onClick: onPrintClick,
          icon: 'print',
          title: intl.messages['preview.print'] as string,
        },
      ].filter(({ hidden }) => !hidden),
    [
      intl.messages,
      isApplicationOnline,
      onEditClick,
      onPrintClick,
      openDeleteModal,
      openShareModal,
    ],
  );
  const infoItems = useMemo(
    () =>
      [
        {
          title: intl.messages['preview.name'] as string,
          value: activeReceiptName,
        },
        {
          title: intl.messages['preview.amount'] as string,
          value: currencyFormatter(currency, receiptToEdit?.amount || 0),
          hidden: hasAppAccess(AppType.idocument),
        },
        {
          title: intl.messages['preview.date'] as string,
          value: HelperService.unixTimeToString(receiptToEdit?.receiptDate),
        },
        {
          title: intl.messages['preview.created'] as string,
          value: receiptToEdit?.createdBy,
        },
      ].filter(({ hidden }) => !hidden),
    [
      activeReceiptName,
      currency,
      intl.messages,
      receiptToEdit?.amount,
      receiptToEdit?.createdBy,
      receiptToEdit?.receiptDate,
    ],
  );

  const deleteCurrentReceipt = useCallback(() => {
    if (!receiptToEdit) {
      return;
    }
    deleteReceiptList({
      ids: [receiptToEdit.id],
      parentId: activeSliceID as CategoryId,
    });
    history.goBack();
  }, [activeSliceID, deleteReceiptList, history, receiptToEdit]);

  const { safeAreaTop, safeAreaBottom } = useGetSaveAreaPaddings();

  const imageContainerHeight = useMemo(() => {
    const isIreceipt = hasAppAccess(AppType.ireceipt);
    const moveBillingDocumentButtonHeight = isMobileResolution ? 50 : 60;
    const finalHeight =
      document.body.offsetHeight -
      (isMobileResolution ? 175 : 230) -
      (isIreceipt ? moveBillingDocumentButtonHeight : 0) -
      safeAreaBottom -
      safeAreaTop;

    return finalHeight;
  }, [isMobileResolution, receiptToEdit?.type, safeAreaBottom, safeAreaTop]);

  useEffect(() => {
    if (!receiptToEdit) {
      history.goBack();
    }
  }, [history, receiptToEdit]);

  return {
    onShareEnd,
    isPDFFile,
    imageContainerHeight,
    previewBottomControls,
    infoItems,
    shareModalOpened,
    closeShareModal,
    deleteModalOpened,
    closeDeleteModal,
    moveBillingDocument,
    moveBillingDocumentModalOpened,
    moveBillingDocumentModal,
    closeBillingDocumentModal,
    deleteCurrentReceipt,
  };
};
