import { memo, useCallback, useMemo, useState } from 'react';
import { AppBackButton } from '../../components/app-back-button';
import { AppForwardButton } from '../../components/app-forward-button';
import { FILTER_TYPE } from '../../components/CropImages/image-filters';
import { useCropStepContext } from '../crop-step-context';
import { CropStepImageActionControl } from '../crop-step-image-action-control';
import { CONTROL_TYPE } from '../crop-step.constants';
import './crop-step-image-actions.scss';

let rotateProgress = false;

export const CropStepImageActions = memo(() => {
  const {
    toggleStyle,
    toggleIntensity,
    hideIntensity,
    hideStyle,
    enableProgress,
    disableProgress,
    progressEnabled,
    onBackClick,
    isSmallResolution,
    onNextClick,
    intl,
  } = useCropStepContext();

  const [rotateDegree, setRotateDegree] = useState(
    window.newPoly.filters.rotateDegree,
  );

  const onRotateClick = useCallback(async () => {
    try {
      // @ts-ignore
      if (!rotateProgress || !window.instIdCrop.handles.length) {
        rotateProgress = true;
        const newRotateDegree = rotateDegree === 360 ? 90 : rotateDegree + 90;
        setRotateDegree(newRotateDegree);
        await window.newPoly.applyFilters({ rotateDegree: newRotateDegree });
      }
    } finally {
      rotateProgress = false;
    }
  }, [rotateDegree]);

  const onCorrectionClick = useCallback(async () => {
    try {
      enableProgress();
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.megaFilter });
      disableProgress();
    } finally {
      disableProgress();
    }
  }, [disableProgress, enableProgress]);

  const onStyleClick = useCallback(() => {
    try {
      hideIntensity();
      toggleStyle();
    } finally {
      disableProgress();
    }
  }, [disableProgress, hideIntensity, toggleStyle]);

  const onIntensityClick = useCallback(() => {
    try {
      hideStyle();
      toggleIntensity();
    } finally {
      disableProgress();
    }
  }, [disableProgress, hideStyle, toggleIntensity]);

  const imageControlsList = useMemo(
    () => [
      {
        onClick: onRotateClick,
        id: 'rotate-button',
        type: CONTROL_TYPE.rotate,
      },
      {
        onClick: onCorrectionClick,
        id: 'correction-button',
        type: CONTROL_TYPE.correction,
      },
      {
        onClick: onStyleClick,
        id: 'style-button',
        type: CONTROL_TYPE.style,
      },
      {
        onClick: onIntensityClick,
        id: 'intensity-button',
        type: CONTROL_TYPE.intensity,
      },
    ],
    [onCorrectionClick, onIntensityClick, onRotateClick, onStyleClick],
  );

  const renderImageAction = useCallback(
    (action) => (
      <CropStepImageActionControl
        {...action}
        key={action.id}
        disabled={progressEnabled}
      />
    ),
    [progressEnabled],
  );

  return (
    <div className='image-actions-wrapper'>
      <div className='image-actions-content'>
        {!isSmallResolution && (
          <AppBackButton
            label={intl.messages.back as string}
            onBackClick={onBackClick}
          />
        )}
        <div className='image-actions'>
          <div className='image-action-buttons'>
            {imageControlsList.map(renderImageAction)}
          </div>
        </div>
        {!isSmallResolution && (
          <AppForwardButton
            label={intl.messages.next as string}
            onNextClick={onNextClick}
          />
        )}
      </div>
    </div>
  );
});
