import { RECOGNITION_LANGUAGES, RECOGNITION_TYPES } from '../core.types';

export const localStorageEffect =
  (key: string, numericValue = false) =>
  ({ setSelf, onSet, trigger }: any) => {
    if (trigger === 'get') {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(numericValue ? Number(savedValue) : savedValue);
      }
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, String(newValue));
    });
  };

const RECOGNITION_KEY = 'recognition';

export const localStorageRecognitionEffect = ({
  setSelf,
  onSet,
  trigger,
}: any) => {
  if (trigger === 'get') {
    const savedValue = localStorage.getItem(RECOGNITION_KEY);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
  }

  onSet((newValue: any, _: any, isReset: boolean) => {
    isReset
      ? localStorage.removeItem(RECOGNITION_KEY)
      : localStorage.setItem(RECOGNITION_KEY, JSON.stringify(newValue));
  });
};

export const resolveRecognitionByLanguage = (language: string) => {
  const recognitionStore = localStorage.getItem(RECOGNITION_KEY);
  if (!recognitionStore) {
    const recognition = {
      languages: [RECOGNITION_LANGUAGES.en],
      recognitionType: RECOGNITION_TYPES.fast,
    };
    if (language !== 'en') {
      recognition.languages.push(RECOGNITION_LANGUAGES[language]);
    }
    localStorage.setItem(RECOGNITION_KEY, JSON.stringify(recognition));
  }
};
