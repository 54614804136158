import { useIntl } from 'react-intl';
import { ReceiptType } from '../core.types';

export const useGetReceiptName = ({
  name,
  type,
}: {
  type?: ReceiptType;
  name?: string;
}) => {
  const intl = useIntl();
  if (name) {
    return name;
  }

  switch (type) {
    case ReceiptType.receipt:
      return intl.messages.unfilledReceipt as string;
    case ReceiptType.invoice:
      return intl.messages.unfilledInvoice as string;
    case ReceiptType.document:
      return intl.messages.unfilledDocument as string;
  }
};
