import { useCallback, useState } from 'react';
import { useBooleanState } from '../../../../hooks';
import { useChangeEmail } from './gql';
import { HelperService } from '../../../../services';

export const useSettingsEmailLogic = () => {
  const { loading, dataEmails, deleteEmail, addEmail } = useChangeEmail();
  const [email, setEmail] = useState('');
  const [emailIdToDelete, setEmailIdToDelete] = useState(null);
  const [deleteEmailModalOpened, openDeleteEmailModal, closeDeleteEmailModal] =
    useBooleanState(false);
  const [addEmailModalOpened, openAddEmailModal, closeAddEmailModal] =
    useBooleanState(false);

  const onDeleteEmailClick = useCallback(
    (id) => () => {
      setEmailIdToDelete(id);
      openDeleteEmailModal();
    },
    [openDeleteEmailModal],
  );

  const deleteUserEmail = useCallback(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    deleteEmail(emailIdToDelete).then(() => {
      setEmailIdToDelete(null);
      closeDeleteEmailModal();
    });
  }, [closeDeleteEmailModal, deleteEmail, emailIdToDelete]);

  const addNewEmail = useCallback(
    () =>
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      addEmail(email).then(() => {
        setEmail('');
        closeAddEmailModal();
      }),
    [addEmail, closeAddEmailModal, email],
  );

  const onEmailChange = useCallback(({ currentTarget: { value } }) => {
    setEmail(value);
  }, []);

  return {
    userEmailsList: dataEmails?.user.emails,
    email,
    addNewEmail,
    deleteUserEmail,
    onDeleteEmailClick,
    deleteEmailModalOpened,
    closeDeleteEmailModal,
    addEmailModalOpened,
    openAddEmailModal,
    closeAddEmailModal,
    loading,
    onEmailChange,
    saveAddEmailModalDisabled: !HelperService.isValidEmail(email) || loading,
  };
};
