import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import c from 'classnames';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { AppIcon } from 'components/app-icon';
import {
  ChangeEventHandler,
  FocusEventHandler,
  memo,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { hasError } from 'services/form-service';
import { AppFormFieldClearButton } from '../app-form-field-clear-button';
import { AppFormFieldError } from '../app-form-field-error';
import { BaseAppFormFieldProps } from '../app-form.types';
import './app-form-text-field.scss';

export const DEFAULT_ICON_SIZE = { width: 22, height: 22 };
export const DEFAULT_CLEAR_ICON_SIZE = { width: 12, height: 12 };

export type AppFormFieldIconConfig = {
  name: string;
  size?: {
    width: number;
    height: number;
  };
};

export type AppFormTextFieldProps = {
  name: string;
  type: 'text' | 'email' | 'password';
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder: string;
  icon?: AppFormFieldIconConfig;
  inputClassName?: string;
  autoFocus?: boolean;
  clearable?: boolean;
  onKeyPress?(event: SyntheticEvent<HTMLInputElement>): void;
} & BaseAppFormFieldProps;

export const AppFormTextField = memo(
  ({
    name,
    type,
    onChange,
    disabled,
    onFocus,
    onBlur,
    placeholder,
    icon,
    inputClassName = '',
    autoFocus,
    clearable = true,
    onKeyPress,
  }: AppFormTextFieldProps) => {
    const context = useFormContext();
    const fieldId = `form-text-field${name}`;

    const {
      register,
      setValue,
      formState: { isDirty, errors },
    } = context;
    const { showError, error } = hasError({ errors: errors, name, isDirty });

    const onClearClick = useCallback(() => {
      setValue(name, '');
    }, [name, setValue]);

    const extraStyle = useMemo(() => {
      if (!clearable && !icon) {
        return {};
      }

      return { paddingRight: icon && clearable ? 70 : 40 };
    }, [clearable, icon]);

    const onClickOutside = useCallback(() => {
      const input = document.getElementById(fieldId);
      input?.blur();
    }, [fieldId]);

    return (
      <ClickAwayListener onClickAway={onClickOutside}>
        <div className='app-form__control'>
          <input
            id={fieldId}
            {...register(name)}
            {...{
              autoFocus,
              type,
              disabled,
              // onChange,
              // onFocus,
              // onBlur,
              // onKeyPress,
              placeholder: placeholder as string,
              style: extraStyle,
              className: c('app-form__text-field', inputClassName),
            }}
          />

          <div className='app-form__text-field-right-part'>
            {clearable && (
              <AppFormFieldClearButton
                onClick={onClearClick}
                fieldName={name}
              />
            )}
            {icon && (
              <AppIcon
                className={'app-form__text-field-icon'}
                name={icon.name}
                size={icon.size || DEFAULT_ICON_SIZE}
                color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
              />
            )}
          </div>

          <AppFormFieldError {...{ name, showError, error }} />
        </div>
      </ClickAwayListener>
    );
  },
);
