import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AppBackButton } from '../components/app-back-button';
import './preview-receipt.scss';

export const PreviewReceiptHeader = memo(() => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <div className='preview-receipt__header'>
      <AppBackButton
        onBackClick={history.goBack}
        label={intl.messages.back as string}
      />
    </div>
  );
});
