import { DNDDropType, ReceiptId } from 'core.types';
import { useDrag } from 'react-dnd';
import { useRecoilValue } from 'recoil';
import {
  useSelectedIDsAtomSetters,
  useSetBooleanAtomState,
} from 'store/category-store/category-store.callbacks';
import {
  dragEnabledATOM,
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
} from '../store';

export const useReceiptDNDLogic = (id: ReceiptId) => {
  const selectedReceiptIdsList = useRecoilValue(selectedReceiptIDsAtom);
  const [_1, _2, clearSelectedCategories] = useSelectedIDsAtomSetters(
    selectedCategoryIDsAtom,
  );
  const [addSelectedReceipt, _3, clearSelectedReceipts] =
    useSelectedIDsAtomSetters(selectedReceiptIDsAtom);
  const [enabledDrag, disabledDrag] = useSetBooleanAtomState(dragEnabledATOM);
  const [_4, disabledEditMode] = useSetBooleanAtomState(editModeEnabledATOM);

  const [_5, drag] = useDrag({
    item: { id, type: DNDDropType.receipt },
    begin: () => {
      enabledDrag();
      disabledEditMode();

      if (!selectedReceiptIdsList.includes(id)) {
        addSelectedReceipt(id);
      }
    },
    end: () => {
      clearSelectedCategories();
      clearSelectedReceipts();
      disabledDrag();
    },
    collect: (monitor) => ({
      isDraggable: !!monitor.isDragging(),
    }),
  });

  return { drag };
};
