import { loader } from 'graphql.macro';
import { useCustomMutation } from '../../../hooks/use-server-logic';

const mutationFacebookLogin = loader('./mutationFacebookLogin.graphql');
const mutationGoogleLogin = loader('./mutationGoogleLogin.graphql');
const mutationAppleLogin = loader('./mutationAppleLogin.graphql');

export const useRegisterBySocial = () => {
  const [_mutationFacebook, { data: dataFacebook, loading: loadingFacebook }] =
    useCustomMutation(mutationFacebookLogin);
  const [_mutationGoogle, { data: dataGoogle, loading: loadingGoogle }] =
    useCustomMutation(mutationGoogleLogin);
  const [_mutationApple, { data: dataApple, loading: loadingApple }] =
    useCustomMutation(mutationAppleLogin);

  const registerBySocial = async (unsetLoading, access_token, type) => {
    try {
      if (type === 'fb') {
        await _mutationFacebook({
          variables: {
            access_token,
          },
        });
      } else if (type === 'go') {
        await _mutationGoogle({
          variables: {
            access_token,
          },
        });
      } else if (type === 'ap') {
        await _mutationApple({
          variables: {
            access_token,
          },
        });
      }
    } catch (error) {
      console.warn(error.message);
    } finally {
      unsetLoading();
    }
  };

  let token = '';
  let accountId = null;

  if (dataFacebook) {
    token = dataFacebook.facebookLogin.token;
    accountId = dataFacebook.facebookLogin.account.id;
  } else if (dataGoogle) {
    token = dataGoogle.googleLogin.token;
    accountId = dataGoogle.googleLogin.account.id;
  } else if (dataApple) {
    token = dataApple.appleLogin.token;
    accountId = dataApple.appleLogin.account.id;
  }

  return {
    registerBySocial,
    token,
    accountId,
    loading: loadingFacebook || loadingGoogle || loadingApple,
  };
};
