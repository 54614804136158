import Point from './Point.js';

/*
 * TODO: Change this class to extend HTMLElement when it's stabilized and
 * available in most browsers. Right now it only works in Chrome.
 * https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements
 */

let inx = 0;

export default class HandleLine {
  constructor(parent, handle1, handle2, point, inx) {
    this.parent = parent;
    this.handle1 = handle1;
    this.handle2 = handle2;
    this.point = new Point(point.x, point.y);
    this.node = document.createElement('div');
    this.childNode = document.createElement('div');
    this.inx = inx;
  }

  updateByHandles() {
    this.updatePoint({
      x: (this.handle1.point.x + this.handle2.point.x) / 2,
      y: (this.handle1.point.y + this.handle2.point.y) / 2,
    });
  }

  updatePoint(point) {
    this.point = new Point(point.x, point.y);
    this.updateStyle();
  }

  updateStyle() {
    this.node.style.left =
      this.point.x + this.handle1.offsetX - this.node.offsetWidth / 2 + 'px';
    this.node.style.top =
      this.point.y + this.handle1.offsetY - this.node.offsetHeight / 2 + 'px';
    this.node.style.transform = this.getTransformRotateValue();
  }

  getTransformRotateValue() {
    let degree =
      (Math.atan2(
        this.handle2.point.y - this.handle1.point.y,
        this.handle2.point.x - this.handle1.point.x,
      ) *
        180) /
      Math.PI;
    return `rotate(${degree}deg)`;
  }

  init() {
    this.node.className = 'idwall-handle-line';
    this.node.dataset.inx = this.inx;
    this.node.appendChild(this.childNode);
    this.parent.appendChild(this.node);
    this.updateStyle();
  }
}
