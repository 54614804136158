import { useRequestDeviceNotifications } from 'hooks/use-request-device-notifications';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import { setFormServerErrors } from 'scenes/common-auth-logic';
import { clearAccountIdToken, setAccountIdToken } from 'services/auth-service';
import { useIsAuthorizedAtomState } from 'store/root-store';
import { useLoginByEmail as useLoginByEmailM } from '../../gql/api-user';
import { PAGE_ROUTES } from '../../page-routes';
import { HelperService } from '../../services';
import './login-page.scss';

type LoginDTO = {
  email: string;
  password: string;
};

export const useLoginLogic = () => {
  const { setIsAuthorised } = useIsAuthorizedAtomState();
  const requestDeviceNotifications = useRequestDeviceNotifications();
  const history = useHistory();

  const {
    loginByEmail: loginByEmailMutation,
    loginByEmailError,
    loading,
  } = useLoginByEmailM();

  const errorObject = HelperService.validationError(loginByEmailError);

  const onSubmit = useCallback(
    async (values: LoginDTO, _, { setError }) => {
      try {
        clearAccountIdToken();
        const {
          data: {
            loginByEmail: {
              token,
              account: { id },
            },
          },
        } = (await loginByEmailMutation({
          variables: values,
        })) as any;

        // !Note- order is important here - todo refactor
        setAccountIdToken(id);
        await setIsAuthorised(token);
        requestDeviceNotifications();
        history.push(PAGE_ROUTES.category);
      } catch (errorResponse: any) {
        setFormServerErrors(errorResponse, setError);
      }
    },
    [
      history,
      loginByEmailMutation,
      requestDeviceNotifications,
      setIsAuthorised,
    ],
  );

  const onLogoClick = useCallback(() => {
    history.push(PAGE_ROUTES.auth);
  }, [history]);

  return {
    loading,
    mutationError: errorObject,
    onLogoClick,
    onSubmit,
  };
};
