import React, { memo } from 'react';

export const LanguageIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M10 20C15.5062 20 20 15.5059 20 10C20 4.49375 15.5059 0 10 0C4.49379 0 0 4.4941 0 10C0 15.5063 4.4941 20 10 20ZM12.9667 18.3147C13.5333 17.5907 13.9676 16.7271 14.2938 15.859H16.5971C15.6211 16.957 14.3763 17.8102 12.9667 18.3147ZM17.4775 14.6875H14.6726C15.0184 13.4302 15.2213 12.0361 15.2644 10.5859H18.8082C18.7095 12.0855 18.2348 13.4838 17.4775 14.6875ZM17.4775 5.3125C18.2348 6.51621 18.7095 7.91453 18.8082 9.41406H15.2644C15.2213 7.96391 15.0184 6.56984 14.6726 5.3125H17.4775ZM16.5971 4.14062H14.2938C13.9677 3.27324 13.5334 2.40953 12.9666 1.68527C14.3763 2.18977 15.6211 3.04297 16.5971 4.14062ZM10.5859 1.27211C11.7358 1.66184 12.5607 3.02934 13.0325 4.14062H10.5859V1.27211ZM10.5859 5.3125H13.4542C13.8268 6.54602 14.0459 7.94543 14.0921 9.41406H10.5859V5.3125ZM10.5859 10.5855H14.0921C14.0459 12.0546 13.8268 13.454 13.4542 14.6875H10.5859V10.5855ZM10.5859 15.859H13.0325C12.5599 16.9725 11.7348 18.3385 10.5859 18.7279V15.859ZM3.40289 15.8594H5.70625C6.03234 16.7268 6.4666 17.5905 7.03336 18.3147C5.62371 17.8103 4.37887 16.957 3.40289 15.8594ZM9.41406 18.7279C8.26519 18.3385 7.4402 16.9727 6.96754 15.8594H9.41406V18.7279ZM9.41406 14.6875H6.54582C6.17324 13.454 5.95406 12.0546 5.90793 10.5859H9.41406V14.6875ZM9.41406 9.41406H5.90793C5.95406 7.94543 6.17324 6.54602 6.54582 5.3125H9.41406V9.41406ZM9.41406 1.27211V4.14062H6.96754C7.44016 3.02742 8.26512 1.66152 9.41406 1.27211ZM7.03332 1.68527C6.46672 2.4093 6.03246 3.27285 5.70621 4.14062H3.40289C4.37887 3.04297 5.62371 2.18973 7.03332 1.68527ZM2.52254 5.31211H5.32738C4.9816 6.56984 4.77875 7.96391 4.73563 9.41406H1.1918C1.29055 7.91453 1.76523 6.51621 2.52254 5.31211ZM1.1918 10.5859H4.73563C4.77875 12.0361 4.9816 13.4302 5.32738 14.6875H2.52254C1.76523 13.4838 1.29055 12.0855 1.1918 10.5859Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
