import React, { memo } from 'react';

export const PrintIcon = memo(
  ({ size = { width: 30, height: 30 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M25.3695 7.72388H24.5674V4.63013C24.5674 2.26083 22.6398 0.333252 20.2705 0.333252H9.72884C7.35954 0.333252 5.43197 2.26083 5.43197 4.63013V7.72388H4.62988C2.26059 7.72388 0.333008 9.65145 0.333008 12.0208V18.8958C0.333008 21.265 2.26059 23.1926 4.62988 23.1926H5.43197V27.0885C5.43197 28.51 6.58851 29.6666 8.01009 29.6666H21.9893C23.4108 29.6666 24.5674 28.51 24.5674 27.0885V23.1926H25.3695C27.7388 23.1926 29.6663 21.265 29.6663 18.8958V12.0208C29.6663 9.65145 27.7388 7.72388 25.3695 7.72388ZM7.15072 4.63013C7.15072 3.20855 8.30726 2.052 9.72884 2.052H20.2705C21.6921 2.052 22.8486 3.20855 22.8486 4.63013V7.72388H7.15072V4.63013ZM22.8486 27.0885C22.8486 27.5623 22.4631 27.9478 21.9893 27.9478H8.01009C7.53623 27.9478 7.15072 27.5623 7.15072 27.0885V18.6093H22.8486V27.0885ZM27.9476 18.8958C27.9476 20.3173 26.791 21.4739 25.3695 21.4739H24.5674V18.6093H25.083C25.5576 18.6093 25.9424 18.2245 25.9424 17.7499C25.9424 17.2753 25.5576 16.8905 25.083 16.8905H4.91634C4.44174 16.8905 4.05697 17.2753 4.05697 17.7499C4.05697 18.2245 4.44174 18.6093 4.91634 18.6093H5.43197V21.4739H4.62988C3.2083 21.4739 2.05176 20.3173 2.05176 18.8958V12.0208C2.05176 10.5992 3.2083 9.44263 4.62988 9.44263H25.3695C26.791 9.44263 27.9476 10.5992 27.9476 12.0208V18.8958Z'
          fill={color}
        />
        <path
          d='M17.2913 20.5571H12.708C12.2334 20.5571 11.8486 20.9419 11.8486 21.4165C11.8486 21.8911 12.2334 22.2759 12.708 22.2759H17.2913C17.7659 22.2759 18.1507 21.8911 18.1507 21.4165C18.1507 20.9419 17.7659 20.5571 17.2913 20.5571Z'
          fill={color}
        />
        <path
          d='M17.2913 24.2239H12.708C12.2334 24.2239 11.8486 24.6086 11.8486 25.0833C11.8486 25.5579 12.2334 25.9426 12.708 25.9426H17.2913C17.7659 25.9426 18.1507 25.5579 18.1507 25.0833C18.1507 24.6086 17.7659 24.2239 17.2913 24.2239Z'
          fill={color}
        />
        <path
          d='M7.66601 11.3906H4.91602C4.44141 11.3906 4.05664 11.7754 4.05664 12.25C4.05664 12.7246 4.44141 13.1094 4.91602 13.1094H7.66601C8.14062 13.1094 8.52539 12.7246 8.52539 12.25C8.52539 11.7754 8.14062 11.3906 7.66601 11.3906Z'
          fill={color}
        />
      </svg>
    );
  },
);
