import { useGetUser } from 'gql/api-user';
import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from 'store/user-store';

const mutationAddEmail = loader('./mutationAddEmail.graphql');
const mutationDeleteEmail = loader('./mutationDeleteEmail.graphql');

export const useChangeEmail = () => {
  const { fetchUser } = useGetUser();
  const user = useRecoilValue(currentUserAtom);

  const [_mutationAddEmail, { loading: processingAddEmail }] =
    useCustomMutation(mutationAddEmail);
  const [_mutationDeleteEmail, { loading: processingDeleteEmail }] =
    useCustomMutation(mutationDeleteEmail);

  const addEmail = (email: string) =>
    (() => {
      try {
        return _mutationAddEmail({
          variables: { email },
        }).then((e) => {
          // todo update user locally
          fetchUser();
        });
      } catch (error) {
        console.warn(error);
      }
    })();

  const deleteEmail = (id: number) =>
    (() => {
      try {
        return _mutationDeleteEmail({
          variables: { id },
        }).then((e) => {
          fetchUser();
        });
      } catch (error) {
        console.warn(error);
      }
    })();

  return {
    loading: processingDeleteEmail || processingAddEmail,
    dataEmails: user,
    deleteEmail,
    addEmail,
  };
};
