import React, { memo } from 'react';

export const LogoutIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.72116 5.69531L6.3156 5.69531L6.3156 2.7265C6.3156 1.49874 7.26138 0.500001 8.42387 0.500001L16.8919 0.500002C18.0542 0.500002 19 1.49874 19 2.7265L19 17.2735C19 18.5011 18.0542 19.5 16.8919 19.5L8.42387 19.5C7.26138 19.5 6.3156 18.5011 6.3156 17.2735L6.3156 14.3047L7.72116 14.3047L7.72116 17.2735C7.72116 17.6827 8.03633 18.0157 8.42387 18.0157L16.8919 18.0157C17.2793 18.0157 17.5946 17.6827 17.5946 17.2735L17.5946 2.7265C17.5946 2.31734 17.2793 1.98433 16.8919 1.98433L8.42387 1.98433C8.03633 1.98433 7.72116 2.31734 7.72116 2.7265L7.72116 5.69531ZM4.31279 13.4989L5.30664 12.4492L3.69041 10.7421L12.7808 10.7421L12.7808 9.25776L3.69041 9.25776L5.30664 7.55079L4.31279 6.50115L0.999999 9.99993L4.31279 13.4989Z'
          fill={color}
        />
      </svg>
    );
  },
);
