import type { ReactNode } from 'react';
import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import './app-form.scss';

export type AppFormProps = {
  onSubmit(values: Record<string, any>, event: any, methods: any): void;
  children: ReactNode;
  className?: string;
  formConfig?: any;
};

export const AppForm = memo(
  ({ children, className, formConfig, onSubmit }: AppFormProps) => {
    const methods = useForm({ ...formConfig, reValidateMode: 'onChange' });

    return (
      <FormProvider {...methods}>
        <form
          className={className}
          onSubmit={methods.handleSubmit((payload, event) =>
            onSubmit(payload, event, methods),
          )}
        >
          {children}
        </form>
      </FormProvider>
    );
  },
);
