import { FC, memo } from 'react';
import { IonLabel } from '@ionic/react';
import c from 'classnames';

import './app-ion-label.scss';

type AppIonLabelProps = {
  label: string;
  className?: string;
};

export const AppIonLabel: FC<AppIonLabelProps> = memo(
  ({ label, className }) => {
    return (
      <IonLabel className={c('app-ion-label', { [`${className}`]: className })}>
        {label}
      </IonLabel>
    );
  },
);
