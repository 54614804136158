import React, { memo } from 'react';

export const HomeIcon = memo(
  ({ size = { width: 36, height: 32 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 36 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M29.1408 12.9575V29.3713H23.094V22.2725C23.094 21.6501 22.6018 21.1457 21.9946 21.1457H14.2621C13.6548 21.1457 13.1627 21.6501 13.1627 22.2725V29.3713H7.15254V12.9575H4.95375V30.4981C4.95375 31.1205 5.44594 31.6249 6.05315 31.6249H14.2621C14.8694 31.6249 15.3615 31.1205 15.3615 30.4981V23.3993H20.8952V30.4982C20.8952 31.1206 21.3874 31.625 21.9946 31.625H30.2402C30.8475 31.625 31.3396 31.1205 31.3396 30.4982V12.9575H29.1408Z'
          fill={color}
        />
        <path
          d='M34.8394 15.9661L18.8978 0.679158C18.4782 0.276129 17.8259 0.273514 17.4027 0.672002L1.16799 15.959C0.720917 16.3801 0.691577 17.0926 1.1024 17.5508C1.31899 17.7931 1.61507 17.9156 1.91229 17.9156C2.17796 17.9156 2.44477 17.8172 2.65585 17.6184L18.14 3.03829L33.3371 17.6117C33.7813 18.0372 34.4762 18.0129 34.891 17.5587C35.3055 17.1042 35.2828 16.3913 34.8394 15.9661Z'
          fill={color}
        />
      </svg>
    );
  },
);
