import { memo } from 'react';

import './preview-receipt.scss';

type PreviewReceiptInfoItemProps = {
  title?: string;
  value?: string;
};

export const PreviewReceiptInfoItem = memo(
  ({ title = '', value = '' }: PreviewReceiptInfoItemProps) => (
    <div className='preview-receipt__info-item'>
      <div className='preview-receipt__info-item-title'>{title}</div>
      <div className='preview-receipt__info-item-value'>{value}</div>
    </div>
  ),
);
