import { CategoryTreeContext } from 'category-tree-provider';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { AppHasAccess } from 'components/app-has-access';
import { AppType, ReceiptType } from 'core.types';
import { useBooleanState } from 'hooks/use-boolean-state';
import { memo, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { hasAppAccess } from 'services/app-service';
import {
  getDateFromUnixTimestamp,
  getUnixTimestampFromDate,
} from 'services/HelperService';
import { AppIcon, DefaultInput } from '../components';
import { AppDatePicker } from '../components/app-date-picker';
import { AppNumberInput } from '../components/app-number-input';
import { CreateReceiptModalTree } from './create-receipt-modal-tree';
import { UpdateReceiptDTO } from './use-create-receipt-logic';

export const useReminders = true;

type CreateReceiptInputSectionProps = {
  formState: UpdateReceiptDTO;
  receiptType: ReceiptType;
  setField(field: keyof UpdateReceiptDTO, value: any): void;
};

const NAME_MAX_LENGTH = 70;

export const INPUT_SECTION_PREFIX = 'step-three-input-section';

export const CreateReceiptInputSection = memo(
  ({ setField, formState, receiptType }: CreateReceiptInputSectionProps) => {
    const intl = useIntl();
    const { activeSliceSelector } = useContext(CategoryTreeContext);
    const activeSlice = activeSliceSelector();
    const [treeModalOpened, openTreeModal, closeTreeModal] =
      useBooleanState(false);
    const onChangeAmount = useCallback(
      ({ currentTarget: { value } }: any) => {
        setField('amount', value);
      },
      [setField],
    );

    const onDateChange = useCallback(
      (date) => {
        setField('receiptDate', getUnixTimestampFromDate(date.value));
      },
      [setField],
    );

    const onRemindDateChange = useCallback(
      (date) => {
        setField('remindedAt', getUnixTimestampFromDate(date.value));
      },
      [setField],
    );

    const onChangeName = useCallback(
      (event) => {
        const {
          currentTarget: { value },
        } = event;
        if (value.length <= NAME_MAX_LENGTH) {
          setField('name', value);
        } else {
          event.preventDefault();
        }
      },
      [setField],
    );

    return (
      <div className={INPUT_SECTION_PREFIX} id={INPUT_SECTION_PREFIX}>
        {/* @ts-ignore */}
        <DefaultInput
          {...{
            value: formState.name,
            placeholder: intl.messages[
              hasAppAccess(AppType.ireceipt) ? 'receipt.name' : 'document.name'
            ] as string,
            onChange: onChangeName,
          }}
        />
        <AppHasAccess appType={AppType.ireceipt}>
          <AppNumberInput
            {...{
              value: formState.amount,
              placeholder: intl.messages.amount as string,
              onChange: onChangeAmount,
            }}
          />
        </AppHasAccess>
        {receiptType === ReceiptType.invoice && useReminders && (
          <AppDatePicker
            value={
              formState.remindedAt !== null
                ? getDateFromUnixTimestamp(formState.remindedAt)
                : getDateFromUnixTimestamp(formState.receiptDate)
            }
            onChange={onRemindDateChange}
            placeholder={intl.messages['menu.profile.date'] as string}
            iconName='clock'
            iconSize={{ width: 18, height: 18 }}
            timeSelection
          />
        )}
        <AppDatePicker
          value={getDateFromUnixTimestamp(formState.receiptDate)}
          onChange={onDateChange}
          placeholder={intl.messages['menu.profile.date'] as string}
          iconName='calendar'
        />

        <button
          onClick={openTreeModal}
          className={`${INPUT_SECTION_PREFIX}__button`}
        >
          {activeSlice?.name || 'Home'}
          <AppIcon
            name='folder-open'
            iconSize={{ width: 20, height: 20 }}
            color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
          />
        </button>

        <CreateReceiptModalTree {...{ treeModalOpened, closeTreeModal }} />
      </div>
    );
  },
);
