import { Capacitor } from '@capacitor/core';
import { waitUntilCategoryTreeInitialized } from 'category-tree-hooks/category-service';
import { PAGE_ROUTES } from 'page-routes';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { SendIntent } from 'send-intent';
import { newReceiptFileAtom } from 'store/category-store';

export const useOpenWith = () => {
  const history = useHistory();
  const setNewReceiptFile = useSetRecoilState(newReceiptFileAtom);

  const onFileOpenedWith = useCallback(
    async (result: any) => {
      if (!result.url) {
        return;
      }
      // @ts-ignore
      const resultUrl = window.Ionic.WebView.convertFileSrc(result.url);

      try {
        const response = await fetch(resultUrl);
        const blob = (await response.blob()) as Blob & { name: string };
        blob.name = resultUrl.split('/').pop();
        // @ts-ignore
        await setNewReceiptFile(blob);
        const isPDFReceipt = String(blob?.type).includes('pdf');
        await waitUntilCategoryTreeInitialized();

        history.push(
          `${PAGE_ROUTES.category}${
            isPDFReceipt ? PAGE_ROUTES.createReceipt : PAGE_ROUTES.cropStep
          }`,
        );
      } catch {}
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, setNewReceiptFile],
  );

  const setupOpenWith = useCallback(() => {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    SendIntent.checkSendIntentReceived().then(onFileOpenedWith);
    window.addEventListener('sendIntentReceived', () => {
      SendIntent.checkSendIntentReceived().then(onFileOpenedWith);
    });
  }, [onFileOpenedWith]);

  useEffect(() => {
    setupOpenWith();
  }, [setupOpenWith]);
};
