import { LocalCategory, LocalReceipt } from 'core.types';
import isEqual from 'lodash/isEqual';
import { memo, useCallback } from 'react';
import {
  CategoryItemTableRowFolder,
  CategoryItemTableRowReceipt,
} from './category-table-row';
import './category-table.scss';

export const CategoryItemTable = memo(
  ({ foldersList, emptyReceiptList, nonEmptyReceiptList }: any) => {
    const renderCategoryItemRow = (item: LocalCategory) => (
      <CategoryItemTableRowFolder {...item} key={item.id} />
    );

    const renderReceiptItemRow = (item: LocalReceipt) => (
      <CategoryItemTableRowReceipt {...item} key={item.id} />
    );

    const renderTableSeparator = useCallback(
      () =>
        Boolean(emptyReceiptList.length) && (
          <div className='category-table category-table__separator' />
        ),
      [emptyReceiptList.length],
    );

    return (
      <>
        <div className='category-table'>
          <div>{emptyReceiptList.map(renderReceiptItemRow)}</div>
        </div>
        {renderTableSeparator()}
        <div className='category-table'>
          <div>
            {foldersList.map(renderCategoryItemRow)}
            {nonEmptyReceiptList.map(renderReceiptItemRow)}
          </div>
        </div>
      </>
    );
  },
  isEqual,
);
