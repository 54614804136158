import { useEffect, useState } from 'react';
import { HelperService } from '../services';
import { Maybe } from '../core.types';
import { getLocalImage, preloadLocalImage } from '../services/HelperService';

export type PreloadImage = {
  src: string;
  height: number;
  width: number;
};

export const preloadImage = (
  src: string,
  isPdfFile?: boolean,
): Promise<PreloadImage> => {
  return new Promise((resolve) => {
    if (isPdfFile && !window.cordova) {
      window.preloadImage = {
        width: 0,
        height: 0,
        src: src,
      };
      resolve(window.preloadImage);
      return;
    }

    const imagePromise = isPdfFile
      ? getLocalImage(src)
      : preloadLocalImage(src);
    imagePromise.then((size) => {
      window.preloadImage = {
        width: size.width,
        height: size.height,
        src: src,
      };
      resolve(window.preloadImage);
    });
  });
};

export const usePreloadImage = (
  imagePath?: string | null,
  isPdfFile?: boolean,
): PreloadImage => {
  const src = HelperService.resolveFilePath(imagePath);
  const [preloadedImage, setPreloadImage] = useState<Maybe<PreloadImage>>(
    window.preloadImage || null,
  );

  useEffect(() => {
    if (!preloadedImage && imagePath) {
      preloadImage(src, isPdfFile).then((image: PreloadImage) => {
        setPreloadImage(image);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePath]);

  return preloadedImage as PreloadImage;
};
