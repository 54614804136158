import React, { memo } from 'react';

export const RotateIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M4.58099 3.96606C7.53375 1.2951 11.9527 1.25426 14.9463 3.69243L12.5735 3.78228C12.2672 3.79453 12.0303 4.04774 12.0425 4.35404C12.0548 4.65218 12.2998 4.88497 12.5939 4.88497C12.602 4.88497 12.6061 4.88497 12.6143 4.88497L16.2573 4.75019C16.5554 4.73794 16.7882 4.49698 16.7882 4.19885V4.15801C16.7882 4.14984 16.7882 4.14576 16.7882 4.13759V4.1335L16.6534 0.53138C16.6412 0.225077 16.3839 -0.0117975 16.0816 0.000454631C15.7753 0.0127068 15.5385 0.265917 15.5507 0.57222L15.6365 2.8307C14.154 1.61773 12.3284 0.91528 10.3762 0.817263C7.95849 0.694742 5.63467 1.5238 3.84177 3.14925C1.37501 5.38322 0.3295 8.78114 1.11364 12.0157C1.1749 12.2689 1.39952 12.4364 1.64864 12.4364C1.69357 12.4364 1.73441 12.4323 1.77933 12.42C2.07339 12.3465 2.25717 12.0484 2.18365 11.7543C1.49754 8.91183 2.41236 5.9264 4.58099 3.96606Z'
            fill={color}
          />
          <path
            d='M18.8873 7.98498C18.8138 7.69093 18.5157 7.50715 18.2216 7.58066C17.9276 7.65418 17.7438 7.95231 17.8173 8.24636C18.5075 11.0889 17.5886 14.0743 15.42 16.0346C13.8966 17.411 11.9853 18.0889 10.0821 18.0889C8.26473 18.0889 6.45142 17.4722 4.98933 16.2552L7.39075 16.0387C7.69297 16.0101 7.91759 15.7447 7.889 15.4384C7.86041 15.1321 7.59495 14.9115 7.28865 14.9401L3.65793 15.2668C3.35571 15.2954 3.13109 15.5609 3.15968 15.8672L3.4864 19.4979C3.51091 19.7838 3.75187 20.0002 4.03366 20.0002C4.05 20.0002 4.06634 20.0002 4.08267 19.9962C4.38489 19.9676 4.60951 19.7021 4.58093 19.3958L4.38489 17.1863C5.8674 18.387 7.6848 19.0854 9.62472 19.1834C9.77991 19.1916 9.9351 19.1957 10.0862 19.1957C12.3365 19.1957 14.4766 18.3707 16.1592 16.8514C18.6259 14.6175 19.6715 11.2236 18.8873 7.98498Z'
            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width={width} height={height} fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
