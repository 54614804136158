import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useRecoilCallback } from 'recoil';
import type { ServerErrorResponse } from '../core.types';
import { ServerErrorType } from '../core.types';
import { useLogout } from './use-logout';

export const useErrorMiddleware = () => {
  const logout = useLogout();

  const errorMiddleware = useRecoilCallback(
    () => async (errorResponse: ServerErrorResponse) => {
      const errors = errorResponse.graphQLErrors || errorResponse.errors;

      if (!errors) {
        return;
      }

      for (const error of errors) {
        switch (error.type) {
          case ServerErrorType.TYPE_CODE_TOKEN_ISSUE:
          case ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER:
            await logout();
            break;
          case ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE:
          case ServerErrorType.TYPE_CODE_OFFLINE_ERROR:
          case ServerErrorType.TYPE_CODE_ONLINE_ERROR:
            const toastCaller =
              error.type === ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE
                ? toast.info
                : toast.error;

            toastCaller(error.message, {
              autoClose: 3000,
              position: 'bottom-center',
            });
            break;
        }
      }
    },
  );

  return errorMiddleware;
};

export const useCustomMutation = <T>(mutation: any, ...restOptions: any[]) => {
  const [_mutation, ...rest] = useMutation<T>(mutation, ...restOptions);
  const errorMiddleware = useErrorMiddleware();

  const customMutation = async (options?: any) => {
    try {
      return await _mutation(options);
    } catch (errorResponse) {
      await errorMiddleware(errorResponse as ServerErrorResponse);
      throw errorResponse;
    }
  };

  // @ts-ignore
  return [customMutation, ...rest] as ReturnType<typeof useMutation>;
};
