import { Maybe, ServerErrorResponse } from 'core.types';
import request from 'graphql-request';
import { loader } from 'graphql.macro';
import { useBooleanState } from 'hooks/use-boolean-state';
import { useCustomMutation, useErrorMiddleware } from 'hooks/use-server-logic';
import { getRequestHTTPHeaders } from 'ir-apollo-client';
import { useRecoilCallback } from 'recoil';
import { fileDownloadQueueTable } from 'services';
import { setAccountIdToken } from 'services/auth-service';
import {
  activeSliceIdAtom,
  lastCategoryUpdateAtom,
  lastReceiptUpdateAtom,
  rootIDAtom,
} from 'store/category-store';
import { getSnapshotValue } from 'store/root-store';
import {
  currentUserAtom,
  currentUserSharedAccountSelector,
  getUserSharedAccount,
} from 'store/user-store';
import { userQuery } from './api-user-queries';
import { AvailableAccountShare, UserPayload } from './api-user.types';

const mutationLoginByEmail = loader('./mutationLoginByEmail.graphql');
const mutationRemoveUser = loader('./remove-user.mutation.graphql');

export const useGetUser = () => {
  const [userLoading, setUserLoading, unsetUserLoading] =
    useBooleanState(false);
  const errorMiddleware = useErrorMiddleware();
  const resetRecoilLastUpdatedBasedOnSharedAccounts =
    useResetRecoilLastUpdatedBasedOnSharedAccounts();

  const fetchUser = useRecoilCallback(
    ({ set }) =>
      async () => {
        //@ts-ignore
        if (!window.onlineDispatcher.isOnline) {
          return;
        }

        try {
          setUserLoading();
          const data = await request<UserPayload>(
            // @ts-ignore
            process.env.REACT_APP_SINGLE_QL_LINK,
            userQuery,
            null,
            getRequestHTTPHeaders().headers,
          );

          const newCurrentSharedAccount = getUserSharedAccount(data);

          resetRecoilLastUpdatedBasedOnSharedAccounts(newCurrentSharedAccount);
          unsetUserLoading();
          set(currentUserAtom, data);
          return data;
        } catch (errorResponse) {
          await errorMiddleware(
            (errorResponse as any)?.response as ServerErrorResponse,
          );
        }
      },
    [setUserLoading, unsetUserLoading],
  );

  return {
    fetchUser,
    userLoading,
  } as const;
};

export const useLoginByEmail = () => {
  // @ts-ignore
  const [loginByEmail, { data, errors, error, loading }] =
    useCustomMutation(mutationLoginByEmail);
  return { loginByEmail, loginByEmailError: error, data, errors, loading };
};

export const useSwitchUser = () => {
  const { fetchUser } = useGetUser();

  return useRecoilCallback(({ set }) => async (account_id: number) => {
    setAccountIdToken(account_id);
    window.localStorage.removeItem('root-id');
    await Promise.all([fileDownloadQueueTable.clear(), fetchUser()]);
    set(rootIDAtom, null);
    set(activeSliceIdAtom, null);
  });
};

export const useRemoveUser = () => {
  // @ts-ignore
  const [removeUser] = useCustomMutation(mutationRemoveUser);
  return { removeUser };
};

export const useResetRecoilLastUpdatedBasedOnSharedAccounts = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async (newCurrentSharedAccount: Maybe<AvailableAccountShare>) => {
        const currentUser = getSnapshotValue(snapshot, currentUserAtom);
        const currentSharedAccount = getSnapshotValue(
          snapshot,
          currentUserSharedAccountSelector,
        );

        if (currentUser && newCurrentSharedAccount) {
          // TODO use immer
          set(currentUserAtom, {
            ...currentUser,
            user: {
              ...currentUser.user,
              account: {
                ...currentUser.user.account,
                availableAccountShares:
                  currentUser?.user.account.availableAccountShares.map(
                    (sharedAccount) => {
                      if (
                        sharedAccount.accountFrom.id ===
                          newCurrentSharedAccount.accountFrom.id &&
                        sharedAccount.accountTo.id ===
                          newCurrentSharedAccount.accountTo.id
                      ) {
                        return newCurrentSharedAccount;
                      }
                      return sharedAccount;
                    },
                  ),
              },
            },
          });
        }

        if (
          currentSharedAccount?.canViewGroup === true &&
          newCurrentSharedAccount?.canViewGroup === false
        ) {
          set(lastReceiptUpdateAtom, null);
          set(lastCategoryUpdateAtom, null);
        }
      },
  );
