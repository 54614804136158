import React, { memo } from 'react';

export const ArrowLeftIcon = memo(
  ({ size = { width: 12, height: 22 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 12 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.04338 11.0051L11.6036 1.44487C11.9341 1.11431 11.9341 0.578399 11.6036 0.247843C11.273 -0.0826143 10.7371 -0.0826143 10.4066 0.247843L0.247843 10.4066C-0.0826143 10.7371 -0.0826143 11.2731 0.247843 11.6036L10.4066 21.7624C10.7429 22.0872 11.2788 22.0778 11.6036 21.7415C11.9204 21.4135 11.9204 20.8934 11.6036 20.5653L2.04338 11.0051Z'
          fill={color}
        />
      </svg>
    );
  },
);
