import React, { memo } from 'react';

export const ChangeAccountIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.1007 9.55936L14.8938 10.4406L18.8312 6.06569C19.0493 5.82191 19.0493 5.42825 18.8312 5.18446L14.8938 0.80957L14.1007 1.69705L17.0819 5.00322H1.00043V6.25319H17.0819L14.1007 9.55936Z'
          fill={color}
        />
        <path
          d='M19 13.753H2.91853L5.89971 10.4469L5.10098 9.55939L1.16358 13.9343C0.945474 14.1781 0.945474 14.5717 1.16358 14.8155L5.10098 19.1904L5.89408 18.3092L2.91853 15.003H19V13.753Z'
          fill={color}
        />
      </svg>
    );
  },
);
