import { AppButton } from 'components/app-button';
import { AppIcon } from 'components/app-icon';
import { memo, SyntheticEvent } from 'react';
import { DEFAULT_CLEAR_ICON_SIZE } from './default-input';
import { DEFAULT_INPUT_CLASSES } from './default-input.constants';
import c from 'classnames';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';

type InputClearableButtonProps = {
  clearable: boolean;
  value: string | number;
  onChange(event: SyntheticEvent<HTMLInputElement>): void;
  isSecondIcon?: boolean;
};

export const InputClearableButton = memo(
  ({
    clearable,
    value,
    onChange,
    isSecondIcon = false,
  }: InputClearableButtonProps) => {
    const onClearPress = () => {
      onChange({
        currentTarget: { value: '' },
      } as SyntheticEvent<HTMLInputElement>);
    };

    if (!clearable || !value) {
      return null;
    }

    return (
      <AppButton
        onPress={onClearPress}
        className={DEFAULT_INPUT_CLASSES.button}
      >
        <AppIcon
          className={c(
            DEFAULT_INPUT_CLASSES.icon,
            DEFAULT_INPUT_CLASSES.clearIcon,
            {
              [DEFAULT_INPUT_CLASSES.clearIconLeftSide]: isSecondIcon,
            },
          )}
          color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
          name='close'
          size={DEFAULT_CLEAR_ICON_SIZE}
        />
      </AppButton>
    );
  },
);
