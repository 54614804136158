import { useReceiptDNDLogic } from '../../use-receipt-dnd-logic';
import { memo } from 'react';
import Tappable from 'react-tappable';
import { useRecoilValue } from 'recoil';
import { isReceiptEmpty } from 'category-tree-hooks/category-service';
import { editModeEnabledATOM } from 'store/category-store';
import { AppPseudoRadio } from '../../../components/app-pseudo-radio';
import type { LocalReceipt } from '../../../core.types';
import { HelperService } from '../../../services';
import '../../category.scss';
import { useReceiptItemLogic } from '../../use-receipt-item-logic';
import { CategoryGridTileDescription } from './category-grid-tile-description';
import { CategoryGridTileImage } from './category-grid-tile-image';

export const CategoryReceiptTile = memo((localReceipt: LocalReceipt) => {
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const {
    onItemClick,
    date,
    currency,
    isItemActive,
    onItemDescriptionCLick,
    itemName,
  } = useReceiptItemLogic(localReceipt);
  const { createdBy, thumbPath } = localReceipt;
  const isEmpty = isReceiptEmpty(localReceipt);
  const { drag } = useReceiptDNDLogic(localReceipt.id);

  return (
    <div className='category-grid__tile' ref={drag}>
      {editModeEnabled && (
        <AppPseudoRadio
          {...{
            isItemActive,
            className: 'category-grid__tile-indicator',
            onClick: onItemClick,
          }}
        />
      )}
      <Tappable onTap={onItemClick}>
        <CategoryGridTileImage
          imagePath={HelperService.resolveFilePath(thumbPath)}
        />
      </Tappable>
      <CategoryGridTileDescription
        {...{
          name: itemName as string,
          isEmpty,
          amount: localReceipt.amount,
          currency,
          onClick: onItemDescriptionCLick,
          createdBy,
          date: date as string,
        }}
      />
    </div>
  );
});
