import { useCallback, useState } from 'react';
import { DefaultInput } from '../../../../components';
import { AppSubscriptionId } from 'core.types';
import { Glassfy } from 'capacitor-plugin-glassfy';
import { AppModal } from '../../../../components/app-modal';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';

export const Promo = ({
  appSubscriptionId,
}: {
  appSubscriptionId: AppSubscriptionId;
}) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [promo, setPromo] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();

  const getAddEmailModalContent = useCallback(
    () => (
      // @ts-ignore
      <DefaultInput
        value={promo}
        placeholder=''
        onChange={({ currentTarget }: any) => setPromo(currentTarget.value)}
      />
    ),
    [promo],
  );

  const onSaveClick = useCallback(async () => {
    if (loading || !promo) return;
    setLoading(true);

    try {
      await Glassfy.connectGlassfyUniversalCode({
        universalCode: promo,
        force: true,
      });
      setModalOpened(false);
      setPromo('');

      toast.success('Promo code is applied ', {
        autoClose: 3000,
        position: 'bottom-center',
      });
    } catch (e) {
      toast.error(`${(e as any).message}`, {
        autoClose: 3000,
        position: 'bottom-center',
      });
      setPromo('');
    } finally {
      setLoading(false);
    }
  }, [loading, promo]);

  const openModal = (e) => {
    e.stopPropagation();
    setModalOpened(true);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={stopPropagation} onTouchEnd={stopPropagation}>
      <span
        className='plan__promo-link'
        onClick={openModal}
        onTouchEnd={openModal}
      >
        <FormattedMessage id='menu.profile.enter-promo' />
      </span>

      <AppModal
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        content={getAddEmailModalContent()}
        title={intl.messages['menu.profile.redeem-promo'] as string}
        saveButtonLabel={intl.messages['menu.profile.redeem'] as string}
        onSaveClick={onSaveClick}
        saveDisabled={loading}
      />
    </div>
  );
};
