import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { AppIcon } from 'components/app-icon';
import { AppModal } from 'components/app-modal';
import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';

type AddPhoneModalProps = {
  open: boolean;
  closeAddPhoneModal(): void;
  addPhone(phone: string): void;
};

export const AddPhoneModal = memo(
  ({ open, closeAddPhoneModal, addPhone }: AddPhoneModalProps) => {
    const intl = useIntl();
    const [phone, setPhone] = useState('');
    const [validFormatPhone, setValidFormatPhone] = useState(false);
    const normalisePhone = (phone: string) => phone.replace(/ /g, '');

    const onChangePhone = (value: string, data: any) => {
      setPhone(value);
      if (!data.format) {
        setValidFormatPhone(value.length > 8);
      } else {
        setValidFormatPhone(data.format.length === value.length);
      }
    };

    const onCloseClick = () => {
      setPhone('');
      closeAddPhoneModal();
    };

    const onSaveClick = async () => {
      if (validFormatPhone) {
        await addPhone(normalisePhone(phone));
        onCloseClick();
      }
    };

    return (
      <AppModal
        open={open}
        content={
          <div className='add-phone__input-wrapper'>
            <PhoneInput
              country='no'
              value={phone}
              onChange={onChangePhone}
              inputClass='add-phone-input'
            />
            <AppIcon
              name='phone'
              className='add-phone__phone-icon'
              color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
            />
          </div>
        }
        onSaveClick={onSaveClick}
        onClose={onCloseClick}
        title={intl.messages['menu.profile.phone.new'] as string}
      />
    );
  },
);
