import { memo } from 'react';
import { AppIcon } from '../app-icon';
import './app-forward-button.scss';
import { AppButton } from 'components/app-button';

type AppForwardButtonProps = {
  onNextClick(): void;
  label: string;
};

export const AppForwardButton = memo(
  ({ onNextClick, label }: AppForwardButtonProps) => {
    return (
      <AppButton className='app-forward-button' onPress={onNextClick}>
        <div className='app-forward-button__name'> {label} </div>
        <AppIcon className='app-forward-button__icon' name='arrow-right' />
      </AppButton>
    );
  },
);
