import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { waitUntilCategoryTreeInitialized } from 'category-tree-hooks/category-service';
import { CategoryTreeContext } from 'category-tree-provider';
import { PAGE_ROUTES } from 'page-routes';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilCallback } from 'recoil';
import { receiptToEditAtom } from 'store/category-store';
import { getSnapshotValue } from 'store/root-store';
import { currentAccountIdSelector } from 'store/user-store';

export const useInitPushNotifications = () => {
  const { getReceiptById } = useContext(CategoryTreeContext);
  const history = useHistory();

  const onNotificationReceived = useRecoilCallback(
    ({ set, snapshot }) =>
      async (notification: ActionPerformed) => {
        const {
          notification: {
            data: { receiptId, accountId },
          },
        } = notification;

        await waitUntilCategoryTreeInitialized();

        const currentAccountId = getSnapshotValue(
          snapshot,
          currentAccountIdSelector,
        );

        if (Number(accountId) !== Number(currentAccountId)) {
          return;
        }

        const receiptToEdit = getReceiptById(Number(receiptId));
        set(receiptToEditAtom, receiptToEdit);
        history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.previewReceipt}`);
      },
  );

  useEffect(() => {
    if (window.cordova) {
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('PushNotification received', notification);
        },
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        onNotificationReceived,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
