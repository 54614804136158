import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import { FacebookLogin as FacebookLoginMobile } from '@capacitor-community/facebook-login';
import {
  GoogleAuth,
  GoogleAuth as GoogleLoginMobile,
} from '@codetrix-studio/capacitor-google-auth';
import Button from '@material-ui/core/Button';
import cx from 'classnames';
import { AppLogo } from 'components/app-logo/app-logo';
import { useGetIOSKeyboardHeight } from 'hooks/use-get-ios-keyboard-height';
import { useRequestDeviceNotifications } from 'hooks/use-request-device-notifications';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import Tappable from 'react-tappable';
import { getAppName } from 'services/app-service';
import { AppIcon, AppLoader } from '../../components';
import { PAGE_ROUTES } from '../../page-routes';
import { setAccountIdToken } from '../../services/auth-service';
import { useIsAuthorizedAtomState } from '../../store';
import './Auth.scss';
import { useRegisterBySocial } from './gql';
import { useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { useBooleanState } from '../../hooks';

const appType = process.env.REACT_APP_PROJECT_TYPE;
const appleSignInOptions: SignInWithAppleOptions = {
  clientId: 'io.' + appType,
  redirectURI: 'https://' + appType + '.io',
  scopes: 'email name',
  state: '12345',
  nonce: 'nonce',
};

export const Auth = () => {
  const history = useHistory();
  const { registerBySocial, token, loading, accountId } = useRegisterBySocial();
  const { setIsAuthorised } = useIsAuthorizedAtomState();
  const keyboardHeight = useGetIOSKeyboardHeight();
  const lang = localStorage.getItem('locale');
  const requestDeviceNotifications = useRequestDeviceNotifications();
  const [socialLoading, setLoading, unsetLoading] = useBooleanState(false);
  useEffect(() => {
    if (!isPlatform('capacitor')) {
      const googleClientId =
        process.env.REACT_APP_PROJECT_TYPE == 'ireceipt'
          ? process.env.REACT_APP_IRECEIPT_GOOGLE_WEB_CLIENT_ID
          : process.env.REACT_APP_IDOCUMENT_GOOGLE_WEB_CLIENT_ID;

      const facebookAppId =
        process.env.REACT_APP_PROJECT_TYPE === 'ireceipt'
          ? process.env.REACT_APP_IRECEIPT_FACEBOOK_APP_ID
          : process.env.REACT_APP_IDOCUMENT_FACEBOOK_APP_ID;

      GoogleAuth.initialize({
        clientId: googleClientId + '.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
      FacebookLoginMobile.initialize({ appId: facebookAppId });
    }
  }, []);

  if (token) {
    // !Note- order is important here - todo refactor
    setAccountIdToken(accountId);
    setIsAuthorised(token).then(() => {
      requestDeviceNotifications();
      history.push(PAGE_ROUTES.category);
    });
  }

  const APPLELogin = () => {
    setLoading();
    SignInWithApple.authorize(appleSignInOptions)
      .then((result: SignInWithAppleResponse) => {
        registerBySocial(unsetLoading, JSON.stringify(result), 'ap');
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        unsetLoading();
      });
  };

  const FBLogin = () => {
    setLoading();
    FacebookLoginMobile.login({ permissions: ['public_profile', 'email'] })
      .then((result) => {
        // @ts-ignore
        registerBySocial(unsetLoading, result.accessToken.token, 'fb');
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        unsetLoading();
      });
  };

  const GlLogin = () => {
    setLoading();
    GoogleLoginMobile.signIn()
      .then((credential) => {
        registerBySocial(
          unsetLoading,
          credential.authentication.accessToken,
          'go',
        );
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        unsetLoading();
      });
  };

  return (
    <div
      className='auth-page__wrapper'
      style={{ paddingBottom: keyboardHeight }}
    >
      <div className={`auth__wrap ${loading ? 'disabled' : ''}`}>
        <Link to='/auth'>
          <div className='auth__img align-center'>
            <AppLogo />
          </div>
        </Link>
        <h1 className='auth__app-name'>{getAppName()}</h1>

        <div
          className={cx('auth__text', 'align-center', {
            'auth__text--nor': lang === 'no',
          })}
        >
          <p>
            <FormattedMessage id='auth.p' />
          </p>
        </div>

        <div className='auth-login__btn align-center'>
          <Link to='/login'>
            <Button variant='contained' color='primary'>
              <FormattedMessage id='btn.login' />
            </Button>
          </Link>
          <Link to='/sign-up'>
            <Button variant='contained' className='MuiButton-containedGreen'>
              <FormattedMessage id='btn.sign' />
            </Button>
          </Link>
        </div>

        <div className='auth__social align-center'>
          <p>
            <FormattedMessage id='auth.social' />
          </p>
          <div className='auth-social__btn'>
            {isPlatform('capacitor') && (
              <Tappable component='button' onTap={APPLELogin}>
                <AppIcon className='apple-sign-in-icon' name='apple' />
              </Tappable>
            )}

            <Tappable component='button' onTap={FBLogin}>
              <AppIcon name='facebook' />
            </Tappable>
            <Tappable component='button' onTap={GlLogin}>
              <AppIcon name='google' />
            </Tappable>
            {socialLoading && <AppLoader />}
          </div>
          <div className='copyright'>
            <p>
              © New Conception AS
              <a href='https://ireceipt.io/privacy_policy.html'>
                Privacy policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
