import c from 'classnames';
import { AppButton } from 'components/app-button';
import { RECEIPT_STYLE, RECEIPT_STYLE_TEXT_MAP } from '../crop-step.constants';
import './crop-step-style-filters.scss';

type CropStepStyleFilterProps = {
  onClick(): void;
  type: RECEIPT_STYLE;
  styleFilter: string;
};

export const CropStepStyleFilter = ({
  onClick,
  type,
  styleFilter,
}: CropStepStyleFilterProps) => {
  const title = RECEIPT_STYLE_TEXT_MAP[type];
  const selectedStyle = styleFilter === type;

  return (
    <AppButton
      className={c('style-filter', { 'style-filter-active': selectedStyle })}
      onPress={onClick}
      disabled={selectedStyle}
    >
      <img
        src={window.newPoly.miniImages[type] || ''}
        alt=''
        className='style-filter-image'
      />
      <span className='style-filter-label'>{title}</span>
    </AppButton>
  );
};
