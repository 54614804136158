import { AppButton } from 'components/app-button';
import { CategoryId, Maybe } from 'core.types';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { currentUserIconSelector } from 'store/user-store';
import { useDetectMobileResolution } from '../../hooks';
import {
  activeSliceIdAtom,
  rootIDAtom,
  useSetScrollPosition,
} from '../../store';
import { AppIcon } from '../app-icon';
import { AddReceiptButton } from './add-receipt-button';
import './app-footer.scss';

const ICON_MOBILE_SIZE = { width: 28, height: 25 };
const ICON_DESKTOP_SIZE = { width: 45, height: 40 };

export const AppFooter = memo(() => {
  const isMobile = useDetectMobileResolution();
  const history = useHistory();
  const userIconUrl = useRecoilValue(currentUserIconSelector);
  const setPositionHandler = useSetScrollPosition();
  const onHomeClick = useRecoilCallback(({ snapshot, set }) => () => {
    const rootId: Maybe<CategoryId> = snapshot.getLoadable(rootIDAtom).contents;
    if (rootId) {
      set(activeSliceIdAtom, rootId);
      setPositionHandler(rootId, 0);
    }
    history.push('/category');
  });

  const onProfileClick = () => {
    history.push('/settings');
  };

  const iconSize = useMemo(
    () => (isMobile ? ICON_MOBILE_SIZE : ICON_DESKTOP_SIZE),
    [isMobile],
  );

  return (
    <footer className='app-footer'>
      <AppButton onPress={onHomeClick} className='app-footer__item'>
        <AppIcon size={iconSize} name='home' />
        <FormattedMessage id='home' />
      </AppButton>

      <AddReceiptButton />

      <AppButton onPress={onProfileClick} className='app-footer__item'>
        {userIconUrl ? (
          <img
            src={userIconUrl}
            className='app-footer__right-item-avatar'
            alt='profile-pic'
          />
        ) : (
          <AppIcon size={iconSize} name='no-user' />
        )}
        <FormattedMessage id='profile' />
      </AppButton>
    </footer>
  );
});
