import { addClickHandle } from '../../../../services';
import c from 'classnames';
import { AppIcon } from '../../../../components';
import { APP_CSS_THEME_VARIABLES } from '../../../../color-theme.constants';
import './PermissionItem.scss';

export const PermissionItem = ({
  onClick,
  permissionLabel = 'Label',
  permissionInfo = 'Permission info',
  isActive = false,
}: any) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  return (
    <li className={'permission-item'}>
      <div
        {...addClickHandle(onClick)}
        className={'permission-item__input-wrapper'}
      >
        <input
          className='permission-item__input'
          defaultChecked={isActive}
          type='checkbox'
        />

        {isActive && (
          <AppIcon
            className='permission-item__input-icon'
            name='check'
            color={colorEmphasized}
          />
        )}

        <label
          className={c('permission-item__label', {
            'permission-item__label--active': isActive,
          })}
        >
          {permissionLabel}
        </label>
      </div>

      <div className='permission-item__info'>{permissionInfo}</div>
    </li>
  );
};
