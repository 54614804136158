import { Maybe } from 'core.types';
import {
  getUnixTimestampFromDate,
  HelperService,
} from 'services/HelperService';

export const getUnixTimeDates = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: Maybe<Date>;
  dateTo: Maybe<Date>;
}) => {
  const { dateFrom: defaultDateFrom, dateTo: defaultDateTo } =
    HelperService.getMonthPeriod();
  dateFrom = dateFrom || defaultDateFrom;
  dateTo = dateTo || defaultDateTo;
  const ONE_DAY = 1000 * 60 * 60 * 24 - 1;
  return {
    dateFrom: getUnixTimestampFromDate(dateFrom),
    dateTo: Math.floor((dateTo.getTime() + ONE_DAY) / 1000),
  };
};
