import { AppButton } from 'components/app-button';
import { RECOGNITION_TYPES } from 'core.types';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { AppIonSelect, AppIonSwitch } from '../../../../components';
import './recognition.scss';
import { useRecognitionLogic } from './use-recognition-logic';

export const Recognition = memo(() => {
  const intl = useIntl();
  const {
    recognitionSwitchOptions,
    recognitionType,
    onRecognitionTypeChange,
    recognitionLanguageSelectOptions,
    recognitionLanguages,
    onRecognitionLanguagesChange,
    onSaveButtonClick,
  } = useRecognitionLogic();

  const saveButtonDisabled =
    recognitionType !== RECOGNITION_TYPES.none && !recognitionLanguages?.length;

  return (
    <div className='recognition-edit__wrapper'>
      <AppIonSwitch
        label={`${intl.messages.recognition}:`}
        value={recognitionType}
        options={recognitionSwitchOptions}
        onChange={onRecognitionTypeChange}
      />
      <AppIonSelect
        className='recognition-edit__language-select'
        label={`${intl.messages['menu.lang']}:`}
        interfaceOptions={{
          header: intl.messages['menu.lang'],
        }}
        cancelText={String(intl.messages.cancel)}
        confirmText={String(intl.messages.save)}
        onChange={onRecognitionLanguagesChange}
        disabled={recognitionType === RECOGNITION_TYPES.none}
        value={recognitionLanguages}
        options={recognitionLanguageSelectOptions}
        isMultiple
      />

      <AppButton
        className='settings__save-button'
        onPress={onSaveButtonClick}
        disabled={saveButtonDisabled}
      >
        {intl.messages.save as string}
      </AppButton>
    </div>
  );
});
