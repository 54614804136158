import { memo, useRef } from 'react';
import { createObjectURL, HelperService } from 'services/HelperService';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useGetIOSKeyboardHeight } from 'hooks/use-get-ios-keyboard-height';
import noImage from '../../../../images/no-avatar-icon.svg';
export const MAX_PROFILE_IMAGE_SIZE = 600;

export const ProfileUploadPhoto = memo(({ setState, state }: any) => {
  const fileUpload = useRef(null);
  const keyboardHeight = useGetIOSKeyboardHeight();

  if (keyboardHeight) {
    return null;
  }

  return (
    <div
      onClick={() => {
        // @ts-ignore
        fileUpload.current.click();
      }}
      className='profile-avatar-block'
    >
      <div
        className={cx('avatar-wrapper', {
          'avatar-wrapper--no-image': !state.img,
        })}
      >
        <img
          className={'profile-pic'}
          src={state.img ? state.img : HelperService.resolvePathSvg(noImage)}
          alt='profile-pic'
        />
        <input
          onChange={(e: any) => {
            const image = new Image();
            image.onload = () => {
              HelperService.getBlob(
                HelperService.HTMLImageElementToCanvas(
                  image,
                  MAX_PROFILE_IMAGE_SIZE,
                ),
              ).then((e: any) => {
                setState({
                  ...state,
                  fileForSend: e.file,
                  img: e.url,
                  dirty: true,
                });
              });
            };

            // @ts-ignore
            image.src = createObjectURL(e.target.files[0]);
          }}
          ref={fileUpload}
          className='file-upload'
          type='file'
          accept='image/*'
        />
      </div>

      <div className={'profile-change-pic-text'}>
        <FormattedMessage id='menu.profile.photo' />
      </div>
    </div>
  );
});
