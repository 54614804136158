import c from 'classnames';
import { AppButton } from 'components/app-button';
import { memo } from 'react';
import { APP_CSS_THEME_VARIABLES } from '../../color-theme.constants';
import { AppIcon } from '../app-icon';
import './app-back-button.scss';

type AppBackButtonProps = {
  onBackClick(): void;
  label?: string;
  className?: string;
};

export const AppBackButton = memo(
  ({ onBackClick, label, className = '' }: AppBackButtonProps) => (
    <AppButton
      className={c('app-back-button', className)}
      onPress={onBackClick}
    >
      <AppIcon
        className='app-back-button__icon'
        name='arrow-left'
        color={`var(${APP_CSS_THEME_VARIABLES.actionText})`}
      />
      <div className='app-back-button__name'> {label} </div>{' '}
    </AppButton>
  ),
);
