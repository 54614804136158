import { memo, useCallback, useMemo } from 'react';
import './app-preview-receipt-image.scss';
import { TransformComponent, TransformWrapper } from './external';
import { PreloadImage } from '../../hooks/use-preload-image';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { Filesystem } from '@capacitor/filesystem';

type AppPreviewReceiptImageProps = {
  imageContainerHeight: number;
  isPDFFile: boolean;
  preloadImage: PreloadImage;
  name: string;
};

const ALIGNMENT_ANIMATION = { sizeX: 0, sizeY: 0 };

export const AppPreviewReceiptImage = memo(
  ({
    preloadImage,
    imageContainerHeight,
    isPDFFile,
    name,
  }: AppPreviewReceiptImageProps) => {
    const imageStyle = isPDFFile ? { backgroundColor: 'white' } : {};

    const onImagePDFMobileClick = useCallback(async () => {
      if (!isPDFFile) {
        return;
      }

      let srcPathArray = preloadImage.src.split('/');
      const varPrefixIndex = srcPathArray.findIndex(
        (prefix) => prefix === 'var',
      );
      srcPathArray = srcPathArray.slice(varPrefixIndex);

      const oldFile = 'file:///' + srcPathArray.join('/');
      if (name) {
        name = name.replace(/[\u0250-\ue007]/g, '').replace(/\s/g, '-');
      } else {
        name = 'iReceipt';
      }
      await Filesystem.copy({
        from: oldFile,
        to: window.cordova.file.tempDirectory + name,
      });

      FileOpener.open(
        window.cordova.file.tempDirectory + name,
        'application/pdf',
      )
        .then(() => console.log('File is opened'))
        .catch((e) => console.log(e));
    }, [isPDFFile, name, preloadImage.src]);

    const useHeight = useMemo(() => {
      const scaleX = document.body.offsetWidth / preloadImage.width;
      const scaleY = imageContainerHeight / preloadImage.height;

      return scaleX >= scaleY;
    }, [imageContainerHeight, preloadImage]);

    const image = useHeight ? (
      <img
        alt=''
        style={imageStyle}
        height={imageContainerHeight}
        src={preloadImage.src}
        className='app-preview-receipt__image'
      />
    ) : (
      <img
        alt=''
        style={imageStyle}
        width={document.body.offsetWidth}
        src={preloadImage.src}
        className='app-preview-receipt__image'
      />
    );

    const renderZoomWrapperContent = () => {
      return <TransformComponent>{image}</TransformComponent>;
    };

    if (!image) {
      return null;
    }

    return (
      <div
        style={{ width: '100%', height: '100%' }}
        onClick={onImagePDFMobileClick}
      >
        <TransformWrapper centerOnInit alignmentAnimation={ALIGNMENT_ANIMATION}>
          {renderZoomWrapperContent}
        </TransformWrapper>
      </div>
    );
  },
);
