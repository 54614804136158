import React, { memo } from 'react';

export const NoUserIcon = memo(
  ({ size = { width: 24, height: 30 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.0001 0.999878C8.3609 0.999878 5.40015 4.1401 5.40015 7.99993C5.40015 11.8598 8.3609 14.9999 12.0001 14.9999C15.6394 14.9999 18.6001 11.8597 18.6001 7.99988C18.6001 4.14004 15.6394 0.999878 12.0001 0.999878ZM12.0001 13.25C9.27073 13.25 7.05015 10.8948 7.05015 7.99993C7.05015 5.10507 9.27073 2.74989 12.0001 2.74989C14.7296 2.74989 16.9501 5.10507 16.9501 7.99993C16.9501 10.8948 14.7296 13.25 12.0001 13.25Z'
          fill={color}
          stroke={color}
          strokeWidth='0.521848'
        />
        <path
          d='M12 13.4442C7.92222 13.4442 4.35554 15.8251 2.45586 19.3529C1.70033 20.756 1.30185 22.2359 1.12236 23.7746C0.9529 25.2273 1.57644 26.6498 2.89198 27.289C4.55415 28.0966 7.43381 28.9998 12 28.9998C16.5662 28.9998 19.4458 28.0966 21.108 27.289C22.4236 26.6498 23.0471 25.2273 22.8776 23.7746C22.6981 22.2359 22.2997 20.756 21.5441 19.3529C19.6444 15.8251 16.0778 13.4442 12 13.4442ZM3.87469 25.3152C3.01704 24.7713 2.65643 23.7509 2.88742 22.762C3.90566 18.4024 7.60509 15.1626 12 15.1626C16.3949 15.1626 20.0943 18.4023 21.1126 22.762C21.3436 23.7509 20.983 24.7713 20.1253 25.3152C18.7711 26.174 16.2088 27.2815 12 27.2815C7.79116 27.2815 5.22892 26.174 3.87469 25.3152Z'
          fill={color}
          stroke={color}
          strokeWidth='0.521848'
        />
      </svg>
    );
  },
);
