export const SETTINGS_LANGUAGE_MAP = [
  'en', // English
  'es', // Spanish
  'fr', // French
  'de', // German
  'it', // Italian
  'uk', // Ukrainian
  'pl', // Polish
  'ru', // Russian
  'pt', // Portuguese
  'se', // Swedish
  'da', // Danish
  'no', // Norwegian
  'fi', // Finnish
];
