import {
  useGetPDFBlobFromSelectedList,
  useGetReceiptsFromSelectedTitle,
} from 'hooks/use-get-pdf-blob-from-selected-list';
import noop from 'lodash/noop';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { downloadBlob } from 'services/pdf-service';
import { isApplicationOnlineAtom } from 'store/root-store';
import { uploadFile } from '../../gql/api-receipt';
import { useGetServerPdfLink } from '../../gql/useCreatePdfLink';
import { HelperService, copyToClipboard } from '../../services/HelperService';
import type { ShareModalProps } from './share-modal';
import { isPlatform } from '@ionic/react';

export const useShareModalLogic = ({
  selectedCategoryIdsList = [],
  selectedReceiptIdsList = [],
  closeShareModal,
  onShareEnd = noop,
}: Omit<ShareModalProps, 'shareModalOpened'>) => {
  const isApplicationOnline = useRecoilValue(isApplicationOnlineAtom);
  const { getServerPDFLink, notifyPdfSaved } = useGetServerPdfLink();
  const intl = useIntl();
  const isMobile = isPlatform('capacitor');
  const getPDFBlob = useGetPDFBlobFromSelectedList();
  const getTitle = useGetReceiptsFromSelectedTitle();
  const [loading, setLoading] = useState(false);

  const getSharePdfLink = useCallback(async () => {
    let RETRY_COUNT = 3;

    while (RETRY_COUNT > 0) {
      try {
        setLoading(true);
        const title = await getTitle({
          selectedCategoryIdsList,
          selectedReceiptIdsList,
        });
        const {
          data: {
            // @ts-ignore
            createPdfLink: { id, path, uploadFileLink },
          },
        } = await getServerPDFLink(title);
        const pdfBlob = await getPDFBlob({
          selectedCategoryIdsList,
          selectedReceiptIdsList,
        });
        await uploadFile({
          url: uploadFileLink,
          file: pdfBlob,
        });

        await notifyPdfSaved(id);
        return HelperService.resolveFilePath(path);
      } catch (e) {
        RETRY_COUNT--;
      } finally {
        setLoading(false);
      }
    }
  }, [
    getPDFBlob,
    getServerPDFLink,
    notifyPdfSaved,
    selectedCategoryIdsList,
    selectedReceiptIdsList,
  ]);

  const onDownloadClick = async () => {
    setLoading(true);
    const pdfBlob = await getPDFBlob({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const title = getTitle({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });

    downloadBlob(pdfBlob, title + '.pdf');
    setLoading(false);
    closeShareModal();
    onShareEnd();
  };

  const onShareLinkOnDesktopClick = useCallback(async () => {
    const pdfLink = await getSharePdfLink();
    copyToClipboard({
      link: pdfLink,
      message: intl.messages['toast.share'] as string,
    });
    closeShareModal();
    onShareEnd();
  }, [closeShareModal, getSharePdfLink, intl.messages, onShareEnd]);

  const shareMultipleReceipts = useCallback(async () => {
    if (!navigator.share) {
      return null;
    }
    const pdfLink = await getSharePdfLink();
    window.plugins.socialsharing.share(null, null, null, pdfLink);
    closeShareModal();
    onShareEnd();
  }, [closeShareModal, getSharePdfLink, onShareEnd]);

  const onSharePdfFileClick = useCallback(async () => {
    setLoading(true);
    const filename = await getTitle({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const pdfBlob = await getPDFBlob({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = function () {
      const base64data = reader.result;
      window.plugins.socialsharing.share(null, null, [
        'data:application/pdf;df:' + filename + '.pdf;base64,' + base64data,
      ]);
      setLoading(false);
      closeShareModal();
      onShareEnd();
    };
  }, [
    closeShareModal,
    getPDFBlob,
    onShareEnd,
    selectedCategoryIdsList,
    selectedReceiptIdsList,
  ]);

  const ShareLinkOption = {
    onClick: !isMobile ? onShareLinkOnDesktopClick : shareMultipleReceipts,
    label: intl.messages['modal.share.link'] as string,
    type: 'share',
    icon: 'share',
    hidden: !isApplicationOnline,
  };

  const DownloadLinkOption = {
    onClick: onDownloadClick,
    label: intl.messages['modal.share.download'] as string,
    type: 'download',
    icon: 'pdf',
    hidden: false,
  };

  const SharePDFOption = {
    onClick: onSharePdfFileClick,
    label: intl.messages['modal.share.pdf'] as string,
    type: 'download',
    icon: 'pdf',
    hidden: false,
  };
  const desktopButtons = [ShareLinkOption, DownloadLinkOption];
  const mobileButtons = [ShareLinkOption, SharePDFOption];
  const buttons = isMobile ? mobileButtons : desktopButtons;
  return {
    buttons: buttons.filter(({ hidden }) => !hidden),
    isMobile,
    loading: loading,
  };
};
