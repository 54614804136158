import { useSyncCategoryTree } from 'category-tree-hooks/use-sync-category-tree';
import { Maybe } from 'core.types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from 'store/user-store';
import { useSwitchUser } from '../../../../../gql/api-user';
import { HelperService } from '../../../../../services';
import type { Account, User } from './account.types';

export const useQuerySharedAccounts = () => {
  const [changeAccountInprogress, setChangeAccountInProgress] = useState(false);
  const [accountData, setAccountData] = useState<any>(null);
  const [activeId, setActiveId] = useState<Maybe<number>>(null);
  const userPayload = useRecoilValue(currentUserAtom);

  const switchUser = useSwitchUser();
  const startSync = useSyncCategoryTree();

  useEffect(() => {
    const getCommonAccountInfo = ({
      ownerFullName,
      ownerCurrency,
      id,
      ownerPictureUrl,
    }: Account) => ({
      ownerFullName,
      ownerCurrency,
      id,
      ownerPictureUrl: HelperService.resolveFilePath(ownerPictureUrl),
    });

    const updateState = ({ account, currentAccount }: User) => {
      setAccountData({
        personalAccount: getCommonAccountInfo(account),
        availableAccount: [
          ...account.availableAccountShares.map(
            ({ accountFrom, canViewGroup, canEditGroup, canDeleteGroup }) => ({
              ...getCommonAccountInfo(accountFrom),
              canViewGroup,
              canEditGroup,
              canDeleteGroup,
            }),
          ),
        ],
      });

      setActiveId(currentAccount.id);
    };

    if (userPayload?.user) {
      // @ts-ignore
      updateState(userPayload?.user);
    }
  }, [userPayload]);

  const switchAccount = useCallback(
    async (account_id: number) => {
      try {
        setChangeAccountInProgress(true);
        // Additional check for online status
        await switchUser(account_id);
        setActiveId(account_id);
        startSync();
        setChangeAccountInProgress(false);
      } catch (error) {
        if (error instanceof Error) {
          console.warn(error.message);
        }
      }
    },
    [startSync, switchUser],
  );

  return {
    data: accountData,
    activeId,
    loading: false,
    switchAccount,
    changeAccountInprogress,
  };
};
