import { memo } from 'react';
import './app-form-field-error.scss';

export type AppFormFieldErrorProps = {
  error?:
    | string
    | {
        message?: string;
      };
};

export const AppFormFieldError = memo(({ error }: AppFormFieldErrorProps) => {
  return error ? (
    <div className='app-form__error'>
      {typeof error === 'string' ? error : error.message}
    </div>
  ) : (
    <span className='app-form__error-stub' />
  );
});
