import { Glassfy } from 'capacitor-plugin-glassfy';
import { AppSubscriptionId, Maybe } from 'core.types';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionPlanListAtom } from 'store/root-store';
import { useBooleanState } from '../../../../hooks';
import './Plan.scss';
import {
  PlanInformation,
  SubscriptionGroup,
  mockedPlansInfo,
} from './app-plan.types';
import { useQuerySharedAccounts } from './gql';
import { useIntl } from 'react-intl';

const pt = process.env.REACT_APP_PROJECT_TYPE;

const SubscriptionListTuples = {
  [SubscriptionGroup.personal]: [
    pt + AppSubscriptionId.personalMonthly,
    pt + AppSubscriptionId.personalYear,
  ],
  [SubscriptionGroup.family]: [
    pt + AppSubscriptionId.familyMonthly,
    pt + AppSubscriptionId.familyYear,
  ],
  [SubscriptionGroup.business]: [
    pt + AppSubscriptionId.businessMonthly,
    pt + AppSubscriptionId.businessYear,
  ],
};

export const usePlanLogic = () => {
  const [loader, loaderSetProgress, loaderHide] = useBooleanState(false);
  const [activeSubscriptionGroupTab, setActiveSubscriptionGroupTab] = useState(
    SubscriptionGroup.personal,
  );
  const intl = useIntl();

  const planList = useRecoilValue(subscriptionPlanListAtom);
  const { changeAccountType } = useQuerySharedAccounts();

  const activePlanInformation = useMemo((): Maybe<PlanInformation> => {
    if (!planList || !window.cordova) {
      return null;
    }

    try {
      const subscriptionIdList =
        SubscriptionListTuples[activeSubscriptionGroupTab];

      const [monthlySubscriptionId, yearlySubscriptionId] = subscriptionIdList;

      const monthlyProduct = planList[monthlySubscriptionId];
      const yearlyProduct = planList[yearlySubscriptionId];

      const monthlyPrice = monthlyProduct.price;
      const yearlyPrice = yearlyProduct.price;
      const currentPlan = {
        currency: monthlyProduct.currencyCode,
        monthlyPrice: Number(monthlyPrice.toFixed(2)),
        yearlyPrice: Number(yearlyPrice.toFixed(2)),
        monthlyId: monthlySubscriptionId,
        yearlyId: yearlySubscriptionId,
        discount: Math.round(
          ((monthlyPrice * 12 - yearlyPrice) * 100) / (monthlyPrice * 12),
        ),
        yearSubscriptionMonthlyPrice: Math.round(yearlyPrice / 12),
      } as PlanInformation;

      return currentPlan;
    } catch (error) {
      console.log(JSON.stringify(error));
      return null;
    }
  }, [activeSubscriptionGroupTab, planList]);

  const onTabClick = useCallback(
    (subscriptionGroup: SubscriptionGroup) => {
      if (activeSubscriptionGroupTab !== subscriptionGroup) {
        setActiveSubscriptionGroupTab(subscriptionGroup);
        if (process.env.REACT_APP_EMULATE_SUBCRIPTIONS) {
          changeAccountType(subscriptionGroup);
        }
      }
    },
    [activeSubscriptionGroupTab, changeAccountType],
  );

  const onTabTileClick = useCallback(
    async (id: AppSubscriptionId) => {
      loaderSetProgress();
      try {
        const sku = await Glassfy.skuWithId({ identifier: id });
        const transaction = await Glassfy.purchaseSku({ sku: sku });
        if (transaction.receiptValidated) {
          console.log('receiptValidated');
        }
      } finally {
        loaderHide();
      }
    },
    [planList],
  );

  return {
    // todo add filtering of final products
    plansList: !!process.env.REACT_APP_EMULATE_SUBCRIPTIONS
      ? mockedPlansInfo[activeSubscriptionGroupTab]
      : activePlanInformation,
    onTabTileClick,
    planBenefits:
      activeSubscriptionGroupTab === SubscriptionGroup.personal
        ? [
            {
              title: intl.messages[
                'menu.plan.personal.storage-title'
              ] as string,
              description: intl.messages[
                'menu.plan.personal.storage-description'
              ] as string,
            },
          ]
        : [
            {
              title: intl.messages['menu.plan.family.storage-title'] as string,
              description: intl.messages[
                'menu.plan.family.storage-description'
              ] as string,
            },
          ],
    onTabClick,
    activePlanTab: activeSubscriptionGroupTab,
    loader,
  };
};
