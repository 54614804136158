import React, { memo } from 'react';

export const PersonalAccountIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M10 1C7.5187 1 5.5 3.01871 5.5 5.50002C5.5 7.98133 7.5187 10 10 10C12.4813 10 14.5 7.98129 14.5 5.49998C14.5 3.01867 12.4813 1 10 1ZM10 8.87503C8.13903 8.87503 6.625 7.36099 6.625 5.50002C6.625 3.63904 8.13903 2.125 10 2.125C11.861 2.125 13.375 3.63904 13.375 5.50002C13.375 7.36099 11.861 8.87503 10 8.87503Z'
            fill={color}
          />
          <path
            d='M10 9C7.21969 9 4.78787 10.5305 3.49263 12.7984C2.93719 13.771 2.66477 14.8009 2.5574 15.8738C2.47898 16.6573 2.82099 17.4165 3.5236 17.7719C4.60383 18.3185 6.62708 19 10 19C13.3729 19 15.3962 18.3185 16.4764 17.7719C17.179 17.4165 17.521 16.6573 17.4426 15.8738C17.3352 14.8009 17.0628 13.771 16.5074 12.7984C15.2121 10.5305 12.7803 9 10 9ZM4.29348 16.528C3.83191 16.2305 3.63156 15.6823 3.75025 15.1462C4.38802 12.2651 6.94806 10.1047 10 10.1047C13.0519 10.1047 15.612 12.2651 16.2498 15.1462C16.3684 15.6823 16.1681 16.2305 15.7065 16.528C14.8347 17.0898 13.0525 17.8953 10 17.8953C6.9475 17.8953 5.1653 17.0898 4.29348 16.528Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
