import { AppForm, AppFormField } from 'components/app-form';
import { AppFormSubmitButton } from 'components/app-form/app-form-submit-button';
import { AppLogo } from 'components/app-logo/app-logo';
import { useGetIOSKeyboardHeight } from 'hooks/use-get-ios-keyboard-height';
import { PAGE_ROUTES } from 'page-routes';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FORM_VALIDATION_RESOLVER } from 'scenes/common-auth-logic';
import { AppLoader } from '../../components';
import { useSignUpLogic } from './use-sign-up-logic';

export const SignUp = () => {
  const intl = useIntl();
  const { onSubmit, loading, onLogoClick } = useSignUpLogic();
  const keyboardHeight = useGetIOSKeyboardHeight();

  return (
    <div
      className='auth-page__wrapper'
      style={{ paddingBottom: keyboardHeight }}
    >
      <AppLogo onClick={onLogoClick} className='auth-page__image' />
      <h2 className='auth-page__title'>
        <FormattedMessage id='signUp.h1' />
      </h2>

      <AppForm
        onSubmit={onSubmit}
        className='auth__form'
        formConfig={{
          resolver: FORM_VALIDATION_RESOLVER,
        }}
      >
        <AppFormField
          type={'email' as const}
          name='email'
          placeholder={intl.messages['signUp.email'] as string}
          icon={{
            name: 'mail',
          }}
        />
        <AppFormField
          type={'password' as const}
          name='password'
          icon={{ name: 'password' }}
          placeholder={intl.messages['signUp.pass'] as string}
        />
        <AppFormSubmitButton>
          <FormattedMessage id='btn.sign' />
        </AppFormSubmitButton>
        <Link className='auth-page__link' to={PAGE_ROUTES.otpLogin}>
          <FormattedMessage id='login.otp' />
        </Link>

        <Link className='auth-page__link' to={PAGE_ROUTES.forgotPassword}>
          <FormattedMessage id='login.forgot' />
        </Link>
      </AppForm>
      <Link to='/login' className='auth-page__link'>
        {<FormattedMessage id='signUp.footer' />}{' '}
        <FormattedMessage id='signUp.footer.in' />
      </Link>
      {loading && <AppLoader />}
    </div>
  );
};
