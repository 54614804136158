import { memo } from 'react';
import { AppIconProps } from '../app-icon';

export const TermsIcon = memo(
  ({
    size = { width: 20, height: 20 },
    color = 'white',
    opacity = '1',
  }: AppIconProps) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M6.31579 6.5C6.31579 6.77614 6.08015 7 5.78947 7H3.68421C3.39353 7 3.15789 6.77614 3.15789 6.5C3.15789 6.22386 3.39353 6 3.68421 6H5.78947C6.08015 6 6.31579 6.22386 6.31579 6.5Z'
            fill={color}
          />
          <path
            d='M6.31579 9.5C6.31579 9.77614 6.08015 10 5.78947 10H3.68421C3.39353 10 3.15789 9.77614 3.15789 9.5C3.15789 9.22386 3.39353 9 3.68421 9H5.78947C6.08015 9 6.31579 9.22386 6.31579 9.5Z'
            fill={color}
          />
          <path
            d='M6.31579 12.5C6.31579 12.7761 6.08015 13 5.78947 13H3.68421C3.39353 13 3.15789 12.7761 3.15789 12.5C3.15789 12.2239 3.39353 12 3.68421 12H5.78947C6.08015 12 6.31579 12.2239 6.31579 12.5Z'
            fill={color}
          />
          <path
            d='M3.15789 3.5C3.15789 3.22386 3.39353 3 3.68421 3H7.89474C8.18541 3 8.42105 3.22386 8.42105 3.5C8.42105 3.77614 8.18541 4 7.89474 4H3.68421C3.39353 4 3.15789 3.77614 3.15789 3.5Z'
            fill={color}
          />
          <path
            d='M10 7C9.70932 7 9.47368 7.22386 9.47368 7.5C9.47368 7.77614 9.70932 8 10 8H14.2105C14.5012 8 14.7368 7.77614 14.7368 7.5C14.7368 7.22386 14.5012 7 14.2105 7H10Z'
            fill={color}
          />
          <path
            d='M9.47368 13.5C9.47368 13.2239 9.70932 13 10 13H14.2105C14.5012 13 14.7368 13.2239 14.7368 13.5C14.7368 13.7761 14.5012 14 14.2105 14H10C9.70932 14 9.47368 13.7761 9.47368 13.5Z'
            fill={color}
          />
          <path
            d='M10 10C9.70932 10 9.47368 10.2239 9.47368 10.5C9.47368 10.7761 9.70932 11 10 11H16.3158C16.6065 11 16.8421 10.7761 16.8421 10.5C16.8421 10.2239 16.6065 10 16.3158 10H10Z'
            fill={color}
          />
          <path
            d='M9.47368 16.5C9.47368 16.2239 9.70932 16 10 16H16.3158C16.6065 16 16.8421 16.2239 16.8421 16.5C16.8421 16.7761 16.6065 17 16.3158 17H10C9.70932 17 9.47368 16.7761 9.47368 16.5Z'
            fill={color}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.6842 4H17.8947C19.0574 4 20 4.89543 20 6V18C20 19.1046 19.0574 20 17.8947 20H8.42105C7.25835 20 6.31579 19.1046 6.31579 18V16H2.10526C0.942558 16 0 15.1046 0 14V2C0 0.895431 0.942559 0 2.10526 0H11.5789C12.7417 0 13.6842 0.895431 13.6842 2V4ZM11.5789 1H2.10526C1.52391 1 1.05263 1.44772 1.05263 2V14C1.05263 14.5523 1.52391 15 2.10526 15H6.31579V12.5V9.5V6.5V6C6.31579 4.89543 7.25835 4 8.42105 4H12.6316V2C12.6316 1.44772 12.1603 1 11.5789 1ZM8.42105 5H17.8947C18.4761 5 18.9474 5.44772 18.9474 6V18C18.9474 18.5523 18.4761 19 17.8947 19H8.42105C7.8397 19 7.36842 18.5523 7.36842 18V6C7.36842 5.44772 7.8397 5 8.42105 5Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
