import React, { memo } from 'react';

export const GiftIcon = memo(({ size = { width: 60, height: 64 } }) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 60 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.10345 28.6912H56.8966V64.0016H3.10345V28.6912Z'
        fill='#ACB5E5'
      />
      <path
        d='M3.10345 28.6912H56.8966V33.105H3.10345V28.6912Z'
        fill='#A2ACE2'
      />
      <path d='M0 17.6546H60V28.6891H0V17.6546Z' fill='#ACB5E5' />
      <path
        d='M25.8621 17.6546H34.1379V63.9995H25.8621V17.6546Z'
        fill='#FB5D5C'
      />
      <path
        d='M25.862 17.6546L15.5172 41.9305L12.4138 37.5167H7.24136L16.5517 17.6546H25.862Z'
        fill='#FB5D5C'
      />
      <path
        d='M29.0689 5.28883C27.7505 2.0749 24.773 -0.00124082 21.4841 5.56383e-07H13.2082C16.4911 0.0200006 19.4588 2.08924 20.793 5.28883L25.862 17.6552H34.1379L29.0689 5.28883Z'
        fill='#EF2C2C'
      />
      <path
        d='M12.4138 8.82759C12.4144 5.50703 14.1637 2.46828 16.9396 0.965517C15.7842 0.33531 14.5059 0.00455172 13.2083 0H12.4138C7.84317 0 4.13791 3.95228 4.13791 8.82759C4.13791 13.7029 7.84317 17.6552 12.4138 17.6552H20.6896C16.119 17.6552 12.4138 13.7029 12.4138 8.82759Z'
        fill='#FB5D5C'
      />
      <path
        d='M30.931 5.28883C32.2495 2.0749 35.227 -0.00124082 38.5159 5.56383e-07H46.7917C43.5088 0.0200006 40.5411 2.08924 39.2069 5.28883L34.1379 17.6552H25.8621L30.931 5.28883Z'
        fill='#FB5D5C'
      />
      <path
        d='M47.5862 8.82759C47.5855 5.50703 45.8362 2.46828 43.0603 0.965517C44.2157 0.33531 45.4941 0.00455172 46.7917 0H47.5862C52.1568 0 55.862 3.95228 55.862 8.82759C55.862 13.7029 52.1568 17.6552 47.5862 17.6552H39.3103C43.8809 17.6552 47.5862 13.7029 47.5862 8.82759Z'
        fill='#E31F1F'
      />
      <path
        d='M34.1379 17.6546L44.4827 41.9305L47.5862 37.5167H52.7586L43.4483 17.6546H34.1379Z'
        fill='#FB5D5C'
      />
      <path
        d='M25.8621 17.6546H34.1379V23.1719H25.8621V17.6546Z'
        fill='#E31F1F'
      />
    </svg>
  );
});
