import { AppFooter } from 'components/app-footer';
import { memo } from 'react';
import './app-wrap.scss';

type AppWrapProps = {
  children: any;
};

export const AppWrap = memo(({ children }: AppWrapProps) => {
  return (
    <div className='app'>
      {children}
      <AppFooter />
    </div>
  );
});
