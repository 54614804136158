import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useRecoilCallback } from 'recoil';
import { clearTokens } from 'services/auth-service';
import {
  requestTable,
  fileDownloadQueueTable,
  waitUntilTableWillBeEmpty,
} from 'services/indexed-db-service';
import { activeSliceIdAtom, rootIDAtom } from 'store/category-store';
import { isAuthorizedAtom } from 'store/root-store';
import { currentUserAtom } from 'store/user-store';

export const useLogout = () => {
  const logOut = useRecoilCallback(({ set }) => async () => {
    await waitUntilTableWillBeEmpty(requestTable);
    clearTokens();
    // !Need to clear at first file download queue
    await Promise.all([
      fileDownloadQueueTable.clear(),
      GoogleAuth.signOut().catch(() => Promise.resolve()),
      set(isAuthorizedAtom, false),
      set(rootIDAtom, null),
      set(currentUserAtom, null),
      set(activeSliceIdAtom, null),
    ]);
  });

  return logOut;
};
