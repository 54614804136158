import { AppButton } from 'components/app-button';
import { ReceiptType } from 'core.types';
import { PAGE_ROUTES } from '../../page-routes';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { receiptTypeAtom } from 'store/category-store';
import './category-tabs.scss';

type CategoryTabProps = {
  receiptType: ReceiptType;
  label: string;
};

export const CategoryTab = memo(({ receiptType, label }: CategoryTabProps) => {
  const history = useHistory();
  const onTabClick = useRecoilCallback(({ set }) => () => {
    set(receiptTypeAtom, receiptType);
    history.push(PAGE_ROUTES.category);
  });

  return (
    <AppButton className='category-tab' onPress={onTabClick}>
      {label}
    </AppButton>
  );
});
