export default class Magnify {
  img = null;
  w = null;
  h = null;
  bw = null;
  glass = null;

  constructor(imgID, zoom) {
    this.imgID = imgID;
    this.zoom = zoom;
  }

  init() {
    this.magnify();
  }

  reset() {
    this.img && this.img.removeEventListener('mousemove', this.moveMagnifier);
    this.img && this.img.removeEventListener('touchmove', this.moveMagnifier);

    this.glass && document.getElementById('magnifierGlass').remove();
    var q = document.getElementById('magnifierGlass');

    if (q) {
      q.remove();
    }

    this.img = null;
    this.w = null;
    this.h = null;
    this.bw = null;
    this.glass = null;
  }

  magnify() {
    this.canvas = document.getElementsByTagName('canvas')[0];
    this.img = document.getElementById(this.imgID);

    /* Create magnifier glass: */
    this.glass = document.createElement('DIV');
    this.glass.setAttribute('id', 'magnifierGlass');
    this.glass.setAttribute('class', 'img-magnifier-glass');

    /* Insert magnifier glass: */
    this.img.append(this.glass);

    /* Set background properties for the magnifier glass: */
    this.glass.style.backgroundImage = this.img.style.backgroundImage;
    this.glass.style.backgroundRepeat = 'no-repeat';
    this.glass.style.backgroundSize =
      parseInt(this.canvas.width) * this.zoom +
      'px ' +
      parseInt(this.canvas.height) * this.zoom +
      'px';
    this.bw = 3;
    this.w = this.glass.offsetWidth / 2;
    this.h = this.glass.offsetHeight / 2;

    // /* Execute a function when someone moves the magnifier glass over the image: */
    // this.img.addEventListener('mousemove', this.moveMagnifier);
    // /*and also for touch screens:*/
    // this.img.addEventListener('touchmove', this.moveMagnifier);
  }

  moveMagnifier(e, point, pointTarget) {
    var pos, x, y;
    /* Prevent any other actions that may occur when moving over the image */
    // e.preventDefault();
    /* Get the cursor's x and y positions: */
    pos = this.getCursorPos(e);

    x = pos.x;
    y = pos.y;

    if (x > this.canvas.offsetWidth) {
      x = this.canvas.offsetWidth;
    }

    if (x < 0) {
      x = 0;
    }

    if (y > this.canvas.offsetHeight) {
      y = this.canvas.offsetHeight;
    }

    if (y < 0) {
      y = 0;
    }

    /* Set the position of the magnifier glass: */

    let generalOffset = 30;
    let distanceLeft = pointTarget
      ? this.img.offsetWidth - this.w * 2 - generalOffset
      : generalOffset;
    let distanceTop = generalOffset;
    this.glass.style.left = distanceLeft + 'px';
    this.glass.style.top = distanceTop + 'px';

    this.glass.setAttribute(
      'class',
      'img-magnifier-glass img-magnifier-glass__move',
    );

    /* Display what the magnifier glass "sees": */

    let xpos = x * this.zoom - this.w + this.bw;
    let ypos = y * this.zoom - this.h + this.bw;

    let xposString = `${xpos < 0 ? Math.abs(xpos) : '-' + xpos}px`;
    let yposString = `${ypos < 0 ? Math.abs(ypos) : '-' + ypos}px`;

    const BGposition = '' + xposString + ' ' + yposString;
    this.glass.style.backgroundPosition = BGposition;
  }

  getCursorPos(e) {
    if (e.type === 'touchmove') {
      e = e.targetTouches[0];
    }

    var a,
      x = 0,
      y = 0;
    e = e || window.event;
    /* Get the x and y positions of the image: */
    a = this.canvas.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {
      x: x,
      y: y,
    };
  }
}
