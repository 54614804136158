import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
  component: Component,
  hasAccess = true,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) =>
      hasAccess === true ? (
        <Component {...{ ...props, ...rest }} />
      ) : (
        <Redirect to='/' />
      )
    }
  />
);
