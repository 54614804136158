import React, { memo } from 'react';

export const GoogleIcon = memo(({ size = { width: 30, height: 30 } }) => {
  const { width, height } = size;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      width={width}
      height={height}
    >
      <circle fill='#fff' cx='256' cy='256' r='256' />
      <path
        fill='#EA4335'
        d='M256,169.853c22.235,0,42.436,8.497,57.72,22.326l35.997-38.538
	c-24.693-22.621-57.589-36.433-93.717-36.433c-53.157,0-99.311,29.896-122.625,73.778l42.356,33.921
	C188.213,192.717,219.401,169.853,256,169.853z'
      />
      <path
        fill='#FBBC05'
        d='M169.855,256c0-10.978,2.132-21.437,5.877-31.092l-42.356-33.921
	c-10.305,19.397-16.167,41.517-16.167,65.014c0,23.605,5.911,45.825,16.305,65.286l42.105-34.504
	C171.951,277.209,169.855,266.861,169.855,256z'
      />
      <path
        fill='#34A853'
        d='M303.895,327.547c-13.702,9.192-30.153,14.601-47.892,14.601c-36.719,0-67.986-23.012-80.386-55.367
	l-42.105,34.504c23.362,43.735,69.441,73.506,122.487,73.506c34.451,0,65.943-12.579,90.207-33.358L303.895,327.547z'
      />
      <path
        fill='#4285F4'
        d='M394.792,256c0-8.03-0.741-15.879-2.06-23.531H261.584v49.055h76.717
	c-5.891,19.016-18.225,35.166-34.406,46.023l42.312,33.887C375.932,335.977,394.792,298.203,394.792,256z'
      />
    </svg>
  );
});
