import React, { memo } from 'react';

export const PaletteIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M11.18 15.5V15.509L11.1803 15.518C11.1884 15.7665 11.2822 16.0045 11.4457 16.1918C11.7728 16.5668 11.95 17.0298 11.95 17.5C11.95 18.0172 11.7446 18.5132 11.3789 18.8789C11.0132 19.2446 10.5172 19.45 10 19.45C4.79376 19.45 0.55 15.2062 0.55 10C0.55 4.79376 4.79376 0.55 10 0.55C15.2622 0.55 19.45 4.39687 19.45 9C19.45 12.0062 17.0062 14.45 14 14.45H12.23C11.6462 14.45 11.18 14.9162 11.18 15.5ZM13.45 9.5C13.45 10.6338 14.3662 11.55 15.5 11.55C16.6338 11.55 17.55 10.6338 17.55 9.5C17.55 8.36624 16.6338 7.45 15.5 7.45C14.3662 7.45 13.45 8.36624 13.45 9.5ZM10.45 5.5C10.45 6.63376 11.3662 7.55 12.5 7.55C13.6338 7.55 14.55 6.63376 14.55 5.5C14.55 4.36624 13.6338 3.45 12.5 3.45C11.3662 3.45 10.45 4.36624 10.45 5.5ZM4.5 7.45C3.36624 7.45 2.45 8.36624 2.45 9.5C2.45 10.6338 3.36624 11.55 4.5 11.55C5.63376 11.55 6.55 10.6338 6.55 9.5C6.55 8.36624 5.63376 7.45 4.5 7.45ZM7.5 7.55C8.63376 7.55 9.55 6.63376 9.55 5.5C9.55 4.36624 8.63376 3.45 7.5 3.45C6.36624 3.45 5.45 4.36624 5.45 5.5C5.45 6.63376 6.36624 7.55 7.5 7.55Z'
            stroke={color}
            strokeWidth='1.1'
          />
        </g>
      </svg>
    );
  },
);
