// eslint-disable-next-line @typescript-eslint/no-var-requires
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

export const getCurrency = (abbreviation) => {
  const m = {
    ALL: {
      currency: 'Albania Lek',
      symbol: '&#76;&#101;&#107;',
    },
    AFN: {
      currency: 'Afghanistan Afghani',
      symbol: '&#1547;',
    },
    ARS: {
      currency: 'Argentina Peso',
      symbol: '&#36;',
    },
    AWG: {
      currency: 'Aruba Guilder',
      symbol: '&#402;',
    },
    AUD: {
      currency: 'Australia Dollar',
      symbol: '&#36;',
    },
    AZN: {
      currency: 'Azerbaijan New Manat',
      symbol: '&#1084;&#1072;&#1085;',
    },
    BSD: {
      currency: 'Bahamas Dollar',
      symbol: '&#36;',
    },
    BBD: {
      currency: 'Barbados Dollar',
      symbol: '&#36;',
    },
    BYR: {
      currency: 'Belarus Ruble',
      symbol: '&#112;&#46;',
    },
    BZD: {
      currency: 'Belize Dollar',
      symbol: '&#66;&#90;&#36;',
    },
    BMD: {
      currency: 'Bermuda Dollar',
      symbol: '&#36;',
    },
    BOB: {
      currency: 'Bolivia Boliviano',
      symbol: '&#36;&#98;',
    },
    BAM: {
      currency: 'Bosnia and Herzegovina Convertible Marka',
      symbol: '&#75;&#77;',
    },
    BWP: {
      currency: 'Botswana Pula',
      symbol: '&#80;',
    },
    BGN: {
      currency: 'Bulgaria Lev',
      symbol: '&#1083;&#1074;',
    },
    BRL: {
      currency: 'Brazil Real',
      symbol: '&#82;&#36;',
    },
    BND: {
      currency: 'Brunei Darussalam Dollar',
      symbol: '&#36;',
    },
    KHR: {
      currency: 'Cambodia Riel',
      symbol: '&#6107;',
    },
    CAD: {
      currency: 'Canada Dollar',
      symbol: '&#36;',
    },
    KYD: {
      currency: 'Cayman Islands Dollar',
      symbol: '&#36;',
    },
    CLP: {
      currency: 'Chile Peso',
      symbol: '&#36;',
    },
    CNY: {
      currency: 'China Yuan Renminbi',
      symbol: '&#165;',
    },
    COP: {
      currency: 'Colombia Peso',
      symbol: '&#36;',
    },
    CRC: {
      currency: 'Costa Rica Colon',
      symbol: '&#8353;',
    },
    HRK: {
      currency: 'Croatia Kuna',
      symbol: '&#107;&#110;',
    },
    CUP: {
      currency: 'Cuba Peso',
      symbol: '&#8369;',
    },
    CZK: {
      currency: 'Czech Republic Koruna',
      symbol: '&#75;&#269;',
    },
    DKK: {
      currency: 'Denmark Krone',
      symbol: '&#107;&#114;',
    },
    DOP: {
      currency: 'Dominican Republic Peso',
      symbol: '&#82;&#68;&#36;',
    },
    XCD: {
      currency: 'East Caribbean Dollar',
      symbol: '&#36;',
    },
    EGP: {
      currency: 'Egypt Pound',
      symbol: '&#163;',
    },
    SVC: {
      currency: 'El Salvador Colon',
      symbol: '&#36;',
    },
    EEK: {
      currency: 'Estonia Kroon',
      symbol: '&#107;&#114;',
    },
    EUR: {
      currency: 'Euro Member Countries',
      symbol: '&#8364;',
    },
    FKP: {
      currency: 'Falkland Islands (Malvinas) Pound',
      symbol: '&#163;',
    },
    FJD: {
      currency: 'Fiji Dollar',
      symbol: '&#36;',
    },
    GHC: {
      currency: 'Ghana Cedis',
      symbol: '&#162;',
    },
    GIP: {
      currency: 'Gibraltar Pound',
      symbol: '&#163;',
    },
    GTQ: {
      currency: 'Guatemala Quetzal',
      symbol: '&#81;',
    },
    GGP: {
      currency: 'Guernsey Pound',
      symbol: '&#163;',
    },
    GYD: {
      currency: 'Guyana Dollar',
      symbol: '&#36;',
    },
    HNL: {
      currency: 'Honduras Lempira',
      symbol: '&#76;',
    },
    HKD: {
      currency: 'Hong Kong Dollar',
      symbol: '&#36;',
    },
    HUF: {
      currency: 'Hungary Forint',
      symbol: '&#70;&#116;',
    },
    ISK: {
      currency: 'Iceland Krona',
      symbol: '&#107;&#114;',
    },
    INR: {
      currency: 'India Rupee',
      symbol: '&#x20b9',
    },
    IDR: {
      currency: 'Indonesia Rupiah',
      symbol: '&#82;&#112;',
    },
    IRR: {
      currency: 'Iran Rial',
      symbol: '&#65020;',
    },
    IMP: {
      currency: 'Isle of Man Pound',
      symbol: '&#163;',
    },
    ILS: {
      currency: 'Israel Shekel',
      symbol: '&#8362;',
    },
    JMD: {
      currency: 'Jamaica Dollar',
      symbol: '&#74;&#36;',
    },
    JPY: {
      currency: 'Japan Yen',
      symbol: '&#165;',
    },
    JEP: {
      currency: 'Jersey Pound',
      symbol: '&#163;',
    },
    KZT: {
      currency: 'Kazakhstan Tenge',
      symbol: '&#1083;&#1074;',
    },
    KPW: {
      currency: 'Korea (North) Won',
      symbol: '&#8361;',
    },
    KRW: {
      currency: 'Korea (South) Won',
      symbol: '&#8361;',
    },
    KGS: {
      currency: 'Kyrgyzstan Som',
      symbol: '&#1083;&#1074;',
    },
    LAK: {
      currency: 'Laos Kip',
      symbol: '&#8365;',
    },
    LVL: {
      currency: 'Latvia Lat',
      symbol: '&#76;&#115;',
    },
    LBP: {
      currency: 'Lebanon Pound',
      symbol: '&#163;',
    },
    LRD: {
      currency: 'Liberia Dollar',
      symbol: '&#36;',
    },
    LTL: {
      currency: 'Lithuania Litas',
      symbol: '&#76;&#116;',
    },
    MKD: {
      currency: 'Macedonia Denar',
      symbol: '&#1076;&#1077;&#1085;',
    },
    MYR: {
      currency: 'Malaysia Ringgit',
      symbol: '&#82;&#77;',
    },
    MUR: {
      currency: 'Mauritius Rupee',
      symbol: '&#8360;',
    },
    MXN: {
      currency: 'Mexico Peso',
      symbol: '&#36;',
    },
    MNT: {
      currency: 'Mongolia Tughrik',
      symbol: '&#8366;',
    },
    MZN: {
      currency: 'Mozambique Metical',
      symbol: '&#77;&#84;',
    },
    NAD: {
      currency: 'Namibia Dollar',
      symbol: '&#36;',
    },
    NPR: {
      currency: 'Nepal Rupee',
      symbol: '&#8360;',
    },
    ANG: {
      currency: 'Netherlands Antilles Guilder',
      symbol: '&#402;',
    },
    NZD: {
      currency: 'New Zealand Dollar',
      symbol: '&#36;',
    },
    NIO: {
      currency: 'Nicaragua Cordoba',
      symbol: '&#67;&#36;',
    },
    NGN: {
      currency: 'Nigeria Naira',
      symbol: '&#8358;',
    },
    NOK: {
      currency: 'Norway Krone',
      symbol: '&#107;&#114;',
    },
    OMR: {
      currency: 'Oman Rial',
      symbol: '&#65020;',
    },
    PKR: {
      currency: 'Pakistan Rupee',
      symbol: '&#8360;',
    },
    PAB: {
      currency: 'Panama Balboa',
      symbol: '&#66;&#47;&#46;',
    },
    PYG: {
      currency: 'Paraguay Guarani',
      symbol: '&#71;&#115;',
    },
    PEN: {
      currency: 'Peru Nuevo Sol',
      symbol: '&#83;&#47;&#46;',
    },
    PHP: {
      currency: 'Philippines Peso',
      symbol: '&#8369;',
    },
    PLN: {
      currency: 'Poland Zloty',
      symbol: '&#122;&#322;',
    },
    QAR: {
      currency: 'Qatar Riyal',
      symbol: '&#65020;',
    },
    RON: {
      currency: 'Romania New Leu',
      symbol: '&#108;&#101;&#105;',
    },
    SHP: {
      currency: 'Saint Helena Pound',
      symbol: '&#163;',
    },
    SAR: {
      currency: 'Saudi Arabia Riyal',
      symbol: '&#65020;',
    },
    RSD: {
      currency: 'Serbia Dinar',
      symbol: '&#1044;&#1080;&#1085;&#46;',
    },
    SCR: {
      currency: 'Seychelles Rupee',
      symbol: '&#8360;',
    },
    SGD: {
      currency: 'Singapore Dollar',
      symbol: '&#36;',
    },
    SBD: {
      currency: 'Solomon Islands Dollar',
      symbol: '&#36;',
    },
    SOS: {
      currency: 'Somalia Shilling',
      symbol: '&#83;',
    },
    ZAR: {
      currency: 'South Africa Rand',
      symbol: '&#82;',
    },
    LKR: {
      currency: 'Sri Lanka Rupee',
      symbol: '&#8360;',
    },
    SEK: {
      currency: 'Sweden Krona',
      symbol: '&#107;&#114;',
    },
    CHF: {
      currency: 'Switzerland Franc',
      symbol: '&#67;&#72;&#70;',
    },
    SRD: {
      currency: 'Suriname Dollar',
      symbol: '&#36;',
    },
    SYP: {
      currency: 'Syria Pound',
      symbol: '&#163;',
    },
    TWD: {
      currency: 'Taiwan New Dollar',
      symbol: '&#78;&#84;&#36;',
    },
    THB: {
      currency: 'Thailand Baht',
      symbol: '&#3647;',
    },
    TTD: {
      currency: 'Trinidad and Tobago Dollar',
      symbol: '&#84;&#84;&#36;',
    },
    TRY: {
      currency: 'Turkey Lira',
      symbol: '&#x20BA;',
    },
    TRL: {
      currency: 'Turkey Lira',
      symbol: '&#8356;',
    },
    TVD: {
      currency: 'Tuvalu Dollar',
      symbol: '&#36;',
    },
    UAH: {
      currency: 'Ukraine Hryvna',
      symbol: '&#8372;',
    },
    GBP: {
      currency: 'United Kingdom Pound',
      symbol: '&#163;',
    },
    USD: {
      currency: 'United States Dollar',
      symbol: '&#36;',
    },
    UYU: {
      currency: 'Uruguay Peso',
      symbol: '&#36;&#85;',
    },
    UZS: {
      currency: 'Uzbekistan Som',
      symbol: '&#1083;&#1074;',
    },
    VEF: {
      currency: 'Venezuela Bolivar',
      symbol: '&#66;&#115;',
    },
    VND: {
      currency: 'Viet Nam Dong',
      symbol: '&#8363;',
    },
    YER: {
      currency: 'Yemen Rial',
      symbol: '&#65020;',
    },
    ZWD: {
      currency: 'Zimbabwe Dollar',
      symbol: '&#90;&#36;',
    },
  };

  return m[abbreviation] ? entities.decode(m[abbreviation].symbol) : '';
};
