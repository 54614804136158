import { PAGE_ROUTES } from 'page-routes';
import { RefObject, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { newReceiptFileAtom } from 'store/category-store';

export const useSelectReceiptFile = (
  fileInputRef: RefObject<HTMLInputElement>,
) => {
  const history = useHistory();
  const setNewReceiptFile = useSetRecoilState(newReceiptFileAtom);

  const onChangeInput = useCallback(
    async ({
      target: {
        files: [file],
      },
    }: any) => {
      await setNewReceiptFile(file);
      const isPDFReceipt = String(file?.type).includes('pdf');
      history.push(
        `${PAGE_ROUTES.category}${
          isPDFReceipt ? PAGE_ROUTES.createReceipt : PAGE_ROUTES.cropStep
        }`,
      );

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [fileInputRef, history, setNewReceiptFile],
  );

  return onChangeInput;
};
