import { AppModalLayout } from 'components/app-modal-layout';
import { CropStep } from 'crop-step';
import usePrepareLink from 'hooks/use-prepare-link';
import { PAGE_ROUTES } from 'page-routes';
import { FC, memo, useCallback, useEffect } from 'react';
import { Route } from 'react-router';
import { CreateReceipt } from '../create-receipt';
import { PreviewReceipt } from '../preview-receipt';

import { CategoryPullToRefresh } from './category-pull-to-refresh';
import './category.scss';

export const Category = memo(() => {
  const previewReceiptModalLink = usePrepareLink({
    to: PAGE_ROUTES.previewReceipt,
    isRelativePath: true,
  } as any);

  const updateReceiptLink = usePrepareLink({
    to: PAGE_ROUTES.updateReceipt,
    isRelativePath: true,
  } as any);

  const createReceiptLink = usePrepareLink({
    to: PAGE_ROUTES.createReceipt,
    isRelativePath: true,
  } as any);

  const cropStepLink = usePrepareLink({
    to: PAGE_ROUTES.cropStep,
    isRelativePath: true,
  } as any);

  const renderModalPageLayout = useCallback(
    (Component: FC) =>
      ({ match }: any) => {
        return (
          <AppModalLayout open={Boolean(match)}>
            <Component />
          </AppModalLayout>
        );
      },
    [],
  );

  useEffect(() => {
    window.newPoly.reset();
  }, []);

  return (
    <>
      <CategoryPullToRefresh />
      <Route
        path={previewReceiptModalLink.pathname}
        children={renderModalPageLayout(PreviewReceipt)}
      />
      <Route
        path={updateReceiptLink.pathname}
        children={renderModalPageLayout(CreateReceipt)}
      />
      <Route
        path={createReceiptLink.pathname}
        children={renderModalPageLayout(CreateReceipt)}
      />

      <Route
        path={cropStepLink.pathname}
        children={renderModalPageLayout(CropStep)}
      />
    </>
  );
});
