import React, { memo } from 'react';

export const UsersIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.77063 15.6718H1.99399C1.77936 15.6718 1.58379 15.5723 1.45734 15.3988C1.33085 15.2252 1.29603 15.0084 1.36175 14.8039C2.01804 12.7633 4.01951 11.3381 6.22892 11.3381C7.25696 11.3381 8.24419 11.6477 9.08391 12.2334C9.38469 12.4432 9.79851 12.3695 10.0084 12.0687C10.2181 11.7679 10.1444 11.354 9.84361 11.1441C9.42793 10.8542 8.98383 10.6176 8.51929 10.4371C9.13736 9.87502 9.5474 9.08803 9.61864 8.20676C10.5558 7.29373 11.7842 6.79343 13.1011 6.79343C14.1292 6.79343 15.1164 7.10306 15.9561 7.68877C16.2569 7.89857 16.6707 7.82482 16.8806 7.52401C17.0903 7.22321 17.0166 6.8093 16.7158 6.59947C16.3001 6.30951 15.856 6.07297 15.3915 5.89237C16.0733 5.2724 16.5019 4.37866 16.5019 3.38665C16.502 1.51927 14.9829 0 13.1157 0C11.2484 0 9.72936 1.51927 9.72936 3.38672C9.72936 4.3747 10.1546 5.26525 10.8316 5.88492C10.7389 5.92058 10.647 5.9585 10.5557 5.99869C10.1402 6.18154 9.74942 6.40601 9.38618 6.67006C8.88502 5.42548 7.6651 4.54471 6.24342 4.54471C4.37619 4.54471 2.85713 6.06398 2.85713 7.93143C2.85713 8.91652 3.27987 9.80474 3.95339 10.4242C2.15241 11.0992 0.692119 12.5488 0.0976248 14.3974C-0.099542 15.0105 0.00489517 15.6606 0.384195 16.1811C0.763429 16.7015 1.35014 17 1.99402 17H7.77067C8.13739 17 8.43464 16.7027 8.43464 16.3359C8.43464 15.9692 8.13733 15.6718 7.77063 15.6718ZM13.1157 1.3281C14.2507 1.3281 15.174 2.25158 15.174 3.38669C15.174 4.52179 14.2507 5.44527 13.1157 5.44527C11.9807 5.44527 11.0573 4.52179 11.0573 3.38669C11.0573 2.25158 11.9807 1.3281 13.1157 1.3281ZM6.24346 5.87278C7.37843 5.87278 8.3018 6.79626 8.3018 7.93137C8.3018 9.06647 7.37843 9.98995 6.24346 9.98995C5.10848 9.98995 4.18511 9.06647 4.18511 7.93137C4.18511 6.79626 5.10848 5.87278 6.24346 5.87278Z'
          fill={color}
        />
        <path
          d='M21.7998 12.3563C21.7998 12.0491 21.5545 11.8 21.2519 11.8H12.3478C12.2349 11.8 12.1248 11.8354 12.0325 11.9013C11.785 12.0781 11.7255 12.4251 11.8996 12.6764L15.128 17.336L15.1371 20.726C15.1376 20.9376 15.1996 21.1443 15.3152 21.3204C15.6402 21.8153 16.2988 21.949 16.7863 21.6191L17.9393 20.8387C18.2457 20.6313 18.429 20.2816 18.4273 19.9078L18.4156 17.3565L21.6981 12.6791C21.7643 12.5848 21.7998 12.472 21.7998 12.3563ZM17.3181 17.003L17.3315 19.913L16.2328 20.6566L16.223 16.9826L13.4031 12.9126H20.1887L17.3181 17.003Z'
          fill={color}
          stroke={color}
          strokeWidth='0.3'
        />
      </svg>
    );
  },
);
