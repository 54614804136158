import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import c from 'classnames';
import { memo } from 'react';
import { Option } from '../../core.types';
import './app-select.scss';

type AppSelectProps = {
  options: Option[];
  value: Option;
  className?: string;
  placeholder: string;
  onChange<T>(event: any, value: T | T[], reason: string): void;
};

export const AppSelect = memo(
  ({ options, onChange, value, placeholder, className }: AppSelectProps) => {
    const renderOption = (option: Option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    };

    return (
      <FormControl className={c('app-select', className)}>
        <Select
          variant='outlined'
          id='demo-simple-select'
          value={value || ''}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          // @ts-ignore
          onChange={onChange}
        >
          <MenuItem value='' disabled>
            {placeholder}
          </MenuItem>
          {options.map(renderOption)}
        </Select>
      </FormControl>
    );
  },
);
