import { memo, useCallback } from 'react';
import { DefaultInput } from '../../../../components';
import { AppLoader } from '../../../../components/app-loader';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { AddButton } from '../AddButton';
import { useDetectMobileResolution } from '../../../../hooks';
import { AppConfirmModal } from '../../../../components/app-confirm-modal';
import { useSettingsEmailLogic } from './use-settings-email-logic';
import { SettingsEmailsList } from './settings-emails-list';
import { AppModal } from '../../../../components/app-modal';

import './settings-email.scss';

export const SettingsEmail = memo(() => {
  const intl = useIntl();
  const isMobile = useDetectMobileResolution();
  const {
    email,
    userEmailsList,
    addNewEmail,
    deleteUserEmail,
    onDeleteEmailClick,
    deleteEmailModalOpened,
    closeDeleteEmailModal,
    addEmailModalOpened,
    openAddEmailModal,
    closeAddEmailModal,
    loading,
    saveAddEmailModalDisabled,
    onEmailChange,
  } = useSettingsEmailLogic();

  const getAddEmailModalContent = useCallback(
    () => (
      // @ts-ignore
      <DefaultInput
        value={email}
        onChange={onEmailChange}
        iconName='mail'
        placeholder='example@gmail.com'
        isMobile={isMobile}
      />
    ),
    [email, isMobile, onEmailChange],
  );

  return (
    <>
      <div className={'email__wrapper'}>
        {loading && <AppLoader />}

        <span className='email__mail-text'>
          {intl.messages['menu.profile.your.mail'] as string}
        </span>

        <SettingsEmailsList {...{ userEmailsList, onDeleteEmailClick }} />
        <div className={'email__separation-line'} />
        <AddButton
          label={<FormattedMessage id='menu.profile.add.mail' />}
          className={cx({
            // @ts-ignore
            'email__mail-item--margin-top': !userEmailsList.length,
          })}
          onClick={openAddEmailModal}
        />
      </div>

      <AppConfirmModal
        open={deleteEmailModalOpened}
        onClose={closeDeleteEmailModal}
        onConfirmClick={deleteUserEmail}
        // @ts-ignore
        onCancelClick={closeDeleteEmailModal}
        title={intl.messages.alert as string}
      />

      <AppModal
        open={addEmailModalOpened}
        onClose={closeAddEmailModal}
        content={getAddEmailModalContent()}
        title={intl.messages['menu.profile.new.mail'] as string}
        onSaveClick={addNewEmail}
        saveDisabled={saveAddEmailModalDisabled}
      />
    </>
  );
});
