import { AppButton, AppButtonProps } from 'components/app-button';
import { ButtonHTMLAttributes } from 'react';
import c from 'classnames';
import { useFormContext } from 'react-hook-form';
import './app-form-submit-button.scss';

export const AppFormSubmitButton = ({
  children,
  ...props
}: AppButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <AppButton
      {...props}
      loading={isSubmitting}
      type='submit'
      className={c('app-form-submit-button', props.className)}
    >
      {children}
    </AppButton>
  );
};
