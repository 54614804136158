import { memo } from 'react';
import c from 'classnames';
import noop from 'lodash/noop';

import './app-pseudo-radio.scss';

type AppPseudoRadioProps = {
  onClick?(): void;
  isItemActive: boolean;
  className?: string;
};

export const AppPseudoRadio = memo(
  ({ isItemActive, className = '', onClick = noop }: AppPseudoRadioProps) => {
    return (
      <div
        onTouchEnd={onClick}
        onClick={onClick}
        role='button'
        className={c(
          'app-pseudo-radio-button',
          {
            'app-pseudo-radio-button--active': isItemActive,
          },
          className,
        )}
      />
    );
  },
);
