import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import c from 'classnames';
import { FILTER_TYPE } from '../../components/CropImages/image-filters';
import { useCropStepContext } from '../crop-step-context';
import { RECEIPT_STYLE } from '../crop-step.constants';
import { CropStepStyleFilter } from './crop-step-style-filter';
import './crop-step-style-filters.scss';
import { useCallback, useMemo } from 'react';

// !Note - do not wrap in memo!!! Need to get rid of window.newPoly, after you can do it
export const CropStepStyleFilters = () => {
  const { setStyleFilter, styleFilter, hideStyle, styleEnabled } =
    useCropStepContext();

  const onOriginalStyleClick = useCallback(async () => {
    try {
      setStyleFilter(RECEIPT_STYLE.original);
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.original });
    } catch {
      console.log('error original');
    }
  }, [setStyleFilter]);

  const onGrayStyleClick = useCallback(async () => {
    try {
      setStyleFilter(RECEIPT_STYLE.gray);
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.gray });
    } catch {
      console.log('error gray');
    }
  }, []);

  const onBWStyleClick = useCallback(async () => {
    try {
      setStyleFilter(RECEIPT_STYLE.bw);
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.bw });
    } catch {
      console.log('error BW');
    }
  }, []);

  const styleFilters = useMemo(
    () => [
      {
        onClick: onOriginalStyleClick,
        type: RECEIPT_STYLE.original,
      },
      {
        onClick: onGrayStyleClick,
        type: RECEIPT_STYLE.gray,
      },
      {
        onClick: onBWStyleClick,
        type: RECEIPT_STYLE.bw,
      },
    ],
    [],
  );

  const renderStyleFilterItem = useCallback(
    (item, index) => (
      <CropStepStyleFilter {...item} key={index} {...{ styleFilter }} />
    ),
    [styleFilter],
  );

  const onClickAway = useCallback(
    (event) => {
      const styleButton = document.getElementById('style-button');

      if (styleEnabled && styleButton && !styleButton.contains(event.target)) {
        hideStyle();
      }
    },
    [hideStyle, styleEnabled],
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={c('style-filters', {
          'style-filters--active': styleEnabled,
        })}
      >
        <div className='style-filters-content'>
          {styleFilters.map(renderStyleFilterItem)}
        </div>
      </div>
    </ClickAwayListener>
  );
};
