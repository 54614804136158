import { useCountdownTimer } from 'components/useCountdownTimer';
import { loader } from 'graphql.macro';
import { useBooleanState } from 'hooks/use-boolean-state';
import { useRequestDeviceNotifications } from 'hooks/use-request-device-notifications';
import { PAGE_ROUTES } from 'page-routes';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { setFormServerErrors } from 'scenes/common-auth-logic';
import { setAccountIdToken } from 'services/auth-service';
import { useIsAuthorizedAtomState } from 'store/root-store';
import { useCustomMutation } from '../../hooks/use-server-logic';

const mutationVerifySMS = loader('./mutationVerifySMS.graphql');

export const useLoginBySMS = () => {
  const [phone, setPhone] = useState('');
  const [_mutationVerify, { loading: loginBySMSLoading }] =
    useCustomMutation(mutationVerifySMS);
  const [codeSectionShown, showCodeSection, hideCodeSection] =
    useBooleanState(false);
  const history = useHistory();
  const requestDeviceNotifications = useRequestDeviceNotifications();
  const { setIsAuthorised } = useIsAuthorizedAtomState();

  const onLogoClick = useCallback(() => {
    history.push(PAGE_ROUTES.auth);
  }, [history]);

  const { countdown, start: startCountdownTimer } = useCountdownTimer({
    timer: 60,
    interval: 1,
    speed: 1000,
    onExpire: () => {
      setPhone('');
      hideCodeSection();
    },
  });

  const normalisePhone = useCallback(
    (phoneValue: string) => phoneValue.replace(/ /g, ''),
    [],
  );

  const onCodeSubmit = useCallback(
    async (
      { otpCode }: { otpCode: string },
      _: any,
      { setValue, setError },
    ) => {
      try {
        const {
          data: {
            loginBySMS: {
              token,
              account: { id },
            },
          },
        } = (await _mutationVerify({
          variables: {
            code: otpCode,
            phone: normalisePhone(phone),
          },
        })) as any;

        // !Note- order is important here - todo refactor
        setAccountIdToken(id);
        await setIsAuthorised(token);
        requestDeviceNotifications();
        history.push(PAGE_ROUTES.category);
      } catch (errorResponse: any) {
        setFormServerErrors(errorResponse, setError);
      } finally {
        setValue('otpCode', '');
      }
    },
    [
      _mutationVerify,
      history,
      normalisePhone,
      phone,
      requestDeviceNotifications,
      setIsAuthorised,
    ],
  );

  const onPhoneSubmit = useCallback(
    async ({ phone }: { phone: string }, _: any, { setValue, setError }) => {
      try {
        await _mutationVerify({
          variables: {
            phone: normalisePhone(phone),
          },
        });

        showCodeSection();
        startCountdownTimer();
        setPhone(phone);
      } catch (errorResponse: any) {
        setFormServerErrors(errorResponse, setError);
      } finally {
        setValue('phone', '');
      }
    },
    [_mutationVerify, normalisePhone, showCodeSection, startCountdownTimer],
  );

  return {
    phone,
    countdown,
    onLogoClick,
    onPhoneSubmit,
    onCodeSubmit,
    loginBySMSLoading,
    codeSectionShown,
    hideCodeSection,
  };
};
