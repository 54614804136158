import React, { memo } from 'react';

export const SmsIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M9.96094 0.625C4.39863 0.625 0 4.60715 0 9.41406C0 11.4618 0.810273 13.4457 2.285 15.0175C2.5768 16.2207 2.22254 17.4957 1.34352 18.3747C0.976016 18.7422 1.23613 19.375 1.75781 19.375C3.4275 19.375 5.03688 18.7207 6.23336 17.5675C7.41664 17.9895 8.66867 18.2031 9.96094 18.2031C15.5387 18.2031 20 14.2093 20 9.41406C20 4.61613 15.5356 0.625 9.96094 0.625ZM9.96094 17.0391C8.68277 17.0391 7.45051 16.7986 6.29836 16.3398C6.07207 16.2497 5.81371 16.3089 5.6493 16.4886C4.94156 17.262 4.02152 17.8013 3.01934 18.0491C3.57152 16.991 3.71266 15.7436 3.37652 14.5618C3.35004 14.4686 3.30082 14.3835 3.23332 14.314C1.90398 12.9447 1.16406 11.2046 1.16406 9.41406C1.16406 5.21395 5.11465 1.78906 9.96094 1.78906C14.8503 1.78906 18.8359 5.21395 18.8359 9.41406C18.8359 13.6142 14.8503 17.0391 9.96094 17.0391Z'
            fill={color}
          />
          <path
            d='M5.27344 7.65625C4.30418 7.65625 3.51562 8.4448 3.51562 9.41406C3.51562 10.3833 4.30418 11.1719 5.27344 11.1719C6.2427 11.1719 7.03125 10.3833 7.03125 9.41406C7.03125 8.4448 6.2427 7.65625 5.27344 7.65625ZM5.27344 10C4.95035 10 4.6875 9.73715 4.6875 9.41406C4.6875 9.09098 4.95035 8.82812 5.27344 8.82812C5.59652 8.82812 5.85938 9.09098 5.85938 9.41406C5.85938 9.73715 5.59652 10 5.27344 10Z'
            fill={color}
          />
          <path
            d='M9.96094 7.65625C8.99168 7.65625 8.20312 8.4448 8.20312 9.41406C8.20312 10.3833 8.99168 11.1719 9.96094 11.1719C10.9302 11.1719 11.7188 10.3833 11.7188 9.41406C11.7188 8.4448 10.9302 7.65625 9.96094 7.65625ZM9.96094 10C9.63785 10 9.375 9.73715 9.375 9.41406C9.375 9.09098 9.63785 8.82812 9.96094 8.82812C10.284 8.82812 10.5469 9.09098 10.5469 9.41406C10.5469 9.73715 10.284 10 9.96094 10Z'
            fill={color}
          />
          <path
            d='M14.7266 7.65625C13.7573 7.65625 12.9688 8.4448 12.9688 9.41406C12.9688 10.3833 13.7573 11.1719 14.7266 11.1719C15.6958 11.1719 16.4844 10.3833 16.4844 9.41406C16.4844 8.4448 15.6958 7.65625 14.7266 7.65625ZM14.7266 10C14.4035 10 14.1406 9.73715 14.1406 9.41406C14.1406 9.09098 14.4035 8.82812 14.7266 8.82812C15.0496 8.82812 15.3125 9.09098 15.3125 9.41406C15.3125 9.73715 15.0496 10 14.7266 10Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
