import React, { memo, useCallback } from 'react';
import noop from 'lodash';
import { ACCOUNT_ITEM_TYPES } from './account-item.constants';
import { ProfileInfo } from '../ProfileInfo';
import { AppIcon } from '../app-icon';
import { addClickHandle } from '../../services';
import { APP_CSS_THEME_VARIABLES } from '../../color-theme.constants';
import { AppPseudoRadio } from '../app-pseudo-radio';
import './account-item.scss';

export const AccountItem = memo(
  ({
    onClick = noop,
    userIcon,
    ownerFullName,
    userCurrency,
    type = ACCOUNT_ITEM_TYPES.radio,
    isActive = false,
  }) => {
    const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;

    // eslint-disable-next-line no-undef
    const renderAccountItemController = useCallback(
      () =>
        type === ACCOUNT_ITEM_TYPES.gear ? (
          <AppIcon
            name='gear'
            color={colorTextMain20}
            size={{ width: 22, height: 22 }}
          />
        ) : (
          <div className='account-item__radio'>
            <AppPseudoRadio isItemActive={isActive} />
          </div>
        ),
      [colorTextMain20, isActive, type],
    );

    return (
      <div className='account-item' {...addClickHandle(onClick)}>
        <ProfileInfo
          userIcon={userIcon}
          ownerFullName={ownerFullName}
          userCurrency={userCurrency}
        />
        {renderAccountItemController()}
      </div>
    );
  },
);
