import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/react';
import { AppFooter } from 'components/app-footer';
import { AppLoader } from 'components/app-loader';
import type { CategoryId, Maybe } from 'core.types';
import { memo, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  activeSliceIdAtom,
  categoryLoadingAtom,
  dragEnabledATOM,
  pullToRefreshInProgressATOM,
} from 'store/category-store';
import { scrollPositionsMapAtom, useSetScrollPosition } from 'store/root-store';
import { useRefreshTree } from '../category-tree-hooks/use-refresh-tree';
import { CategoryContent } from './category-content';
import { CategoryDNDPreview } from './category-dnd-preview';
import { CategoryDndProvider } from './category-dnd-provider';
import { CategoryFilters } from './category-filters';
import { CategoryPageHeader } from './category-header';

export const CategoryPullToRefresh = memo(() => {
  const activeSliceId: Maybe<CategoryId> = useRecoilValue(activeSliceIdAtom);
  const [pullToRefreshDisabled, setPullToRefreshDisabled] = useState(false);
  const triggerRefetch = useRefreshTree();
  const categoryLoading = useRecoilValue(categoryLoadingAtom);
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const [pullToRefreshInProgress, setPullToRefreshProgress] = useRecoilState(
    pullToRefreshInProgressATOM,
  );
  const pullToRefreshProgress = useRecoilValue(pullToRefreshInProgressATOM);
  const setPositionHandler = useSetScrollPosition();
  const scrollPositionsMap = useRecoilValue(scrollPositionsMapAtom);

  const saveScrollPosition = useCallback(
    (scrollTop) => {
      if (activeSliceId) {
        setPositionHandler(activeSliceId, scrollTop);
      }
    },
    [activeSliceId, setPositionHandler],
  );

  const onPullDown = useCallback(
    async (event: any) => {
      try {
        if (
          !pullToRefreshInProgress &&
          !dragEnabled &&
          !categoryLoading &&
          !pullToRefreshDisabled
        ) {
          setPullToRefreshProgress(true);
          await triggerRefetch();
          setPullToRefreshProgress(false);
        }
        event.detail.complete();
      } catch {
        if (pullToRefreshInProgress) {
          setPullToRefreshProgress(false);
        }
      }
    },
    [
      categoryLoading,
      dragEnabled,
      pullToRefreshDisabled,
      pullToRefreshInProgress,
      setPullToRefreshProgress,
      triggerRefetch,
    ],
  );

  const onScroll = useCallback(
    (event: any) => {
      setPullToRefreshDisabled(event.detail.currentY > 0);
      saveScrollPosition(event.detail.scrollTop);
    },
    [saveScrollPosition],
  );

  useEffect(() => {
    const scrollContent = document.getElementById('category-content-wrapper');
    if (scrollContent) {
      if (activeSliceId) {
        const scrollTop = scrollPositionsMap[activeSliceId] || 0;
        // @ts-ignore
        scrollContent.scrollToPoint(0, scrollTop);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSliceId]);

  return (
    <CategoryDndProvider>
      <div className='category-main-container'>
        <CategoryPageHeader />
        <CategoryFilters />
        <IonContent className='category-main-container__grid-container'>
          <IonRefresher
            disabled={pullToRefreshDisabled}
            onIonRefresh={onPullDown}
            pullFactor={0.5}
            pullMin={100}
            pullMax={200}
            slot='fixed'
            className='category-refresher'
          >
            <IonRefresherContent />
          </IonRefresher>

          <IonContent
            scrollEvents
            onIonScroll={onScroll}
            id='category-content-wrapper'
            className='ion-content-scroll-host'
          >
            <CategoryContent />
          </IonContent>
        </IonContent>
        <AppFooter />
        <CategoryDNDPreview />
        {categoryLoading && !pullToRefreshProgress && <AppLoader />}
      </div>
    </CategoryDndProvider>
  );
});
