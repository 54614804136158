import { CategoryId, ReceiptId } from 'core.types';
import printJS from 'print-js';
import { useCallback } from 'react';
import { getBase64FromBlob } from '../services';
import { useGetPDFBlobFromSelectedList } from './use-get-pdf-blob-from-selected-list';

type PrintDTO = {
  receiptIds?: ReceiptId[];
  categoryIds?: CategoryId[];
};

export const usePrint = () => {
  const getPDFBlob = useGetPDFBlobFromSelectedList();

  const print = useCallback(
    async ({ categoryIds = [], receiptIds = [] }: PrintDTO) => {
      try {
        const pdfBlob = await getPDFBlob({
          selectedCategoryIdsList: categoryIds,
          selectedReceiptIdsList: receiptIds,
        });

        let base64Code = await getBase64FromBlob(pdfBlob);
        base64Code = base64Code.split(',')[1];

        if (window.cordova) {
          window.cordova.plugins.printer.print(`base64://${base64Code}`);
        } else {
          printJS({
            printable: base64Code,
            type: 'pdf',
            base64: true,
          });
        }
      } catch (error) {
        console.log('IReceipt: error during pdf print', error);
      }
    },
    [getPDFBlob],
  );

  return {
    print,
  };
};
