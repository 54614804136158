import c from 'classnames';
import { AppFilePreview } from 'components/app-file-preview';
import { AppType, ReceiptType } from 'core.types';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { AppIcon } from '../components';
import { AppConfirmModal } from '../components/app-confirm-modal';
import { ShareModal } from '../components/share-modal';
import { receiptToEditAtom } from '../store';
import { PreviewReceiptControls } from './preview-receipt-controls';
import { PreviewReceiptHeader } from './preview-receipt-header';
import { PreviewReceiptInfoItem } from './preview-receipt-info-item';
import './preview-receipt.scss';
import { usePreviewReceiptLogic } from './use-preview-receipt-logic';
import { usePreloadImage } from '../hooks/use-preload-image';
import { hasAppAccess } from 'services/app-service';

export const PreviewReceipt = memo(() => {
  const intl = useIntl();
  const {
    infoItems,
    previewBottomControls,
    shareModalOpened,
    closeShareModal,
    deleteModalOpened,
    closeDeleteModal,
    moveBillingDocument,
    moveBillingDocumentModalOpened,
    moveBillingDocumentModal,
    closeBillingDocumentModal,
    deleteCurrentReceipt,
    imageContainerHeight,
    isPDFFile,
    onShareEnd,
  } = usePreviewReceiptLogic();
  const receiptToEdit = useRecoilValue(receiptToEditAtom);
  const renderPreviewReceiptInfoItem = useCallback(
    (item, index) => <PreviewReceiptInfoItem {...item} key={index} />,
    [],
  );

  const moveButton = useMemo(
    () => (
      <div
        onClick={moveBillingDocumentModal}
        className='preview-receipt__pay-invoice-button'
      >
        <AppIcon className='preview-receipt__pay-invoice-icon' name='payment' />
        <span>
          {
            (receiptToEdit?.type == ReceiptType.invoice
              ? intl.messages['move.to.receipts']
              : intl.messages['move.to.invoices']) as string
          }
        </span>
      </div>
    ),
    [intl.messages, moveBillingDocumentModal],
  );

  const preloadImage = usePreloadImage(receiptToEdit?.imagePath, isPDFFile);

  if (!receiptToEdit) {
    return null;
  }
  if (!preloadImage) {
    return null;
  }

  return (
    <>
      <div className={c('preview-receipt')}>
        <PreviewReceiptHeader />
        {/* !NOTE-we need here extra div for calculating button height */}
        <div className='preview-receipt__file-container'>
          <AppFilePreview
            {...{
              isPDFFile,
              imageContainerHeight,
              preloadImage,
              name: receiptToEdit.name,
            }}
          />
          {receiptToEdit.type !== ReceiptType.document && moveButton}
        </div>
        <div
          className={c('preview-receipt__info', {
            'preview-receipt__info--idoc': hasAppAccess(AppType.idocument),
          })}
        >
          {infoItems.map(renderPreviewReceiptInfoItem)}
        </div>
        <PreviewReceiptControls {...{ previewBottomControls }} />
      </div>
      <ShareModal
        {...{
          onShareEnd,
          shareModalOpened,
          closeShareModal,
          selectedReceiptIdsList: [receiptToEdit.id],
        }}
      />
      <AppConfirmModal
        open={deleteModalOpened}
        onConfirmClick={deleteCurrentReceipt}
        title={intl.messages.alert as string}
        onClose={closeDeleteModal}
      />
      <AppConfirmModal
        open={moveBillingDocumentModalOpened}
        onConfirmClick={moveBillingDocument}
        title={
          (receiptToEdit.type == ReceiptType.invoice
            ? intl.messages['move.to.receipts.modal.title']
            : intl.messages['move.to.invoices.modal.title']) as string
        }
        onClose={closeBillingDocumentModal}
      />
    </>
  );
});
