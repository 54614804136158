import { AppBackButton } from 'components/app-back-button';
import { AppButton } from 'components/app-button';
import { AppTypography } from 'components/app-typography';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import './create-receipt.scss';

type CreateReceiptSaveButtonsProps = {
  onSaveCloseClick(): void;
  onBackClick?(): void;
};

export const UPDATE_RECEIPT_FOOTER_PREFIX = 'update-receipt-footer';

export const UpdateReceiptFooter = memo(
  ({ onSaveCloseClick, onBackClick }: CreateReceiptSaveButtonsProps) => {
    const intl = useIntl();

    return (
      <div
        className={UPDATE_RECEIPT_FOOTER_PREFIX}
        id={UPDATE_RECEIPT_FOOTER_PREFIX}
      >
        {onBackClick && (
          <AppBackButton
            onBackClick={onBackClick}
            label={intl.messages.back as string}
            className={`${UPDATE_RECEIPT_FOOTER_PREFIX}__back-button`}
          />
        )}
        <AppButton
          className={`${UPDATE_RECEIPT_FOOTER_PREFIX}__save-button`}
          onPress={onSaveCloseClick}
        >
          <AppTypography variant='label'>
            {intl.messages['save.close'] as string}
          </AppTypography>
        </AppButton>
      </div>
    );
  },
);
