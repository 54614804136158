import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { hasAppAccess } from 'services/app-service';
import { isRootSelector } from 'store';
import { dragEnabledATOM } from 'store/category-store';
import { AppType, SortField } from '../../core.types';
import { CategorySortHeaderCell } from './category-sort-header-cell';

export const CategorySortHeader = memo(() => {
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const isRoot = useRecoilValue(isRootSelector);

  const sortCells = useMemo(
    () =>
      [
        {
          label: <FormattedMessage id='sort.store' />,
          sortField: SortField.name,
        },
        {
          label: <FormattedMessage id='sort.madeBy' />,
          sortField: SortField.madeBy,
        },
        {
          label: <FormattedMessage id='sort.amount' />,
          sortField: SortField.amount,
          hidden: !hasAppAccess(AppType.ireceipt),
        },
        {
          label: <FormattedMessage id='sort.date' />,
          sortField: SortField.receiptDate,
        },
      ].filter(({ hidden }) => !hidden),
    [],
  );

  const renderSortCell = useCallback(
    (cell, index) => <CategorySortHeaderCell {...cell} key={index} />,
    [],
  );

  if (!isRoot && dragEnabled) {
    return null;
  }

  return (
    <div className='category-list__sort-header'>
      {sortCells.map(renderSortCell)}
    </div>
  );
});
