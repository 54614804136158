import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import { PAGE_ROUTES } from 'page-routes';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { setAccountIdToken } from 'services/auth-service';
import { useIsAuthorizedAtomState } from 'store/root-store';

const mutationMailConfirm = loader('./mutationMailConfirm.graphql');

export const useMailConfirmPageLogic = () => {
  const [_mutation, { loading }] = useCustomMutation(mutationMailConfirm);
  const history = useHistory();
  const { pathname } = useLocation();
  const { setIsAuthorised } = useIsAuthorizedAtomState();

  useEffect(() => {
    const mailConfirmRequest = async () => {
      const mailToken = pathname.split('/').pop() as string;
      try {
        const {
          mailConfirm: {
            token,
            account: { id },
          },
        } = (await _mutation({
          variables: {
            token: mailToken,
          },
        })) as any;
        if (token) {
          await setIsAuthorised(token);
          setAccountIdToken(id);
          toast.info('Mail is successfully confirmed', {
            autoClose: 3000,
            position: 'bottom-center',
          });
          history.push(PAGE_ROUTES.login);
        }
      } catch (e: any) {
        history.push(PAGE_ROUTES.login);
      }
    };

    mailConfirmRequest();
  }, [_mutation, history, pathname, setIsAuthorised]);

  return {
    loading,
  };
};
