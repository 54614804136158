import React, { memo } from 'react';

export const ComingSoonIcon = memo(({ size = { width: 86, height: 86 } }) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 86 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M45.1479 15.4246H18.9528C18.2467 15.4246 17.6744 14.8523 17.6744 14.1462C17.6744 13.4401 18.2469 12.8678 18.9528 12.8678H45.1479C45.8541 12.8678 46.4265 13.4401 46.4265 14.1462C46.4265 14.8523 45.8542 15.4246 45.1479 15.4246Z'
        fill='#8792D2'
      />
      <path
        d='M20.1098 24.7957H6.62631C5.92017 24.7957 5.3479 24.2235 5.3479 23.5173C5.3479 22.8112 5.92034 22.2389 6.62631 22.2389H20.1098C20.816 22.2389 21.3882 22.8112 21.3882 23.5173C21.3882 24.2235 20.816 24.7957 20.1098 24.7957Z'
        fill='#8792D2'
      />
      <path
        d='M19.6472 33.5074H14.3209C13.6148 33.5074 13.0425 32.9352 13.0425 32.229C13.0425 31.5229 13.6149 30.9506 14.3209 30.9506H19.6473C20.3535 30.9506 20.9258 31.5229 20.9258 32.229C20.9258 32.9352 20.3533 33.5074 19.6472 33.5074Z'
        fill='#8792D2'
      />
      <path
        d='M14.3212 39.8169H1.27841C0.57227 39.8169 0 39.2446 0 38.5385C0 37.8323 0.572438 37.2601 1.27841 37.2601H14.321C15.0272 37.2601 15.5994 37.8323 15.5994 38.5385C15.5994 39.2446 15.0272 39.8169 14.3212 39.8169Z'
        fill='#8792D2'
      />
      <path
        d='M46.8526 73.1321H18.9528C18.2467 73.1321 17.6744 72.5598 17.6744 71.8535C17.6744 71.1472 18.2469 70.5751 18.9528 70.5751H46.8526C47.5588 70.5751 48.131 71.1474 48.131 71.8535C48.131 72.5597 47.5588 73.1321 46.8526 73.1321Z'
        fill='#8792D2'
      />
      <path
        d='M20.1098 63.761H6.62631C5.92017 63.761 5.3479 63.1887 5.3479 62.4826C5.3479 61.7765 5.92034 61.204 6.62631 61.204H20.1098C20.816 61.204 21.3882 61.7763 21.3882 62.4826C21.3882 63.1889 20.816 63.761 20.1098 63.761Z'
        fill='#8792D2'
      />
      <path
        d='M19.6472 55.0485H14.3209C13.6148 55.0485 13.0425 54.4762 13.0425 53.7699C13.0425 53.0636 13.6149 52.4915 14.3209 52.4915H19.6473C20.3535 52.4915 20.9258 53.0637 20.9258 53.7699C20.9258 54.476 20.3533 55.0485 19.6472 55.0485Z'
        fill='#8792D2'
      />
      <path
        d='M14.3212 48.74H1.27841C0.57227 48.74 0 48.1677 0 47.4616C0 46.7555 0.572438 46.183 1.27841 46.183H14.321C15.0272 46.183 15.5994 46.7553 15.5994 47.4616C15.5994 48.1679 15.0272 48.74 14.3212 48.74Z'
        fill='#8792D2'
      />
      <path
        d='M54.4689 74.531C71.8831 74.531 86.0001 60.414 86.0001 42.9998C86.0001 25.5855 71.8831 11.4685 54.4689 11.4685C37.0547 11.4685 22.9376 25.5855 22.9376 42.9998C22.9376 60.414 37.0547 74.531 54.4689 74.531Z'
        fill='#A3ACE2'
      />
      <path
        d='M54.4683 11.4685C53.6702 11.4685 52.8792 11.4987 52.0959 11.557C68.402 12.7701 81.2548 26.3836 81.2548 42.9998C81.2548 59.6159 68.402 73.2294 52.0959 74.4425C52.8792 74.5008 53.6702 74.531 54.4683 74.531C71.8827 74.531 85.9998 60.4141 85.9998 42.9998C85.9998 25.5854 71.8827 11.4685 54.4683 11.4685Z'
        fill='#8792D2'
      />
      <path
        d='M54.4688 67.2113C67.8405 67.2113 78.6805 56.3714 78.6805 42.9996C78.6805 29.6279 67.8405 18.7879 54.4688 18.7879C41.097 18.7879 30.2571 29.6279 30.2571 42.9996C30.2571 56.3714 41.097 67.2113 54.4688 67.2113Z'
        fill='white'
      />
      <path
        d='M54.469 18.7878C53.7323 18.7878 53.004 18.8225 52.2841 18.887C64.6318 19.9917 74.3108 30.3645 74.3108 42.9996C74.3108 55.6347 64.6318 66.0073 52.2841 67.112C53.004 67.1763 53.7323 67.2111 54.469 67.2111C67.8408 67.2111 78.6807 56.3713 78.6807 42.9994C78.6809 29.6278 67.8408 18.7878 54.469 18.7878Z'
        fill='#E2E6FF'
      />
      <path
        d='M54.4688 27.6288C53.7626 27.6288 53.1902 27.0566 53.1902 26.3504V24.3988C53.1902 23.6927 53.7625 23.1204 54.4688 23.1204C55.1751 23.1204 55.7472 23.6927 55.7472 24.3988V26.3503C55.7472 27.0564 55.1749 27.6288 54.4688 27.6288Z'
        fill='#141B50'
      />
      <path
        d='M66.2416 32.5053C65.9146 32.5053 65.5872 32.3805 65.3374 32.1309C64.8382 31.6315 64.8382 30.8221 65.3374 30.3227L66.7173 28.9428C67.2166 28.4438 68.0261 28.4438 68.5255 28.9428C69.0247 29.4422 69.0247 30.2516 68.5255 30.751L67.1456 32.1309C66.896 32.3805 66.5688 32.5053 66.2416 32.5053Z'
        fill='#141B50'
      />
      <path
        d='M73.0695 44.2781H71.1182C70.412 44.2781 69.8396 43.7058 69.8396 42.9995C69.8396 42.2932 70.4119 41.7211 71.1182 41.7211H73.0695C73.7756 41.7211 74.3481 42.2933 74.3481 42.9995C74.3481 43.7056 73.7756 44.2781 73.0695 44.2781Z'
        fill='#141B50'
      />
      <path
        d='M67.6215 57.4306C67.2944 57.4306 66.967 57.3058 66.7173 57.0562L65.3374 55.6763C64.8382 55.177 64.8382 54.3675 65.3374 53.8681C65.8368 53.3691 66.6462 53.3691 67.1456 53.8681L68.5255 55.248C69.0247 55.7474 69.0247 56.5568 68.5255 57.0562C68.2759 57.3058 67.9487 57.4306 67.6215 57.4306Z'
        fill='#141B50'
      />
      <path
        d='M54.4688 62.8787C53.7626 62.8787 53.1902 62.3064 53.1902 61.6001V59.6488C53.1902 58.9426 53.7625 58.3704 54.4688 58.3704C55.1751 58.3704 55.7472 58.9426 55.7472 59.6488V61.6001C55.7472 62.3064 55.1749 62.8787 54.4688 62.8787Z'
        fill='#141B50'
      />
      <path
        d='M41.3159 57.4306C40.9887 57.4306 40.6615 57.3058 40.4119 57.0562C39.9125 56.5568 39.9125 55.7474 40.4119 55.248L41.7918 53.8681C42.291 53.3691 43.1006 53.3691 43.5998 53.8681C44.0991 54.3675 44.0991 55.177 43.5998 55.6763L42.2199 57.0562C41.9703 57.3058 41.6431 57.4306 41.3159 57.4306Z'
        fill='#141B50'
      />
      <path
        d='M37.8196 44.2781H35.8681C35.162 44.2781 34.5897 43.7058 34.5897 42.9995C34.5897 42.2932 35.1622 41.7211 35.8681 41.7211H37.8196C38.5257 41.7211 39.098 42.2933 39.098 42.9995C39.098 43.7056 38.5256 44.2781 37.8196 44.2781Z'
        fill='#141B50'
      />
      <path
        d='M42.6958 32.5053C42.3686 32.5053 42.0414 32.3805 41.7918 32.1309L40.4119 30.751C39.9125 30.2516 39.9125 29.4422 40.4119 28.9428C40.9111 28.4438 41.7207 28.4438 42.2199 28.9428L43.5998 30.3227C44.0991 30.8221 44.0991 31.6315 43.5998 32.1309C43.3502 32.3805 43.023 32.5053 42.6958 32.5053Z'
        fill='#141B50'
      />
      <path
        d='M60.0651 44.2781H54.4688C53.7626 44.2781 53.1902 43.7058 53.1902 42.9995V32.2288C53.1902 31.5227 53.7625 30.9504 54.4688 30.9504C55.1751 30.9504 55.7472 31.5227 55.7472 32.2288V41.7209H60.0651C60.7713 41.7209 61.3437 42.2932 61.3437 42.9993C61.3437 43.7055 60.7713 44.2781 60.0651 44.2781Z'
        fill='#141B50'
      />
    </svg>
  );
});
