import { useBooleanState } from 'hooks/use-boolean-state';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AccountItem, ProfileInfo } from '../../../../components';
import { AppLoader } from '../../../../components/app-loader';
import { AddButton } from '../AddButton';
import { AddEmailModal } from './add-email-modal';
import { useQuerySharedAccounts } from './gql';
import './Manage.scss';
import { useCallback } from 'react';

export const Manage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [addAccountModalOpened, openAccountModal, closeAccountModal] =
    useBooleanState(false);
  const { personalAccount, sharedAccounts, loading, addAccount } =
    useQuerySharedAccounts();

  const onManageShareAccountItemClick = useCallback(
    (accountId) => {
      history.push(`${match.path}/${accountId}`);
    },
    [history, match.path],
  );

  if (!personalAccount || loading) {
    return (
      <div className='manage-account__wrapper'>
        <AppLoader />
      </div>
    );
  }

  return (
    <div className='manage-account__wrapper'>
      <div className='manage-account__info'>
        <div className='manage-account__info-title'>
          <FormattedMessage id='menu.settings.account.personal' />
        </div>
        {/* @ts-ignore */}
        <ProfileInfo
          userIcon={personalAccount.ownerPictureUrl}
          ownerFullName={personalAccount.ownerFullName}
          userCurrency={personalAccount.ownerCurrency}
        />
      </div>

      <div className='manage-account__info-separation-line' />

      <div className='manage-account__info'>
        <div className='manage-account__info-title'>
          <FormattedMessage
            id={`menu.settings.account.${
              personalAccount.accountType || 'personal'
            }`}
          />
        </div>

        <ul className='manage-account__info-list'>
          {sharedAccounts.map(
            (
              { ownerPictureUrl, ownerFullName, ownerCurrency, id }: any,
              index: any,
            ) => {
              return (
                <li key={index}>
                  <AccountItem
                    // @ts-ignore
                    userIcon={ownerPictureUrl}
                    ownerFullName={ownerFullName}
                    userCurrency={ownerCurrency}
                    onClick={() => onManageShareAccountItemClick(id)}
                    type='gear'
                  />
                </li>
              );
            },
          )}
        </ul>
      </div>
      <div className='manage-account__info-separation-line' />
      <AddButton
        withInfo
        label={<FormattedMessage id='menu.settings.account.add' />}
        className='manage-account__info-add-button'
        onClick={openAccountModal}
      />
      <AddEmailModal
        open={addAccountModalOpened}
        {...{
          closeAccountModal,
          addAccount,
        }}
      />
    </div>
  );
};
