import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlContext } from './intl-context';
import { detectUserLocale } from '../../services';
import messages from '../../translations/messages';
import iDocumentMessages from '../../translations/idocument-override-messages';
import { isPlatform } from '@ionic/react';
import { resolveRecognitionByLanguage } from '../../store/store.effects';

export const IntlWrapper = (props: any) => {
  const localeStore = localStorage.getItem('locale') || detectUserLocale();
  if (isPlatform('capacitor')) {
    resolveRecognitionByLanguage(localeStore);
  }
  const [locale, setLocale] = useState(localeStore);
  const projectType = process.env.REACT_APP_PROJECT_TYPE;
  const getMessages = (locale: string) => {
    if (projectType === 'idocument') {
      return { ...messages[locale], ...iDocumentMessages[locale] };
    }

    return messages[locale];
  };

  const changeLocale = (localeStore: any) => {
    const projectMessages = getMessages(localeStore);

    // @ts-ignore
    if (!localeStore || !projectMessages) {
      setLocale('en');
    } else {
      setLocale(localeStore);
    }
  };

  useEffect(() => {
    changeLocale(localeStore);
  }, [localeStore]);

  return (
    <IntlContext.Provider value={{ locale, setLocale: changeLocale }}>
      {/* @ts-ignore */}
      <IntlProvider
        locale={locale}
        messages={getMessages(locale)}
        defaultLocale='en'
      >
        {props.children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
