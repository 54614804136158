import { getAppName } from 'services/app-service';

export const LogoHeader = ({
  className,
  activeCategory: { id, name },
  isEdit,
  customName,
}: any) => {
  return (
    <>
      {(/Home/gi.test(name) && !isEdit) || (!id && !isEdit) ? (
        <div className={className}>{customName || getAppName()}</div>
      ) : null}
    </>
  );
};

LogoHeader.defaultProps = {
  className: 'app-col header-col header-col__logo',
  activeCategory: {},
  customName: null,
};
