import { AppType, ReceiptType } from 'core.types';
import { useBooleanState } from 'hooks/use-boolean-state';
import { useEffect, useMemo, useState } from 'react';
import { useDetectMobileResolution } from 'hooks/use-detect-mobile-resolution';
import { hasAppAccess } from '../services/app-service';
import { useReminders } from './create-receipt-input-section';

export const usePageLayoutSizeStyle = ({
  safeAreaTop,
  safeAreaBottom,
  receiptType,
}: {
  safeAreaTop: number;
  safeAreaBottom: number;
  receiptType: ReceiptType;
}) => {
  const [keyboardShown, showKeyboard, hideKeyboard] = useBooleanState(false);
  const isMobileResolution = useDetectMobileResolution();
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const footerHeight = isMobileResolution ? 75 : 100;
  const rowGap = isMobileResolution ? 20 : 30;

  const receiptInputSectionHeight = useMemo(() => {
    if (
      (hasAppAccess(AppType.ireceipt) && !useReminders) ||
      receiptType === ReceiptType.receipt
    ) {
      return isMobileResolution ? 190 : 205;
    }

    if (receiptType === ReceiptType.invoice) {
      return 260;
    }

    if (receiptType === ReceiptType.document) {
      return 150;
    }

    return 0;
  }, [isMobileResolution, receiptType]);

  useEffect(() => {
    window.addEventListener('keyboardWillShow', ({ keyboardHeight }: any) => {
      setKeyboardHeight(keyboardHeight);
      showKeyboard();
    });
    window.addEventListener('keyboardWillHide', () => {
      setKeyboardHeight(0);
      hideKeyboard();
    });
  }, [
    footerHeight,
    hideKeyboard,
    receiptInputSectionHeight,
    safeAreaBottom,
    safeAreaTop,
    showKeyboard,
  ]);

  const imageContainerHeight = useMemo(() => {
    return (
      document.body.offsetHeight -
      receiptInputSectionHeight -
      rowGap * 2 -
      footerHeight -
      safeAreaTop -
      safeAreaBottom
    );
  }, [
    footerHeight,
    receiptInputSectionHeight,
    rowGap,
    safeAreaBottom,
    safeAreaTop,
  ]);

  return {
    keyboardShown,
    imageContainerHeight,
    rowGap,
    gridTemplateRows: keyboardShown
      ? `auto ${footerHeight}px ${keyboardHeight - rowGap * 2}px`
      : `auto ${receiptInputSectionHeight}px ${footerHeight}px`,
  };
};
