import { memo } from 'react';
import transparentImageUrl from './images/transparent-image.png';
import './app-image-placeholder.scss';

export const AppImagePlaceholder = memo(() => (
  <>
    <div className='app-image-placeholder'>
      <div className='app-image-placeholder__activity' />
    </div>
    <img
      className='app-image-placeholder__placeholder-image'
      src={transparentImageUrl}
      alt='transparency'
    />
  </>
));
