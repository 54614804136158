import { AppLoader } from 'components/app-loader';
import { memo } from 'react';
import { CropStepContext } from './crop-step-context';
import { CropStepFooter } from './crop-step-footer';
import { CropStepImageActions } from './crop-step-image-actions';
import { CropStepImageIntensityControls } from './crop-step-image-intensity-controls';
import { CropStepStyleFilters } from './crop-step-style-filters';
import './crop-step.scss';
import { useCropStepLogic } from './use-crop-step-logic';

export const CropStep = memo(() => {
  const value = useCropStepLogic();
  const {
    progressEnabled,
    refContainer,
    isSmallResolution,
    previewImageLoaded,
    disableBackSwipe,
    displayRef,
  } = value;

  return (
    <CropStepContext.Provider value={value}>
      {(progressEnabled || !previewImageLoaded) && <AppLoader />}
      <div className='react-crop__wrap' onTouchStart={disableBackSwipe}>
        <div className='react-crop__body' ref={refContainer}>
          <div
            id='displayId'
            className='id-crop__wrapper idwall-display'
            ref={displayRef}
          />
        </div>

        <div className='react-crop__footer'>
          <CropStepImageIntensityControls />
          <CropStepStyleFilters />
          <CropStepImageActions />
          {isSmallResolution && <CropStepFooter />}
        </div>
      </div>
    </CropStepContext.Provider>
  );
});
