import { memo, useCallback } from 'react';
import { addClickHandle } from 'services/HelperService';
import c from 'classnames';
import { SubscriptionGroup } from './app-plan.types';

type ActivePlanTabProps = {
  onClick(subscriptionGroup: SubscriptionGroup): void;
  active: boolean;
  subscriptionGroup: SubscriptionGroup;
  label: any;
};

export const ActivePlanTab = memo(
  ({ onClick, subscriptionGroup, active, label }: ActivePlanTabProps) => {
    const onTabClick = useCallback(() => {
      onClick(subscriptionGroup);
    }, [onClick, subscriptionGroup]);

    return (
      <li
        {...addClickHandle(onTabClick)}
        className={c('plan__tabs-item', {
          'plan__tabs-item--active': active,
        })}
      >
        {label}
      </li>
    );
  },
);
