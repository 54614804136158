import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';

const deviceInfoMutation = loader('./device-info.mutation.graphql');

export type AddDeviceDTO = {
  uuid: string;
  os: string;
  model: string;
  osVersion: string;
  platform: string;
  diskFree: number;
  name: string;
  pushToken?: string;
  timezone?: string;
};

export const useSaveDeviceInfo = () => {
  const [saveDeviceInfo, { loading }] =
    useCustomMutation<AddDeviceDTO>(deviceInfoMutation);

  return {
    saveDeviceInfo,
    loading,
  };
};
