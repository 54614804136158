//@ts-nocheck
import { useState, useEffect } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DefaultInput } from '../../../../components';
import { AppLoader } from '../../../../components/app-loader';
import { useChangePassword } from './gql';

import './Password.scss';
import { AppButton } from 'components/app-button';

const VALIDATION_MESSAGE = 'Passwords don’t match.';

export const Password = ({ mobile }: any) => {
  const intl = useIntl();
  const { push } = useHistory();

  const [newPass, setNewPass] = useState('');
  const [againNewPass, setAgainNewPass] = useState('');
  const [isValidationError, setIsValidationError] = useState(false);
  const [isDirtyForm, srtDirtyForm] = useState(false);

  const reset = () => {
    setNewPass('');
    setAgainNewPass('');
    setIsValidationError(false);
    srtDirtyForm(false);
  };

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (isDirtyForm) {
      setIsValidationError(newPass !== againNewPass);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPass, againNewPass]);

  const { changePassword, loading, data } = useChangePassword();

  useEffect(() => {
    if (!loading && newPass && againNewPass && data) {
      reset();
    }
  }, [againNewPass, data, loading, newPass]);

  const changePasswordHandler = () => {
    if (newPass === againNewPass) {
      return changePassword(newPass).then(() => mobile && push('/settings'));
    } else {
      srtDirtyForm(true);
      setIsValidationError(true);
    }
  };

  return (
    <>
      <div className='password-edit__wrapper'>
        <DefaultInput
          type='password'
          value={newPass}
          placeholder={intl.messages['menu.profile.password.new']}
          onChange={({ currentTarget: { value } }) => setNewPass(value)}
        />

        <DefaultInput
          type='password'
          value={againNewPass}
          placeholder={intl.messages['menu.profile.password.again']}
          onChange={({ currentTarget: { value } }) => setAgainNewPass(value)}
          hasError={isValidationError}
          errorMessage={VALIDATION_MESSAGE}
        />

        <AppButton
          className='settings__save-button'
          onPress={changePasswordHandler}
          disabled={isValidationError}
        >
          {intl.messages['save']}
        </AppButton>
        {loading && <AppLoader />}
      </div>
    </>
  );
};
