import { useFolderItemLogic } from 'category/use-folder-item-logic';
import c from 'classnames';
import { memo, useContext, useMemo } from 'react';
import Tappable from 'react-tappable';
import { useRecoilValue } from 'recoil';
import {
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
} from 'store/category-store';
import { AppIcon } from '../../../components';
import { AppPseudoRadio } from '../../../components/app-pseudo-radio';
import { AppType, LocalCategory } from '../../../core.types';
import { useDetectMobileResolution } from '../../../hooks';
import { useCategoryDndLogic } from '../../use-category-dnd-logic';
import '../../category.scss';
import { currencyFormatter } from '../../../services';
import { AppHasAccess } from 'components/app-has-access';
import { CategoryTreeContext } from 'category-tree-provider';

const MOBILE_FOLDER_ICON_SIZE = { width: 30, height: 25 };
const DESKTOP_FOLDER_ICON_SIZE = { width: 35, height: 28 };

export const CategoryItemTableRowFolder = memo(
  (localCategory: LocalCategory) => {
    const editModeEnabled = useRecoilValue(editModeEnabledATOM);
    const { onItemClick, isItemActive, itemName, currency } =
      useFolderItemLogic(localCategory);
    const { isOver, isDraggable, drop, drag } = useCategoryDndLogic(
      localCategory.id,
    );
    const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
    const { createdBy } = localCategory;
    const isMobileResolution = useDetectMobileResolution();
    const { isEmptyActiveSliceSelector } = useContext(CategoryTreeContext);
    const isEmpty = isEmptyActiveSliceSelector(localCategory);

    const image = useMemo(() => {
      return (
        <div className='category-table__icon-wrapper'>
          <AppIcon
            name={isEmpty ? 'green-folder-empty' : 'green-folder-non-empty'}
            id={localCategory.id}
            size={
              isMobileResolution
                ? MOBILE_FOLDER_ICON_SIZE
                : DESKTOP_FOLDER_ICON_SIZE
            }
            className='category-table__icon'
          />
        </div>
      );
    }, [isEmpty, isMobileResolution, localCategory.id]);

    const createdByBlock = useMemo(
      () => (
        <div className='category-table__row-created-by'>
          <span className='category-table__row-created-by-label'>BY </span>
          {createdBy}
        </div>
      ),
      [createdBy],
    );

    return (
      <div className='category-table__row-block' ref={drag}>
        <Tappable
          onTap={onItemClick}
          className='category-table__row drop-container'
        >
          <div
            className={c('category-table__row-name-wrapper', 'drop', {
              'is-over':
                !isDraggable &&
                isOver &&
                !selectedCategoryIdsList.includes(localCategory.id),
            })}
            ref={drop}
          >
            {editModeEnabled && (
              <AppPseudoRadio
                {...{
                  isItemActive,
                  className: 'category-table__row-indicator',
                }}
              />
            )}
            {image}
            <div className={c('category-table__row-name-wrapper-title')}>
              {itemName}
              {isMobileResolution && createdByBlock}
            </div>
          </div>
          {!isMobileResolution && createdByBlock}
          <AppHasAccess appType={AppType.ireceipt}>
            <div className='category-table__row-currency'>
              {/* @ts-ignore */}
              {currencyFormatter(currency, localCategory.amount)}
            </div>
          </AppHasAccess>
          <div
            className={c('category-table__row-date', {
              'category-table__row--empty-item': true,
            })}
          />
        </Tappable>
      </div>
    );
  },
);
