import { AppForm, AppFormField } from 'components/app-form';
import { AppFormSubmitButton } from 'components/app-form/app-form-submit-button';
import { AppLoader } from 'components/app-loader';
import { AppLogo } from 'components/app-logo/app-logo';
import { useGetIOSKeyboardHeight } from 'hooks/use-get-ios-keyboard-height';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FORM_VALIDATION_RESOLVER } from 'scenes/common-auth-logic';
import './forgot-password-page.scss';
import { useForgotPasswordLogic } from './use-forgot-password-logic';

export const ForgotPasswordPage = () => {
  const intl = useIntl();
  const { onSubmit, isReset, loading } = useForgotPasswordLogic();
  const keyboardHeight = useGetIOSKeyboardHeight();

  return (
    <div
      className='auth-page__wrapper'
      style={{
        paddingBottom: keyboardHeight,
      }}
    >
      <Link to='/auth'>
        <AppLogo className='auth-page__image' />
      </Link>

      <h2 className='auth-page__title'>
        <FormattedMessage id='forgot.h1' />
      </h2>
      <p className='auth-page__title'>
        <FormattedMessage id='forgot.p' />
      </p>

      {isReset ? (
        <p className='auth-page__title'>
          <FormattedMessage id='forgot.success' />
        </p>
      ) : (
        <AppForm
          onSubmit={onSubmit}
          className='auth__form'
          formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
        >
          <AppFormField
            type={'email' as const}
            name='email'
            placeholder={intl.messages['forgot.email'] as string}
            icon={{
              name: 'mail',
            }}
          />
          <AppFormSubmitButton>
            <FormattedMessage id='btn.submit' />
          </AppFormSubmitButton>
        </AppForm>
      )}
      {loading && <AppLoader />}
    </div>
  );
};
