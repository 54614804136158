import { useCallback, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountItem } from '../../../../components';
import { AppLoader } from '../../../../components/app-loader';
import './Account.scss';
import { useQuerySharedAccounts } from './gql';

export const Account = memo(() => {
  const { data, activeId, loading, switchAccount, changeAccountInprogress } =
    useQuerySharedAccounts();

  const onAccountItemClick = useCallback(
    (accountId: number) => {
      if (activeId !== accountId) {
        switchAccount(accountId);
      }
    },
    [activeId, switchAccount],
  );

  const renderAvailableAccounts = useCallback(
    () =>
      data?.availableAccount.map(
        ({ id, ownerPictureUrl, ownerFullName, ownerCurrency }: any) => {
          return (
            <li key={id}>
              <AccountItem
                // @ts-expect-error
                userIcon={ownerPictureUrl}
                ownerFullName={ownerFullName}
                userCurrency={ownerCurrency}
                onClick={() => onAccountItemClick(id)}
                isActive={activeId === id}
              />
            </li>
          );
        },
      ),
    [activeId, data?.availableAccount, onAccountItemClick],
  );

  const renderLoader = useCallback(
    () =>
      (!data ||
        loading ||
        !data?.availableAccount ||
        changeAccountInprogress) && <AppLoader />,
    [changeAccountInprogress, data, loading],
  );

  return (
    <div className={'account__wrapper'}>
      <div className={'account__info'}>
        <div className={'account__info-title'}>
          <FormattedMessage id='menu.settings.account.personal' />
        </div>

        {data && (
          <AccountItem
            // @ts-expect-error
            userIcon={data.personalAccount.ownerPictureUrl}
            ownerFullName={data.personalAccount.ownerFullName}
            userCurrency={data.personalAccount.ownerCurrency}
            onClick={() => onAccountItemClick(data.personalAccount.id)}
            isActive={activeId === data.personalAccount.id}
          />
        )}
      </div>

      <div className='account__info-separation-line' />

      <div className='account__info'>
        {data?.availableAccount?.length > 0 && (
          <div className='account__info-title'>
            <FormattedMessage id='menu.settings.account.family' />
          </div>
        )}
        <ul className='account__info-list'>{renderAvailableAccounts()}</ul>
      </div>
      {renderLoader()}
    </div>
  );
});
