import { loader } from 'graphql.macro';
import { useRequestDeviceNotifications } from 'hooks/use-request-device-notifications';
import { useCustomMutation } from 'hooks/use-server-logic';
import { PAGE_ROUTES } from 'page-routes';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { setFormServerErrors } from 'scenes/common-auth-logic';

const mutationRegisterByMail = loader('./mutationRegisterByMail.graphql');

type SignUpValues = {
  email: string;
  password: string;
};

export const useSignUpLogic = () => {
  const intl = useIntl();
  const [_registerByMail, { loading }] = useCustomMutation(
    mutationRegisterByMail,
  );
  const history = useHistory();
  const requestDeviceNotifications = useRequestDeviceNotifications();

  const onSubmit = useCallback(
    async (values: SignUpValues, _: any, { setError }: any) => {
      try {
        await _registerByMail({
          variables: values,
        });

        toast.info(intl.messages['signUp.success'] as string, {
          autoClose: 3000,
          position: 'bottom-center',
        });

        requestDeviceNotifications();
        history.push(PAGE_ROUTES.login);
      } catch (errorResponse: any) {
        setFormServerErrors(errorResponse, setError);
      }
    },
    [_registerByMail, history, intl.messages, requestDeviceNotifications],
  );

  const onLogoClick = useCallback(() => {
    history.push(PAGE_ROUTES.auth);
  }, [history]);

  return {
    onSubmit,
    loading,
    onLogoClick,
  };
};
