import { AppType } from 'core.types';
import { memo } from 'react';

type AppHasAccessProps = {
  appType: AppType;
  children: any;
};

export const AppHasAccess = memo(({ appType, children }: AppHasAccessProps) => {
  if (appType !== process.env.REACT_APP_PROJECT_TYPE) {
    return null;
  }

  return children;
});
