import { StatusBar, Style } from '@capacitor/status-bar';

export enum APP_CSS_THEME_VARIABLES {
  primary = '--primary',
  secondary = '--secondary',
  emphasized = '--emphasized',
  emphasized10 = '--emphasized10',
  emphasized30 = '--emphasized30',
  emphasized50 = '--emphasized50',
  additional = '--additional',
  photoBackground = '--photoBackground',
  folderColorFront = '--folderColorFront',
  folderColorBack = '--folderColorBack',
  mistakeText = '--mistakeText',
  mainBackground = '--mainBackground',
  fillSecondary40 = '--fillSecondary40',
  textInfo = '--textInfo',
  actionText = '--actionText',
  actionText50 = '--actionText50',
  icons = '--icons',
  icons70 = '--icons70',
  radioBackground = '--radioBackground',
  buttonPlus = '--buttonPlus',
  textMain = '--textMain',
  textMain20 = '--textMain20',
  textMain40 = '--textMain40',
  textMain60 = '--textMain60',
  textMain10 = '--textMain10',
  mistakeStroke = '--mistakeStroke',
  boxShadow = '--box-shadow',
  logo = '--logo',
  statusBar = 'statusBar',
  authTextColor = '--authTextColor',
}

export enum APP_COLOR_THEME_TYPE {
  IRECEIPT = 'IRECEIPT',
  IDOCUMENT = 'IDOCUMENT',
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export const IRECEIPT_THEME = {
  [APP_CSS_THEME_VARIABLES.primary]: '#252F6F',
  [APP_CSS_THEME_VARIABLES.secondary]: '#A3ACE2',
  [APP_CSS_THEME_VARIABLES.emphasized]: '#252F6F',
  [APP_CSS_THEME_VARIABLES.emphasized10]: 'rgba(37, 47, 111, 0.1)',
  [APP_CSS_THEME_VARIABLES.emphasized30]: 'rgba(37, 47, 111, 0.3)',
  [APP_CSS_THEME_VARIABLES.emphasized50]: 'rgba(37, 47, 111, 0.5)',
  [APP_CSS_THEME_VARIABLES.additional]: '#141B50',
  [APP_CSS_THEME_VARIABLES.photoBackground]: '#0D1442',
  [APP_CSS_THEME_VARIABLES.folderColorFront]: '#6EC6B4',
  [APP_CSS_THEME_VARIABLES.folderColorBack]: '#3E9A87',
  [APP_CSS_THEME_VARIABLES.mistakeText]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.mainBackground]: '#F6F6F6',
  [APP_CSS_THEME_VARIABLES.fillSecondary40]: 'rgba(163, 172, 226, 0.4)',
  [APP_CSS_THEME_VARIABLES.textInfo]: '#252F6F',
  [APP_CSS_THEME_VARIABLES.actionText]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.actionText50]: 'rgba(255, 255, 255, 0.5)',
  [APP_CSS_THEME_VARIABLES.icons]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.icons70]: 'rgba(255, 255, 255, 0.7)',
  [APP_CSS_THEME_VARIABLES.radioBackground]: 'rgba(255, 255, 255, 0.7)',
  [APP_CSS_THEME_VARIABLES.buttonPlus]: '#252F6F',
  [APP_CSS_THEME_VARIABLES.textMain]: '#1A1C29',
  [APP_CSS_THEME_VARIABLES.textMain10]: 'rgba(26, 28, 41, 0.1)',
  [APP_CSS_THEME_VARIABLES.textMain20]: 'rgba(26, 28, 41, 0.2)',
  [APP_CSS_THEME_VARIABLES.textMain40]: 'rgba(26, 28, 41, 0.4)',
  [APP_CSS_THEME_VARIABLES.textMain60]: 'rgba(26, 28, 41, 0.6)',
  [APP_CSS_THEME_VARIABLES.mistakeStroke]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.boxShadow]: 'rgba(0, 0, 0, 0.1)',
  [APP_CSS_THEME_VARIABLES.logo]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.authTextColor]: '#FFF',
  [APP_CSS_THEME_VARIABLES.statusBar]: Style.Dark,
};

export const DARK_THEME = {
  [APP_CSS_THEME_VARIABLES.primary]: 'rgba(24, 24, 24, 1)',
  [APP_CSS_THEME_VARIABLES.secondary]: '#3079E8',
  [APP_CSS_THEME_VARIABLES.emphasized]: '#639DF3',
  [APP_CSS_THEME_VARIABLES.emphasized10]: 'rgb(99, 157, 243, 0.1)',
  [APP_CSS_THEME_VARIABLES.emphasized30]: 'rgb(99, 157, 243, 0.3)',
  [APP_CSS_THEME_VARIABLES.emphasized50]: 'rgb(99, 157, 243, 0.5)',
  [APP_CSS_THEME_VARIABLES.additional]: '#101010',
  [APP_CSS_THEME_VARIABLES.photoBackground]: '#0F0F0F',
  [APP_CSS_THEME_VARIABLES.folderColorFront]: '#89BCD9',
  [APP_CSS_THEME_VARIABLES.folderColorBack]: '#708C9D',
  [APP_CSS_THEME_VARIABLES.mistakeText]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.mainBackground]: '#010101',
  [APP_CSS_THEME_VARIABLES.fillSecondary40]: 'rgba(48, 121, 232, 0.4)',
  [APP_CSS_THEME_VARIABLES.textInfo]: '#737987',
  [APP_CSS_THEME_VARIABLES.actionText]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.actionText50]: 'rgba(255, 255, 255, 0.5)',
  [APP_CSS_THEME_VARIABLES.icons]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.icons70]: 'rgba(255, 255, 255, 0.7)',
  [APP_CSS_THEME_VARIABLES.radioBackground]: '#010101',
  [APP_CSS_THEME_VARIABLES.buttonPlus]: '#417EFB',
  [APP_CSS_THEME_VARIABLES.textMain]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.textMain10]: 'rgba(255, 255, 255, 0.1)',
  [APP_CSS_THEME_VARIABLES.textMain20]: 'rgba(255, 255, 255, 0.2)',
  [APP_CSS_THEME_VARIABLES.textMain40]: 'rgba(255, 255, 255, 0.4)',
  [APP_CSS_THEME_VARIABLES.textMain60]: 'rgba(255, 255, 255, 0.6)',
  [APP_CSS_THEME_VARIABLES.mistakeStroke]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.boxShadow]: 'rgba(42, 47, 52, 0.25)',
  [APP_CSS_THEME_VARIABLES.logo]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.authTextColor]: '#FFF',
  [APP_CSS_THEME_VARIABLES.statusBar]: Style.Dark,
};

export const LIGHT_THEME = {
  [APP_CSS_THEME_VARIABLES.primary]: '#E2E2E2',
  [APP_CSS_THEME_VARIABLES.secondary]: '#59A4AE',
  [APP_CSS_THEME_VARIABLES.emphasized]: '#37737B',
  [APP_CSS_THEME_VARIABLES.emphasized10]: 'rgba(55, 115, 123, 0.1)',
  [APP_CSS_THEME_VARIABLES.emphasized30]: 'rgba(55, 115, 123, 0.3)',
  [APP_CSS_THEME_VARIABLES.emphasized50]: 'rgba(55, 115, 123, 0.5)',
  [APP_CSS_THEME_VARIABLES.additional]: '#EAEAEA',
  [APP_CSS_THEME_VARIABLES.photoBackground]: '#EEEEEE',
  [APP_CSS_THEME_VARIABLES.folderColorFront]: '#F1CDAB',
  [APP_CSS_THEME_VARIABLES.folderColorBack]: '#E0B287',
  [APP_CSS_THEME_VARIABLES.mistakeText]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.mainBackground]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.fillSecondary40]: 'rgba(89, 164, 174, 0.4)',
  [APP_CSS_THEME_VARIABLES.textInfo]: '#37737B',
  [APP_CSS_THEME_VARIABLES.actionText]: '#222222',
  [APP_CSS_THEME_VARIABLES.actionText50]: 'rgba(34, 34, 34, 0.5)',
  [APP_CSS_THEME_VARIABLES.icons]: '#222222',
  [APP_CSS_THEME_VARIABLES.icons70]: 'rgba(34, 34, 34, 0.7)',
  [APP_CSS_THEME_VARIABLES.radioBackground]: 'rgba(255, 255, 255, 0.7)',
  [APP_CSS_THEME_VARIABLES.buttonPlus]: '#37737B',
  [APP_CSS_THEME_VARIABLES.textMain]: '#222222',
  [APP_CSS_THEME_VARIABLES.textMain10]: 'rgba(34, 34, 34, 0.1)',
  [APP_CSS_THEME_VARIABLES.textMain20]: 'rgba(34, 34, 34, 0.2)',
  [APP_CSS_THEME_VARIABLES.textMain40]: 'rgba(34, 34, 34, 0.4)',
  [APP_CSS_THEME_VARIABLES.textMain60]: 'rgba(34, 34, 34, 0.6)',
  [APP_CSS_THEME_VARIABLES.mistakeStroke]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.boxShadow]: 'rgba(0, 0, 0, 0.1)',
  [APP_CSS_THEME_VARIABLES.logo]: '#37737B',
  [APP_CSS_THEME_VARIABLES.authTextColor]: '#000',
  [APP_CSS_THEME_VARIABLES.statusBar]: Style.Light,
};

export const IDOCUMENT_THEME = {
  [APP_CSS_THEME_VARIABLES.primary]: '#2E6F95',
  [APP_CSS_THEME_VARIABLES.secondary]: '#4FA7BA',
  [APP_CSS_THEME_VARIABLES.emphasized]: '#0A354E',
  [APP_CSS_THEME_VARIABLES.emphasized10]: 'rgba(10, 53, 78, 0.1)',
  [APP_CSS_THEME_VARIABLES.emphasized30]: 'rgba(10, 53, 78, 0.3)',
  [APP_CSS_THEME_VARIABLES.emphasized50]: 'rgba(10, 53, 78, 0.5)',
  [APP_CSS_THEME_VARIABLES.additional]: '#115479',
  [APP_CSS_THEME_VARIABLES.photoBackground]: '#0B496B',
  [APP_CSS_THEME_VARIABLES.folderColorFront]: '#F1CDAB',
  [APP_CSS_THEME_VARIABLES.folderColorBack]: '#E0B287',
  [APP_CSS_THEME_VARIABLES.mistakeText]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.mainBackground]: '#F6F6F6',
  [APP_CSS_THEME_VARIABLES.fillSecondary40]: 'rgba(79, 167, 186, 0.4)',
  [APP_CSS_THEME_VARIABLES.textInfo]: '#2E6F95',
  [APP_CSS_THEME_VARIABLES.actionText]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.actionText50]: 'rgba(255, 255, 255, 0.5)',
  [APP_CSS_THEME_VARIABLES.icons]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.icons70]: 'rgba(255, 255, 255, 0.7)',
  [APP_CSS_THEME_VARIABLES.radioBackground]: 'rgba(255, 255, 255, 0.7)',
  [APP_CSS_THEME_VARIABLES.buttonPlus]: '#2E6F95',
  [APP_CSS_THEME_VARIABLES.textMain]: '#1A1C29',
  [APP_CSS_THEME_VARIABLES.textMain10]: 'rgba(26, 28, 41, 0.1)',
  [APP_CSS_THEME_VARIABLES.textMain20]: 'rgba(26, 28, 41, 0.2)',
  [APP_CSS_THEME_VARIABLES.textMain40]: 'rgba(26, 28, 41, 0.4)',
  [APP_CSS_THEME_VARIABLES.textMain60]: 'rgba(26, 28, 41, 0.6)',
  [APP_CSS_THEME_VARIABLES.mistakeStroke]: '#E31F1F',
  [APP_CSS_THEME_VARIABLES.boxShadow]: 'rgba(0, 0, 0, 0.1)',
  [APP_CSS_THEME_VARIABLES.logo]: '#FFFFFF',
  [APP_CSS_THEME_VARIABLES.authTextColor]: '#FFF',
  [APP_CSS_THEME_VARIABLES.statusBar]: Style.Dark,
};

export const THEME_MAP = {
  [APP_COLOR_THEME_TYPE.IRECEIPT]: IRECEIPT_THEME,
  [APP_COLOR_THEME_TYPE.IDOCUMENT]: IDOCUMENT_THEME,
  [APP_COLOR_THEME_TYPE.DARK]: DARK_THEME,
  [APP_COLOR_THEME_TYPE.LIGHT]: LIGHT_THEME,
};

export const applyColorTheme = (theme: APP_COLOR_THEME_TYPE) => {
  const selectedTheme = THEME_MAP[theme];

  Object.entries(selectedTheme).forEach(([key, value]) => {
    if (key !== APP_CSS_THEME_VARIABLES.statusBar) {
      document.documentElement.style.setProperty(key, value);
    } else {
      if (window.cordova) {
        // @ts-ignore
        StatusBar.setStyle({ style: value });
      }
    }
  });
};
