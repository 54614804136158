import { CategoryId, DNDDropType, ReceiptId } from 'core.types';
import { useCallback, useEffect, useState } from 'react';
import { usePreview } from 'react-dnd-preview';
import { useRecoilValue } from 'recoil';
import { selectedCategoryIDsAtom, selectedReceiptIDsAtom } from 'store';
import './category-dnd-preview.scss';
import { DndPreviewFolderItem } from './dnd-preview-folder-item';
import { DndPreviewReceiptItem } from './dnd-preview-receipt-item';

export const CategoryDNDPreview = () => {
  const { display, item, style } = usePreview();
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const selectedReceiptIdsList = useRecoilValue(selectedReceiptIDsAtom);
  const areItemsSelected =
    selectedCategoryIdsList.length > 0 || selectedReceiptIdsList.length > 0;
  const [autoScrollInProgress, setAutoScrollProgress] = useState(false);

  const scrollElement = useCallback(() => {
    // TODO fix bottom base on grid view and height of item

    const container = document.getElementById('category-content-wrapper');
    if (!container || !style || autoScrollInProgress) {
      return;
    }
    try {
      const containerYPosStart = container.getBoundingClientRect().top;
      const containerYPosEnd = container.getBoundingClientRect().bottom;
      const transformYString = style.transform.split(', ')[1];
      const transformYValue = +transformYString.substring(
        0,
        transformYString.length - 3,
      );
      setAutoScrollProgress(true);

      if (transformYValue <= containerYPosStart) {
        // @ts-ignore
        container.scrollToPoint(0, container.detail.scrollTop - 20);
      } else if (transformYValue >= containerYPosEnd) {
        // @ts-ignore
        container.scrollToPoint(0, container.detail.scrollTop + 20);
      }
      setAutoScrollProgress(false);
    } catch {}
  }, [autoScrollInProgress, style]);

  useEffect(() => {
    scrollElement();
  }, [scrollElement]);

  const renderPreviewFolderItem = useCallback(
    (id: CategoryId, index: number) => (
      <DndPreviewFolderItem id={id} key={index} />
    ),
    [],
  );

  const renderPreviewReceiptItem = useCallback(
    (id: ReceiptId, index: number) => (
      <DndPreviewReceiptItem id={id} key={index} />
    ),
    [],
  );

  const renderSingleItem = useCallback((singleItem: any) => {
    return singleItem.type === DNDDropType.category ? (
      <DndPreviewFolderItem id={singleItem.id} key={singleItem.id} />
    ) : (
      <DndPreviewReceiptItem id={singleItem.id} key={singleItem.id} />
    );
  }, []);

  if (!display) {
    return null;
  }

  return (
    <div className='dnd-preview-items' style={style}>
      {!areItemsSelected && renderSingleItem(item)}
      {areItemsSelected && selectedCategoryIdsList.map(renderPreviewFolderItem)}
      {areItemsSelected && selectedReceiptIdsList.map(renderPreviewReceiptItem)}
    </div>
  );
};
