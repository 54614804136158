export class ClientService {
  static getLocale() {
    let locale = localStorage.getItem('locale');
    if (!locale) {
      locale = 'en';
    }

    return locale;
  }
}
