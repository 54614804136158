import { SETTINGS_LANGUAGE_MAP } from './settings-language.constants';
import { useIntl } from 'react-intl';
import { useCallback, useMemo, useState } from 'react';
import { useIntlContext } from '../../../../components';
import { useLangEdit } from './gql';

type SettingLanguageOption = {
  label: string;
  onClick: () => void;
  isActive: boolean;
};

type SettingsLanguageHookData = {
  settingsLanguageOptions: SettingLanguageOption[];
};

export const useSettingsLanguageLogic = (): SettingsLanguageHookData => {
  const intl = useIntl();
  const { locale, setLocale } = useIntlContext();
  const { changeLocale } = useLangEdit();
  const [selectedLanguage, selectLanguage] = useState<string>(locale);

  const onLanguageChange = useCallback(
    (locale: string): void => {
      changeLocale({ locale } as any).then(() => {
        localStorage.setItem('locale', locale);
        setLocale(locale);
        selectLanguage(locale);
      });
    },
    [changeLocale, setLocale],
  );

  const settingsLanguageOptions = useMemo(
    () =>
      SETTINGS_LANGUAGE_MAP.map((language) => ({
        label: String(intl.messages[`menu.lang.${language}`]),
        isActive: language === selectedLanguage,
        onClick: () => onLanguageChange(language),
      })),
    [intl.messages, onLanguageChange, selectedLanguage],
  );

  return {
    settingsLanguageOptions,
  };
};
