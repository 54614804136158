import { AppSubscriptionId } from 'core.types';
import { FormattedMessage } from 'react-intl';
import {
  PlanInformation,
  PlansInfoMap,
  SubscriptionGroup,
  SubscriptionPeriod,
} from './app-plan.types';
import { PlanBenefit } from './plan-benefit';
import { PlanTile } from './plan-tile';

type PlanInfoProps = {
  planInfo: PlanInformation | null;
  planBenefits: {
    title: string;
    description: string;
  }[];
  subscriptionGroup: SubscriptionGroup;
  onTabTileClick(id: AppSubscriptionId): void;
};

export const PlanInfo = ({
  planBenefits,
  planInfo,
  onTabTileClick,
  subscriptionGroup,
}: PlanInfoProps) => {
  const { maxAccounts, text } = PlansInfoMap[subscriptionGroup];

  if (!planInfo) {
    return null;
  }

  const { monthlyPrice, yearlyPrice, discount, currency, monthlyId, yearlyId } =
    planInfo;

  return (
    <>
      <div className='plan__proposals'>
        <PlanTile
          price={monthlyPrice}
          {...{
            onTabTileClick,
            id: monthlyId,
            currency,
            period: SubscriptionPeriod.monthly,
          }}
        />

        <PlanTile
          price={yearlyPrice}
          isYearlyPlan
          {...{
            currency,
            onTabTileClick,
            discount,
            id: yearlyId,
            period: SubscriptionPeriod.yearly,
          }}
        />
      </div>

      <div className='plan__list'>
        {planBenefits.map((benefit, index) => (
          <PlanBenefit
            key={benefit.title}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
        {maxAccounts > 1 && (
          <PlanBenefit
            title={
              <>
                <FormattedMessage id='menu.profile.plan.argument.2.title' />
                {maxAccounts}
                <FormattedMessage id='menu.profile.plan.argument.2.title-2' />
              </>
            }
            description={text}
          />
        )}
      </div>
    </>
  );
};
