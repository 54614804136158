import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import c from 'classnames';
import debounce from 'lodash/debounce';
import { memo, useCallback, useState } from 'react';
import { AppSlider } from '../../components/app-slider';
import { interpolate } from '../../services';
import {
  CONTRAST_RANGE_MAP,
  INTENSITY_SLIDER_RANGE_MAP,
  SLIDER_RANGE_TYPE,
  SLIDER_STEP,
} from '../crop-step.constants';
import { useCropStepContext } from '../crop-step-context';
import './crop-step-image-intensity-controls.scss';
import { useIntl } from 'react-intl';

const applyFilters = debounce((params) => {
  window.newPoly.applyFilters(params);
}, 200);

export const CropStepImageIntensityControls = memo(() => {
  const intl = useIntl();
  const { hideIntensity, intensityEnabled } = useCropStepContext();
  const [brightness, setBrightness] = useState(
    window.newPoly.filters.brightness,
  );
  const [contrast, setContrast] = useState(window.newPoly.filters.contrast);
  const [contrastPercentageValue, setContrastPercentageValue] = useState(0);

  const onBrightnessChange = useCallback(
    (brightness) => {
      setBrightness(brightness);
      applyFilters({ brightness, contrast });
    },
    [contrast],
  );

  const onChangeContrast = useCallback((percentageValue) => {
    const rangeType =
      percentageValue < 0
        ? SLIDER_RANGE_TYPE.negative
        : SLIDER_RANGE_TYPE.positive;

    const interpolateValue = interpolate({
      inputRange: INTENSITY_SLIDER_RANGE_MAP[rangeType],
      outputRange: CONTRAST_RANGE_MAP[rangeType],
    });
    const interpolatedValue = interpolateValue(percentageValue);
    setContrast(interpolatedValue);
    setContrastPercentageValue(percentageValue);
    applyFilters({ brightness, contrast: interpolatedValue });
  }, []);

  const onClickAway = useCallback(
    (event) => {
      const styleButton = document.getElementById('intensity-button');
      if (
        intensityEnabled &&
        styleButton &&
        !styleButton.contains(event.target)
      ) {
        hideIntensity();
      }
    },
    [hideIntensity, intensityEnabled],
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={c('intensity-controls', {
          'intensity-controls--active': intensityEnabled,
        })}
      >
        <div className='intensity-filters-content'>
          <AppSlider
            {...{
              min: INTENSITY_SLIDER_RANGE_MAP.all[0],
              max: INTENSITY_SLIDER_RANGE_MAP.all[1],
              step: SLIDER_STEP,
              value: brightness,
              caption: intl.messages['style.style'] as string,
              onRangeChange: onBrightnessChange,
            }}
          />
          <AppSlider
            {...{
              min: INTENSITY_SLIDER_RANGE_MAP.all[0],
              max: INTENSITY_SLIDER_RANGE_MAP.all[1],
              step: SLIDER_STEP,
              value: contrastPercentageValue,
              onRangeChange: onChangeContrast,
              caption: intl.messages['style.contrast'] as string,
            }}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
});
