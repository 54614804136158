import { ReceiptType } from 'core.types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { receiptTypeAtom } from '../../store';

const AddReceiptMessageMap = {
  [ReceiptType.invoice]: 'new.invoice',
  [ReceiptType.receipt]: 'new.receipt',
  [ReceiptType.document]: 'new.document',
};

export const AddReceiptButtonSignature = memo(() => {
  const receiptType = useRecoilValue(receiptTypeAtom);

  return (
    <div className='app-footer-signature'>
      <FormattedMessage id={AddReceiptMessageMap[receiptType]} />
    </div>
  );
});
