import { AppModal } from 'components/app-modal';
import { DefaultInput } from 'components/default-input';
import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { HelperService } from 'services/HelperService';

type AddEmailModalProps = {
  open: boolean;
  closeAccountModal(): void;
  addAccount(email: string): void;
};

export const AddEmailModal = memo(
  ({ open, closeAccountModal, addAccount }: AddEmailModalProps) => {
    const [email, setEmail] = useState('');
    const intl = useIntl();

    const onSaveClick = async () => {
      if (HelperService.isValidEmail(email)) {
        await addAccount(email);
        closeAccountModal();
      }
    };

    const onChangeEmail = ({ target }: any) => setEmail(target.value);

    return (
      <AppModal
        className='add-mail__modal'
        open={open}
        saveDisabled={!HelperService.isValidEmail(email)}
        onSaveClick={onSaveClick}
        content={
          <div className='add-mail__input-wrapper'>
            {/* @ts-ignore */}
            <DefaultInput
              {...{
                value: email,
                placeholder: 'example@gmail.com',
                onChange: onChangeEmail,
                iconName: 'mail',
              }}
            />
          </div>
        }
        onClose={closeAccountModal}
        title={intl.messages['menu.profile.new.account'] as string}
      />
    );
  },
);
