import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import { useGetUser } from '../../../../../gql/api-user';

const mutationChangeAccountType = loader('./mutationСhangeAccountType.graphql');

export const useQuerySharedAccounts = () => {
  const [_mutationChangeAccountType, { loading: processingChangeAccountType }] =
    useCustomMutation(mutationChangeAccountType);

  const { fetchUser } = useGetUser();

  const changeAccountType = (type: any) => {
    try {
      return _mutationChangeAccountType({
        variables: {
          accountType: type,
        },
      }).then(() => {
        fetchUser();
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    loading: processingChangeAccountType,
    changeAccountType,
  };
};
