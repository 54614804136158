import { loader } from 'graphql.macro';
import { useIsAuthorizedAtomState } from '../../../../../store';
import { useCustomMutation } from '../../../../../hooks/use-server-logic';

const mutationProfileEdit = loader('./mutationProfileEdit.graphql');

export const useChangePassword = () => {
  const [_mutation, { loading, data }] = useCustomMutation(mutationProfileEdit);
  const { setIsAuthorised } = useIsAuthorizedAtomState();

  const changePassword = (password: string) => {
    return (async () => {
      try {
        const response = await _mutation({
          variables: { password },
        });

        try {
          // @ts-ignore
          await setIsAuthorised(response.data.profileEdit.token);
        } catch (e) {}
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  return {
    changePassword,
    loading,
    data,
  };
};
