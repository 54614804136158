import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { AppButton } from 'components/app-button';
import { AppIcon } from 'components/app-icon';
import { memo, SyntheticEvent } from 'react';
import { useWatch } from 'react-hook-form';
import './app-form-field-clear-button.scss';

type InputClearableButtonProps = {
  onClick(event: SyntheticEvent<HTMLInputElement>): void;
  fieldName: string;
};

const CLEAR_ICON_SIZE = { width: 12, height: 12 };

export const AppFormFieldClearButton = memo(
  ({ onClick, fieldName }: InputClearableButtonProps) => {
    const fieldValue = useWatch({ name: fieldName });

    if (!fieldValue) {
      return null;
    }

    return (
      <AppButton onPress={onClick} className='app-form__field-clear-button'>
        <AppIcon
          className='app-form__field-clear-button-icon'
          color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
          name='close'
          size={CLEAR_ICON_SIZE}
        />
      </AppButton>
    );
  },
);
