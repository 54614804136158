import { AppConfirmModal } from 'components/app-confirm-modal';
import { useLogout } from 'hooks/use-logout';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import Tappable from 'react-tappable';
import { AppLoader } from '../../../../components/app-loader';
import { useBooleanState } from '../../../../hooks';
import { SettingsIcon } from '../../settings-icon';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';

export const LogOut = () => {
  const [
    confirmLogoutModalOpened,
    openConfirmLogoutModal,
    closeConfirmLogoutModal,
  ] = useBooleanState(false);
  const intl = useIntl();
  const [logoutInProgress, setLogoutProgress, unsetLogoutProgress] =
    useBooleanState(false);

  const logOut = useLogout();
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  const onConfirmClick = useCallback(async () => {
    if (!logoutInProgress) {
      setLogoutProgress();
      closeConfirmLogoutModal();
      logOut();
      unsetLogoutProgress();
    }
  }, [
    closeConfirmLogoutModal,
    logOut,
    logoutInProgress,
    setLogoutProgress,
    unsetLogoutProgress,
  ]);

  return (
    <>
      <Tappable
        component='li'
        onTap={openConfirmLogoutModal}
        className='menu-item menu-item--logout'
      >
        <div className='menu-logout'>
          {/* @ts-ignore */}
          <SettingsIcon name='logout' color={colorEmphasized} />
          <span className='menu-logout__text'>
            {intl.messages['menu.logout'] as string}
          </span>
        </div>
      </Tappable>

      <AppConfirmModal
        open={confirmLogoutModalOpened}
        onConfirmClick={onConfirmClick}
        title={intl.messages['menu.logout.modal.title'] as string}
        onClose={closeConfirmLogoutModal}
      />
      {logoutInProgress && <AppLoader />}
    </>
  );
};
