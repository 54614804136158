import { isReceiptEmpty } from 'category-tree-hooks/category-service';
import {
  AppType,
  CategoryId,
  CreatorType,
  LocalCategory,
  LocalReceipt,
  Maybe,
  ReceiptId,
  ReceiptType,
} from 'core.types';
import { AvailableAccountShare } from 'gql/api-user/api-user.types';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { createContext, PureComponent } from 'react';
import { hasAppAccess } from 'services/app-service';
import {
  localCategoriesTable,
  localReceiptsTable,
} from 'services/indexed-db-service';
import { getUnixTimeDates } from 'store/filters-store';
import {
  filterReceiptPredicate,
  ReceiptEmptyType,
  shouldSkipUnderPermissions,
} from './category-tree-provider.helpers';

export const CategoryTreeContext = createContext({} as any);

export type ModifyParentItemDTO = {
  propType: 'children' | 'receipts';
  operation: 'add' | 'remove';
};

export type DeleteCategoryListDTO = {
  parentId: CategoryId;
  ids: CategoryId[];
};

export type GetActiveSliceAllChildrenAndReceiptsDTO = {
  category: LocalCategory;
  receipts?: ReceiptId[];
  children?: CategoryId[];
};

export type CategoryAmountDTO = {
  category: LocalCategory;
  currentUserSharedAccount: Maybe<AvailableAccountShare>;
  userId: Maybe<number>;
  filters: {
    searchFilter: string;
    receiptType: ReceiptType;
    filterMadeBy: CreatorType;
    dateFrom: Maybe<Date>;
    dateTo: Maybe<Date>;
  };
};

export class CategoryTreeProvider extends PureComponent<any> {
  categoryMap = {} as any;
  receiptMap = {} as any;

  getCategoryById = (id: Maybe<CategoryId>): Maybe<LocalCategory> => {
    if (id) {
      return this.categoryMap[id];
    }
    return null;
  };

  getReceiptById = (id: Maybe<ReceiptId>): Maybe<LocalReceipt> => {
    if (id) {
      return this.receiptMap[id];
    }
    return null;
  };

  updateCategoryItemList = (categoryList: LocalCategory[]) => {
    const newCategoryListMap = {} as Record<string, LocalCategory>;

    for (const categoryToUpdate of categoryList) {
      newCategoryListMap[categoryToUpdate.id] = categoryToUpdate;
    }
    localCategoriesTable.bulkPut(categoryList);

    if (this.categoryMap) {
      Object.assign(this.categoryMap, newCategoryListMap);
    }
  };

  updateReceiptItemList = (receiptList: Array<LocalReceipt>) => {
    const newReceiptListMap = {} as Record<string, LocalReceipt>;

    for (const receiptToUpdate of receiptList) {
      newReceiptListMap[receiptToUpdate.id] = receiptToUpdate;
    }

    localReceiptsTable.bulkPut(receiptList);

    Object.assign(this.receiptMap, newReceiptListMap);
  };

  deleteReceiptItemList = (ids: ReceiptId[]) => {
    for (const id of ids) {
      delete this.receiptMap[id];
    }
    localReceiptsTable.where('id').anyOf(ids).delete();
  };

  deleteCategoryItemList = (ids: CategoryId[]) => {
    for (const id of ids) {
      delete this.categoryMap[id];
    }

    localCategoriesTable.where('id').anyOf(ids).delete();
  };

  modifyItemIDToParentCategory =
    ({ propType, operation }: ModifyParentItemDTO) =>
    ({
      itemId,
      parentId,
    }: {
      itemId: CategoryId | ReceiptId;
      parentId: CategoryId;
    }) => {
      const parentCategory = this.getCategoryById(parentId);

      if (!parentCategory) {
        return;
      }

      const finalArrayWithAddOperation = !parentCategory[propType].includes(
        itemId,
      )
        ? [...parentCategory[propType], itemId]
        : parentCategory[propType];

      const finalChildArray =
        operation === 'add'
          ? finalArrayWithAddOperation
          : parentCategory[propType].filter((id: any) => id !== itemId);

      // ! ad new id to parent category propType specific list
      if (!isEqual(finalChildArray, parentCategory[propType])) {
        this.updateCategoryItemList([
          {
            ...parentCategory,
            [propType]: finalChildArray,
          },
        ]);
      }
    };

  // !Category processing
  createNewCategoryList = (categoryList: LocalCategory[]) => {
    const addCategoryIDToParentCategory = this.modifyItemIDToParentCategory({
      propType: 'children',
      operation: 'add',
    });

    for (const category of categoryList) {
      addCategoryIDToParentCategory({
        itemId: category.id,
        parentId: category.parentId,
      });
    }

    this.updateCategoryItemList(categoryList);
    this.forceUpdate();
  };

  updateCategoryList = (categoryList: LocalCategory[]) => {
    const addCategoryIDToParentCategory = this.modifyItemIDToParentCategory({
      propType: 'children',
      operation: 'add',
    });

    const removeCategoryIDFromOldParentCategory =
      this.modifyItemIDToParentCategory({
        propType: 'children',
        operation: 'remove',
      });

    for (const category of categoryList) {
      const existedCategory = this.getCategoryById(category.id);

      if (existedCategory && existedCategory.parentId !== category.parentId) {
        // !First always remove
        removeCategoryIDFromOldParentCategory({
          itemId: category.id,
          parentId: existedCategory.parentId,
        });
      }

      // !Note it will skip if item.id is already here
      addCategoryIDToParentCategory({
        itemId: category.id,
        parentId: category.parentId,
      });

      this.updateCategoryItemList([
        {
          ...category,
          receipts: uniq([
            ...(existedCategory?.receipts || []),
            ...category.receipts,
          ]),
          children: uniq([
            ...(existedCategory?.children || []),
            ...category.children,
          ]),
        },
      ]);
    }
    this.forceUpdate();
  };

  deleteCategoryList = ({
    ids,
    parentId,
  }: {
    ids: CategoryId[];
    parentId: CategoryId;
  }) => {
    this.deleteCategoryItemList(ids);

    const parentCategory = this.getCategoryById(parentId);

    if (parentCategory) {
      this.updateCategoryItemList([
        {
          ...parentCategory,
          children: parentCategory.children.filter(
            (id: any) => !ids.includes(id),
          ),
        },
      ]);
    }

    this.forceUpdate();
  };

  // !Receipt processing
  createNewReceiptList = (receiptList: LocalReceipt[]) => {
    const addReceiptIDToNewParentCategory = this.modifyItemIDToParentCategory({
      propType: 'receipts',
      operation: 'add',
    });

    for (const receipt of receiptList) {
      addReceiptIDToNewParentCategory({
        itemId: receipt.id,
        parentId: receipt.parentId,
      });
    }
    this.updateReceiptItemList(receiptList);
    this.forceUpdate();
  };

  updateReceiptList = (receiptList: LocalReceipt[]) => {
    const removeReceiptIDFromOldParentCategory =
      this.modifyItemIDToParentCategory({
        propType: 'receipts',
        operation: 'remove',
      });
    const addReceiptIDToNewParentCategory = this.modifyItemIDToParentCategory({
      propType: 'receipts',
      operation: 'add',
    });

    for (const receipt of receiptList) {
      const existedReceipt = this.getReceiptById(receipt.id);

      // !remove receipt id if it's moved
      if (existedReceipt && existedReceipt?.parentId !== receipt.parentId) {
        removeReceiptIDFromOldParentCategory({
          itemId: receipt.id,
          parentId: existedReceipt.parentId,
        });
      }

      // !Note it will skip if item.id is already here
      addReceiptIDToNewParentCategory({
        itemId: receipt.id,
        parentId: receipt.parentId,
      });
    }
    this.updateReceiptItemList(receiptList);
    this.forceUpdate();
  };

  deleteReceiptList = ({
    parentId,
    ids,
  }: {
    parentId: CategoryId;
    ids: ReceiptId[];
  }) => {
    const removeReceiptIDFromOldParentCategory =
      this.modifyItemIDToParentCategory({
        propType: 'receipts',
        operation: 'remove',
      });

    for (const idToRemove of ids) {
      removeReceiptIDFromOldParentCategory({
        itemId: idToRemove,
        parentId: parentId,
      });
    }
    this.deleteReceiptItemList(ids);
    this.forceUpdate();
  };

  updateReceiptTempIdOnRealId = ({
    tempId,
    id,
    updatedAt,
  }: {
    tempId: string;
    id: number;
    updatedAt: number;
  }) => {
    const receipt = this.getReceiptById(tempId);
    const parentId = receipt?.parentId;

    if (!receipt) {
      return;
    }
    this.deleteReceiptItemList([tempId]);

    // !Create new receipt receipt with new id and updatedAt
    this.updateReceiptItemList([
      {
        ...receipt,
        updatedAt,
        id,
      },
    ]);

    // @ts-ignore
    const parentReceiptCategory = this.getCategoryById(parentId);

    if (!parentReceiptCategory) {
      return;
    }

    this.updateCategoryItemList([
      {
        ...parentReceiptCategory,
        receipts: parentReceiptCategory.receipts.map((receiptId: ReceiptId) =>
          receiptId === tempId ? id : receiptId,
        ),
      },
    ]);

    const { receiptToEdit, setReceiptToEdit } = this.props;

    // !Change receipt to edit id on temp id
    if (receiptToEdit?.id === tempId) {
      setReceiptToEdit({ ...receiptToEdit, updatedAt, id });
    }
    this.props.setSelectedReceiptIdsList((list: ReceiptId[]) =>
      list.map((selectedId: ReceiptId) =>
        selectedId === tempId ? id : selectedId,
      ),
    );

    this.forceUpdate();
  };

  updateCategoryTempIdOnRealId = async ({
    tempId,
    id,
    updatedAt,
  }: {
    tempId: string;
    id: number;
    updatedAt: number;
  }) => {
    const category = this.getCategoryById(tempId);
    const parentId = category?.parentId;

    if (!category) {
      return;
    }

    // !Reset old id
    this.deleteCategoryItemList([tempId]);

    // !Create new category with new id
    this.updateCategoryItemList([
      {
        ...category,
        updatedAt,
        id,
      },
    ]);

    // @ts-ignore
    const patentCategory = this.getCategoryById(parentId);

    // !Update parent category children list
    if (patentCategory) {
      this.updateCategoryItemList([
        {
          ...patentCategory,
          children: patentCategory.children.map((categoryId: CategoryId) => {
            return categoryId === tempId ? id : categoryId;
          }),
        },
      ]);
    }

    // !Update child children list parentId
    for (const childId of category.children) {
      const childCategory = this.getCategoryById(childId);

      if (childCategory) {
        this.updateCategoryItemList([
          {
            ...childCategory,
            parentId: id,
          },
        ]);
      }
    }

    // !Update child receipt parentId
    for (const childId of category.receipts) {
      const childReceipt = this.getReceiptById(childId);

      if (childReceipt) {
        this.updateReceiptItemList([
          {
            ...childReceipt,
            parentId: id,
          },
        ]);
      }
    }

    const { activeSliceId, setActiveSliceId, tempIdMap, setTempIdMap } =
      this.props;

    if (activeSliceId === tempId) {
      setActiveSliceId(id);

      // !Add inspection on temp id map
      setTempIdMap({ ...tempIdMap, [tempId]: id });
    }

    this.props.setSelectedCategoryIdsList((list: CategoryId[]) =>
      list.map((selectedId: CategoryId) =>
        selectedId === tempId ? id : selectedId,
      ),
    );
    this.forceUpdate();
  };

  // !Selectors
  activeSliceSelector = () => {
    const { activeSliceId } = this.props;
    return activeSliceId ? this.getCategoryById(activeSliceId) : null;
  };

  getSliceAllReceiptsAndCategoryList = ({
    category,
    receipts = [],
    children = [],
  }: GetActiveSliceAllChildrenAndReceiptsDTO) => {
    receipts = receipts.concat(category.receipts);
    children = children.concat(category.children);

    category.children.forEach((id: CategoryId) => {
      const childCategory: Maybe<LocalCategory> = this.getCategoryById(id);

      if (!childCategory) {
        return;
      }

      ({ receipts, children } = this.getSliceAllReceiptsAndCategoryList({
        category: childCategory,
        receipts,
        children,
      }));
    });

    return {
      receipts,
      children,
    };
  };

  flatActiveSliceSelector = () => {
    const activeSlice = this.activeSliceSelector();
    if (!activeSlice) {
      return null;
    }

    const { receipts, children } = this.getSliceAllReceiptsAndCategoryList({
      category: activeSlice,
    });

    return {
      ...activeSlice,
      receipts,
      children,
    };
  };

  folderPathSelector = () => {
    const { rootId, activeSliceId } = this.props;
    const folderPath: CategoryId[] = [];

    let currentSliceId = activeSliceId;

    if (!currentSliceId) {
      return [];
    }

    while (currentSliceId !== rootId) {
      const currentCategory: Maybe<LocalCategory> =
        this.getCategoryById(currentSliceId);
      if (!currentCategory) {
        return [];
      }
      folderPath.unshift(currentCategory.id);
      currentSliceId = currentCategory.parentId;
    }

    folderPath.unshift(rootId);

    return folderPath;
  };

  activeSliceReceiptListSelectorFamily = (
    receiptEmptyType: ReceiptEmptyType,
  ) => {
    const {
      searchFilter,
      sortField,
      sortType,
      receiptType,
      filterMadeBy,
      dateFrom,
      dateTo,
      currentUserSharedAccount,
      userId,
    } = this.props;

    const finalSlice = !!searchFilter
      ? this.flatActiveSliceSelector()
      : this.activeSliceSelector();

    if (!finalSlice) {
      return [];
    }

    const filteredReceipts: LocalReceipt[] = [];

    for (const id of finalSlice.receipts) {
      const receipt = this.getReceiptById(id);

      if (!receipt) {
        continue;
      }

      const isEmpty = isReceiptEmpty(receipt);

      if (
        filterReceiptPredicate({
          receipt,
          isEmpty,
          currentUserSharedAccount,
          receiptEmptyType,
          userId,
          filters: {
            searchFilter,
            receiptType,
            filterMadeBy,
            ...getUnixTimeDates({ dateFrom, dateTo }),
          },
        })
      ) {
        filteredReceipts.push(receipt);
      }
    }

    return orderBy(filteredReceipts, sortField, sortType);
  };

  getCategoryAmount = ({
    category,
    currentUserSharedAccount,
    userId,
    filters: { searchFilter, receiptType, filterMadeBy, dateFrom, dateTo },
  }: CategoryAmountDTO) => {
    let finalAmount = 0;

    for (const receiptId of category.receipts) {
      const receipt: Maybe<LocalReceipt> = this.getReceiptById(receiptId);
      if (!receipt) {
        return 0;
      }

      const isEmpty = isReceiptEmpty(receipt);

      if (
        filterReceiptPredicate({
          receipt,
          isEmpty,
          currentUserSharedAccount,
          receiptEmptyType: 'all',
          userId,
          filters: {
            searchFilter,
            receiptType,
            filterMadeBy,
            ...getUnixTimeDates({ dateFrom, dateTo }),
          },
        })
      ) {
        finalAmount += receipt.amount;
      }
    }

    for (const categoryId of category.children) {
      const childCategory = this.getCategoryById(categoryId);

      if (childCategory) {
        finalAmount += this.getCategoryAmount({
          category: childCategory,
          currentUserSharedAccount,
          userId,
          filters: {
            searchFilter,
            receiptType,
            filterMadeBy,
            dateFrom,
            dateTo,
          },
        });
      }
    }

    return finalAmount;
  };

  categoryAmountSelector = (id: CategoryId) => {
    const category: Maybe<LocalCategory> = this.getCategoryById(id);
    const {
      searchFilter,
      receiptType,
      filterMadeBy,
      dateFrom,
      dateTo,
      currentUserSharedAccount,
      userId,
    } = this.props;

    if (!category) {
      return 0;
    }

    const amount = hasAppAccess(AppType.ireceipt)
      ? this.getCategoryAmount({
          category,
          currentUserSharedAccount,
          userId,
          filters: {
            searchFilter,
            receiptType,
            filterMadeBy,
            dateFrom,
            dateTo,
          },
        })
      : 0;

    return Math.round(amount * 1000) / 1000;
  };

  activeSliceCategoryListSelectorFamily = () => {
    const {
      searchFilter,
      sortField,
      sortType,
      receiptType,
      currentUserSharedAccount,
      userId,
    } = this.props;

    const activeSlice = !!searchFilter
      ? this.flatActiveSliceSelector()
      : this.activeSliceSelector();

    if (!activeSlice) {
      return [];
    }

    const filteredCategoryList = activeSlice.children.reduce(
      (localCategoryList: any[], id: CategoryId) => {
        const category = this.getCategoryById(id);
        const categoryAmount = this.categoryAmountSelector(id);

        if (!category) {
          return localCategoryList;
        }

        const isItemInSearch = String(category.name)
          .toLowerCase()
          .includes(searchFilter.toLowerCase());

        if (
          isItemInSearch &&
          !shouldSkipUnderPermissions(
            category,
            currentUserSharedAccount,
            userId,
          ) &&
          category.type === receiptType &&
          !category.deleted
        ) {
          localCategoryList.push({ ...category, amount: categoryAmount });
        }

        return localCategoryList;
      },
      [] as Array<LocalCategory & { amount: number }>,
    );

    return orderBy(filteredCategoryList, sortField, sortType);
  };

  isEmptyActiveSliceSelector = (activeSlice = this.activeSliceSelector()) => {
    if (!activeSlice) {
      return false;
    }

    const filteredSliceReceipts = activeSlice.receipts.filter((receiptId) => {
      const receipt = this.getReceiptById(receiptId);

      if (!receipt) {
        return false;
      }

      const isEmpty = isReceiptEmpty(receipt);

      const {
        currentUserSharedAccount,
        userId,
        searchFilter,
        receiptType,
        filterMadeBy,
        dateFrom,
        dateTo,
      } = this.props;

      return filterReceiptPredicate({
        receipt,
        isEmpty,
        currentUserSharedAccount,
        receiptEmptyType: 'all',
        userId,
        filters: {
          searchFilter,
          receiptType,
          filterMadeBy,
          ...getUnixTimeDates({ dateFrom, dateTo }),
        },
      });
    });

    return (
      activeSlice?.children.length === 0 && filteredSliceReceipts.length === 0
    );
  };

  getAllReceiptFromCategory = (category: LocalCategory) => {
    let receipts: LocalReceipt[] = [];

    for (const receiptId of category.receipts) {
      const receipt = this.getReceiptById(receiptId);
      if (receipt) {
        receipts.push(receipt);
      }
    }

    for (const categoryId of category.children) {
      const childCategory = this.getCategoryById(categoryId);
      const childReceipts = childCategory
        ? this.getAllReceiptFromCategory(childCategory)
        : [];
      receipts = receipts.concat(childReceipts);
    }

    return receipts;
  };

  categoryReceiptsSelector = (categoryId: CategoryId) => {
    const category = this.getCategoryById(categoryId);
    return category ? this.getAllReceiptFromCategory(category) : [];
  };

  getReceiptsFromSelectedLists = ({
    selectedCategoryIdsList,
    selectedReceiptIdsList,
  }: {
    selectedCategoryIdsList: CategoryId[];
    selectedReceiptIdsList: ReceiptId[];
  }) => {
    let receipts: LocalReceipt[] = [];

    for (const receiptId of selectedReceiptIdsList) {
      const receipt = this.getReceiptById(receiptId);

      if (receipt) {
        receipts.push(receipt);
      }
    }

    for (const categoryId of selectedCategoryIdsList) {
      const categoryReceipts = this.categoryReceiptsSelector(categoryId);

      receipts = receipts.concat(categoryReceipts);
    }

    return receipts;
  };

  getReceiptsFromSelectedTitles = ({
    selectedCategoryIdsList,
    selectedReceiptIdsList,
  }: {
    selectedCategoryIdsList: CategoryId[];
    selectedReceiptIdsList: ReceiptId[];
  }) => {
    const titles: string[] = [];
    for (const categoryId of selectedCategoryIdsList) {
      const category = this.getCategoryById(categoryId);
      if (category && category.name) {
        titles.push(category.name);
      }
    }
    for (const receiptId of selectedReceiptIdsList) {
      const receipt = this.getReceiptById(receiptId);

      if (receipt && receipt.name) {
        titles.push(receipt.name);
      }
    }

    return titles;
  };

  render() {
    return (
      <CategoryTreeContext.Provider
        value={{
          getCategoryById: this.getCategoryById,
          getReceiptById: this.getReceiptById,
          createNewCategoryList: this.createNewCategoryList,
          updateCategoryList: this.updateCategoryList,
          deleteCategoryList: this.deleteCategoryList,
          createNewReceiptList: this.createNewReceiptList,
          updateReceiptList: this.updateReceiptList,
          deleteReceiptList: this.deleteReceiptList,
          updateReceiptTempIdOnRealId: this.updateReceiptTempIdOnRealId,
          updateCategoryTempIdOnRealId: this.updateCategoryTempIdOnRealId,
          activeSliceSelector: this.activeSliceSelector,
          getSliceAllReceiptsAndCategoryList:
            this.getSliceAllReceiptsAndCategoryList,
          flatActiveSliceSelector: this.flatActiveSliceSelector,
          folderPathSelector: this.folderPathSelector,
          activeSliceReceiptListSelectorFamily:
            this.activeSliceReceiptListSelectorFamily,
          getCategoryAmount: this.getCategoryAmount,
          categoryAmountSelector: this.categoryAmountSelector,
          activeSliceCategoryListSelectorFamily:
            this.activeSliceCategoryListSelectorFamily,
          isEmptyActiveSliceSelector: this.isEmptyActiveSliceSelector,
          getReceiptsFromSelectedLists: this.getReceiptsFromSelectedLists,
          getReceiptsFromSelectedTitles: this.getReceiptsFromSelectedTitles,
        }}
      >
        {this.props.children}
      </CategoryTreeContext.Provider>
    );
  }
}
