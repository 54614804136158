import React, { memo } from 'react';

export const FolderOpenIcon = memo(
  ({ size = { width: 22, height: 18 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 22 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.4185 7.98718C21.0946 7.46407 20.5216 7.1519 19.8859 7.1519H18.299V4.24093C18.299 3.53829 17.7062 2.96684 16.9777 2.96684H9.20931C9.19792 2.96684 9.19027 2.96358 9.18717 2.96163L7.79687 1.01713C7.55029 0.672404 7.14372 0.466675 6.70915 0.466675H1.65479C0.926107 0.466675 0.333496 1.03829 0.333496 1.74076V15.7053C0.333496 16.4244 0.940592 17.0095 1.68701 17.0095H17.4105C17.6554 17.0095 17.867 16.868 17.9694 16.6628L17.9699 16.663L21.4933 9.5863C21.7489 9.07345 21.7207 8.47563 21.4185 7.98718ZM1.65479 1.71667H6.70915C6.74658 1.71667 6.77197 1.73311 6.77995 1.74434L8.17236 3.69161C8.40739 4.02039 8.79508 4.21684 9.20931 4.21684H16.9777C17.0213 4.21684 17.0435 4.23735 17.049 4.24467V7.1519H5.73763C5.03613 7.1519 4.39811 7.54724 4.11214 8.15922L1.5835 13.5705V1.74451C1.58903 1.73718 1.61117 1.71667 1.65479 1.71667ZM20.3743 9.02917L17.0234 15.7593H1.94043L5.24463 8.68852C5.32471 8.51713 5.52295 8.4019 5.73763 8.4019H19.8859C20.0856 8.4019 20.2612 8.49288 20.3556 8.64522C20.4087 8.731 20.4549 8.86723 20.3743 9.02917Z'
          fill={color}
        />
      </svg>
    );
  },
);
