import React, { memo } from 'react';

export const TrashIcon = memo(
  ({ size = { width: 22, height: 32 }, color = 'white' }) => {
    const { width, height } = size;

    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 22 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.8325 3.56427H14.7307V2.28881C14.7307 1.23379 13.8724 0.375488 12.8174 0.375488H8.99098C7.93596 0.375488 7.07766 1.23379 7.07766 2.28881V3.56427H1.97555C0.920526 3.56427 0.0625 4.42257 0.0625 5.47759V8.66611H1.33796V29.7121C1.33796 30.7671 2.19598 31.6254 3.25101 31.6254H18.5571C19.6121 31.6254 20.4704 30.7671 20.4704 29.7121V8.66611H21.7458V5.47759C21.7458 4.42257 20.8875 3.56427 19.8325 3.56427ZM8.35312 2.28881C8.35312 1.93705 8.63894 1.65095 8.99098 1.65095H12.8174C13.1694 1.65095 13.4552 1.93705 13.4552 2.28881V3.56427H8.35312V2.28881ZM19.1949 29.7121C19.1949 30.0642 18.9091 30.35 18.5571 30.35H3.25101C2.89924 30.35 2.61342 30.0642 2.61342 29.7121V8.66611H19.1949V29.7121ZM20.4704 7.39065H1.33796V5.47759C1.33796 5.12583 1.62379 4.83973 1.97555 4.83973H19.8325C20.1846 4.83973 20.4704 5.12583 20.4704 5.47759V7.39065Z'
          fill={color}
        />
        <path
          d='M14.7308 27.1608C15.0834 27.1608 15.3684 26.8755 15.3684 26.5232V12.4924C15.3684 12.14 15.0834 11.8545 14.7308 11.8545C14.3782 11.8545 14.093 12.14 14.093 12.4924V26.5232C14.093 26.8755 14.3782 27.1608 14.7308 27.1608Z'
          fill={color}
        />
        <path
          d='M10.9042 27.1608C11.2568 27.1608 11.5421 26.8755 11.5421 26.5232V12.4924C11.5421 12.14 11.2568 11.8545 10.9042 11.8545C10.5519 11.8545 10.2667 12.14 10.2667 12.4924V26.5232C10.2667 26.8755 10.5519 27.1608 10.9042 27.1608Z'
          fill={color}
        />
        <path
          d='M7.07787 27.1608C7.43045 27.1608 7.71546 26.8755 7.71546 26.5232V12.4924C7.71546 12.14 7.43045 11.8545 7.07787 11.8545C6.72529 11.8545 6.44 12.14 6.44 12.4924V26.5232C6.44 26.8755 6.72529 27.1608 7.07787 27.1608Z'
          fill={color}
        />
      </svg>
    );
  },
);
