import { useGetUser } from 'gql/api-user';
import { loader } from 'graphql.macro';
import { useCustomMutation } from 'hooks/use-server-logic';
import { useRecoilValue } from 'recoil';
import { currentUserPhonesSelector } from 'store/user-store';

const mutationAddPhone = loader('./mutationAddPhone.graphql');
const mutationDeletePhone = loader('./mutationDeletePhone.graphql');

export const usePhoneLogic = () => {
  const userPhones = useRecoilValue(currentUserPhonesSelector);
  const { fetchUser } = useGetUser();

  const [addPhoneRequest, { loading: processingAddPhone }] =
    useCustomMutation(mutationAddPhone);
  const [deletePhoneRequest, { loading: processingDeletePhone }] =
    useCustomMutation(mutationDeletePhone);

  const addPhone = async (phone: string) => {
    try {
      await addPhoneRequest({
        variables: { phone },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  const deletePhone = async (id: number) => {
    try {
      await deletePhoneRequest({
        variables: { id },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    loading: processingDeletePhone || processingAddPhone,
    userPhones,
    deletePhone,
    addPhone,
  };
};
