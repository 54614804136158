// import { IAPProduct } from '@ionic-native/in-app-purchase-2';
import { useCallback } from 'react';
import {
  atom,
  RecoilValue,
  Snapshot,
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import {
  clearTokens,
  isUserAuthorized,
  setUserToken,
} from 'services/auth-service';
import { Poly } from '../components/CropImages/Poly';
import {
  AppSubscriptionId,
  CategoryId,
  RecognitionConfig,
  RECOGNITION_TYPES,
  ScrollPositionMapType,
} from '../core.types';
import { localStorageRecognitionEffect } from './store.effects';
import { GlassfyProduct } from 'capacitor-plugin-glassfy/dist/esm/definitions';

export const scrollPositionsMapAtom = atom({
  key: 'scrollPositionsMapAtom',
  default: {} as ScrollPositionMapType,
});

export const useSetScrollPosition = () => {
  const setPosition = useSetRecoilState(scrollPositionsMapAtom);

  const setPositionHandler = (id: CategoryId, scrollTop: number) => {
    setPosition((scrollPosition: ScrollPositionMapType) => ({
      ...scrollPosition,
      [id]: scrollTop,
    }));
  };

  return setPositionHandler;
};

window.newPoly = new Poly();

export const queryQueueItemsCountAtom = atom({
  key: 'queryQueueItemsCount',
  default: 0,
});

export const imageQueryQueueItemsCountAtom = atom({
  key: 'imageQueryQueueItemsCount',
  default: 0,
});

export const tempIdMapAtom = atom({
  key: 'tempIdMapAtom',
  default: {} as Record<string, number>,
});

export const useTempIdMapAtomState = () => {
  const [tempIdMap, setTempIdMap] = useRecoilState(tempIdMapAtom);

  const setNewTempId = useCallback(
    ({ tempId, id }) => {
      setTempIdMap({
        ...tempIdMap,
        [tempId]: id,
      });
    },
    [setTempIdMap, tempIdMap],
  );

  return {
    tempIdMap,
    setNewTempId,
    setTempIdMap,
  };
};

export const isAuthorizedAtom = atom<boolean>({
  key: 'isAuthorizedAtom',
  default: isUserAuthorized(),
});

export const isApplicationOnlineAtom = atom<boolean>({
  key: 'isApplicationOnlineAtom',
  default: window.navigator.onLine,
});

export const recognitionConfigAtom = atom<RecognitionConfig>({
  key: 'recognitionConfigAtom',
  default: {
    languages: [],
    recognitionType: RECOGNITION_TYPES.fast,
  },
  effects_UNSTABLE: [localStorageRecognitionEffect],
});

export const useIsAuthorizedAtomState = () => {
  const [isAuthorized, setIsAuthorisedInternal] =
    useRecoilState(isAuthorizedAtom);

  const setIsAuthorised = useCallback(
    async (token: string) => {
      setUserToken(token);
      await setIsAuthorisedInternal(true);
    },
    [setIsAuthorisedInternal],
  );

  const unsetAuthorised = useCallback(() => {
    clearTokens();
    setIsAuthorisedInternal(false);
  }, [setIsAuthorisedInternal]);

  return { isAuthorized, setIsAuthorised, unsetAuthorised };
};

export const getSnapshotValue = <T>(
  snapshot: Snapshot,
  value: RecoilValue<T>,
): T => snapshot.getLoadable(value).contents;

export const subscriptionPlanListAtom = atom<
  Record<AppSubscriptionId, GlassfyProduct>
>({
  key: 'subscriptionPlanListAtom',
  default: {} as any,
});
