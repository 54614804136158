import { ALL_PROFILES } from 'app.constants';
import {
  CreatorType,
  Maybe,
  SortField,
  SortFilter,
  SortType,
} from 'core.types';
import { atom } from 'recoil';
import { dateFilterEffect } from './filters-store.effects';

export const filterMadeByAtom = atom<CreatorType>({
  key: 'filterMadeByAtom',
  default: ALL_PROFILES,
});

export const searchFilterAtom = atom<string>({
  key: 'searchFilterAtom',
  default: '',
});

export const sortFilterAtom = atom<SortFilter>({
  key: 'sortFilterAtom',
  default: {
    sortField: SortField.receiptDate,
    sortType: SortType.desc,
  },
});

export const dateFromFilterAtom = atom<Maybe<Date>>({
  key: 'dateFromFilterAtom',
  default: null,
  effects_UNSTABLE: [dateFilterEffect('dateFrom')],
});

export const dateToFilterAtom = atom<Maybe<Date>>({
  key: 'dateToFilterAtom',
  default: null,
  effects_UNSTABLE: [dateFilterEffect('dateTo')],
});
