import { loader } from 'graphql.macro';
import { PAGE_ROUTES } from 'page-routes';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { setFormServerErrors } from 'scenes/common-auth-logic';
import { useIsAuthorizedAtomState } from 'store/root-store';
import { useCustomMutation } from '../../hooks/use-server-logic';
import { getUserToken, setAccountIdToken } from '../../services/auth-service';

const mutationResetPass = loader('./mutationResetPass.graphql');

export const useResetPasswordLogic = () => {
  const [resetPasswordMutation, { loading }] =
    useCustomMutation(mutationResetPass);
  const { setIsAuthorised } = useIsAuthorizedAtomState();
  const history = useHistory();
  const { pathname } = useLocation();

  const onSubmit = useCallback(
    async ({ password }: { password: string }, _: any, { setError }: any) => {
      try {
        const pathList = pathname.split('/');
        await setIsAuthorised(pathList[pathList.length - 1]);
        const {
          data: {
            resetPassword: {
              token,
              account: { id },
            },
          },
        } = (await resetPasswordMutation({
          variables: {
            newPassword: password,
            token: getUserToken(),
          },
        })) as any;

        if (token) {
          // !Note- order is important here - todo refactor
          setAccountIdToken(id);
          await setIsAuthorised(token);
          history.push(PAGE_ROUTES.category);
        }
      } catch (errorResponse: any) {
        setFormServerErrors(errorResponse, setError);
      }
    },
    [history, pathname, resetPasswordMutation, setIsAuthorised],
  );

  const onLogoClick = useCallback(() => {
    history.push(PAGE_ROUTES.auth);
  }, [history]);

  return {
    onSubmit,
    loading,
    onLogoClick,
  };
};
