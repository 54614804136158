import c from 'classnames';
import { memo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { editModeEnabledATOM } from 'store/category-store';
import { useDetectMobileResolution } from '../../../hooks';
import {
  imageQueryQueueItemsCountAtom,
  queryQueueItemsCountAtom,
} from '../../../store';
import './app-sync-counter.scss';
import { ProgressBar } from './progress-bar';

export const CategorySyncCounter = memo(() => {
  const queryQueueItemsCount = useRecoilValue(queryQueueItemsCountAtom);
  const imageQueryQueueItemsCount = useRecoilValue(
    imageQueryQueueItemsCountAtom,
  );
  const isMobile = useDetectMobileResolution();
  const isSmallMobile = useDetectMobileResolution(340);
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);

  const renderImageQueryQueueProcess = useCallback(() => {
    if (!imageQueryQueueItemsCount) {
      return null;
    }

    return <ProgressBar {...{ value: imageQueryQueueItemsCount, isMobile }} />;
  }, [imageQueryQueueItemsCount, isMobile]);

  const renderQueryQueueProcess = useCallback(() => {
    if (!queryQueueItemsCount) {
      return null;
    }

    return (
      <ProgressBar
        {...{ value: queryQueueItemsCount, isMobile, isUpload: true }}
      />
    );
  }, [isMobile, queryQueueItemsCount]);

  // if (process.env.NODE_ENV !== 'development') {
  //   return null;
  // }

  return (
    <div
      className={c('app-sync-counter', {
        'app-sync-counter--hidden': isSmallMobile && editModeEnabled,
      })}
    >
      {renderImageQueryQueueProcess()}
      {renderQueryQueueProcess()}
    </div>
  );
});
