import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { unlockBackSwipe } from 'services/HelperService';
import {
  activeSliceIdAtom,
  categoryListViewAtom,
  categoryToEditAtom,
  searchFilterAtom,
} from 'store';
import {
  createFolderModalOpenedATOM,
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
} from 'store/category-store';
import {
  useSelectedIDsAtomSetters,
  useSetBooleanAtomState,
} from 'store/category-store/category-store.callbacks';
import { currentUserCurrencySelector } from 'store/user-store';
import { CategoryId, LocalCategory } from '../core.types';

export const useFolderItemLogic = (item: LocalCategory) => {
  const setSearchFilter = useSetRecoilState(searchFilterAtom);
  const { name, id } = item;
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const [openCreateFolderModal] = useSetBooleanAtomState(
    createFolderModalOpenedATOM,
  );
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const [addSelectedCategoryId, removeSelectedCategoryId] =
    useSelectedIDsAtomSetters(selectedCategoryIDsAtom);
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const setActiveSliceId = useSetRecoilState(activeSliceIdAtom);
  const currency = useRecoilValue(currentUserCurrencySelector);
  const setCategoryToEdit = useSetRecoilState(categoryToEditAtom);
  const history = useHistory();
  const isItemSelected = !!selectedCategoryIdsList.find(
    (selectedId: CategoryId) => selectedId === item.id,
  );

  const onItemClick = useCallback(async () => {
    if (!editModeEnabled) {
      setSearchFilter('');
      await unlockBackSwipe();
      history.push(`/category/${id}`);
      await setActiveSliceId(id);
      return;
    }

    return isItemSelected
      ? removeSelectedCategoryId(item.id)
      : addSelectedCategoryId(item.id);
  }, [
    addSelectedCategoryId,
    editModeEnabled,
    history,
    id,
    isItemSelected,
    item.id,
    removeSelectedCategoryId,
    setActiveSliceId,
    setSearchFilter,
  ]);

  const onItemDescriptionCLick = useCallback(async () => {
    await setCategoryToEdit(item);
    openCreateFolderModal();
  }, [item, openCreateFolderModal, setCategoryToEdit]);

  return {
    onItemDescriptionCLick,
    isItemActive: isItemSelected,
    onItemClick,
    itemName: name,
    categoryListView,
    currency,
  };
};
