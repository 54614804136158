import cx from 'classnames';
import { AppHasAccess } from 'components/app-has-access';
import { AppType } from 'core.types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getCurrency } from '../../currencySymbols';
import group from '../../images/category/group-icon.svg';
import noImage from '../../images/no-avatar-icon.svg';
import { HelperService } from '../../services';
import './ProfileInfo.scss';

export const ProfileInfo = ({
  groupIcon,
  userIcon,
  ownerFullName,
  userCurrency,
  containerStyles,
}: any) => {
  const userIconCheck = !userIcon || userIcon.includes('null');

  const finalIcon = useMemo(() => {
    if (userIcon) {
      return userIconCheck ? HelperService.resolvePathSvg(noImage) : userIcon;
    }
    return HelperService.resolvePathSvg(groupIcon ? group : noImage);
  }, [groupIcon, userIcon, userIconCheck]);

  return (
    <div
      style={containerStyles ? containerStyles : {}}
      className='profile-info'
    >
      <div
        className={cx('profile-info__user-icon', {
          'profile-info__user-icon--no-image': userIconCheck,
          'profile-info__user-icon--group-icon': groupIcon,
        })}
      >
        <img src={finalIcon} width='65' height='65' alt='' />
      </div>
      <p className='profile-info__user-info'>
        <span className='profile-info__user-name'>{ownerFullName}</span>
        <AppHasAccess appType={AppType.ireceipt}>
          <span className='profile-info__user-currency'>
            <span>
              <FormattedMessage id='menu.profile.info.currency' />
            </span>
            <span>{` ${getCurrency(userCurrency)} (${userCurrency})`}</span>
          </span>
        </AppHasAccess>
      </p>
    </div>
  );
};

ProfileInfo.defaultProps = {
  userFirstName: 'Firstname',
  userLastName: '',
  userCurrency: 'USD',
  groupIcon: false,
};
