import { AppButton } from 'components/app-button';
import { AppConfirmModal } from 'components/app-confirm-modal';
import { AppHasAccess } from 'components/app-has-access';
import { AppType } from 'core.types';
import { useRemoveUser } from 'gql/api-user';
import { useBooleanState } from 'hooks';
import { useLogout } from 'hooks/use-logout';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AppDatePicker, AppSelect, DefaultInput } from '../../../../components';
import { AppLoader } from '../../../../components/app-loader';
import { useGetCurrencyOptions } from '../../../../hooks/use-get-currency-options';
import { useProfileEdit } from './gql';
import { ProfileUploadPhoto } from './profile-upload-photo';
import './Profile.scss';

export const Profile = ({ mobile }: any) => {
  const { push } = useHistory();
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    currency: null,
    gender: '',
    birthday: null,
    email: '',
    img: null,
    fileForSend: null,
    dirty: false,
  });

  const { loading, data, changeProfile } = useProfileEdit();
  const intl = useIntl();
  const currencyOptions = useGetCurrencyOptions();
  const [removeConfirmOpened, openRemoveConfirm, closeRemoveConfirm] =
    useBooleanState(false);
  const { removeUser } = useRemoveUser();
  const logOut = useLogout();
  const [
    removeFirstValidationCompleted,
    completeFirstValidation,
    revertDeletion,
  ] = useBooleanState(false);

  useEffect(() => {
    if (data?.user) {
      const {
        firstName,
        lastName,
        currency,
        gender,
        birthday,
        facebookPictureUrl,
        googlePictureUrl,
        customPictureUrl,
        email,
      } = data.user;

      const img = customPictureUrl
        ? `${process.env.REACT_APP_WEBSITE_URL}/${customPictureUrl}`
        : facebookPictureUrl;

      setState({
        ...state,
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        currency: currencyOptions.find(({ value }) => value === currency)
          ?.value,
        gender: gender ? gender : '',
        birthday: birthday ? new Date(birthday * 1000) : birthday,
        email: email ? email : '',
        img: img || googlePictureUrl || null,
      } as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetNewProfile = () => {
    // @ts-ignore
    changeProfile({
      // @ts-ignore
      currency: state.currency,
      firstName: state.firstName,
      lastName: state.lastName,
      birthday: state.birthday
        ? parseInt((new Date(state.birthday).getTime() / 1000).toFixed(0))
        : state.birthday,
      gender: state.gender,
      customPicture: state.fileForSend,
    }).then(() => {
      mobile && push('/settings');
    });
  };

  const onRemoveConfirmClick = useCallback(async () => {
    await removeUser();
    logOut();
  }, [logOut, removeUser]);

  const onFirstRemoveValidationClick = useCallback(async () => {
    completeFirstValidation();
    closeRemoveConfirm();
  }, [closeRemoveConfirm, completeFirstValidation]);

  const changeState = useCallback(
    (type, value) => {
      setState({
        ...state,
        [type]: value,
        dirty: true,
      });
    },
    [state],
  );

  const changeDateHandler = useCallback(
    (date) => {
      changeState('birthday', date.value);
    },
    [changeState],
  );

  return (
    <>
      <div className='edit-profile-wrapper'>
        <ProfileUploadPhoto {...{ state, setState }} />
        <div className='setting-body wrap-for__inp'>
          <div className={'inp__field inp__field--firstname'}>
            {/* @ts-ignore */}
            <DefaultInput
              value={state.firstName}
              placeholder={intl.messages['menu.profile.first'] as string}
              onChange={({ currentTarget }: any) =>
                changeState('firstName', currentTarget.value)
              }
            />
          </div>

          <div className={'inp__field inp__field--lastname'}>
            {/* @ts-ignore */}
            <DefaultInput
              value={state.lastName}
              placeholder={intl.messages['menu.profile.last'] as string}
              onChange={({ currentTarget }: any) =>
                changeState('lastName', currentTarget.value)
              }
            />
          </div>

          <div className={'inp__field inp__field--date'}>
            <AppDatePicker
              // @ts-ignore
              value={state.birthday}
              onChange={changeDateHandler}
              placeholder={intl.messages['menu.profile.date'] as string}
              iconName='calendar'
              maxDate={new Date()}
            />
          </div>

          <AppHasAccess appType={AppType.ireceipt}>
            <div className={'inp__field inp__field--currency'}>
              <AppSelect
                // @ts-ignore
                value={state.currency}
                options={currencyOptions}
                // @ts-ignore
                placeholder={intl.messages['menu.profile.currency']}
                onChange={(event) => {
                  changeState('currency', event.target.value);
                }}
              />
            </div>
          </AppHasAccess>

          <AppButton
            className='settings__save-button'
            onPress={onSetNewProfile}
            disabled={!state.dirty || loading || !state.currency}
          >
            <FormattedMessage id='save' />
          </AppButton>
        </div>

        <div className='always-change-data-text'>
          <FormattedMessage id='menu.profile.change.data' />
        </div>
        {/* !TODO implement on future account deletion */}
        <div className='delete-account' onClick={openRemoveConfirm}>
          <FormattedMessage id='menu.profile.delete.account' />
        </div>
      </div>
      <AppConfirmModal
        open={removeConfirmOpened}
        onConfirmClick={onFirstRemoveValidationClick}
        // TODO -change title title for 1 modal
        title={intl.messages['menu.profile.remove.confirm1'] as string}
        onClose={closeRemoveConfirm}
      />
      <AppConfirmModal
        open={removeFirstValidationCompleted}
        onConfirmClick={onRemoveConfirmClick}
        // TODO -change title title for 1 modal
        title={intl.messages['menu.profile.remove.confirm2'] as string}
        onClose={revertDeletion}
      />
      {loading && <AppLoader />}
    </>
  );
};
