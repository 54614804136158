import { memo, ReactNode, useEffect } from 'react';
import { useSyncCategoryTree } from 'category-tree-hooks/use-sync-category-tree';
import { useRecoilValue } from 'recoil';
import { isAuthorizedAtom, userIdSelector } from 'store';
import { useImageRequestSync } from '../category-tree-hooks/use-image-request-sync';
import { useRequestSync } from '../category-tree-hooks/use-request-sync';
import { useInitPushNotifications } from 'hooks/use-init-push-notifications';
import { useRegisterPurchaseList } from 'hooks/use-register-purchase-list';

export const RootWrapper = memo(({ children }: { children: ReactNode }) => {
  const isAuthorized = useRecoilValue(isAuthorizedAtom);
  const startSync = useSyncCategoryTree();
  const userId = useRecoilValue(userIdSelector);

  useEffect(() => {
    if (isAuthorized) {
      startSync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  useRequestSync();
  useImageRequestSync();
  useInitPushNotifications();
  useRegisterPurchaseList(userId);

  return <>{children}</>;
});
