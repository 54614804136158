import c from 'classnames';
import { AppButton } from 'components/app-button';
import { Portal } from 'components/app-portal';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import './app-confirm-modal.scss';

type AppConfirmModalProps = {
  open: boolean;
  onClose(event: any, reason: string): void;
  title: string;
  onConfirmClick(): void;
  confirmLabel?: any;
  cancelLabel?: any;
};

export const AppConfirmModal = memo(
  ({
    open,
    onClose,
    title,
    confirmLabel = <FormattedMessage id='confirm' />,
    cancelLabel = <FormattedMessage id='cancel' />,
    onConfirmClick,
  }: AppConfirmModalProps) => {
    if (!open) {
      return null;
    }

    return (
      <Portal>
        <div className='app-confirm-modal-backdrop'>
          <div className='app-confirm-modal'>
            <div className='app-confirm-modal__header'>{title}</div>
            <div className='app-confirm-modal__body'>
              <AppButton
                className={c(
                  'app-confirm-modal__cancel',
                  'app-confirm-modal__button',
                )}
                onPress={onClose}
              >
                {cancelLabel}
              </AppButton>
              <AppButton
                className={c(
                  'app-confirm-modal__confirm',
                  'app-confirm-modal__button',
                )}
                onPress={onConfirmClick}
              >
                {confirmLabel}
              </AppButton>
            </div>
          </div>
        </div>
      </Portal>
    );
  },
);
