import c from 'classnames';
import { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { sortFilterAtom } from 'store';
import { SortField, SortType } from '../../core.types';

type CategorySortHeaderCellProps = {
  label: string;
  sortField: SortField;
};

export const CategorySortHeaderCell = memo(
  ({ label, sortField }: CategorySortHeaderCellProps) => {
    const [sortFilter, setSortFilter] = useRecoilState(sortFilterAtom);
    const onClick = useCallback(() => {
      const sortType =
        sortField === sortFilter.sortField &&
        sortFilter.sortType === SortType.asc
          ? SortType.desc
          : SortType.asc;

      setSortFilter({ sortType, sortField });
    }, [setSortFilter, sortField, sortFilter.sortField, sortFilter.sortType]);

    const isSortCellActive = sortFilter.sortField === sortField;

    return (
      <div
        className={c('category-list__sort-header-cell', {
          'category-list__sort-header-cell--active': isSortCellActive,
        })}
        onClick={onClick}
      >
        {label}
        <div className='category-list__sort-header-arrows'>
          <div
            className={c('category-list__sort-header-top-arrow', {
              'category-list__sort-header-top-arrow--active':
                isSortCellActive && sortFilter.sortType === SortType.asc,
            })}
          />
          <div
            className={c('category-list__sort-header-bottom-arrow', {
              'category-list__sort-header-bottom-arrow--active':
                isSortCellActive && sortFilter.sortType === SortType.desc,
            })}
          />
        </div>
      </div>
    );
  },
);
