import { CategoryTreeContext } from 'category-tree-provider';
import c from 'classnames';
import { useGetReceiptName } from 'hooks/use-get-receipt-name';
import { memo, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { categoryListViewAtom } from 'store';
import { AppIcon } from '../../components';
import { CategoryView, LocalReceipt, Maybe, ReceiptId } from '../../core.types';
import { HelperService } from '../../services';

type DndPreviewItemProps = {
  id: ReceiptId;
};

export const DndPreviewReceiptItem = memo(({ id }: DndPreviewItemProps) => {
  const { getReceiptById } = useContext(CategoryTreeContext);
  const selectedReceipt: Maybe<LocalReceipt> = getReceiptById(id);
  const receiptName = useGetReceiptName({
    name: selectedReceipt?.name,
    type: selectedReceipt?.type,
  });
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const isTableView = categoryListView === CategoryView.list;

  const tableViewIconSize = useMemo(
    () => ({
      width: 29,
      height: 35,
    }),
    [],
  );

  if (!selectedReceipt) {
    return null;
  }

  const image = isTableView ? (
    <AppIcon name='receipt' size={tableViewIconSize} />
  ) : (
    <img
      src={HelperService.resolveFilePath(selectedReceipt.thumbPath)}
      className='dnd-preview-item__image'
      alt=''
    />
  );

  return (
    <div
      className={c('dnd-preview-item', {
        'dnd-preview-item--table': isTableView,
      })}
    >
      {image}
      <div className='dnd-preview-item__name'>{receiptName}</div>
    </div>
  );
});
