import debounce from 'lodash/debounce';
import {
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { searchFilterAtom } from 'store';
import './category-search-input.scss';
import { AppIcon } from 'components/app-icon';
import { Maybe } from '../../../core.types';
import { useOnClickOutside } from '../../../hooks';
import { AppButton } from '../../../components/app-button';
import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';

export const SearchInput = memo(() => {
  const inputRef = useRef<Maybe<HTMLInputElement>>(null);
  const [searchFilter, setSearchFilter] = useRecoilState(searchFilterAtom);
  const [internalSearch, setInternalSearch] = useState(searchFilter);
  const setSearchDebounce = debounce((value) => setSearchFilter(value), 300);
  const onSearchChange = useCallback(
    ({ currentTarget: { value } }) => {
      setSearchDebounce(value);
      setInternalSearch(value);
    },
    [setSearchDebounce],
  );
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  useEffect(() => {
    if (searchFilter === '') {
      setInternalSearch(searchFilter);
    }
  }, [searchFilter]);

  const handleClearClick = () =>
    onSearchChange({
      currentTarget: { value: '' },
    } as SyntheticEvent<HTMLInputElement>);

  useOnClickOutside(inputRef, () => inputRef?.current?.blur());

  return (
    <div className='app-search-block'>
      <AppIcon
        name='search-icon'
        className='app-search-block__icon'
        color={colorEmphasized}
      />
      <div className='app-search-block__input-container'>
        <input
          ref={inputRef}
          placeholder='Search'
          onChange={onSearchChange}
          value={internalSearch}
        />
        {searchFilter && (
          <AppButton
            onPress={handleClearClick}
            className='app-search-block__button'
          >
            <AppIcon
              className='app-search-block__clear-icon'
              name='close'
              size={{ width: '12px', height: '12px' }}
              color='black'
            />
          </AppButton>
        )}
      </div>
    </div>
  );
});
