import { CategoryTreeContext } from 'category-tree-provider';
import { useCategoryDndLogic } from 'category/use-category-dnd-logic';
import c from 'classnames';
import { memo, useContext } from 'react';
import Tappable from 'react-tappable';
import { useRecoilValue } from 'recoil';
import {
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
} from 'store/category-store';
import { AppIcon } from '../../../components';
import { AppPseudoRadio } from '../../../components/app-pseudo-radio';
import type { LocalCategory } from '../../../core.types';
import '../../category.scss';
import { useFolderItemLogic } from '../../use-folder-item-logic';
import { CategoryGridTileDescription } from './category-grid-tile-description';

const FOLDER_ICON_SIZE = { width: '100%', height: '100%' };

export const CategoryFolderTile = memo(
  (localCategory: LocalCategory & { amount: number }) => {
    const editModeEnabled = useRecoilValue(editModeEnabledATOM);
    const {
      onItemClick,
      currency,
      isItemActive,
      onItemDescriptionCLick,
      itemName,
    } = useFolderItemLogic(localCategory);
    const { isEmptyActiveSliceSelector } = useContext(CategoryTreeContext);
    const { createdBy } = localCategory;
    const isEmpty = isEmptyActiveSliceSelector(localCategory);

    const { isOver, isDraggable, drop, drag } = useCategoryDndLogic(
      localCategory.id,
    );
    const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);

    return (
      <div className='drop-container' ref={drag}>
        <div
          ref={drop}
          className={c('drop', {
            'is-over':
              !isDraggable &&
              isOver &&
              !selectedCategoryIdsList.includes(localCategory.id),
          })}
        >
          <div className='category-grid__tile'>
            {editModeEnabled && (
              <AppPseudoRadio
                {...{
                  isItemActive,
                  className: 'category-grid__tile-indicator',
                  onClick: onItemClick,
                }}
              />
            )}
            <Tappable onTap={onItemClick}>
              <AppIcon
                name={c({
                  'green-folder-empty': isEmpty,
                  'green-folder-non-empty': !isEmpty,
                })}
                id={localCategory.id}
                size={FOLDER_ICON_SIZE}
                className='category-grid__tile-icon-wrapper'
              />
            </Tappable>
            <CategoryGridTileDescription
              {...{
                name: itemName,
                // ! Note folder name always not red
                isEmpty: false,
                amount: localCategory.amount,
                currency,
                onClick: onItemDescriptionCLick,
                createdBy,
                date: null,
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);
