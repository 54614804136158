import {
  useSyncLastUpdatedValuesFromServer,
  useSyncServerCategoryTree,
} from 'category-tree-hooks/category-service';
import { useGetCategory } from 'gql/api-category';
import { useRecoilCallback } from 'recoil';
import {
  lastCategoryUpdateAtom,
  lastReceiptUpdateAtom,
} from 'store/category-store';
import { getSnapshotValue } from 'store/root-store';
import { delay } from '../services/HelperService';
import {
  checkIfTableIsEmpty,
  fileDownloadQueueTable,
  requestTable,
} from '../services/indexed-db-service';

export const useRefreshTree = () => {
  const { fetchCategoryTree } = useGetCategory();
  const syncServerCategoryTree = useSyncServerCategoryTree();
  const syncLastUpdatedValues = useSyncLastUpdatedValuesFromServer();
  return useRecoilCallback(({ snapshot }) => async () => {
    const lastReceiptUpdatedAt = getSnapshotValue(
      snapshot,
      lastReceiptUpdateAtom,
    );
    const lastCategoryUpdatedAt = getSnapshotValue(
      snapshot,
      lastCategoryUpdateAtom,
    );

    let retryCount = 10;
    while (--retryCount) {
      const [isEmptyQueryQueue, isEmptyImageQueryQueue] = await Promise.all([
        checkIfTableIsEmpty(requestTable),
        checkIfTableIsEmpty(fileDownloadQueueTable),
      ]);
      if (isEmptyQueryQueue && isEmptyImageQueryQueue) {
        break;
      }
      await delay(1000);
    }

    if (retryCount === 0) {
      return;
    }

    const { receipts, categories, accountId } = await fetchCategoryTree({
      lastReceiptUpdatedAt,
      lastCategoryUpdatedAt,
    });
    syncLastUpdatedValues(receipts, categories);

    syncServerCategoryTree({
      receipts,
      categories,
      accountId,
    });
  });
};
