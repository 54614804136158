import { Portal } from 'components/app-portal';
import { memo, ReactElement } from 'react';
import './app-modal-layout.scss';

type AppModalLayoutProps = {
  open: boolean;
  children: ReactElement;
};

export const AppModalLayout = memo(
  ({ open, children }: AppModalLayoutProps) => {
    if (!open) {
      return null;
    }

    return (
      <Portal>
        <div className='app-modal-layout'>{children}</div>
      </Portal>
    );
  },
);
