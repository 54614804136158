import React, { memo } from 'react';

export const LockIcon = memo(
  ({ size = { width: 20, height: 20 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M16.3952 7.67456H3.60447C2.9633 7.67456 2.4417 8.1962 2.4417 8.83733V18.8373C2.4417 19.4785 2.96334 20.0001 3.60447 20.0001H16.3952C17.0363 20.0001 17.5579 19.4785 17.5579 18.8373V8.83733C17.5579 8.1962 17.0363 7.67456 16.3952 7.67456ZM16.1626 18.6048H3.83705V9.06991H16.1626V18.6048H16.1626Z'
            fill={color}
          />
          <path
            d='M9.9999 0C6.85826 0 4.30225 2.57699 4.30225 5.74418V8.13953H5.6976V5.74418C5.6976 3.34629 7.6276 1.39535 9.99994 1.39535C12.3723 1.39535 14.3023 3.34629 14.3023 5.74418V8.13953H15.6976V5.74418C15.6976 2.57699 13.1418 0 9.9999 0Z'
            fill={color}
          />
          <path
            d='M9.88368 13.0232C9.49832 13.0232 9.18602 13.3355 9.18602 13.7209V15.3487C9.18602 15.7341 9.49836 16.0464 9.88368 16.0464C10.269 16.0464 10.5813 15.7341 10.5813 15.3487V13.7209C10.5814 13.3355 10.269 13.0232 9.88368 13.0232Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
