import { ReceiptType } from 'core.types';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { receiptTypeAtom } from '../../store';
import './category-tabs.scss';
import { CategoryTab } from './category-tab';
import c from 'classnames';

export type AppTabItem = {
  label: string;
  onClick(): void;
};

export const CategoryTabs = memo(() => {
  const intl = useIntl();
  const receiptType = useRecoilValue(receiptTypeAtom);

  return (
    <div className='category-tabs'>
      <CategoryTab
        label={intl.messages['receipts'] as string}
        receiptType={ReceiptType.receipt}
      />
      <CategoryTab
        label={intl.messages['invoices'] as string}
        receiptType={ReceiptType.invoice}
      />
      <div
        className={c('category-tab-indicator', {
          'category-tab-indicator__receipt':
            receiptType === ReceiptType.receipt,
          'category-tab-indicator__invoice':
            receiptType === ReceiptType.invoice,
        })}
      />
    </div>
  );
});
