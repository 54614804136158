import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';
import 'react-image-crop/dist/ReactCrop.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import './app.scss';
import { client } from './ir-apollo-client';
import { IntlWrapper } from './components';
import { Root } from './root';
import './scss/_mixins.scss';
import './scss/_reset.scss';
import { HelperService, lockScreenOrientation } from './services';
import { register } from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN } from 'app.constants';
import { setupIonicReact } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { OnlineDispatcher } from 'components/online-dispatcher/OnlineDispatcher';
import * as process from 'process';

setupIonicReact();
// @ts-ignore
window.onlineDispatcher = new OnlineDispatcher();

if (
  process.env.NODE_ENV !== 'development' &&
  HelperService.parceBoolEnv(process.env.REACT_APP_SENTRY_ENABLED)
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (Capacitor.getPlatform() !== 'web') {
  Keyboard.setScroll({ isDisabled: false });
}

const startApp = () => {
  lockScreenOrientation();

  (document as any).querySelector('html').style.height = '100%';

  ReactDOM.render(
    // @ts-ignore
    <RecoilRoot>
      <ApolloProvider client={client}>
        <IntlWrapper>
          <Router>
            <Root />
          </Router>
        </IntlWrapper>
        <ToastContainer />
      </ApolloProvider>
    </RecoilRoot>,
    document.getElementById('root'),
  );
};
register();
startApp();
