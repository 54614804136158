import React, { memo } from 'react';

export const ArrowRightIcon = memo(
  ({ size = { width: 12, height: 22 }, color = 'white', opacity = '1' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 12 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity={opacity}>
          <path
            d='M9.95662 10.9949L0.39638 20.5551C0.0658732 20.8857 0.0658732 21.4216 0.396379 21.7522C0.726985 22.0826 1.26285 22.0826 1.59341 21.7522L11.7522 11.5934C12.0826 11.2629 12.0826 10.7269 11.7522 10.3964L1.59341 0.237628C1.2571 -0.0871744 0.721184 -0.0778494 0.396381 0.258462C0.079565 0.586537 0.079565 1.10663 0.396381 1.43466L9.95662 10.9949Z'
            fill={color}
          />
        </g>
      </svg>
    );
  },
);
