import { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { hasError } from 'services/form-service';
import { AppFormFieldError } from '../app-form-field-error';

type AppFormPhoneFieldProps = {
  name: string;
};

export const AppFormPhoneField = memo(({ name }: AppFormPhoneFieldProps) => {
  const context = useFormContext();

  const {
    register,
    control,
    setValue,
    formState: { isDirty, errors },
  } = context;
  const { showError, error } = hasError({ errors: errors, name, isDirty });
  register(name);
  const value: string = useWatch({ name, control });

  const onChangePhoneField = useCallback(
    (phone: string) => {
      setValue(name, phone);
    },
    [name, setValue],
  );

  return (
    <div className='app-form__control'>
      <PhoneInput country='no' value={value} onChange={onChangePhoneField} />
      <AppFormFieldError {...{ name, showError, error }} />
    </div>
  );
});
