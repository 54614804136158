import { AppForm, AppFormField } from 'components/app-form';
import { AppFormSubmitButton } from 'components/app-form/app-form-submit-button';
import { AppLoader } from 'components/app-loader';
import { AppLogo } from 'components/app-logo/app-logo';
import { useGetIOSKeyboardHeight } from 'hooks/use-get-ios-keyboard-height';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FORM_VALIDATION_RESOLVER } from 'scenes/common-auth-logic';
import { useResetPasswordLogic } from './use-reset-password-logic';

export const ResetPassword = memo(() => {
  const intl = useIntl();
  const { onSubmit, loading, onLogoClick } = useResetPasswordLogic();
  const keyboardHeight = useGetIOSKeyboardHeight();

  return (
    <div
      className='auth-page__wrapper'
      style={{
        paddingBottom: keyboardHeight,
      }}
    >
      <AppLogo onClick={onLogoClick} className='auth-page__image' />
      <h1 className='auth-page__title'>
        <FormattedMessage id='reset.h1' />
      </h1>

      <AppForm
        onSubmit={onSubmit}
        className='auth__form'
        formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
      >
        <AppFormField
          type={'password' as const}
          name='password'
          icon={{ name: 'password' }}
          placeholder={intl.messages['reset.pass'] as string}
        />
        <AppFormSubmitButton>
          <FormattedMessage id='btn.submit' />
        </AppFormSubmitButton>
      </AppForm>
      {loading && <AppLoader />}
    </div>
  );
});
