import { AllHtmlEntities } from 'html-entities';
import { ClientService } from '../services/ClientService';
import { useMemo } from 'react';
import 'react-intl';
import { uppercaseFirstLettersInEachWordOfAString } from '../services';
import symbols from '../currencies.json';

const entities = new AllHtmlEntities();

export const useGetCurrencyOptions = () => {
  const locale = ClientService.getLocale();

  return useMemo(() => {
    // @ts-ignore
    const currencyNames = new Intl.DisplayNames([locale], {
      type: 'currency',
    });

    return symbols.map((el) => {
      const currencySymbol = entities.decode(el.symbol);
      const currencyName = uppercaseFirstLettersInEachWordOfAString(
        // @ts-ignore
        currencyNames.of(el.abbreviation),
      );

      return {
        label: `${currencySymbol} - ${currencyName} - ${el.abbreviation}`,
        value: el.abbreviation,
      };
    });
  }, [locale]);
};
