import React, { memo } from 'react';

export const CreateFolderIcon = memo(
  ({ size = { width: 23, height: 23 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 23 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M22.2105 3.93558C21.7015 3.4267 21.0248 3.14629 20.3052 3.14629C20.305 3.14629 20.3049 3.14629 20.3047 3.14629L11.6032 3.14611C11.3589 3.14857 11.1357 3.02959 11.0045 2.83289L9.62486 0.763672H2.69531C1.20903 0.763672 0 1.9727 0 3.45898V17.9272C0 19.4133 1.20903 20.6225 2.69531 20.6225H11.8594V18.8256H2.69531C2.19994 18.8256 1.79688 18.4225 1.79688 17.9272V8.22263C1.79688 7.98258 1.8904 7.7571 2.06009 7.58723C2.22977 7.41772 2.45526 7.3242 2.69549 7.3242H2.69566L9.59187 7.32384L10.9973 5.27902L11.0043 5.26867C11.132 5.07687 11.373 4.94299 11.5898 4.94299V4.94281C11.5928 4.94281 11.596 4.94299 11.5991 4.94299H11.6053L20.3049 4.94316C20.5447 4.94316 20.7702 5.03651 20.9399 5.2062C21.1096 5.37589 21.2031 5.60155 21.2031 5.84142V14.8258H23V5.84142C23 5.12144 22.7196 4.44463 22.2105 3.93558ZM9.51308 4.266L8.64641 5.52697L2.69584 5.52732C2.69566 5.52732 2.69549 5.52732 2.69531 5.52732C2.38437 5.52732 2.08167 5.57979 1.79688 5.68034V3.45898C1.79688 2.96362 2.19994 2.56055 2.69531 2.56055H8.66325L9.5094 3.82977C9.56099 3.90715 9.61714 3.9805 9.67628 4.05087C9.61855 4.11948 9.56345 4.1909 9.51308 4.266ZM19.2266 16.6227H23V18.4195H19.2266V22.193H17.4297V18.4195H13.6562V16.6227H17.4297V12.8492H19.2266V16.6227Z'
            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width={width} height={height} fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
