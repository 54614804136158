import React, { memo } from 'react';

export const ImageGalleryIcon = memo(
  ({ size = { width: 35, height: 30 }, color = 'white' }) => {
    const { width, height } = size;
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 35 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.3339 10.5884C15.4057 10.5884 13.8424 12.1516 13.8424 14.0799C13.8424 16.0082 15.4056 17.5714 17.3339 17.5714C19.2622 17.5714 20.8255 16.0083 20.8255 14.0799C20.8255 12.1516 19.2623 10.5884 17.3339 10.5884ZM17.3339 15.9087C16.3239 15.9087 15.505 15.0899 15.505 14.0798C15.505 13.0697 16.3239 12.2509 17.3339 12.2509C18.344 12.2509 19.1628 13.0697 19.1628 14.0798C19.1628 15.0899 18.344 15.9087 17.3339 15.9087Z'
          fill={color}
        />
        <path
          d='M31.2584 2.60804L8.56365 0.0310497C7.68286 -0.0941761 6.79044 0.162958 6.11133 0.737692C5.43229 1.26421 4.99703 2.04469 4.90593 2.89907L4.49032 6.30746H3.2017C1.3728 6.30746 0.00112329 7.92847 0.00112329 9.75737V26.7576C-0.0449413 28.5016 1.33151 29.9529 3.0756 29.9989C3.11761 30 3.15969 30.0003 3.2017 29.9997H26.0211C27.85 29.9997 29.5126 28.5865 29.5126 26.7576V26.0926C30.0797 25.983 30.6176 25.7565 31.0921 25.4275C31.7656 24.8605 32.1968 24.0575 32.2975 23.183L34.2096 6.30746C34.4045 4.47434 33.089 2.82532 31.2584 2.60804ZM27.85 26.7576C27.85 27.6721 26.9356 28.3371 26.0211 28.3371H3.2017C2.37564 28.3614 1.68634 27.7114 1.66208 26.8853C1.6608 26.8427 1.66136 26.8002 1.66375 26.7576V23.6818L8.10643 18.9433C8.88038 18.3491 9.9705 18.4019 10.6835 19.068L15.2141 23.0583C15.9022 23.636 16.7682 23.9589 17.6665 23.9727C18.3689 23.9813 19.0597 23.7943 19.6617 23.4324L27.8501 18.6939V26.7576H27.85ZM27.85 16.7403L18.7887 22.0192C18.0106 22.4801 17.0258 22.3967 16.3363 21.8113L11.7642 17.7795C10.4537 16.6535 8.53843 16.5844 7.15037 17.6132L1.66375 21.6035V9.75737C1.66375 8.84292 2.28725 7.97008 3.2017 7.97008H26.0211C26.9981 8.01057 27.7871 8.78166 27.85 9.75737V16.7403ZM32.5485 6.08302C32.548 6.08851 32.5475 6.09408 32.5469 6.09957L30.5933 22.9751C30.5966 23.4127 30.3971 23.8271 30.0529 24.0974C29.8866 24.2637 29.5126 24.3468 29.5126 24.43V9.75737C29.4469 7.86379 27.9153 6.35034 26.021 6.30746H6.15286L6.52695 3.06535C6.6081 2.64552 6.8276 2.26506 7.15045 1.98462C7.51499 1.73258 7.95574 1.61507 8.39746 1.65206L31.0507 4.27067C31.9646 4.35747 32.6353 5.16889 32.5485 6.08302Z'
          fill={color}
        />
      </svg>
    );
  },
);
