import c from 'classnames';
import { DEFAULT_INPUT_CLASSES } from 'components/default-input/default-input.constants';
import { InputClearableButton } from 'components/default-input/input-clearable-button';
import { memo, SyntheticEvent, useCallback } from 'react';

const MAX_NUMBER_VALUE = 999999999.99;
const CURRENCY_PATTERN = /^\d*([.,]\d{0,2})?$/m;

type DefaultInputProps = {
  value: string | number;
  placeholder: string;
  onChange(event: SyntheticEvent<HTMLInputElement> | string): void;
  maxNumberValue?: typeof MAX_NUMBER_VALUE;
  clearable?: boolean;
  inputClassName?: string;
};

const removeZeroOnStartValue = (value: string) => {
  const digitsArray = value.split('');
  let stopFilteringZeros = false;

  return digitsArray
    .filter((digit) => {
      if (stopFilteringZeros) {
        return true;
      }

      if (digit !== '0') {
        stopFilteringZeros = true;
        return true;
      }

      return false;
    })
    .join('');
};

export const AppNumberInput = memo(
  ({
    value,
    placeholder,
    onChange,
    inputClassName = '',
    clearable = true,
    maxNumberValue = MAX_NUMBER_VALUE,
  }: DefaultInputProps) => {
    const handleChange = useCallback(
      ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
        const amountValue = currentTarget.value;
        if (!CURRENCY_PATTERN.test(currentTarget.value)) {
          return;
        }

        const parsedValue = parseFloat(amountValue);

        const finalValue =
          parsedValue < 0
            ? 0
            : parsedValue > maxNumberValue
            ? maxNumberValue
            : removeZeroOnStartValue(amountValue).replace(',', '.');

        // @ts-ignore
        onChange({
          currentTarget: { value: finalValue },
        } as SyntheticEvent<HTMLInputElement>);
      },
      [maxNumberValue, onChange],
    );

    return (
      <div className='default-input__wrapper'>
        <input
          type='text'
          inputMode='decimal'
          onChange={handleChange}
          placeholder={placeholder as string}
          value={String(value)}
          className={c(
            DEFAULT_INPUT_CLASSES.input,
            inputClassName,
            'app-number-input',
          )}
        />
        <div className='default-input__right-part'>
          <InputClearableButton
            {...{
              clearable,
              value,
              onChange,
            }}
          />
        </div>
      </div>
    );
  },
);
