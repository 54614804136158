import { APP_CSS_THEME_VARIABLES } from 'color-theme.constants';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AppLoader } from '../../../../components/app-loader';
import { SettingsIcon } from '../../settings-icon';
import { useInvite } from './gql';
import './Invite.scss';

export const Invite = ({ mobile }: any) => {
  const { push } = useHistory();
  const [email, setEmail] = useState('');
  const { invite, loading } = useInvite();
  const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;

  // @ts-ignore
  const click = () => {
    invite(email).then(() => {
      setEmail('');
      mobile && push('/settings');
    });
  };

  // @ts-ignore
  const onChange = (email: string) => {
    setEmail(email);
  };

  return (
    <>
      <div className='invite__wrapper'>
        {loading ? <AppLoader /> : null}

        <div className={'invite__friends-invite'}>
          <FormattedMessage id={'menu.profile.invite'} />
          <strong>
            <FormattedMessage id={'menu.profile.5premium'} />
          </strong>
          <FormattedMessage id={'menu.profile.friends'} />
          <FormattedMessage id={'menu.profile.have.yours'} />
          <strong>
            <FormattedMessage id={'menu.profile.free'} />
          </strong>
        </div>

        <ul className={'invite__item-list'}>
          <li className={'invite__item invite__item-mail'}>
            <SettingsIcon name='mail' color={colorTextMain20} />
            <span className='invite__item__label'>
              <FormattedMessage id={'menu.profile.invite.mail'} />
            </span>
          </li>

          <li className={'invite__item invite__item-sms'}>
            <SettingsIcon name='sms' color={colorTextMain20} />
            <span className='invite__item__label'>
              <FormattedMessage id={'menu.profile.invite.sms'} />
            </span>
          </li>

          <li className={'invite__item invite__item-share'}>
            <SettingsIcon name='share' color={colorTextMain20} />
            <span className='invite__item__label'>
              <FormattedMessage id={'menu.profile.invite.share'} />
            </span>
          </li>
        </ul>

        <div className={'invite__separation-line'} />

        <div className={'invite__gift'}>
          <div className={'invite__gift-wrapper'}>
            <div className={'invite__gift-container'}>
              <SettingsIcon name='gift' sizeType='custom' />
            </div>
          </div>
          <div className={'invite__gift-text'}>
            <FormattedMessage id={'menu.profile.invite.gift'} />
          </div>
        </div>
      </div>
    </>
  );
};
