import { Maybe } from 'core.types';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { subscriptionPlanListAtom } from 'store/root-store';
import { Glassfy } from 'capacitor-plugin-glassfy';

export const useRegisterPurchaseList = (userId: Maybe<number>) => {
  const setSubscriptionPlanList = useSetRecoilState(subscriptionPlanListAtom);

  useEffect(() => {
    if (!window.cordova || !userId) {
      return;
    }

    (async function () {
      try {
        const glassifyApiKey =
          process.env.REACT_APP_PROJECT_TYPE == 'ireceipt'
            ? process.env.REACT_APP_GLASSIFY_KEY_IRECEIPT
            : process.env.REACT_APP_GLASSIFY_KEY_IDOCUMENT;
        await Glassfy.initialize({
          apiKey: String(glassifyApiKey),
          watcherMode: false,
        });
        await Glassfy.connectCustomSubscriber({ subscriberId: String(userId) });
        const offers = await Glassfy.offerings();
        offers?.all?.forEach((offer) => {
          offer.skus?.forEach((sku) => {
            setSubscriptionPlanList((subscriptionPlanList) => ({
              ...subscriptionPlanList,
              [sku.productId]: sku.product,
            }));
          });
        });
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    })();
  }, [userId]);
};
