export default {
  uk: {
    'menu.profile.plan.family': 'Преміум',
    'app.trial-tree-days': 'Безкоштовний пробний період 3 дні',
    'app.buy': 'Підписатися',
    'menu.profile.plan.personal': 'Плюс',
    'menu.profile.plan.argument.2.personal.description':
      'створіть свій обліковий запис плюс і контролюйте свої квитанції та рахунки',
    'menu.settings.account.personal': 'Обліковий запис Плюс',
    'menu.profile.plan.argument.2.family.description':
      "створіть свій преміум обліковий запис і зробіть окремі акаунти для сім'ї та роботи",
    'menu.settings.account.family': 'Преміум обліковий запис',
    'app.privacyPolicy': 'Політика конфіденційності',
    'app.licenseUserAgreement': 'Ліцензійна угода користувача',
    'app.gdpr': 'Загальний регламент про захист даних (GDPR)',
    'auth.h1': 'Вітаємо',
    'auth.p': 'Ласкаво просимо до вашого гаманця чеків та рахунків',
    'auth.social': 'Або через соціальні мережі',
    'sort.name': "Ім'я",
    'login.h1': 'Ласкаво просимо знову!',
    'login.email': 'Адреса електронної пошти',
    'login.pass': 'Пароль',
    'login.forgot': 'Забули пароль',
    'login.otp': 'Увійти через OTP',
    'login.footer': 'Немає облікового запису?',
    'login.footer.up': 'Зареєструватися',

    'signUp.h1': 'Реєстрація',
    'signUp.email': 'Ваша електронна пошта',
    'signUp.pass': 'Пароль',
    'signUp.success':
      'Успіх: перевірте електронну пошту, щоб підтвердити новий обліковий запис',
    'signUp.footer': 'Вже є обліковий запис?',
    'signUp.footer.in': 'Увійти',

    'forgot.h1': 'Забули пароль?',
    'forgot.p':
      "Введіть адресу електронної пошти, пов'язану з цим обліковим записом",
    'forgot.success': 'Успіх: перевірте електронну пошту, щоб скинути пароль',
    'forgot.email': 'Введіть вашу адресу електронної пошти',

    'reset.h1': 'Новий пароль',
    'reset.p': 'Введіть новий пароль',
    'reset.success': 'Успіх: перевірте електронну пошту, щоб скинути пароль',
    'reset.pass': 'Пароль',
    'reset.confirm': 'Підтвердіть пароль',

    'otp.h1': 'Увійти через OTP',
    'otp.p': 'Ми надішлемо OTP на ваш мобільний номер',
    'verify.h1': 'Перевірка',
    'verify.p': 'Введіть 4-значний номер, який було надіслано до',
    'verify.receive': 'Не отримали?',
    'verify.reSend': 'Повторно надіслати через ',

    'mailConfirm.text': 'Щось пішло не так:',
    'mailConfirm.success': 'Успіх: Електронну пошту підтверджено',
    'mailConfirm.goTo': 'Перейти до додатку',

    'test.title': 'Встановити якість',
    'test.quality': 'Краща якість',
    'test.recognition': 'Швидше розпізнавання',
    'test.submit': 'встановити',

    'btn.login': 'Увійти',
    'btn.continue': 'Продовжити',
    'btn.sign': 'Зареєструватися',
    'btn.submit': 'Надіслати',
    'btn.verify': 'Перевірити зараз',

    /* APP */

    'sort.store': 'Магазин',
    'sort.madeBy': 'Створено',
    'sort.amount': 'Сума',
    'sort.date': 'Дата',

    'breadcrumbs.no.folders': 'Тут ще немає папок',

    cancel: 'Скасувати',
    confirm: 'Підтвердити',
    create: 'Створити',
    next: 'Далі',
    back: 'Назад',
    save: 'Зберегти',
    apply: 'Застосувати',
    name: "Ім'я",
    amount: 'Сума',
    from: 'Від:',
    to: 'До:',

    receipts: 'Чеки',
    invoices: 'Рахунки',

    'new.folder': 'Нова папка',
    folder: 'Папка:',
    home: 'Головна',
    profile: 'Профіль',

    'new.receipt': 'Новий чек',
    'new.invoice': 'Новий рахунок',
    'new.document': 'Новий документ',
    'receipt.name': 'Назва чеку',
    'document.name': 'Назва документу',
    'categoryList.no-documents': 'Ще немає документів',

    'toast.share': 'Посилання для спільного доступу скопійовано в буфер обміну',

    'modal.share': 'Виберіть формат посилання для спільного доступу',
    'modal.share.link': 'Посилання',
    'modal.share.pdf': 'Файл',
    'modal.share.download': 'Завантажити',

    'preview.name': "Ім'я:",
    'preview.amount': 'Сума:',
    'preview.date': 'Дата:',
    'preview.created': 'Створено:',
    'preview.edit': 'Редагувати',
    'preview.share': 'Поділитися',
    'preview.delete': 'Видалити',
    'preview.close': 'Закрити',
    'preview.print': 'Друкувати',

    'categoryList.dont.1': 'в категорії',
    'categoryList.dont.2': ' у вас ще немає чеків та категорій',

    'filter.original': 'Оригінал',
    'filter.bw': 'Чорно-білий',
    'filter.grey': 'Відтінки сірого',

    unfilledReceipt: 'Незаповнений чек',
    unfilledInvoice: 'Незаповнений рахунок',
    unfilledDocument: 'Незаповнений документ',
    tree: 'Дерево категорій',
    CreateNewFolder: 'Створити нову папку',
    alert: 'Ви впевнені, що хочете видалити?',
    'set.date': 'встановити дату',

    'move.home': 'Перемістити до головної папки',
    'move.parent': 'Перемістити до батьківської папки',

    'add.item.additional.info-1': 'У вас може бути максимум ',
    'add.item.additional.info-2': ' облікових записів',

    /* settings */
    'menu.profile': 'Редагувати інформацію',
    'menu.profile.manage': 'Керувати',
    'menu.quality': 'Якість чеку',
    'menu.terms': 'Умови та положення',
    'menu.recognition': 'Розпізнавання чеку',

    'menu.profile.info.currency': 'Валюта:',
    'menu.profile.enter-promo': 'Введіть промокод',
    'menu.profile.redeem-promo': 'Використати промокод',
    'menu.profile.redeem': 'Використати',

    'menu.profile.photo': 'Змінити фотографію профілю',
    'menu.profile.change.data': 'Ви завжди можете змінити ці дані',
    'menu.profile.delete.account': 'Видалити обліковий запис',
    'menu.profile.delete.profile': 'Видалити профіль',
    'menu.profile.your.mail': 'Ваша електронна пошта:',
    'menu.profile.add.mail': 'Додати електронну пошту',
    'menu.profile.new.mail': 'Нова електронна пошта',
    'menu.profile.new.account': 'Новий обліковий запис',
    'menu.profile.account.empty': 'Додати обліковий запис',
    'menu.profile.invite': 'Запросити ',
    'menu.profile.5premium': '5 ПРЕМІУМ ',
    'menu.profile.friends': 'друзів ',
    'menu.profile.have.yours': 'і отримайте свій ',
    'menu.profile.free': 'БЕЗКОШТОВНО',
    'menu.profile.invite.mail': 'Запросити друзів по електронній пошті',
    'menu.profile.invite.sms': 'Запросити друзів через SMS',
    'menu.profile.invite.share': 'Надіслати посилання своїм друзям',
    'menu.profile.invite.gift': 'Подарувати другу',
    'menu.profile.password.current': 'Поточний пароль',
    'menu.profile.password.new': 'Новий пароль',
    'menu.profile.password.again': 'Введіть новий пароль ще раз',
    'menu.profile.password.forgot': 'Забули пароль?',
    'menu.profile.phone.your': 'Ваш номер телефону:',
    'menu.profile.phone.add': 'Додати номер телефону',
    'menu.profile.phone.new': 'Новий телефон',
    'menu.profile.first': "Ім'я",
    'menu.profile.last': 'Прізвище',
    'menu.profile.date': 'Дата народження',
    'menu.profile.gender': 'Стать',
    'menu.profile.currency': 'Валюта',
    'menu.profile.male': 'Чоловіча',
    'menu.profile.female': 'Жіноча',
    'menu.profile.remove.confirm1':
      'Ви впевнені, що хочете видалити свій обліковий запис з усіма даними?',
    'menu.profile.remove.confirm2':
      'ВСІ ДАНІ ВАШОГО ОБЛІКОВОГО ЗАПИСУ, НАЛАШТУВАННЯ, ЗАВАНТАЖЕНІ ДОКУМЕНТИ, КАТЕГОРІЇ БУДУТЬ НАЗАВЖДИ ВИДАЛЕНІ! ВИ ЗГОДНІ?',

    'menu.invite': 'Запросити друга',

    'menu.plan': 'План підписки',
    'menu.plan.title': 'iReceipt Преміум обліковий запис',
    'menu.plan.tab.header.1': 'Особливості',
    'menu.plan.tab.header.2': 'Базовий',
    'menu.plan.tab.header.3': 'Преміум',
    'menu.plan.tab.body.1': 'Зберігати чеки',
    'menu.plan.tab.body.2': 'Поділитися чеками',
    'menu.plan.tab.body.3': 'Хмарне сховище',
    'menu.plan.tab.body.4': 'Створювати папки',
    'menu.plan.tab.body.5': 'Поділитися папками',
    'menu.plan.tab.body.6': 'Чат з іншими',
    'menu.plan.tab.body.7': 'Необмежене сховище',

    'menu.profile.plan.title': 'ПЕРЕЙТИ НА ПРЕМІУМ',
    'menu.profile.plan.description':
      'Зберігайте та діліться своїми чеками, створюйте папки та діліться папками як PDF або як посиланням на PDF',
    'menu.profile.plan.business': 'Діловий',
    'menu.profile.plan.argument.1.title': 'необмежене сховище',
    'menu.profile.plan.argument.1.description':
      'зберігайте необмежену кількість чеків та майте до них доступ з будь-якого пристрою',
    'menu.profile.plan.argument.2.title': 'до ',
    'menu.profile.plan.argument.2.title-2': ' облікових записів',
    'menu.profile.plan.argument.2.business.description':
      'створіть свій діловий обліковий запис та створюйте окремі облікові записи для бізнес-учасників та роботи',
    'menu.profile.plan.subtitle.1': 'Безкоштовний пробний період до ',
    'menu.profile.plan.subtitle.2': ' чеків',
    'menu.profile.plan.proposals.1.period': 'Щомісяця',
    'menu.profile.plan.proposals.1.per-period': 'на місяць',
    'menu.profile.plan.proposals.2.period': 'Щорічно',
    'menu.profile.plan.proposals.2.per-period': 'на рік',
    'menu.profile.plan.proposals.best-value': 'найкраща пропозиція',
    'menu.profile.plan.proposals.info-1': 'Це лише ',
    'menu.profile.plan.proposals.info-2': ' на місяць',
    'menu.profile.plan.soon':
      "Незабаром з'являться нові функції для наших преміум-користувачів",
    'menu.profile.plan.restore': 'Відновити куплене',
    'menu.profile.plan.bottom-info':
      'Оплата буде зарахована на обліковий запис Apple після підтвердження покупки. Підписка автоматично продовжується, якщо автоматичне продовження не вимкнено принаймні за 24 години до кінця поточного періоду. Обліковий запис буде обкладений платою за продовження протягом 24 годин до кінця поточного періоду та визначить вартість продовження. Підписки можуть керувати користувачі, а автоматичне продовження можна вимкнути, перейшовши до налаштувань облікового запису користувача після покупки.',
    'menu.profile.plan.coming.soon': 'Незабаром...',
    'menu.profile.plan.coming.soon.alert':
      "Як тільки план передплати 'Бізнес' стане доступним, ви отримаєте push-сповіщення. Будь ласка, переконайтеся, що ваші сповіщення включені, щоб ви не пропустили це важливе оновлення",
    'menu.profile.plan.notify': 'Повідомити мене першим',

    'menu.profile.permission.viewing': 'Права на перегляд',
    'menu.profile.permission.editing': 'Права на редагування',
    'menu.profile.permission.deletion': 'Права на видалення',
    'menu.profile.permission.admin.view.info':
      'Надання прав адміністратора може бути надано групам учасників старше 18 років. Користувач з цим дозволом зможе переглядати чеки та рахунки',
    'menu.profile.permission.admin.edit.info':
      'Надання прав адміністратора може бути надано групам учасників старше 18 років. Користувач з цим дозволом зможе редагувати чеки та рахунки',
    'menu.profile.permission.admin.delete.info':
      'Надання прав адміністратора може бути надано групам учасників старше 18 років. Користувач з цим дозволом зможе видаляти чеки та рахунки',

    'menu.free': 'Безкоштовно',
    'menu.monthly': 'щомісяця',
    'menu.yearly': 'щорічно',
    'menu.about': 'Про додаток',
    'menu.lang': 'Мова',
    'menu.theme': 'Тема додатку',
    'menu.theme.title': 'Виберіть палітру',
    'menu.theme.subtitle':
      'Насолоджуйтесь додатком з вашими улюбленими кольорами',
    'menu.lang.choose': 'Виберіть мову:',
    'menu.lang.choose2': 'Виберіть мову розпізнавання:',
    'menu.lang.ru': 'Русский',
    'menu.lang.uk': 'Українська',
    'menu.lang.en': 'Англійська',
    'menu.lang.no': 'Норвезька',
    'menu.lang.pt': 'Португальська',
    'menu.lang.pl': 'Польська',
    'menu.lang.fi': 'Фінська',
    'menu.lang.se': 'Шведська',
    'menu.lang.it': 'Італійська',
    'menu.lang.fr': 'Французька',
    'menu.lang.es': 'Іспанська',
    'menu.lang.de': 'Німецька',
    'menu.lang.da': 'Данська',
    'menu.account': 'Змінити обліковий запис',
    'menu.account.add': 'Поділитися обліковим записом',
    'menu.account.notHave': 'у вас немає спільних облікових записів',
    'menu.logout': 'Вийти',
    'menu.logout.alternatives': 'Альтернативні варіанти',
    'menu.logout.modal.title': 'Ви впевнені, що хочете вийти?',

    'menu.support': 'Звернутися до служби підтримки',
    'menu.password': 'Змінити пароль',
    'menu.email': 'Електронна пошта',
    'menu.phone': 'Номер телефону',
    'menu/settings': 'Налаштування',
    'menu/settings/profile': 'Редагувати профіль',
    'menu/settings/invite': 'Запросити друга',
    'menu/settings/plan': 'План підписки',
    'menu/settings/password': 'Пароль',
    'menu/settings/email': 'Електронна пошта',
    'menu/settings/phone': 'Телефон',
    'menu/settings/language': 'Мова',
    'menu/settings/quality': 'Якість чеку',
    'menu/settings/about': 'Про додаток',
    'menu/settings/account': 'Змінити обліковий запис',

    'menu.settings.account.free': 'Безкоштовний обліковий запис',
    'menu.settings.account.business': 'Діловий обліковий запис',
    'menu.settings.account.add': 'Додати обліковий запис',

    'menu.plan.personal.storage-title': 'Сховище - до 10 ГБ',
    'menu.plan.personal.storage-description':
      'Зберігайте чеки та майте до них доступ з будь-якого пристрою',
    'menu.plan.family.storage-title': 'Сховище - до 20 ГБ',
    'menu.plan.family.storage-description':
      'Зберігайте чеки та майте до них доступ з будь-якого пристрою',

    'recognize.categoryName': 'розпізнавання назви',
    'recognize.categoryAmount': 'розпізнавання суми',

    saveTo: 'Зберегти до..',
    'save.new': 'Зберегти та новий',
    'save.close': 'Зберегти та закрити',

    'all.profiles': 'Всі профілі',
    'style.rotate': 'Повернути',
    'style.correction': 'Корекція',
    'style.color': 'Колір',
    'style.intensity': 'Інтенсивність',
    'style.style': 'Яскравість',
    'style.contrast': 'Контраст',
    'move.to.receipts': 'Перемістити до квитанцій',
    'move.to.receipts.modal.title':
      'Цей рахунок-фактура буде переміщено до квитанцій',
    'move.to.invoices': 'Перемістити до рахунків-фактур',
    'move.to.invoices.modal.title':
      'Ця квитанція буде переміщена до рахунків-фактур',
    'create.new.folder': 'Створити нову папку',
    'share.modal.web': 'Лише посилання для веб',
    'app.loading': 'Завантаження',
    'receipt.quality': 'якість чеку',

    'this.month': 'Цього місяця',
    'last.month': 'Минулого місяця',
    'notifications.tryOpenReceiptLater': 'Спробуйте відкрити чек пізніше',

    select: 'Вибрати',

    recognition: 'Розпізнавання',
    'recognition.none': 'Немає',
    'recognition.fast': 'Швидко',
    'recognition.accurate': 'Точно',
  },
  ru: {
    'menu.profile.plan.family': 'Премиум',
    'app.trial-tree-days': 'Бесплатный период 3 дня',
    'app.buy': 'Подписаться',
    'menu.profile.plan.personal': 'Плюс',
    'menu.profile.plan.argument.2.personal.description':
      'создайте свою плюс учетную запись и контролируйте свои квитанции и счета',
    'menu.settings.account.personal': 'Плюс Учетная запись',
    'menu.profile.plan.argument.2.family.description':
      'создайте свою премиум учетную запись и создайте отдельные аккаунты для семьи и работы',
    'menu.settings.account.family': 'Премиум Учетная запись',
    'app.privacyPolicy': 'Политика конфиденциальности',
    'app.licenseUserAgreement': 'Лицензионное соглашение пользователя',
    'app.gdpr': 'Общий регламент по защите данных (GDPR)',
    'auth.h1': 'Добро пожаловать',
    'auth.p': 'Добро пожаловать в ваш кошелек чеков и счетов',
    'auth.social': 'Или через социальные сети',
    'sort.name': 'Название',
    'login.h1': 'Добро пожаловать обратно!',
    'login.email': 'Адрес электронной почты',
    'login.pass': 'Пароль',
    'login.forgot': 'Забыли пароль',
    'login.otp': 'Войти через OTP',
    'login.footer': 'Нет аккаунта?',
    'login.footer.up': 'Зарегистрироваться',
    'signUp.h1': 'Регистрация',
    'signUp.email': 'Ваша электронная почта',
    'signUp.pass': 'Пароль',
    'signUp.success':
      'Проверьте электронную почту, чтобы подтвердить новый аккаунт',
    'signUp.footer': 'Уже есть аккаунт?',
    'signUp.footer.in': 'Войти',

    'forgot.h1': 'Забыли пароль?',
    'forgot.p':
      'Введите адрес электронной почты, связанный с этой учетной записью',
    'forgot.success':
      'Успех: проверьте электронную почту, чтобы сбросить пароль',
    'forgot.email': 'Введите ваш адрес электронной почты',

    'reset.h1': 'Новый пароль',
    'reset.p': 'Введите новый пароль',
    'reset.success':
      'Успех: проверьте электронную почту, чтобы сбросить пароль',
    'reset.pass': 'Пароль',
    'reset.confirm': 'Подтвердите пароль',

    'otp.h1': 'Войти через OTP',
    'otp.p': 'Мы отправим OTP на ваш мобильный номер',
    'verify.h1': 'Проверка',
    'verify.p': 'Введите 4-значный номер, который был отправлен на',
    'verify.receive': 'Не получили?',
    'verify.reSend': 'Повторно отправить через',
    'mailConfirm.text': 'Что-то пошло не так:',
    'mailConfirm.success': 'Электронная почта подтверждена',
    'mailConfirm.goTo': 'Перейти к приложению',

    'test.title': 'Настройка качества',
    'test.quality': 'Лучшее качество',
    'test.recognition': 'Быстрое распознавание',
    'test.submit': 'Установить',

    'btn.login': 'Войти',
    'btn.continue': 'Продолжить',
    'btn.sign': 'Регистрация',
    'btn.submit': 'Отправить',
    'btn.verify': 'Подтвердить сейчас',

    /* APP */

    'sort.store': 'Магазин',
    'sort.madeBy': 'Создано',
    'sort.amount': 'Сумма',
    'sort.date': 'Дата',

    'breadcrumbs.no.folders': 'Здесь еще нет папок',

    cancel: 'Отмена',
    confirm: 'Подтвердить',
    create: 'Создать',
    next: 'Далее',
    back: 'Назад',
    save: 'Сохранить',
    apply: 'Применить',
    name: 'Название',
    amount: 'Сумма',
    from: 'От:',
    to: 'До:',

    receipts: 'Чеки',
    invoices: 'Счета',

    'new.folder': 'Новая папка',
    folder: 'Папка:',
    home: 'Главная',
    profile: 'Профиль',

    'new.receipt': 'Новый чек',
    'new.invoice': 'Новый счет',
    'new.document': 'Новый документ',
    'receipt.name': 'Название чека',
    'document.name': 'Название документа',
    'categoryList.no-documents': 'Пока нет документов',

    'toast.share': 'Ссылка для общего доступа скопирована в буфер обмена',
    'modal.share': 'Выберите формат ссылки для общего доступа',
    'modal.share.link': 'Ссылка',
    'modal.share.pdf': 'Файл',
    'modal.share.download': 'Скачать',

    'preview.name': 'Название:',
    'preview.amount': 'Сумма:',
    'preview.date': 'Дата:',
    'preview.created': 'Создано:',
    'preview.edit': 'Редактировать',
    'preview.share': 'Поделиться',
    'preview.delete': 'Удалить',
    'preview.close': 'Закрыть',
    'preview.print': 'Печать',

    'categoryList.dont.1': 'в категории',
    'categoryList.dont.2': ' у вас пока нет чеков и категорий',

    'filter.original': 'Оригинал',
    'filter.bw': 'Черно-белый',
    'filter.grey': 'Оттенки серого',

    unfilledReceipt: 'Незаполненный чек',
    unfilledInvoice: 'Незаполненный счет',
    unfilledDocument: 'Незаполненный документ',
    tree: 'Дерево категорий',
    CreateNewFolder: 'Создать новую папку',
    alert: 'Вы уверены, что хотите удалить?',
    'set.date': 'установить дату',

    'move.home': 'Переместить в главную папку',
    'move.parent': 'Переместить в родительскую папку',

    'add.item.additional.info-1': 'У вас может быть максимум ',
    'add.item.additional.info-2': ' учетных записей',
    'menu.profile': 'Редактировать информацию',
    'menu.profile.manage': 'Управление',
    'menu.quality': 'Качество чека',
    'menu.terms': 'Условия и положения',
    'menu.recognition': 'Распознавание чека',

    'menu.profile.info.currency': 'Валюта:',
    'menu.profile.enter-promo': 'Введите промокод',
    'menu.profile.redeem-promo': 'Использовать промокод',
    'menu.profile.redeem': 'Использовать',

    'menu.profile.photo': 'Изменить фотографию профиля',
    'menu.profile.change.data': 'Вы всегда можете изменить эти данные',
    'menu.profile.delete.account': 'Удалить учетную запись',
    'menu.profile.delete.profile': 'Удалить профиль',
    'menu.profile.your.mail': 'Ваша электронная почта:',
    'menu.profile.add.mail': 'Добавить электронную почту',
    'menu.profile.new.mail': 'Новая электронная почта',
    'menu.profile.new.account': 'Новая учетная запись',
    'menu.profile.account.empty': 'Добавить учетную запись',
    'menu.profile.invite': 'Пригласить',
    'menu.profile.5premium': '5 ПРЕМИУМ',
    'menu.profile.friends': 'друзей',
    'menu.profile.have.yours': 'и получите свой премиум-аккаунт',
    'menu.profile.free': 'БЕСПЛАТНО',
    'menu.profile.invite.mail': 'Пригласить друзей по электронной почте',
    'menu.profile.invite.sms': 'Пригласить друзей через SMS',
    'menu.profile.invite.share': 'Отправить ссылку друзьям',
    'menu.profile.invite.gift': 'Подарить другу',
    'menu.profile.password.current': 'Текущий пароль',
    'menu.profile.password.new': 'Новый пароль',
    'menu.profile.password.again': 'Введите новый пароль еще раз',
    'menu.profile.password.forgot': 'Забыли пароль?',
    'menu.profile.phone.your': 'Ваш номер телефона:',
    'menu.profile.phone.add': 'Добавить номер телефона',
    'menu.profile.phone.new': 'Новый номер',
    'menu.profile.first': 'Имя',
    'menu.profile.last': 'Фамилия',
    'menu.profile.date': 'Дата рождения',
    'menu.profile.gender': 'Пол',
    'menu.profile.currency': 'Валюта',
    'menu.profile.male': 'Мужской',
    'menu.profile.female': 'Женский',
    'menu.profile.remove.confirm1':
      'Вы уверены, что хотите удалить свою учетную запись со всей информацией?',
    'menu.profile.remove.confirm2':
      'ВСЯ ИНФОРМАЦИЯ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ, НАСТРОЙКИ, ЗАГРУЖЕННЫЕ ДОКУМЕНТЫ, КАТЕГОРИИ БУДУТ НАВСЕГДА УДАЛЕНЫ! ВЫ СОГЛАСНЫ?',

    'menu.invite': 'Пригласить друга',

    'menu.plan': 'План подписки',
    'menu.plan.title': 'Премиум-аккаунт iReceipt',
    'menu.plan.tab.header.1': 'Функции',
    'menu.plan.tab.header.2': 'Базовый',
    'menu.plan.tab.header.3': 'Премиум',
    'menu.plan.tab.body.1': 'Хранение чеков',
    'menu.plan.tab.body.2': 'Поделиться чеками',
    'menu.plan.tab.body.3': 'Облачное хранилище',
    'menu.plan.tab.body.4': 'Создание папок',
    'menu.plan.tab.body.5': 'Обмен папками',
    'menu.plan.tab.body.6': 'чат с другими пользователями',
    'menu.plan.tab.body.7': 'Неограниченное хранилище',

    'menu.profile.plan.title': 'ПЕРЕЙТИ НА ПРЕМИУМ',
    'menu.profile.plan.description':
      'Храните и делитесь своими чеками, создавайте папки и делитесь папками в формате PDF или ссылкой на PDF',

    'menu.profile.plan.business': 'Бизнес',
    'menu.profile.plan.argument.1.title': 'неограниченное хранилище',
    'menu.profile.plan.argument.1.description':
      'храните неограниченное количество чеков и имейте к ним доступ с любого устройства',
    'menu.profile.plan.argument.2.title': 'до ',
    'menu.profile.plan.argument.2.title-2': ' учетных записей',
    'menu.profile.plan.argument.2.business.description':
      'создайте учетную запись для бизнеса и создайте отдельные учетные записи для участников бизнеса и работы',
    'menu.profile.plan.subtitle.1': 'Бесплатный пробный период до ',
    'menu.profile.plan.subtitle.2': ' чеков',
    'menu.profile.plan.proposals.1.period': 'Ежемесячно',
    'menu.profile.plan.proposals.1.per-period': 'в месяц',
    'menu.profile.plan.proposals.2.period': 'Ежегодно',
    'menu.profile.plan.proposals.2.per-period': 'в год',
    'menu.profile.plan.proposals.best-value': 'лучшее предложение',
    'menu.profile.plan.proposals.info-1': 'Это всего лишь',
    'menu.profile.plan.proposals.info-2': ' в месяц',
    'menu.profile.plan.soon':
      'Скоро появятся новые функции для наших премиум-пользователей',
    'menu.profile.plan.restore': 'Восстановить покупку',
    'menu.profile.plan.bottom-info':
      'Оплата будет снята с учетной записи Apple после подтверждения покупки. Подписка автоматически продлевается, если автоматическое продление не отключено по крайней мере за 24 часа до окончания текущего периода. Учетная запись будет обложена платой за продление за 24 часа до окончания текущего периода и определит стоимость продления. Подписками могут управлять пользователи, а автоматическое продление можно отключить, перейдя в настройки учетной записи пользователя после покупки.',
    'menu.profile.plan.coming.soon': 'Скоро...',
    'menu.profile.plan.coming.soon.alert':
      "Как только план подписки 'Бизнес' станет доступен, вы получите push-уведомление. Пожалуйста, убедитесь, что у вас включены уведомления, чтобы не пропустить это важное обновление",
    'menu.profile.plan.notify': 'Сообщить мне первым',

    'menu.profile.permission.viewing': 'Права на просмотр',
    'menu.profile.permission.editing': 'Права на редактирование',
    'menu.profile.permission.deletion': 'Права на удаление',
    'menu.profile.permission.admin.view.info':
      'Права администратора могут быть предоставлены группам участников старше 18 лет. Пользователь с этим разрешением сможет просматривать чеки и счета',
    'menu.profile.permission.admin.edit.info':
      'Права администратора могут быть предоставлены группам участников старше 18 лет. Пользователь с этим разрешением сможет редактировать чеки и счета',
    'menu.profile.permission.admin.delete.info':
      'Права администратора могут быть предоставлены группам участников старше 18 лет. Пользователь с этим разрешением сможет удалять чеки и счета',

    'menu.free': 'Бесплатно',
    'menu.monthly': 'Ежемесячно',
    'menu.yearly': 'Ежегодно',
    'menu.about': 'О приложении',
    'menu.lang': 'Язык',
    'menu.theme': 'Тема приложения',
    'menu.theme.title': 'Выберите палитру',
    'menu.theme.subtitle': 'Наслаждайтесь приложением ваших любимых цветов',
    'menu.lang.choose': 'Выберите язык:',
    'menu.lang.choose2': 'Выберите язык распознавания:',
    'menu.lang.ru': 'Русский',
    'menu.lang.uk': 'Украинский',
    'menu.lang.en': 'Английский',
    'menu.lang.no': 'Норвежский',
    'menu.lang.pt': 'Португальский',
    'menu.lang.pl': 'Польский',
    'menu.lang.fi': 'Финский',
    'menu.lang.se': 'Шведский',
    'menu.lang.it': 'Итальянский',
    'menu.lang.fr': 'Французский',
    'menu.lang.es': 'Испанский',
    'menu.lang.de': 'Немецкий',
    'menu.lang.da': 'Датский',
    'menu.account': 'Изменить учетную запись',
    'menu.account.add': 'Поделиться учетной записью',
    'menu.account.notHave': 'у вас нет совместных учетных записей',
    'menu.logout': 'Выйти',
    'menu.logout.alternatives': 'Альтернативные варианты',
    'menu.logout.modal.title': 'Вы уверены, что хотите выйти?',

    'menu.support': 'Связаться с поддержкой',
    'menu.password': 'Изменить пароль',
    'menu.email': 'Электронная почта',
    'menu.phone': 'Номер телефона',
    'menu/settings': 'Настройки',
    'menu/settings/profile': 'Редактировать профиль',
    'menu/settings/invite': 'Пригласить друга',
    'menu/settings/plan': 'План подписки',
    'menu/settings/password': 'Пароль',
    'menu/settings/email': 'Электронная почта',
    'menu/settings/phone': 'Телефон',
    'menu/settings/language': 'Язык',
    'menu/settings/quality': 'Качество чека',
    'menu/settings/about': 'О приложении',
    'menu/settings/account': 'Изменить учетную запись',

    'menu.settings.account.free': 'Бесплатная учетная запись',
    'menu.settings.account.business': 'Бизнес-аккаунт',
    'menu.settings.account.add': 'Добавить учетную запись',

    'menu.plan.personal.storage-title': 'Хранилище - до 10 ГБ',
    'menu.plan.personal.storage-description':
      'Храните чеки и имейте к ним доступ с любого устройства',
    'menu.plan.family.storage-title': 'Хранилище - до 20 ГБ',
    'menu.plan.family.storage-description':
      'Храните чеки и имейте к ним доступ с любого устройства',

    'recognize.categoryName': 'распознавание названия',
    'recognize.categoryAmount': 'распознавание суммы',

    saveTo: 'Сохранить в...',
    'save.new': 'Сохранить и новый',
    'save.close': 'Сохранить и закрыть',

    'all.profiles': 'Все профили',
    'style.rotate': 'Повернуть',
    'style.correction': 'Коррекция',
    'style.color': 'Цвет',
    'style.intensity': 'Интенсивность',
    'style.style': 'Яркость',
    'style.contrast': 'Контраст',
    'pay.invoice': 'Оплатить счет',
    'move.to.receipts': 'Переместить в квитанции',
    'move.to.receipts.modal.title': 'Этот счет будет перемещен в квитанции',
    'move.to.invoices': 'Переместить в счета',
    'move.to.invoices.modal.title': 'Эта квитанция будет перемещена в счета',
    'share.modal.web': 'Только ссылка для веб-версии',
    'app.loading': 'Загрузка',
    'receipt.quality': 'качество чека',

    'this.month': 'В этом месяце',
    'last.month': 'В прошлом месяце',
    'notifications.tryOpenReceiptLater': 'Попробуйте открыть чек позже',

    select: 'Выбрать',

    recognition: 'Распознавание',
    'recognition.none': 'Нет',
    'recognition.fast': 'Быстро',
    'recognition.accurate': 'Точно',
  },
  en: {
    'app.privacyPolicy': 'Privacy Policy',
    'app.licenseUserAgreement': 'License User Agreement',
    'app.gdpr': 'The General Data Protection Regulation (GDPR)',
    'app.buy': 'Subscribe',
    'app.trial-tree-days': 'Free trial 3 days',
    'auth.h1': 'Welcome',
    'auth.p': 'Welcome to your receipts & invoices wallet',
    'auth.social': 'Or via social media',
    'sort.name': 'Name',
    'login.h1': 'Welcome back!',
    'login.email': 'Email Address',
    'login.pass': 'Password',
    'login.forgot': 'Forgot Password',
    'login.otp': 'Login via OTP',
    'login.footer': 'Don’t have an account?',
    'login.footer.up': 'Sign Up',

    'signUp.h1': 'Sign Up',
    'signUp.email': 'Your Email',
    'signUp.pass': 'Password',
    'signUp.success': 'Success: check email to confirm new account',
    'signUp.footer': 'Already have an account?',
    'signUp.footer.in': 'Sign In',

    'forgot.h1': 'Forgot Password?',
    'forgot.p': 'Enter the email address associated with this account',
    'forgot.success': 'Success: check email to reset password',
    'forgot.email': 'Enter your email address',

    'reset.h1': 'New Password',
    'reset.p': 'Enter new password',
    'reset.success': 'Success: check email to reset password',
    'reset.pass': 'Password',
    'reset.confirm': 'Confirm password',

    'otp.h1': 'Login Via OTP',
    'otp.p': 'We will send an OTP to your mobile number',
    'verify.h1': 'Verification',
    'verify.p': 'Enter 4 digit number that sent to',
    'verify.receive': "Didn't Receive?",
    'verify.reSend': 'Re-send in ',

    'mailConfirm.text': 'Something went wrong:',
    'mailConfirm.success': 'Success: Email Confirmed',
    'mailConfirm.goTo': 'Go to application',

    'test.title': 'Set quality',
    'test.quality': 'Better Quality',
    'test.recognition': 'Faster Recognition',
    'test.submit': 'set',

    'btn.login': 'Login',
    'btn.continue': 'Continue',
    'btn.sign': 'Sign Up',
    'btn.submit': 'Submit',
    'btn.verify': 'Verify now',

    /* APP */

    'sort.store': 'Store',
    'sort.madeBy': 'Made by',
    'sort.amount': 'Amount',
    'sort.date': 'Date',

    'breadcrumbs.no.folders': 'No folders here yet',

    cancel: 'Cancel',
    confirm: 'Confirm',
    create: 'Create',
    next: 'Next',
    back: 'Back',
    save: 'Save',
    apply: 'Apply',
    name: 'Name',
    amount: 'Amount',
    from: 'From:',
    to: 'To:',

    receipts: 'Receipts',
    invoices: 'Invoices',

    'new.folder': 'New folder',
    folder: 'Folder:',
    home: 'Home',
    profile: 'Profile',

    'new.receipt': 'New Receipt',
    'new.invoice': 'New Invoice',
    'new.document': 'New Document',
    'receipt.name': 'Receipt name',
    'document.name': 'Document name',
    'categoryList.no-documents': 'No documents yet',

    'toast.share': 'Share link copied to the clipboard',

    'modal.share': 'Choose share link format',
    'modal.share.link': 'Link',
    'modal.share.pdf': 'File',
    'modal.share.download': 'Download',

    'preview.name': 'Name:',
    'preview.amount': 'Amount:',
    'preview.date': 'Date:',
    'preview.created': 'Created by:',
    'preview.edit': 'Edit',
    'preview.share': 'Share',
    'preview.delete': 'Delete',
    'preview.close': 'Close',
    'preview.print': 'Print',

    'categoryList.dont.1': 'in Category',
    'categoryList.dont.2': " you don't have receipts and categories yet",

    'filter.original': 'Original',
    'filter.bw': 'B&W',
    'filter.grey': 'Greyscale',

    unfilledReceipt: 'Unfilled receipt',
    unfilledInvoice: 'Unfilled invoice',
    unfilledDocument: 'Unfilled document',
    tree: 'Category Tree',
    CreateNewFolder: 'Create New Folder',
    alert: 'Are you sure you want to delete?',
    'set.date': 'set date',

    'move.home': 'Move to Home folder',
    'move.parent': 'Move to parent folder',

    'add.item.additional.info-1': 'You can have maximum ',
    'add.item.additional.info-2': ' accounts',

    /* settings */
    'menu.profile': 'Edit Info',
    'menu.profile.manage': 'Manage',
    'menu.quality': 'Receipt quality',
    'menu.terms': 'Terms and conditions',
    'menu.recognition': 'Receipt recognition',

    'menu.profile.info.currency': 'Currency:',
    'menu.profile.enter-promo': 'Enter promo code',
    'menu.profile.redeem-promo': 'Redeem promo code',
    'menu.profile.redeem': 'Redeem',

    'menu.profile.photo': 'Change Profile Photo',
    'menu.profile.change.data': 'You can always change this data',
    'menu.profile.delete.account': 'Delete Account',
    'menu.profile.delete.profile': 'Delete Profile',
    'menu.profile.your.mail': 'Your email:',
    'menu.profile.add.mail': 'Add email',
    'menu.profile.new.mail': 'New Email',
    'menu.profile.new.account': 'New Account',
    'menu.profile.account.empty': 'Add account',
    'menu.profile.invite': 'Invite ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'friends ',
    'menu.profile.have.yours': 'and have yours for ',
    'menu.profile.free': 'FREE',
    'menu.profile.invite.mail': 'Invite friends by email',
    'menu.profile.invite.sms': 'Invite friends by SMS',
    'menu.profile.invite.share': 'Send a link to your friends',
    'menu.profile.invite.gift': 'Gift a Friend',
    'menu.profile.password.current': 'Current password',
    'menu.profile.password.new': 'New password',
    'menu.profile.password.again': 'Enter new password again',
    'menu.profile.password.forgot': 'Forgot your password?',
    'menu.profile.phone.your': 'Your phone number:',
    'menu.profile.phone.add': 'Add phone number',
    'menu.profile.phone.new': 'New Phone',
    'menu.profile.first': 'First Name',
    'menu.profile.last': 'Last Name',
    'menu.profile.date': 'Date of birth',
    'menu.profile.gender': 'Gender',
    'menu.profile.currency': 'Currency',
    'menu.profile.male': 'Male',
    'menu.profile.female': 'Female',
    'menu.profile.remove.confirm1':
      'Are you sure you want to delete your account with all data?',
    'menu.profile.remove.confirm2':
      'ALL YOUR ACCOUNT DATA, SETTINGS, UPLOADED DOCUMENTS, CATEGORIES WILL BE PERMANENTLY DELETED FOREVER!!! DO YOU AGREE?',

    'menu.invite': 'Invite friend',

    'menu.plan': 'Subscription plan',
    'menu.plan.title': 'iReceipt Premium Account',
    'menu.plan.tab.header.1': 'Features',
    'menu.plan.tab.header.2': 'Basic',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Save receipts',
    'menu.plan.tab.body.2': 'Share receipts',
    'menu.plan.tab.body.3': 'Cloud storage',
    'menu.plan.tab.body.4': 'Create folders',
    'menu.plan.tab.body.5': 'Share folders',
    'menu.plan.tab.body.6': 'Chat with others',
    'menu.plan.tab.body.7': 'Unlimited storage',

    'menu.plan.personal.storage-title': 'Storage - up to 10 GB',
    'menu.plan.personal.storage-description':
      'Save receipts and have access to them from any device',
    'menu.plan.family.storage-title': 'Storage - up to 20 GB',
    'menu.plan.family.storage-description':
      'Save receipts and have access to them from any device',
    'menu.profile.plan.title': 'GO PREMIUM',
    'menu.profile.plan.description':
      'Save & share your receipts, create folders & share folders as a PDF or as a link to PDF',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.family': 'Premium',
    'menu.profile.plan.business': 'Business',
    'menu.profile.plan.argument.1.title': 'unlimited storage',
    'menu.profile.plan.argument.1.description':
      'save unlimited number of receipts and have access to them from any device',
    'menu.profile.plan.argument.2.title': 'up to ',
    'menu.profile.plan.argument.2.title-2': ' accounts',
    'menu.profile.plan.argument.2.personal.description':
      'create your plus account and have control over your receipts & invoices',
    'menu.profile.plan.argument.2.family.description':
      'create your premium account and make separate accounts for family and work',
    'menu.profile.plan.argument.2.business.description':
      'create your business account and make separate accounts for business members and work',
    'menu.profile.plan.subtitle.1': 'Free trial up to ',
    'menu.profile.plan.subtitle.2': ' receipts',
    'menu.profile.plan.proposals.1.period': 'Monthly',
    'menu.profile.plan.proposals.1.per-period': 'per month',
    'menu.profile.plan.proposals.2.period': 'Yearly',
    'menu.profile.plan.proposals.2.per-period': 'per year',
    'menu.profile.plan.proposals.best-value': 'best  value',
    'menu.profile.plan.proposals.info-1': 'That’s only ',
    'menu.profile.plan.proposals.info-2': ' a month',
    'menu.profile.plan.soon': 'More features coming soon for our premium users',
    'menu.profile.plan.restore': 'Restore Purchased',
    'menu.profile.plan.bottom-info':
      'Payment will be charged to Apple Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period. Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal. Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user’s Account Settings after purchase.',
    'menu.profile.plan.coming.soon': 'Coming soon...',
    'menu.profile.plan.coming.soon.alert':
      "As soon as the 'Business' subscription plan becomes available, you will receive a push notification. Please ensure your notifications are turned on so you don't miss this important update",
    'menu.profile.plan.notify': 'Notify me first',

    'menu.profile.permission.viewing': 'Viewing rights',
    'menu.profile.permission.editing': 'Editing rights',
    'menu.profile.permission.deletion': 'Deletion rights',
    'menu.profile.permission.admin.view.info':
      'Granting administrator privileges can be granted participant groups over 18 years. A user with this permission will be able to view receipts and invoices',
    'menu.profile.permission.admin.edit.info':
      'Granting administrator privileges can be granted participant groups over 18 years. A user with this permission will be able to edit receipts and invoices',
    'menu.profile.permission.admin.delete.info':
      'Granting administrator privileges can be granted participant groups over 18 years. A user with this permission will be able to delete receipts and invoices',

    'menu.free': 'Free',
    'menu.monthly': 'monthly',
    'menu.yearly': 'yearly',
    'menu.about': 'About',
    'menu.lang': 'Language',
    'menu.theme': 'Application theme',
    'menu.theme.title': 'Choose the palette',
    'menu.theme.subtitle': 'Enjoy the application with your favorite colors',
    'menu.lang.choose': 'Choose language:',
    'menu.lang.choose2': 'Choose recognize language:',
    'menu.lang.ru': 'Russian',
    'menu.lang.uk': 'Ukrainian',
    'menu.lang.en': 'English',
    'menu.lang.no': 'Norwegian',
    'menu.lang.pt': 'Portuguese',
    'menu.lang.pl': 'Polish',
    'menu.lang.fi': 'Finnish',
    'menu.lang.se': 'Swedish',
    'menu.lang.it': 'Italian',
    'menu.lang.fr': 'French',
    'menu.lang.es': 'Spanish',
    'menu.lang.de': 'German',
    'menu.lang.da': 'Danish',
    'menu.account': 'Change account',
    'menu.account.add': 'Share Account',
    'menu.account.notHave': 'you do not have shared accounts',
    'menu.logout': 'Log Out',
    'menu.logout.alternatives': 'Alternative options',
    'menu.logout.modal.title': 'Are you sure you want to log out?',

    'menu.support': 'Contact to support',
    'menu.password': 'Change password',
    'menu.email': 'Email',
    'menu.phone': 'Phone number',

    'menu/settings': 'Settings',
    'menu/settings/profile': 'Edit Profile',
    'menu/settings/invite': 'Invite Friend',
    'menu/settings/plan': 'Subscription Plan',
    'menu/settings/password': 'Password',
    'menu/settings/email': 'Email',
    'menu/settings/phone': 'Phone',
    'menu/settings/language': 'Language',
    'menu/settings/quality': 'Receipt Quality',
    'menu/settings/about': 'About',
    'menu/settings/account': 'Change Account',

    'menu.settings.account.free': 'Free Account',
    'menu.settings.account.personal': 'Plus Account',
    'menu.settings.account.business': 'Business Account',
    'menu.settings.account.family': 'Premium Account',
    'menu.settings.account.add': 'Add account',

    'recognize.categoryName': 'recognizing name',
    'recognize.categoryAmount': 'recognizing amount',

    saveTo: 'Save to..',
    'save.new': 'Save & New',
    'save.close': 'Save & Close',

    'all.profiles': 'All profiles',
    'style.rotate': 'Rotate',
    'style.correction': 'Correction',
    'style.color': 'Color',
    'style.intensity': 'Intensity',
    'style.style': 'Brightness',
    'style.contrast': 'Contrast',
    'move.to.receipts': 'Move to Receipts',
    'move.to.receipts.modal.title': 'This invoice will be moved to receipts',
    'move.to.invoices': 'Move to Invoices',
    'move.to.invoices.modal.title': 'This receipt will be moved to invoices',
    'create.new.folder': 'Create new folder',
    'share.modal.web': 'Only Link for Web',
    'app.loading': 'Loading',
    'receipt.quality': 'receipt quality',

    'this.month': 'This month',
    'last.month': 'Last month',
    'notifications.tryOpenReceiptLater': 'Try to open receipt later',

    select: 'Select',

    recognition: 'Recognition',
    'recognition.none': 'None',
    'recognition.fast': 'Fast',
    'recognition.accurate': 'Accurate',
  },
  no: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Gratis prøveperiode 3 dager',
    'app.buy': 'Abonner',
    'menu.profile.plan.personal': 'Pluss',
    'menu.profile.plan.argument.2.personal.description':
      'opprett din plusskonto og få kontroll over kvitteringer og fakturaer',
    'menu.settings.account.personal': 'Plusskonto',
    'menu.profile.plan.argument.2.family.description':
      'opprett din premiumkonto og lag separate kontoer for familie og arbeid',
    'menu.settings.account.family': 'Premiumkonto',
    'document.name': 'Dokumentnavn',
    'app.privacyPolicy': 'Personvernerklæring',
    'app.licenseUserAgreement': 'Lisensbrukeravtale',
    'app.gdpr': 'Den generelle databeskyttelsesforordningen',
    'auth.h1': 'Velkommen',
    'auth.p': 'Velkommen til kvitterings- og fakturalommeboken din',
    'auth.social': 'eller bruk din social media profil',
    unfilledDocument: 'Ufylt dokument',
    'categoryList.no-documents': 'Ingen dokumenter ennå',
    'new.document': 'Nytt dokument',

    'login.h1': 'Velkommen tilbake!',
    'login.email': 'E-postadresse',
    'login.pass': 'Passord',
    'login.forgot': 'Glemt passord',
    'login.otp': 'Logg inn med engangskode',
    'login.footer': 'Har du ikke konto?',
    'login.footer.up': 'Opprett konto',

    'signUp.h1': 'Opprett konto',
    'signUp.email': 'Din epost',
    'signUp.pass': 'Ditt passord',
    'signUp.success': 'Det gikk bra! Sjekk inboksen din',
    'signUp.footer': 'Har du allerede en konto?',
    'signUp.footer.in': 'Logg inn',

    'forgot.h1': 'Glemt passord?',
    'forgot.p': 'Fyll inn din e-postadresse',
    'forgot.success': 'Det gikk bra! Sjekk inboksen din',
    'forgot.email': 'Fyll inn e-postadresse',

    'reset.h1': 'Nytt passord',
    'reset.p': 'Fyll inn nytt passord',
    'reset.success': 'Det gikk bra. Sjekk epost inboksen din',
    'reset.pass': 'Passord',
    'reset.confirm': 'Bekreft passord',

    'otp.h1': 'Logg inn med engangskode',
    'otp.p': 'Vi sender deg en kode via SMS',
    'verify.h1': 'Verifisering',
    'verify.p': 'Fyll inn de 4 tallene du fikk på SMS',
    'verify.receive': 'Mottok du ingenting?',
    'verify.reSend': 'Send på nytt om ',

    'mailConfirm.text': 'Noe gikk galt:',
    'mailConfirm.success': 'Det gikk bra. E-post bekreftet',
    'mailConfirm.goTo': 'Gå til applikasjon',

    'test.title': 'Set kvalitet',
    'test.quality': 'Bedre Kvalitet',
    'test.recognition': 'Raskere Gjenkjenning',
    'test.submit': 'sett',

    'btn.login': 'Logg inn',
    'btn.continue': 'Fortsett',
    'btn.sign': 'Opprett konto',
    'btn.submit': 'Bekreft',
    'btn.verify': 'Verifiser nå',

    /* APP */

    'sort.store': 'Butikk',
    'sort.madeBy': 'Laget av',
    'sort.amount': 'Beløp',
    'sort.date': 'Dato',

    'breadcrumbs.no.folders': 'Ingen mapper her ennå',

    cancel: 'Avbryt',
    confirm: 'Bekrefte',
    create: 'Opprett',
    next: 'Neste',
    save: 'Lagre',
    back: 'Tilbake',
    apply: 'Bekreft',
    name: 'Navn',
    amount: 'Beløp',
    from: 'Fra:',
    to: 'Til:',

    receipts: 'Kvitteringer',
    invoices: 'Fakturaer',

    'new.folder': 'Ny mappe',
    folder: 'Mappe:',
    home: 'Hjem',
    profile: 'Profil',

    'new.receipt': 'Ny kvittering',
    'new.invoice': 'Ny faktura',
    'receipt.name': 'Navn kvittering',

    'toast.share': 'Del lenke kopiert til utklippstavlen',

    'modal.share': 'Velg format for delelink',
    'modal.share.link': 'Lenke til nettstedet',
    'modal.share.pdf': 'Lenke',
    'modal.share.pdf.file': 'Fil',
    'modal.share.download': 'Nedlasting',

    'preview.name': 'Navn:',
    'preview.amount': 'Beløp:',
    'preview.date': 'Dato:',
    'preview.created': 'Laget av:',
    'preview.edit': 'Redigere',
    'preview.share': 'Dele',
    'preview.delete': 'Slett',
    'preview.close': 'Lukk',
    'preview.print': 'Skrive ut',

    'categoryList.dont.1': 'i kategori',
    'categoryList.dont.2': ' du har ingen kvitteringer eller kategorier ennå',

    'filter.original': 'Original',
    'filter.grey': 'Gråtone',
    'filter.bw': 'Svart/hvitt',

    unfilledReceipt: 'Ufylt kvittering',
    unfilledInvoice: 'Ufyllt faktura',
    tree: 'Dine kategorier',
    alert: 'Er du sikker på at du vil slette mappene og alt innholdet?',

    'set.date': 'Sett dato',
    CreateNewFolder: 'Opprett ny mappe',

    'move.home': 'Gå til Hjem-mappen',
    'move.parent': 'Gå til overordnet mappe',

    'add.item.additional.info-1': 'Du kan ha maksimalt ',
    'add.item.additional.info-2': ' kontoer',

    /* settings */
    'menu.profile': 'Min profilside',
    'menu.profile.manage': 'Administrere',
    'menu.quality': 'Mottakskvalitet',
    'menu.terms': 'Vilkår og betingelser',
    'menu.recognition': 'Kvitteringsgjenkjenning',

    'menu.profile.info.currency': 'Valuta:',

    'menu.profile.photo': 'Endre Profilbilde',
    'menu.profile.enter-promo': 'Skriv inn kampanjekode',
    'menu.profile.redeem-promo': 'Løs inn kampanjekode',
    'menu.profile.redeem': 'Løs inn',

    'menu.profile.change.data': 'Du kan alltid endre disse dataene',
    'menu.profile.delete.account': 'Slett Konto',
    'menu.profile.delete.profile': 'Slett profil',
    'menu.profile.your.mail': 'Din epost:',
    'menu.profile.new.mail': 'Ny Epost',
    'menu.profile.new.account': 'Ny Regnskap',
    'menu.profile.account.empty': 'legg til konto',
    'menu.profile.add.mail': 'Legg til e-post',
    'menu.profile.invite': 'Invitere ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'venner ',
    'menu.profile.have.yours': 'og ha din',
    'menu.profile.free': ' GRATIS',
    'menu.profile.invite.mail': 'Inviter venner på e-post',
    'menu.profile.invite.sms': 'Inviter venner på SMS',
    'menu.profile.invite.share': 'Send en lenke til vennene dine',
    'menu.profile.invite.gift': 'Gi en venn',
    'menu.profile.password.current': 'Nåværende passord',
    'menu.profile.password.new': 'Nytt passord',
    'menu.profile.password.again': 'Skriv inn nytt passord igjen',
    'menu.profile.password.forgot': 'Glemt passordet?',
    'menu.profile.phone.your': 'Telefonnummeret ditt:',
    'menu.profile.phone.add': 'Legg til telefonnummer',
    'menu.profile.phone.new': 'Ny Telefon',
    'menu.profile.first': 'Fornavn',
    'menu.profile.last': 'Etternavn',
    'menu.profile.date': 'Fødselsdato',
    'menu.profile.gender': 'Kjønn',
    'menu.profile.currency': 'Valuta',
    'menu.profile.male': 'Mann',
    'menu.profile.female': 'Kvinne',
    'menu.profile.remove.confirm1':
      'Er du sikker på at du vil slette kontoen din med alle data?',
    'menu.profile.remove.confirm2':
      'ALLE DINE KONTODATA, INNSTILLINGER, OPPLASTTE DOKUMENTER, KATEGORIER VIL BLI PERMANENT SLETTET FOR ALLTID!!! ER DU ENIG?',

    'menu.invite': 'Inviter venner',

    'menu.plan': 'Abonnement',
    'menu.plan.title': 'iRecipt Premium-konto',
    'menu.plan.tab.header.1': 'Funksjoner',
    'menu.plan.tab.header.2': 'Basic',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Lagre kvitteringer',
    'menu.plan.tab.body.2': 'Dele kvitteringer',
    'menu.plan.tab.body.3': 'Lagre i skyen',
    'menu.plan.tab.body.4': 'Opprette mapper',
    'menu.plan.tab.body.5': 'Dele mapper',
    'menu.plan.tab.body.6': 'Chatte med andre',
    'menu.plan.tab.body.7': 'Ubegrenset lagringsplass',

    'menu.profile.plan.title': 'GO PREMIUM',
    'menu.profile.plan.description':
      'Lagre og del kvitteringene dine, opprett mapper og del mapper som en PDF eller som en lenke til PDF',
    'menu.profile.plan.business': 'Virksomhet',
    'menu.profile.plan.argument.1.title': 'ubegrenset lagring',
    'menu.profile.plan.argument.1.description':
      'lagre ubegrenset antall kvitteringer og få tilgang til dem fra hvilken som helst enhet',
    'menu.profile.plan.argument.2.title': 'opptil ',
    'menu.profile.plan.argument.2.title-2': ' kontoer',
    'menu.profile.plan.argument.2.description':
      'lage separate kontoer for hjemme - arbeid - virksomhet',
    'menu.profile.plan.subtitle.1': 'Gratis prøveversjon opptil ',
    'menu.profile.plan.subtitle.2': ' kvitteringer',
    'menu.profile.plan.proposals.1.period': 'Månedlig',
    'menu.profile.plan.proposals.1.per-period': 'per måned',
    'menu.profile.plan.proposals.2.period': 'Årlig',
    'menu.profile.plan.proposals.2.per-period': 'per år',
    'menu.profile.plan.proposals.best-value': 'beste verdi',
    'menu.profile.plan.proposals.info-1': 'Det er bare ',
    'menu.profile.plan.proposals.info-2': ' i måneden',
    'menu.profile.plan.soon':
      'Flere funksjoner kommer snart for våre premiumbrukere',
    'menu.profile.plan.restore': 'Gjenopprett kjøpt',
    'menu.profile.plan.bottom-info':
      'Betalingen belastes Apple-kontoen ved bekreftelse av kjøpet. Abonnementet fornyes automatisk med mindre automatisk fornyelse er slått av minst 24 timer før utløpet av inneværende periode. Konto belastes for fornyelse innen 24 timer før utløpet av inneværende periode, og identifiserer kostnadene for fornyelsen. Abonnementer kan administreres av brukeren, og automatisk fornyelse kan slås av ved å gå til brukerens kontoinnstillinger etter kjøpet.',
    'menu.profile.plan.coming.soon': 'Kommer snart...',
    'menu.profile.plan.coming.soon.alert':
      "Så snart 'Business' abonnementsplanen blir tilgjengelig, vil du motta et push-varsel. Vennligst sørg for at varslingene dine er slått på, så du ikke går glipp av denne viktige oppdateringen",
    'menu.profile.plan.notify': 'Gi meg beskjed først',

    'menu.profile.permission.viewing': 'visningsrettigheter',
    'menu.profile.permission.editing': 'redigeringsrettigheter',
    'menu.profile.permission.deletion': 'slettingsrettigheter',
    'menu.profile.permission.admin.view.info':
      'Tildeling av administratorrettigheter kan gis deltakergrupper over 18 år. En bruker med denne tillatelsen vil kunne se kvitteringer og fakturaer',
    'menu.profile.permission.admin.edit.info':
      'Tildeling av administratorrettigheter kan gis deltakergrupper over 18 år. En bruker med denne tillatelsen vil kunne redigere kvitteringer og fakturaer',
    'menu.profile.permission.admin.delete.info':
      'Tildeling av administratorrettigheter kan gis deltakergrupper over 18 år. En bruker med denne tillatelsen vil kunne slette kvitteringer og fakturaer',

    'menu.free': 'Gratis',
    'menu.monthly': 'Månedlig',
    'menu.yearly': 'Årlig',

    'menu.about': 'Om-side',
    'menu.lang': 'Språk',
    'menu.theme': 'Søknadstema',
    'menu.theme.title': 'Velg paletten',
    'menu.theme.subtitle': 'Nyt applikasjonen med favorittfargene dine',
    'menu.lang.choose': 'Velg språk:',
    'menu.lang.choose2': 'Velg gjenkjenningsspråk:',
    'menu.lang.ru': 'Russisk',
    'menu.lang.uk': 'Ukrainsk',
    'menu.lang.en': 'Engelsk',
    'menu.lang.no': 'Norsk',
    'menu.lang.pt': 'Portugisisk',
    'menu.lang.pl': 'Pusse',
    'menu.lang.fi': 'Finsk',
    'menu.lang.se': 'Svensk',
    'menu.lang.it': 'Italiensk',
    'menu.lang.fr': 'Fransk',
    'menu.lang.es': 'Spansk',
    'menu.lang.de': 'Tysk',
    'menu.lang.da': 'Dansk',
    'menu.account': 'Bytt konto',
    'menu.account.add': 'Legg til konto',
    'menu.logout': 'Logg ut',
    'menu.logout.alternatives': 'Alternative alternativer',
    'menu.logout.modal.title': 'Sikker på at du vil logge ut?',

    'menu.support': 'Kontakt for å støtte',
    'menu.password': 'Endre passord',
    'menu.email': 'E-post',

    'menu.phone': 'Telefonnummer',

    'menu/settings': 'Innstillinger',
    'menu/settings/profile': 'Rediger profil',
    'menu/settings/invite': 'Inviter Venn',
    'menu/settings/plan': 'Abonnementsplan',
    'menu/settings/password': 'Passord',
    'menu/settings/email': 'E-post',
    'menu/settings/phone': 'Telefon',
    'menu/settings/language': 'Språk',
    'menu/settings/quality': 'Mottakskvalitet',
    'menu/settings/about': 'Om',
    'menu/settings/account': 'Endre konto',

    'menu.settings.account.free': 'Gratis Konto',

    'menu.settings.account.business': 'Virksomhetskonto',
    'menu.settings.account.add': 'Legg til konto',

    'menu.plan.personal.storage-title': 'Lagring - opptil 10 GB',
    'menu.plan.personal.storage-description':
      'Lagre kvitteringer og få tilgang til dem fra hvilken som helst enhet',
    'menu.plan.family.storage-title': 'Lagring - opptil 20 GB',
    'menu.plan.family.storage-description':
      'Lagre kvitteringer og få tilgang til dem fra hvilken som helst enhet',

    'recognize.categoryName': 'gjenkjenne navn',
    'recognize.categoryAmount': 'gjenkjenne beløp',

    saveTo: 'Lagre til..',
    'save.new': 'Lagre og nytt',
    'save.close': 'Lagre og lukk',

    'all.profiles': 'Alle profiler',
    'style.rotate': 'Roter',
    'style.correction': 'Korreksjon',
    'style.color': 'Farge',
    'style.intensity': 'Intensitet',
    'style.style': 'Lysstyrke',
    'style.contrast': 'Kontrast',
    'move.to.receipts': 'Flytt til kvitteringer',
    'move.to.receipts.modal.title':
      'Denne fakturaen vil bli flyttet til kvitteringer',
    'move.to.invoices': 'Flytt til fakturaer',
    'move.to.invoices.modal.title':
      'Denne kvitteringen vil bli flyttet til fakturaer',
    'create.new.folder': 'Opprett en ny mappe',
    'share.modal.web.copy': 'Kopier lenke til utklippstavlen',
    'app.loading': 'Laster',
    'receipt.quality': 'fakturakvalitet',

    'this.month': 'This month',
    'last.month': 'Denne måneden',
    'notifications.tryOpenReceiptLater': 'Prøv å åpne kvitteringen senere',

    select: 'Plukke ut',

    recognition: 'Anerkjennelse',
    'recognition.none': 'Ingen',
    'recognition.fast': 'Rask',
    'recognition.accurate': 'Korrekt',
  },
  pt: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Teste gratuito de 3 dias',
    'app.buy': 'Assinar',
    'menu.profile.plan.personal': 'Mais',
    'menu.profile.plan.argument.2.personal.description':
      'crie sua conta mais e tenha controle sobre seus recibos e faturas',
    'menu.settings.account.personal': 'Conta Mais',
    'menu.profile.plan.argument.2.family.description':
      'crie sua conta premium e faça contas separadas para família e trabalho',
    'menu.settings.account.family': 'Conta Premium',
    'app.privacyPolicy': 'Política de Privacidade',
    'app.licenseUserAgreement': 'Contrato de usuário de licença',
    'app.gdpr': 'O Regulamento Geral de Proteção de Dados (RGPD)',
    'document.name': 'Nome do Documento',
    unfilledDocument: 'documento não preenchido',
    'categoryList.no-documents': 'Ainda não há documentos',
    'new.document': 'Novo Documento',
    'auth.h1': 'Bem-vindo(a)',
    'auth.p': 'Bem-vindo(a) à sua carteira de recibos e faturas',
    'auth.social': 'Ou por meio das mídias sociais',
    'login.h1': 'Bem-vindo(a) de volta!',
    'login.email': 'Endereço de e-mail',
    'login.pass': 'Senha',
    'login.forgot': 'Esqueci a senha',
    'login.otp': 'Login com senha de uso único',
    'login.footer': 'Ainda não tem uma conta?',
    'login.footer.up': 'Cadastrar-se',
    'signUp.h1': 'Cadastrar-se',
    'signUp.email': 'Seu e-mail',
    'signUp.pass': 'Senha',
    'signUp.success': 'Sucesso: verifique o e-mail para confirmar a nova conta',
    'signUp.footer': 'Já tem uma conta?',
    'signUp.footer.in': 'Entrar',
    'forgot.h1': 'Esqueceu sua senha?',
    'forgot.p': 'Digite o endereço de e-mail associado a esta conta',
    'forgot.success': 'Sucesso: verifique o e-mail para redefinir a senha',
    'forgot.email': 'Digite o seu endereço de email',
    'reset.h1': 'Nova Senha',
    'reset.p': 'Digite a nova senha',
    'reset.success': 'Sucesso: verifique o e-mail para redefinir a senha',
    'reset.pass': 'Senha',
    'reset.confirm': 'Confirme a senha',
    'otp.h1': 'Login com senha de uso único',
    'otp.p': 'Você receberá uma senha de uso único no seu celular',
    'verify.h1': 'Verificação',
    'verify.p': 'Digite o número de 4 dígitos que foi enviado para',
    'verify.receive': 'Não recebeu?',
    'verify.reSend': 'Reenviar em ',
    'mailConfirm.text': 'Algo deu errado:',
    'mailConfirm.success': 'Sucesso: E-mail confirmado',
    'mailConfirm.goTo': 'Ir para o aplicativo',
    'test.title': 'Qualidade personalizada',
    'test.quality': 'Qualidade superior',
    'test.recognition': 'Reconhecimento mais rápido',
    'test.submit': 'definir',
    'btn.login': 'Fazer login',
    'btn.continue': 'Continuar',
    'btn.sign': 'Cadastrar-se',
    'btn.submit': 'Enviar',
    'btn.verify': 'Verificar agora',
    'sort.store': 'Loja',
    'sort.madeBy': 'Criado por',
    'sort.amount': 'Valor',
    'sort.date': 'Data',
    'breadcrumbs.no.folders': 'Ainda não há pastas aqui',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    create: 'Criar',
    next: 'Avançar',
    back: 'Voltar',
    save: 'Salvar',
    apply: 'Aplicar',
    name: 'Nome',
    amount: 'Valor',
    from: 'De:',
    to: 'Para:',
    receipts: 'Recibos',
    invoices: 'Faturas',
    'new.folder': 'Nova pasta',
    folder: 'Pasta:',
    home: 'Início',
    profile: 'Perfil',
    'new.receipt': 'Novo recibo',
    'new.invoice': 'Nova fatura',
    'receipt.name': 'Nome do recibo',
    'toast.share': 'Compartilhar o link copiado na área de transferência',
    'modal.share': 'Escolha o formato do link de compartilhamento',
    'modal.share.link': 'Link',
    'modal.share.pdf': 'Arquivo',
    'modal.share.download': 'Baixar',
    'preview.name': 'Nome:',
    'preview.amount': 'Valor:',
    'preview.date': 'Data:',
    'preview.created': 'Criado por:',
    'preview.edit': 'Editar',
    'preview.share': 'Compartilhar',
    'preview.delete': 'Excluir',
    'preview.close': 'Fechar',
    'preview.print': 'Imprimir',
    'categoryList.dont.1': 'na Categoria',
    'categoryList.dont.2': ' você ainda não tem recibos e categorias',
    'filter.original': 'Original',
    'filter.bw': 'Preto e branco',
    'filter.grey': 'Escala de cinza',
    unfilledReceipt: 'Recibo não preenchido',
    unfilledInvoice: 'Fatura não preenchida',
    tree: 'Árvore de categorias',
    CreateNewFolder: 'Criar Nova Pasta',
    alert: 'Tem certeza de que deseja excluir?',
    'set.date': 'definir data',
    'move.home': 'Mover para a pasta inicial',
    'move.parent': 'Mover para a pasta principal',
    'add.item.additional.info-1': 'Você pode ter no máximo ',
    'add.item.additional.info-2': ' contas',
    'menu.profile': 'Editar informações',
    'menu.profile.manage': 'Gerenciar',
    'menu.quality': 'Qualidade do recibo',
    'menu.recognition': 'Reconhecimento de recibo',
    'menu.terms': 'Termos e сondições',
    'menu.profile.info.currency': 'Moeda:',
    'menu.profile.enter-promo': 'Insira o código promocional',
    'menu.profile.redeem-promo': 'Resgatar código promocional',
    'menu.profile.redeem': 'Resgatar',

    'menu.profile.photo': 'Alterar foto do perfil',
    'menu.profile.change.data': 'Você pode modificar esse dado quando quiser',
    'menu.profile.delete.account': 'Excluir conta',
    'menu.profile.delete.profile': 'Excluir perfil',
    'menu.profile.your.mail': 'Seu e-mail:',
    'menu.profile.add.mail': 'Adicionar e-mail',
    'menu.profile.new.mail': 'Novo e-mail',
    'menu.profile.new.account': 'Nova conta',
    'menu.profile.account.empty': 'Adicionar conta',
    'menu.profile.invite': 'Convide ',
    'menu.profile.5premium': '5 amigos ',
    'menu.profile.friends': 'PREMIUM ',
    'menu.profile.have.yours': 'e ganhe uma conta ',
    'menu.profile.free': 'GRÁTIS',
    'menu.profile.invite.mail': 'Convidar amigos por e-mail',
    'menu.profile.invite.sms': 'Convidar amigos por SMS',
    'menu.profile.invite.share': 'Envie um link para os seus amigos',
    'menu.profile.invite.gift': 'Presenteie um amigo',
    'menu.profile.password.current': 'Senha atual',
    'menu.profile.password.new': 'Nova senha',
    'menu.profile.password.again': 'Digite a nova senha novamente',
    'menu.profile.password.forgot': 'Esqueceu sua senha?',
    'menu.profile.phone.your': 'Seu número de telefone:',
    'menu.profile.phone.add': 'Adicionar número de telefone',
    'menu.profile.phone.new': 'Novo telefone',
    'menu.profile.first': 'Primeiro nome',
    'menu.profile.last': 'Sobrenome',
    'menu.profile.date': 'Data de nascimento',
    'menu.profile.gender': 'Gênero',
    'menu.profile.currency': 'Moeda',
    'menu.profile.male': 'Masculino',
    'menu.profile.female': 'Feminino',
    'menu.profile.remove.confirm1':
      'Tem certeza de que deseja excluir sua conta com todos os dados?',
    'menu.profile.remove.confirm2':
      'TODOS OS DADOS DA SUA CONTA, CONFIGURAÇÕES, DOCUMENTOS CARREGADOS, CATEGORIAS SERÃO EXCLUÍDOS PERMANENTEMENTE PARA SEMPRE!!! VOCÊ CONCORDA?',
    'menu.invite': 'Convidar amigo',
    'menu.plan': 'Plano de assinatura',
    'menu.plan.title': 'Conta Premium do iReceipt',
    'menu.plan.tab.header.1': 'Recursos',
    'menu.plan.tab.header.2': 'Básico',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Salvar recibos',
    'menu.plan.tab.body.2': 'Compartilhar recibos',
    'menu.plan.tab.body.3': 'Armazenamento em nuvem',
    'menu.plan.tab.body.4': 'Criar pastas',
    'menu.plan.tab.body.5': 'Compartilhar pastas',
    'menu.plan.tab.body.6': 'Conversar com outras pessoas',
    'menu.plan.tab.body.7': 'Armazenamento ilimitado',
    'menu.profile.plan.title': 'TORNE-SE PREMIUM',
    'menu.profile.plan.description':
      'Salve e compartilhe os seus recibos, crie e compartilhe pastas no formato PDF ou como um link para PDF',
    'menu.profile.plan.business': 'Empresarial',
    'menu.profile.plan.argument.1.title': 'armazenamento ilimitado',
    'menu.profile.plan.argument.1.description':
      'salve um número ilimitado de recibos e acesse-os em qualquer dispositivo',
    'menu.profile.plan.argument.2.title': 'até ',
    'menu.profile.plan.argument.2.title-2': ' contas',
    'menu.profile.plan.argument.2.business.description':
      'crie sua conta empresarial e faça contas separadas para membros da empresa e trabalho',
    'menu.profile.plan.subtitle.1': 'Avaliação grátis com até ',
    'menu.profile.plan.subtitle.2': ' recibos',
    'menu.profile.plan.proposals.1.period': 'Mensal',
    'menu.profile.plan.proposals.1.per-period': 'por mês',
    'menu.profile.plan.proposals.2.period': 'Anual',
    'menu.profile.plan.proposals.2.per-period': 'por ano',
    'menu.profile.plan.proposals.best-value': 'melhor oferta',
    'menu.profile.plan.proposals.info-1': 'Apenas ',
    'menu.profile.plan.proposals.info-2': ' por mês',
    'menu.profile.plan.soon':
      'Em breve, mais recursos para os nossos usuários Premium',
    'menu.profile.plan.restore': 'Restaurar compra',
    'menu.profile.plan.bottom-info':
      'O pagamento será cobrado na conta do Apple ao confirmar a compra. A assinatura será renovada automaticamente, a não ser que a renovação automática seja desativada pelo menos 24 horas antes do término do período atual. A renovação será cobrada na conta 24 horas antes do término do período atual e o custo da renovação será especificado. As assinaturas podem ser gerenciadas pelo usuário. Após efetuar a compra, é possível desativar a renovação automática ao acessar as configurações da conta do usuário.',
    'menu.profile.plan.coming.soon': 'Em breve...',
    'menu.profile.plan.coming.soon.alert':
      "Assim que o plano de assinatura 'Negócios' estiver disponível, você receberá uma notificação push. Certifique-se de que suas notificações estão ativadas para não perder esta importante atualização",
    'menu.profile.plan.notify': 'Avise-me primeiro',
    'menu.profile.permission.viewing': 'Direitos de visualização',
    'menu.profile.permission.editing': 'Direitos de edição',
    'menu.profile.permission.deletion': 'Direitos de exclusão',
    'menu.profile.permission.admin.view.info':
      'A concessão de privilégios de administrador é permitida a grupos de participantes com mais de 18 anos. O usuário que tiver essa permissão poderá visualizar recibos e faturas',
    'menu.profile.permission.admin.edit.info':
      'A concessão de privilégios de administrador é permitida a grupos de participantes com mais de 18 anos. O usuário que tiver essa permissão poderá editar recibos e faturas',
    'menu.profile.permission.admin.delete.info':
      'A concessão de privilégios de administrador é permitida a grupos de participantes com mais de 18 anos. O usuário que tiver essa permissão poderá excluir recibos e faturas',
    'menu.free': 'Grátis',
    'menu.monthly': 'mensal',
    'menu.yearly': 'anual',
    'menu.about': 'Sobre',
    'menu.lang': 'Idioma',
    'menu.theme': 'Tema do aplicativo',
    'menu.theme.title': 'Escolha a paleta',
    'menu.theme.subtitle': 'Aproveite o aplicativo com suas cores favoritas',
    'menu.lang.choose': 'Escolha o idioma:',
    'menu.lang.choose2': 'Escolha reconhecimento automático do idioma:',
    'menu.lang.ru': 'Russo',
    'menu.lang.uk': 'Ucraniano',
    'menu.lang.en': 'Inglês',
    'menu.lang.no': 'Norueguês',
    'menu.lang.pt': 'Português',
    'menu.lang.pl': 'Polonês',
    'menu.lang.fi': 'Finlandês',
    'menu.lang.se': 'Sueco',
    'menu.lang.it': 'Italiano',
    'menu.lang.fr': 'Francês',
    'menu.lang.es': 'Espanhol',
    'menu.lang.de': 'Alemão',
    'menu.lang.da': 'Dinamarquês',
    'menu.account': 'Mudar de conta',
    'menu.account.add': 'Compartilhar conta',
    'menu.account.notHave': 'você não tem contas compartilhadas',
    'menu.logout': 'Sair',
    'menu.logout.alternatives': 'Opções alternativas',
    'menu.logout.modal.title': 'Tem certeza de que deseja sair?',
    'menu.support': 'Falar com a equipe de suporte',
    'menu.password': 'Alterar senha',
    'menu.email': 'E-mail',
    'menu.phone': 'Número de telefone',
    'menu/settings': 'Configurações',
    'menu/settings/profile': 'Editar perfil',
    'menu/settings/invite': 'Convidar amigo',
    'menu/settings/plan': 'Plano de assinatura',
    'menu/settings/password': 'Senha',
    'menu/settings/email': 'E-mail',
    'menu/settings/phone': 'Telefone',
    'menu/settings/language': 'Idioma',
    'menu/settings/quality': 'Qualidade do recibo',
    'menu/settings/about': 'Sobre',
    'menu/settings/account': 'Mudar de conta',
    'menu.settings.account.free': 'Conta Gratis',
    'menu.settings.account.business': 'Conta empresarial',
    'menu.settings.account.add': 'Adicionar conta',

    'menu.plan.personal.storage-title': 'Armazenamento - até 10 GB',
    'menu.plan.personal.storage-description':
      'Salve recibos e acesse-os de qualquer dispositivo',
    'menu.plan.family.storage-title': 'Armazenamento - até 20 GB',
    'menu.plan.family.storage-description':
      'Salve recibos e acesse-os de qualquer dispositivo',

    'recognize.categoryName': 'identificando o nome',
    'recognize.categoryAmount': 'identificando o valor',
    saveTo: 'Salvar em..',
    'save.new': 'Salvar e abrir um novo',
    'save.close': 'Salvar e fechar',

    'all.profiles': 'Todos os perfis',
    'style.rotate': 'Rodar',
    'style.correction': 'Correção',
    'style.color': 'Cor',
    'style.intensity': 'Intensidade',
    'style.style': 'Luminosidade',
    'style.contrast': 'Contraste',
    'move.to.receipts': 'Mover para recibos',
    'move.to.receipts.modal.title': 'Esta fatura será movida para recibos',
    'move.to.invoices': 'Mover para faturas',
    'move.to.invoices.modal.title': 'Este recibo será movido para faturas',
    'create.new.folder': 'Criar uma nova pasta',
    'share.modal.web.copy': 'Copiar a ligação para a área de transferência',
    'app.loading': 'A carregar',
    'receipt.quality': 'qualidade do recibo',

    'this.month': 'Este mês',
    'last.month': 'Mês passado',
    'notifications.tryOpenReceiptLater': 'Tente abrir o recibo mais tarde',

    select: 'Selecionar',

    recognition: 'Reconhecimento',
    'recognition.none': 'Nenhum',
    'recognition.fast': 'Rápido',
    'recognition.accurate': 'Preciso',
  },
  pl: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Darmowa wersja próbna przez 3 dni',
    'app.buy': 'Subskrybuj',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'utwórz swoje konto plus i kontroluj swoje rachunki i faktury',
    'menu.settings.account.personal': 'Konto Plus',
    'menu.profile.plan.argument.2.family.description':
      'utwórz swoje konto premium i stwórz osobne konta dla rodziny i pracy',
    'menu.settings.account.family': 'Konto Premium',
    'app.privacyPolicy': 'Polityka prywatności',
    'app.licenseUserAgreement': 'Umowa licencyjna użytkownika',
    'app.gdpr': 'Rozporządzenie o Ochronie Danych Osobowych (RODO)',
    'document.name': 'Nazwa dokumentu',
    unfilledDocument: 'Niewypełniony dokument',
    'categoryList.no-documents': 'Brak dokumentów',
    'new.document': 'Nowy dokument',
    'auth.h1': 'Witamy!',
    'auth.p': 'Witamy w Twoim osobistym organizerze rachunków i faktur',
    'auth.social': 'Lub za pośrednictwem mediów społecznościowych',
    'login.h1': 'Witamy ponownie!',
    'login.email': 'Adres e-mail',
    'login.pass': 'Hasło',
    'login.forgot': 'Nie pamiętasz hasła?',
    'login.otp': 'Zaloguj się za pomocą hasła jednorazowego',
    'login.footer': 'Nie masz konta?',
    'login.footer.up': 'Zarejestruj się',
    'signUp.h1': 'Zarejestruj się',
    'signUp.email': 'Twój adres e-mail',
    'signUp.pass': 'Hasło',
    'signUp.success':
      'Operacja powiodła się. Sprawdź pocztę e-mail, aby potwierdzić nowe konto',
    'signUp.footer': 'Posiadasz już konto?',
    'signUp.footer.in': 'Zaloguj się',
    'forgot.h1': 'Nie pamiętasz hasła?',
    'forgot.p': 'Wprowadź adres e-mail powiązany z tym kontem',
    'forgot.success':
      'Operacja powiodła się. Sprawdź pocztę e-mail, aby zresetować hasło',
    'forgot.email': 'Wprowadź swój adres e-mail',
    'reset.h1': 'Nowe hasło',
    'reset.p': 'Wprowadź nowe hasło',
    'reset.success':
      'Operacja powiodła się. Sprawdź pocztę e-mail, aby zresetować hasło',
    'reset.pass': 'Hasło',
    'reset.confirm': 'Potwierdź hasło',
    'otp.h1': 'Zaloguj się za pomocą hasła jednorazowego',
    'otp.p': 'Wyślemy hasło jednorazowe na Twój numer telefonu komórkowego',
    'verify.h1': 'Weryfikacja',
    'verify.p': 'Wprowadź 4-cyfrowy numer wysłany do',
    'verify.receive': 'Nie dotarł?',
    'verify.reSend': 'Wyślij ponownie ',
    'mailConfirm.text': 'Coś poszło nie tak:',
    'mailConfirm.success': 'Operacja powiodła się. Adres e-mail potwierdzony',
    'mailConfirm.goTo': 'Przejdź do aplikacji',
    'test.title': 'Ustaw jakość',
    'test.quality': 'Lepsza jakość',
    'test.recognition': 'Szybsze rozpoznawanie',
    'test.submit': 'ustaw',
    'btn.login': 'Zaloguj się',
    'btn.continue': 'Kontynuuj',
    'btn.sign': 'Zarejestruj się',
    'btn.submit': 'Prześlij',
    'btn.verify': 'Zweryfikuj teraz',
    'sort.store': 'Sklep',
    'sort.madeBy': 'Wykonanie:',
    'sort.amount': 'Kwota',
    'sort.date': 'Data',
    'breadcrumbs.no.folders': 'Nie ma jeszcze folderów',
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    create: 'Utwórz',
    next: 'Dalej',
    back: 'Wstecz',
    save: 'Zapisz',
    apply: 'Zastosuj',
    name: 'Nazwa',
    amount: 'Kwota',
    from: 'Od:',
    to: 'Do:',
    receipts: 'Paragony',
    invoices: 'Faktury',
    'new.folder': 'Nowy folder',
    folder: 'Folder:',
    home: 'Strona główna',
    profile: 'Profil',
    'new.receipt': 'Nowy paragon',
    'new.invoice': 'Nowa faktura',
    'receipt.name': 'Nazwa paragonu',
    'toast.share': 'Udostępnij link skopiowany do schowka',
    'modal.share': 'Wybierz format udostępnianego linku',
    'modal.share.link': 'Link',
    'modal.share.pdf': 'Plik',
    'modal.share.download': 'Pobierz',
    'preview.name': 'Nazwa:',
    'preview.amount': 'Kwota:',
    'preview.date': 'Data:',
    'preview.created': 'Utworzenie:',
    'preview.edit': 'Edytuj',
    'preview.share': 'Udostępnij',
    'preview.delete': 'Usuń',
    'preview.close': 'Zamknij',
    'preview.print': 'Drukuj',
    'categoryList.dont.1': 'w Kategorii',
    'categoryList.dont.2': ' nie masz jeszcze paragonów i kategorii',
    'filter.original': 'Oryginał',
    'filter.bw': 'Czarno-biały',
    'filter.grey': 'Skala szarości',
    unfilledReceipt: 'Niewypełniony paragon',
    unfilledInvoice: 'Niewypełniona faktura',
    tree: 'Drzewo kategorii',
    CreateNewFolder: 'Utwórz nowy folder',
    alert: 'Czy na pewno chcesz usunąć?',
    'set.date': 'ustaw datę',
    'move.home': 'Przenieś do folderu głównego',
    'move.parent': 'Przenieś do folderu nadrzędnego',
    'add.item.additional.info-1': 'Możesz mieć maksymalnie ',
    'add.item.additional.info-2': ' kont(a)',
    'menu.profile': 'Edytuj informacje',
    'menu.profile.manage': 'Zarządzaj',
    'menu.quality': 'Jakość paragonu',
    'menu.recognition': 'Rozpoznawanie paragonu',
    'menu.terms': 'Regulamin',
    'menu.profile.info.currency': 'Waluta:',
    'menu.profile.enter-promo': 'Wprowadź kod promocyjny',
    'menu.profile.redeem-promo': 'Zrealiz',
    'menu.profile.redeem': 'Zrealizuj',

    'menu.profile.photo': 'Zmień zdjęcie profilowe',
    'menu.profile.change.data': 'W każdej chwili możesz zmienić te dane',
    'menu.profile.delete.account': 'Usuń konto',
    'menu.profile.delete.profile': 'Usuń profil',
    'menu.profile.your.mail': 'Twój adres e-mail:',
    'menu.profile.add.mail': 'Dodaj adres e-mail',
    'menu.profile.new.mail': 'Nowy adres e-mail',
    'menu.profile.new.account': 'Nowe konto',
    'menu.profile.account.empty': 'Dodaj konto',
    'menu.profile.invite': 'Zaproś ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'znajomi ',
    'menu.profile.have.yours': 'i otrzymaj swoje ',
    'menu.profile.free': 'BEZPŁATNIE',
    'menu.profile.invite.mail': 'Zaproś znajomych e-mailem',
    'menu.profile.invite.sms': 'Zaproś znajomych SMS-em',
    'menu.profile.invite.share': 'Wyślij link znajomym',
    'menu.profile.invite.gift': 'Podaruj znajomemu',
    'menu.profile.password.current': 'Aktualne hasło',
    'menu.profile.password.new': 'Nowe hasło',
    'menu.profile.password.again': 'Wprowadź ponownie nowe hasło',
    'menu.profile.password.forgot': 'Nie pamiętasz hasła?',
    'menu.profile.phone.your': 'Twój numer telefonu:',
    'menu.profile.phone.add': 'Dodaj numer telefonu',
    'menu.profile.phone.new': 'Nowy telefon',
    'menu.profile.first': 'Imię',
    'menu.profile.last': 'Nazwisko',
    'menu.profile.date': 'Data urodzenia',
    'menu.profile.gender': 'Płeć',
    'menu.profile.currency': 'Waluta',
    'menu.profile.male': 'Mężczyzna',
    'menu.profile.female': 'Kobieta',
    'menu.profile.remove.confirm1':
      'Czy na pewno chcesz usunąć swoje konto ze wszystkimi danymi?',
    'menu.profile.remove.confirm2':
      'WSZYSTKIE DANE TWOJEGO KONTA, USTAWIENIA, WYSŁANE DOKUMENTY, KATEGORIE ZOSTANĄ TRWALE USUNIĘTE NA ZAWSZE!!! CZY SIE ZGADZASZ?',
    'menu.invite': 'Zaproś znajomego',
    'menu.plan': 'Plan abonamentowy',
    'menu.plan.title': 'Konto Premium iReceipt',
    'menu.plan.tab.header.1': 'Funkcje',
    'menu.plan.tab.header.2': 'Podstawowy',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Zapisz paragony',
    'menu.plan.tab.body.2': 'Udostępnij paragony',
    'menu.plan.tab.body.3': 'Przechowywanie w chmurze',
    'menu.plan.tab.body.4': 'Utwórz foldery',
    'menu.plan.tab.body.5': 'Udostępnij foldery',
    'menu.plan.tab.body.6': 'Czatuj z innymi',
    'menu.plan.tab.body.7': 'Nieograniczone przechowywanie',
    'menu.profile.plan.title': 'PRZEJDŹ NA PREMIUM',
    'menu.profile.plan.description':
      'Zapisuj i udostępniaj paragony, twórz foldery i udostępniaj foldery jako PDF lub jako link do PDF',

    'menu.profile.plan.business': 'Firmowe',
    'menu.profile.plan.argument.1.title': 'nieograniczone przechowywanie',
    'menu.profile.plan.argument.1.description':
      'zapisuj nieograniczoną liczbę paragonów, dostępnych potem z dowolnego urządzenia',
    'menu.profile.plan.argument.2.title': 'do ',
    'menu.profile.plan.argument.2.title-2': ' kont(a)',

    'menu.profile.plan.argument.2.business.description':
      'utwórz konto firmowe i stwórz oddzielne konta dla członków firmy i pracy',
    'menu.profile.plan.subtitle.1': 'Bezpłatna wersja próbna do ',
    'menu.profile.plan.subtitle.2': ' paragonów',
    'menu.profile.plan.proposals.1.period': 'Miesięcznie',
    'menu.profile.plan.proposals.1.per-period': 'na miesiąc',
    'menu.profile.plan.proposals.2.period': 'Rocznie',
    'menu.profile.plan.proposals.2.per-period': 'na rok',
    'menu.profile.plan.proposals.best-value': 'najlepsza wartość',
    'menu.profile.plan.proposals.info-1': 'To tylko ',
    'menu.profile.plan.proposals.info-2': ' miesięcznie',
    'menu.profile.plan.soon':
      'Więcej funkcji już wkrótce dla użytkowników wersji Premium',
    'menu.profile.plan.restore': 'Przywróć Zakupione',
    'menu.profile.plan.bottom-info':
      'Płatność zostanie pobrana z konta Apple w momencie potwierdzenia zakupu. Abonament odnawia się automatycznie, chyba że automatyczne odnawianie zostanie wyłączone co najmniej 24 godziny przed końcem bieżącego okresu. Konto zostanie obciążone opłatą za odnowienie w okresie 24 godzin przed końcem bieżącego okresu. Użytkownik zostanie poinformowany o koszcie odnowienia. Użytkownik może samodzielnie zarządzać swoimi abonamentami. Może też wyłączyć automatyczne odnawianie przechodząc po zakupie do Ustawień konta użytkownika.',
    'menu.profile.plan.coming.soon': 'Już wkrótce…',
    'menu.profile.plan.coming.soon.alert':
      "Gdy tylko plan subskrypcji 'Biznes' stanie się dostępny, otrzymasz powiadomienie push. Upewnij się, że powiadomienia są włączone, aby nie przegapić tej ważnej aktualizacji",
    'menu.profile.plan.notify': 'Najpierw mnie powiadom',
    'menu.profile.permission.viewing': 'Uprawnienia do wyświetlania',
    'menu.profile.permission.editing': 'Uprawnienia do edycji',
    'menu.profile.permission.deletion': 'Uprawnienia do usuwania',
    'menu.profile.permission.admin.view.info':
      'Uprawnienia administratora można nadawać grupom uczestników powyżej 18 roku życia. Użytkownik z tym uprawnieniem będzie mógł wyświetlać paragony i faktury',
    'menu.profile.permission.admin.edit.info':
      'Uprawnienia administratora można nadawać grupom uczestników powyżej 18 roku życia. Użytkownik z tym uprawnieniem będzie mógł edytować paragony i faktury',
    'menu.profile.permission.admin.delete.info':
      'Uprawnienia administratora można nadawać grupom uczestników powyżej 18 roku życia. Użytkownik z tym uprawnieniem będzie mógł usuwać paragony i faktury',
    'menu.free': 'Bezpłatnie',
    'menu.monthly': 'miesięcznie',
    'menu.yearly': 'rocznie',
    'menu.about': 'O… ',
    'menu.lang': 'Język',
    'menu.theme': 'Motyw aplikacji',
    'menu.theme.title': 'Wybierz paletę',
    'menu.theme.subtitle': 'Ciesz się aplikacją w swoich ulubionych kolorach',
    'menu.lang.choose': 'Wybierz język:',
    'menu.lang.choose2': 'Wybierz język rozpoznawania:',
    'menu.lang.ru': 'Rosyjski',
    'menu.lang.uk': 'Ukraiński',
    'menu.lang.en': 'Angielski',
    'menu.lang.no': 'Norweski',
    'menu.lang.pt': 'Portugalski',
    'menu.lang.pl': 'Polskie',
    'menu.lang.fi': 'Fiński',
    'menu.lang.se': 'Szwedzki',
    'menu.lang.it': 'Włoski',
    'menu.lang.fr': 'Francuski',
    'menu.lang.es': 'Hiszpański',
    'menu.lang.de': 'Niemiecki',
    'menu.lang.da': 'Duński',
    'menu.account': 'Zmień konto',
    'menu.account.add': 'Udostępnij konto',
    'menu.account.notHave': 'nie masz współdzielonych kont',
    'menu.logout': 'Wyloguj się',
    'menu.logout.alternatives': 'Opcje alternatywne',
    'menu.logout.modal.title': 'Czy na pewno chcesz się wylogować?',
    'menu.support': 'Kontakt z pomocą techniczną',
    'menu.password': 'Zmień hasło',
    'menu.email': 'Adres e-mail',
    'menu.phone': 'Numer telefonu',
    'menu/settings': 'Ustawienia',
    'menu/settings/profile': 'Edytuj profil',
    'menu/settings/invite': 'Zaproś znajomego',
    'menu/settings/plan': 'Plan abonamentowy',
    'menu/settings/password': 'Hasło',
    'menu/settings/email': 'Adres e-mail',
    'menu/settings/phone': 'Telefon',
    'menu/settings/language': 'Język',
    'menu/settings/quality': 'Jakość paragonu',
    'menu/settings/about': 'O… ',
    'menu/settings/account': 'Zmień konto',
    'menu.settings.account.free': 'Darmowe Konto',
    'menu.settings.account.business': 'Konto firmowe',
    'menu.settings.account.add': 'Dodaj konto',

    'menu.plan.personal.storage-title': 'Przechowywanie - do 10 GB',
    'menu.plan.personal.storage-description':
      'Zapisuj paragony i uzyskuj do nich dostęp z dowolnego urządzenia',
    'menu.plan.family.storage-title': 'Przechowywanie - do 20 GB',
    'menu.plan.family.storage-description':
      'Zapisuj paragony i uzyskuj do nich dostęp z dowolnego urządzenia',

    'recognize.categoryName': 'rozpoznawanie nazwy',
    'recognize.categoryAmount': 'rozpoznawanie kwoty',
    saveTo: 'Zapisz w..',
    'save.new': 'Zapisz i Nowy',
    'save.close': 'Zapisz i Zamknij',

    'all.profiles': 'Wszystkie profile',
    'style.rotate': 'Obróć',
    'style.correction': 'Korekcja',
    'style.color': 'Kolor',
    'style.intensity': 'Intensywność',
    'style.style': 'Jasność',
    'style.contrast': 'Kontrast',
    'move.to.receipts': 'Przenieś do paragonów',
    'move.to.receipts.modal.title':
      'Ta faktura zostanie przeniesiona do paragonów',
    'move.to.invoices': 'Przenieś do faktur',
    'move.to.invoices.modal.title':
      'Ten paragon zostanie przeniesiony do faktur',
    'create.new.folder': 'Utwórz nowy folder',
    'share.modal.web.copy': 'Skopiuj link do schowka',
    'app.loading': 'Ładowanie',
    'receipt.quality': 'Jakość dokumentu potwierdzającego transakcję',

    'this.month': 'Ten miesiąc',
    'last.month': 'Ostatni miesiąc',
    'notifications.tryOpenReceiptLater': 'Spróbuj otworzyć rachunek później',

    select: 'Wybierać',

    recognition: 'Uznanie',
    'recognition.none': 'Nic',
    'recognition.fast': 'Szybko',
    'recognition.accurate': 'Dokładny',
  },
  fi: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Ilmainen kokeilujakso 3 päivää',
    'app.buy': 'Tilaa',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'luo plus-tilisi ja hallitse kuitteja ja laskuja',
    'menu.settings.account.personal': 'Plus-tili',
    'menu.profile.plan.argument.2.family.description':
      'luo premium-tilisi ja tee erillisiä tilejä perheelle ja työlle',
    'menu.settings.account.family': 'Premium-tili',
    'app.privacyPolicy': 'Tietosuojakäytäntö',
    'app.licenseUserAgreement': 'Käyttöoikeussopimus',
    'app.gdpr': 'Yleinen tietosuoja-asetus (GDPR)',
    'document.name': 'Asiakirjan nimi',
    'categoryList.no-documents': 'Ei asiakirjoja vielä',
    'new.document': 'Uusi asiakirja',
    unfilledDocument: 'Täyttämätön asiakirja',
    'auth.h1': 'Tervetuloa',
    'auth.p': 'Tervetuloa kuittien ja laskujen kansioon',
    'auth.social': 'Tai sosiaalisen median kautta',
    'login.h1': 'Tervetuloa takaisin!',
    'login.email': 'Sähköpostiosoite',
    'login.pass': 'Salasana',
    'login.forgot': 'Unohtunut salasana',
    'login.otp': 'Kirjaudu sisään OTP:n kautta',
    'login.footer': 'Eikö sinulla ole tiliä?',
    'login.footer.up': 'Rekisteröidy',
    'signUp.h1': 'Rekisteröidy',
    'signUp.email': 'Sähköpostisi',
    'signUp.pass': 'Salasana',
    'signUp.success':
      'Onnistuminen: tarkista sähköposti vahvistaaksesi uuden tilin',
    'signUp.footer': 'Onko sinulla jo tili?',
    'signUp.footer.in': 'Kirjaudu sisään',
    'forgot.h1': 'Unohtuiko salasana?',
    'forgot.p': 'Syötä tähän tiliin liittyvä sähköpostiosoite',
    'forgot.success':
      'Onnistuminen: tarkista sähköposti nollataksesi salasanan',
    'forgot.email': 'Syötä sähköpostiosoitteesi',
    'reset.h1': 'Uusi salasana',
    'reset.p': 'Syötä uusi salasana',
    'reset.success': 'Onnistuminen: tarkista sähköposti nollataksesi salasanan',
    'reset.pass': 'Salasana',
    'reset.confirm': 'Vahvista salasana',
    'otp.h1': 'Kirjaudu sisään OTP:n kautta',
    'otp.p': 'Lähetämme OTP:n matkapuhelinnumeroosi',
    'verify.h1': 'Todentaminen',
    'verify.p': 'Syötä 4-numeroinen numero, joka lähetettiin',
    'verify.receive': 'Etkö saanut sitä?',
    'verify.reSend': 'Lähetä uudelleen ',
    'mailConfirm.text': 'Jotain meni pieleen:',
    'mailConfirm.success': 'Onnistuminen: Sähköposti vahvistettu',
    'mailConfirm.goTo': 'Siirry sovellukseen',
    'test.title': 'Aseta laatu',
    'test.quality': 'Parempi laatu',
    'test.recognition': 'Nopeampi tunnistus',
    'test.submit': 'aseta',
    'btn.login': 'Kirjaudu sisään',
    'btn.continue': 'Jatka',
    'btn.sign': 'Rekisteröidy',
    'btn.submit': 'Lähetä',
    'btn.verify': 'Vahvista nyt',
    'sort.store': 'Kauppa',
    'sort.madeBy': 'Tehnyt',
    'sort.amount': 'Määrä',
    'sort.date': 'Päivämäärä',
    'breadcrumbs.no.folders': 'Täällä ei ole vielä kansioita',
    cancel: 'Peruuta',
    confirm: 'Vahvista',
    create: 'Luo',
    next: 'Seuraava',
    back: 'Takaisin',
    save: 'Tallenna',
    apply: 'Käytä',
    name: 'Nimi',
    amount: 'Määrä',
    from: 'Lähettäjä:',
    to: 'Vastaanottaja:',
    receipts: 'Kuitit',
    invoices: 'Laskut',
    'new.folder': 'Uusi kansio',
    folder: 'Kansio:',
    home: 'Koti',
    profile: 'Profiili',
    'new.receipt': 'Uusi kuitti',
    'new.invoice': 'Uusi lasku',
    'receipt.name': 'Kuitin nimi',
    'toast.share': 'Jaa kopioitu linkki leikepöydälle',
    'modal.share': 'Valitse jaetun linkin muoto',
    'modal.share.link': 'Linkki',
    'modal.share.pdf': 'Tiedosto',
    'modal.share.download': 'Lataa',
    'preview.name': 'Nimi:',
    'preview.amount': 'Määrä:',
    'preview.date': 'Päivämäärä:',
    'preview.created': 'Luonut:',
    'preview.edit': 'Muokkaa',
    'preview.share': 'Jaa',
    'preview.delete': 'Poista',
    'preview.close': 'Sulje',
    'preview.print': 'Tulosta',
    'categoryList.dont.1': 'Kategoriassa',
    'categoryList.dont.2': ' sinulla ei ole vielä kuitteja tai luokkia',
    'filter.original': 'Alkuperäinen',
    'filter.bw': 'Mustavalkoinen',
    'filter.grey': 'Harmaasävy',
    unfilledReceipt: 'Täyttämätön kuitti',
    unfilledInvoice: 'Täyttämätön lasku',
    tree: 'Kategoriapuu',
    CreateNewFolder: 'Luo uusi kansio',
    alert: 'Haluatko varmasti poistaa?',
    'set.date': 'aseta päivä',
    'move.home': 'Siirry kotikansioon',
    'move.parent': 'Siirry pääkansioon',
    'add.item.additional.info-1': 'Sinulla voi olla enintään ',
    'add.item.additional.info-2': ' tiliä',
    'menu.profile': 'Muokkaa tietoja',
    'menu.profile.manage': 'Hallitse',
    'menu.quality': 'Kuitin laatu',
    'menu.recognition': 'Kuitin tunnistus',
    'menu.terms': 'Käyttöehdot',
    'menu.profile.info.currency': 'Valuutta:',
    'menu.profile.enter-promo': 'Syötä tarjouskoodi',
    'menu.profile.redeem-promo': 'Lunasta',
    'menu.profile.redeem': 'Lunasta',

    'menu.profile.photo': 'Vaihda profiilikuva',
    'menu.profile.change.data': 'Voit aina muuttaa näitä tietoja',
    'menu.profile.delete.account': 'Poista tili',
    'menu.profile.delete.profile': 'Poista profiili',
    'menu.profile.your.mail': 'Sähköpostisi:',
    'menu.profile.add.mail': 'Lisää sähköposti',
    'menu.profile.new.mail': 'Uusi sähköposti',
    'menu.profile.new.account': 'Uusi tili',
    'menu.profile.account.empty': 'Lisää tili',
    'menu.profile.invite': 'Kutsu ',
    'menu.profile.5premium': '5 PREMIUM- ',
    'menu.profile.friends': 'ystävää ',
    'menu.profile.have.yours': 'ja saat omasi ',
    'menu.profile.free': 'ILMAISEKSI',
    'menu.profile.invite.mail': 'Kutsu ystäviä sähköpostitse',
    'menu.profile.invite.sms': 'Kutsu ystäviä tekstiviestillä',
    'menu.profile.invite.share': 'Lähetä linkki ystävillesi',
    'menu.profile.invite.gift': 'Lahja ystävälle',
    'menu.profile.password.current': 'Nykyinen salasana',
    'menu.profile.password.new': 'Uusi salasana',
    'menu.profile.password.again': 'Syötä uusi salasana uudelleen',
    'menu.profile.password.forgot': 'Unohditko salasanasi?',
    'menu.profile.phone.your': 'Puhelinnumerosi:',
    'menu.profile.phone.add': 'Lisää puhelinnumero',
    'menu.profile.phone.new': 'Uusi puhelin',
    'menu.profile.first': 'Etunimi',
    'menu.profile.last': 'Sukunimi',
    'menu.profile.date': 'Syntymäaika',
    'menu.profile.gender': 'Sukupuoli',
    'menu.profile.currency': 'Valuutta',
    'menu.profile.male': 'Mies',
    'menu.profile.female': 'Nainen',
    'menu.profile.remove.confirm1':
      'Haluatko varmasti poistaa tilisi kaikkine tiedoineen?',
    'menu.profile.remove.confirm2':
      'KAIKKI TILITIEDOT, ASETUKSET, LADATUT ASIAKIRJAT, KATEGORIAT POISTETAAN PYSYVÄSTI IJOISESTI!!! OLETKO SAMAA MIELTÄ?',
    'menu.invite': 'Kutsu ystävä',
    'menu.plan': 'Tilaussuunnitelma',
    'menu.plan.title': 'iReceipt Premium -tili',
    'menu.plan.tab.header.1': 'Ominaisuudet',
    'menu.plan.tab.header.2': 'Perus',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Tallenna kuitit',
    'menu.plan.tab.body.2': 'Jaa kuitit',
    'menu.plan.tab.body.3': 'Pilvitallennus',
    'menu.plan.tab.body.4': 'Luo kansioita',
    'menu.plan.tab.body.5': 'Jaa kansioita',
    'menu.plan.tab.body.6': 'Keskustele muiden kanssa',
    'menu.plan.tab.body.7': 'Rajoittamaton tallennustila',
    'menu.profile.plan.title': 'GO PREMIUM',
    'menu.profile.plan.description':
      'Tallenna jaa kuittisi, luo kansioita jaa kansioita PDF-tiedostona tai linkkinä PDF-tiedostoon',

    'menu.profile.plan.business': 'Yritys',
    'menu.profile.plan.argument.1.title': 'rajoittamaton tallennustila',
    'menu.profile.plan.argument.1.description':
      'tallenna rajaton määrä kuitteja, ja sinulla on pääsy niihin millä tahansa laitteella',
    'menu.profile.plan.argument.2.title': 'enintään ',
    'menu.profile.plan.argument.2.title-2': ' tiliä',
    'menu.profile.plan.argument.2.business.description':
      'luo yritystili ja tee erilliset tilit yrityksen jäsenille ja työlle',
    'menu.profile.plan.subtitle.1': 'Ilmainen kokeiluversio jopa ',
    'menu.profile.plan.subtitle.2': ' kuitille',
    'menu.profile.plan.proposals.1.period': 'Kuukausittain',
    'menu.profile.plan.proposals.1.per-period': 'kuukaudessa',
    'menu.profile.plan.proposals.2.period': 'Vuosittain',
    'menu.profile.plan.proposals.2.per-period': 'vuodessa',
    'menu.profile.plan.proposals.best-value': 'paras arvo',
    'menu.profile.plan.proposals.info-1': 'Se on vain ',
    'menu.profile.plan.proposals.info-2': ' kuukausi',
    'menu.profile.plan.soon':
      'Lisää ominaisuuksia tulossa pian premium-käyttäjillemme',
    'menu.profile.plan.restore': 'Palauta ostettu',
    'menu.profile.plan.bottom-info':
      'Maksu veloitetaan Apple-tililtä oston vahvistuksen yhteydessä. Tilaus uusitaan automaattisesti, ellei automaattista uusimista ole kytketty pois päältä vähintään 24 tuntia ennen nykyisen jakson päättymistä. Tililtä veloitetaan uusiminen 24 tunnin sisällä ennen kuluvan kauden päättymistä, ja ilmoitetaan uusimisen kustannukset. Käyttäjä voi hallinnoida tilauksia, ja automaattinen uusiminen voidaan laittaa pois käytöstä siirtymällä käyttäjän tilin asetuksiin oston jälkeen.',
    'menu.profile.plan.coming.soon': 'Tulossa pian...',
    'menu.profile.plan.coming.soon.alert':
      "Hetkellä, kun 'Business'-tilauspaketti on saatavilla, saat push-ilmoituksen. Varmista, että ilmoitukset ovat päällä, jotta et missaa tätä tärkeää päivitystä",
    'menu.profile.plan.notify': 'Ilmoita minulle ensin',
    'menu.profile.permission.viewing': 'Katseluoikeudet',
    'menu.profile.permission.editing': 'Muokkausoikeudet',
    'menu.profile.permission.deletion': 'Poistamisoikeudet',
    'menu.profile.permission.admin.view.info':
      'Järjestelmänvalvojan oikeudet voidaan myöntää yli 18-vuotiaille osallistujaryhmille. Käyttäjä, jolla on tämä lupa, voi katsella kuitteja ja laskuja',
    'menu.profile.permission.admin.edit.info':
      'Järjestelmänvalvojan oikeudet voidaan myöntää yli 18-vuotiaille osallistujaryhmille. Käyttäjä, jolla on tämä lupa, voi muokata kuitteja ja laskuja',
    'menu.profile.permission.admin.delete.info':
      'Järjestelmänvalvojan oikeudet voidaan myöntää yli 18-vuotiaille osallistujaryhmille. Käyttäjä, jolla on tämä lupa, voi poistaa kuitteja ja laskuja',
    'menu.free': 'Ilmainen',
    'menu.monthly': 'kuukausittain',
    'menu.yearly': 'vuosittain',
    'menu.about': 'Noin',
    'menu.lang': 'Kieli',
    'menu.theme': 'Sovelluksen teema',
    'menu.theme.title': 'Valitse paletti',
    'menu.theme.subtitle': 'Nauti sovelluksesta suosikkiväreilläsi',
    'menu.lang.choose': 'Valitse kieli:',
    'menu.lang.choose2': 'Valitse tunnista kieli:',
    'menu.lang.uk': 'Ukraina',
    'menu.lang.en': 'Englanti',
    'menu.lang.no': 'Norja',
    'menu.lang.ru': 'Venajan',
    'menu.lang.pt': 'Portugalinen',
    'menu.lang.pl': 'Kiillottaa',
    'menu.lang.fi': 'Suomalainen',
    'menu.lang.se': 'Ruotsin',
    'menu.lang.it': 'Italialainen',
    'menu.lang.fr': 'Ranskan',
    'menu.lang.es': 'Espanjan',
    'menu.lang.de': 'Saksan',
    'menu.lang.da': 'Tanskan',
    'menu.account': 'Vaihda tili',
    'menu.account.add': 'Jaa tili',
    'menu.account.notHave': 'sinulla ei ole jaettuja tilejä',
    'menu.logout': 'Kirjaudu ulos',
    'menu.logout.alternatives': 'Vaihtoehtoiset vaihtoehdot',
    'menu.logout.modal.title': 'Haluatko varmasti kirjautua ulos?',
    'menu.support': 'Ota yhteyttä tukeen',
    'menu.password': 'Vaihda salasana',
    'menu.email': 'Sähköposti',
    'menu.phone': 'Puhelinnumero',
    'menu/settings': 'Asetukset',
    'menu/settings/profile': 'Muokkaa profiilia',
    'menu/settings/invite': 'Kutsu ystävä',
    'menu/settings/plan': 'Tilaussuunnitelma',
    'menu/settings/password': 'Salasana',
    'menu/settings/email': 'Sähköposti',
    'menu/settings/phone': 'Puhelin',
    'menu/settings/language': 'Kieli',
    'menu/settings/quality': 'Kuitin laatu',
    'menu/settings/about': 'Noin',
    'menu/settings/account': 'Vaihda tili',
    'menu.settings.account.free': 'Ilmainen tili',
    'menu.settings.account.business': 'Yritystili',
    'menu.settings.account.add': 'Lisää tili',
    'menu.plan.personal.storage-title': 'Tallennustila - jopa 10 Gt',
    'menu.plan.personal.storage-description':
      'Tallenna kuitit ja käytä niitä mistä tahansa laitteesta',
    'menu.plan.family.storage-title': 'Tallennustila - jopa 20 Gt',
    'menu.plan.family.storage-description':
      'Tallenna kuitit ja käytä niitä mistä tahansa laitteesta',

    'recognize.categoryName': 'tunnistetaan nimi',
    'recognize.categoryAmount': 'tunnistetaan määrä',
    saveTo: 'Tallenna kohteeseen..',
    'save.new': 'Tallenna Uusi',
    'save.close': 'Tallenna Sulje',

    'all.profiles': 'Kaikki profiilit',
    'style.rotate': 'Kiertää',
    'style.correction': 'Korjaus',
    'style.color': 'Väri',
    'style.intensity': 'Intensiteetti',
    'style.style': 'Kirkkaus',
    'style.contrast': 'Kontrasti',
    'move.to.receipts': 'Siirrä kuitteihin',
    'move.to.receipts.modal.title': 'Tämä lasku siirretään kuitteihin',
    'move.to.invoices': 'Siirrä laskuihin',
    'move.to.invoices.modal.title': 'Tämä kuitti siirretään laskuihin',
    'create.new.folder': 'Luo uusi kansio',
    'share.modal.web.copy': 'Kopioi linkki leikepöydälle',
    'app.loading': 'Ladataan',
    'receipt.quality': 'vastaanoton laatu',

    'this.month': 'Tässä kuussa',
    'last.month': 'Viime kuukausi',
    'notifications.tryOpenReceiptLater': 'Yritä avata kuitti myöhemmin',

    select: 'Valitse',

    recognition: 'Tunnustus',
    'recognition.none': 'Ei mitään',
    'recognition.fast': 'Nopeasti',
    'recognition.accurate': 'Tarkka',
  },
  se: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Gratis provperiod 3 dagar',
    'app.buy': 'Prenumerera',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'skapa ditt pluskonto och ha kontroll över dina kvitton och fakturor',
    'menu.settings.account.personal': 'Pluskonto',
    'menu.profile.plan.argument.2.family.description':
      'skapa ditt premiumkonto och gör separata konton för familj och arbete',
    'menu.settings.account.family': 'Premiumkonto',
    'app.privacyPolicy': 'Integritetspolicy',
    'app.licenseUserAgreement': 'Användaravtal för licens',
    'app.gdpr': 'Den allmänna dataskyddsförordningen',
    'document.name': 'Dokument namn',
    unfilledDocument: 'Oifyllt dokument',
    'categoryList.no-documents': 'Inga dokument ännu',
    'new.document': 'Nytt dokument',
    'auth.h1': 'Välkommen',
    'auth.p': 'Välkommen till din plånbok för kvitton och fakturor',
    'auth.social': 'Eller via sociala medier',
    'login.h1': 'Välkommen tillbaka!',
    'login.email': 'E-postadress',
    'login.pass': 'Lösenord',
    'login.forgot': 'Glömt lösenord',
    'login.otp': 'Logga in via OTP',
    'login.footer': 'Har du inget konto?',
    'login.footer.up': 'Registrera dig',
    'signUp.h1': 'Registrera dig',
    'signUp.email': 'Din e-postadress',
    'signUp.pass': 'Lösenord',
    'signUp.success':
      'Ditt konto har skapats. Kolla din e-post för att bekräfta nytt konto',
    'signUp.footer': 'Har du redan ett konto?',
    'signUp.footer.in': 'Logga in',
    'forgot.h1': 'Har du glömt ditt lösenord?',
    'forgot.p': 'Ange e-postadressen som är kopplad till detta konto',
    'forgot.success':
      'Du har fått ett nytt lösenord. Kolla din e-post för att återställa lösenordet',
    'forgot.email': 'Ange din e-postadress',
    'reset.h1': 'Nytt lösenord',
    'reset.p': 'Ange nytt lösenord',
    'reset.success':
      'Du har fått ett nytt lösenord. Kolla din e-post för att återställa lösenordet',
    'reset.pass': 'Lösenord',
    'reset.confirm': 'Bekräfta lösenord',
    'otp.h1': 'Logga in via OTP',
    'otp.p': 'Vi skickar en OTP till ditt mobilnummer',
    'verify.h1': 'Verifiering',
    'verify.p': 'Ange det fyrsiffriga numret som skickades till',
    'verify.receive': 'Fick du det inte?',
    'verify.reSend': 'Skicka igen ',
    'mailConfirm.text': 'Något gick fel:',
    'mailConfirm.success': 'Det lyckades: E-post bekräftad',
    'mailConfirm.goTo': 'Gå till ansökan',
    'test.title': 'Ställ kvalitet',
    'test.quality': 'Bättre kvalité',
    'test.recognition': 'Snabbare igenkänning',
    'test.submit': 'ställ in',
    'btn.login': 'Logga in',
    'btn.continue': 'Fortsätt',
    'btn.sign': 'Registrera dig',
    'btn.submit': 'Skicka in',
    'btn.verify': 'Verifiera nu',
    'sort.store': 'Lagra',
    'sort.madeBy': 'Skapad av',
    'sort.amount': 'Belopp',
    'sort.date': 'Datum',
    'breadcrumbs.no.folders': 'Inga mappar här än',
    cancel: 'Avbryt',
    confirm: 'Bekräfta',
    create: 'Skapa',
    next: 'Nästa',
    back: 'Tillbaka',
    save: 'Spara',
    apply: 'Tillämpa',
    name: 'Namn',
    amount: 'Belopp',
    from: 'Från:',
    to: 'Till:',
    receipts: 'Kvitton',
    invoices: 'Fakturor',
    'new.folder': 'Ny mapp',
    folder: 'Mapp:',
    home: 'Hem',
    profile: 'Profil',
    'new.receipt': 'Nytt kvitto',
    'new.invoice': 'Ny faktura',
    'receipt.name': 'Kvitto namn',
    'toast.share': 'Dela länk kopierad till urklipp',
    'modal.share': 'Välj format för delningslänk',
    'modal.share.link': 'Länk',
    'modal.share.pdf': 'Fil',
    'modal.share.download': 'Ladda ner',
    'preview.name': 'Namn:',
    'preview.amount': 'Belopp:',
    'preview.date': 'Datum:',
    'preview.created': 'Skapad av:',
    'preview.edit': 'Redigera',
    'preview.share': 'Dela',
    'preview.delete': 'Radera',
    'preview.close': 'Stäng',
    'preview.print': 'Skriv ut',
    'categoryList.dont.1': 'i kategori',
    'categoryList.dont.2': ' du har inga kvitton eller kategorier än',
    'filter.original': 'Original',
    'filter.bw': 'Svartvitt',
    'filter.grey': 'Gråskala',
    unfilledReceipt: 'Ej ifyllt kvitto',
    unfilledInvoice: 'Ej ifylld faktura',
    tree: 'Kategoriträd',
    CreateNewFolder: 'Skapa ny mapp',
    alert: 'Är du säker på att du vill radera?',
    'set.date': 'ställ in datum',
    'move.home': 'Flytta till hemmappen',
    'move.parent': 'Flytta till överordnad mapp',
    'add.item.additional.info-1': 'Du kan ha max. ',
    'add.item.additional.info-2': ' konton',
    'menu.profile': 'Redigera info',
    'menu.profile.manage': 'Hantera',
    'menu.quality': 'Kvitto kvalitet',
    'menu.recognition': 'Erkännande av kvitto',
    'menu.terms': 'Villkor',
    'menu.profile.info.currency': 'Valuta:',
    'menu.profile.enter-promo': 'Ange kampanjkod',
    'menu.profile.redeem-promo': 'Lösa in',
    'menu.profile.redeem': 'Lösa in',

    'menu.profile.photo': 'Ändra profilbild',
    'menu.profile.change.data': 'Du kan alltid ändra dessa uppgifter',
    'menu.profile.delete.account': 'Radera konto',
    'menu.profile.delete.profile': 'Ta bort profil',
    'menu.profile.your.mail': 'Din e-post:',
    'menu.profile.add.mail': 'Lägg till e-post',
    'menu.profile.new.mail': 'Ny e-postadress',
    'menu.profile.new.account': 'Nytt konto',
    'menu.profile.account.empty': 'Lägg till konto',
    'menu.profile.invite': 'Bjud in ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'vänner ',
    'menu.profile.have.yours': 'och få ditt ',
    'menu.profile.free': 'GRATIS',
    'menu.profile.invite.mail': 'Bjud in vänner via e-post',
    'menu.profile.invite.sms': 'Bjud in vänner via SMS',
    'menu.profile.invite.share': 'Skicka en länk till dina vänner',
    'menu.profile.invite.gift': 'Ge en vän',
    'menu.profile.password.current': 'Nuvarande lösenord',
    'menu.profile.password.new': 'Nytt lösenord',
    'menu.profile.password.again': 'Ange nytt lösenord igen',
    'menu.profile.password.forgot': 'Glömt ditt lösenord?',
    'menu.profile.phone.your': 'Ditt telefonnummer:',
    'menu.profile.phone.add': 'Lägg till telefonnummer',
    'menu.profile.phone.new': 'Ny telefon',
    'menu.profile.first': 'Förnamn',
    'menu.profile.last': 'Efternamn',
    'menu.profile.date': 'Födelsedatum',
    'menu.profile.gender': 'Kön',
    'menu.profile.currency': 'Valuta',
    'menu.profile.male': 'Man',
    'menu.profile.female': 'Kvinna',
    'menu.profile.remove.confirm1':
      'Är du säker på att du vill ta bort ditt konto med all data?',
    'menu.profile.remove.confirm2':
      'ALLA DINA KONTODATA, INSTÄLLNINGAR, UPPLADADE DOKUMENT, KATEGORIER KOMMER ATT RADERAS PERMANENT FÖR ALLTID!!! HÅLLER DU MED?',
    'menu.invite': 'Bjud in en vän',
    'menu.plan': 'Prenumerationsplan',
    'menu.plan.title': 'iReceipt Premium Account',
    'menu.plan.tab.header.1': 'Funktioner',
    'menu.plan.tab.header.2': 'Grundläggande',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Spara kvitton',
    'menu.plan.tab.body.2': 'Dela kvitton',
    'menu.plan.tab.body.3': 'Molnlagring',
    'menu.plan.tab.body.4': 'Skapa mappar',
    'menu.plan.tab.body.5': 'Dela mappar',
    'menu.plan.tab.body.6': 'Chatta med andra',
    'menu.plan.tab.body.7': 'Obegränsad lagring',
    'menu.profile.plan.title': 'GO PREMIUM',
    'menu.profile.plan.description':
      'Spara och dela dina kvitton, skapa mappar och dela mappar som PDF eller som en länk till PDF',
    'menu.profile.plan.business': 'Företag',
    'menu.profile.plan.argument.1.title': 'obegränsad lagring',
    'menu.profile.plan.argument.1.description':
      'spara obegränsade antal kvitton och få tillgång till dem från vilken enhet som helst',
    'menu.profile.plan.argument.2.title': 'upp till ',
    'menu.profile.plan.argument.2.title-2': ' konton',
    'menu.profile.plan.argument.2.business.description':
      'skapa ditt företagskonto och skapa separata konton för företagsmedlemmar och arbete',
    'menu.profile.plan.subtitle.1': 'Gratis provperiod upp till ',
    'menu.profile.plan.subtitle.2': ' kvitton',
    'menu.profile.plan.proposals.1.period': 'Månatlig',
    'menu.profile.plan.proposals.1.per-period': 'per månad',
    'menu.profile.plan.proposals.2.period': 'Årlig',
    'menu.profile.plan.proposals.2.per-period': 'per år',
    'menu.profile.plan.proposals.best-value': 'bästa värde',
    'menu.profile.plan.proposals.info-1': 'Det är bara ',
    'menu.profile.plan.proposals.info-2': ' en månad',
    'menu.profile.plan.soon':
      'Fler funktioner kommer snart för våra premiumanvändare',
    'menu.profile.plan.restore': 'Återställ köp',
    'menu.profile.plan.bottom-info':
      'Betalning debiteras Apple-kontot vid bekräftelse av köpet. Prenumerationen förnyas automatiskt såvida inte automatisk förnyelse är avstängd minst 24 timmar före slutet av den aktuella perioden. Kontot debiteras för förnyelse inom 24 timmar före slutet av den aktuella perioden och identifierar kostnaden för förnyelsen. Prenumerationer kan hanteras av användaren och automatisk förnyelse kan stängas av genom att gå till användarens kontoinställningar efter köpet.',
    'menu.profile.plan.coming.soon': 'Kommer snart ...',
    'menu.profile.plan.coming.soon.alert':
      "Så snart 'Business' prenumerationsplanen blir tillgänglig, kommer du att få en push-notifikation. Se till att dina notifikationer är påslagna så att du inte missar denna viktiga uppdatering",
    'menu.profile.plan.notify': 'Meddela mig först',
    'menu.profile.permission.viewing': 'Visningsrättigheter',
    'menu.profile.permission.editing': 'Redigeringsrättigheter',
    'menu.profile.permission.deletion': 'Raderingsrättigheter',
    'menu.profile.permission.admin.view.info':
      'Beviljande av administratörsbehörighet kan beviljas deltagargrupper över 18 år. En användare med denna behörighet kan visa kvitton och fakturor',
    'menu.profile.permission.admin.edit.info':
      'Beviljande av administratörsbehörighet kan beviljas deltagargrupper över 18 år. En användare med denna behörighet kommer att kunna redigera kvitton och fakturor',
    'menu.profile.permission.admin.delete.info':
      'Beviljande av administratörsbehörighet kan beviljas deltagargrupper över 18 år. En användare med denna behörighet kommer att kunna ta bort kvitton och fakturor',
    'menu.free': 'Gratis',
    'menu.monthly': 'månatlig',
    'menu.yearly': 'årlig',
    'menu.about': 'Om',
    'menu.lang': 'Språk',
    'menu.theme': 'Applikationstema',
    'menu.theme.title': 'Välj paletten',
    'menu.theme.subtitle': 'Njut av applikationen med dina favoritfärger',
    'menu.lang.choose': 'Välj språk:',
    'menu.lang.choose2': 'Välj känn igen språk:',
    'menu.lang.uk': 'Ukrainska',
    'menu.lang.en': 'Engelska',
    'menu.lang.no': 'Norska',
    'menu.lang.ru': 'Ryska',
    'menu.lang.pt': 'Portugisiska',
    'menu.lang.pl': 'Putsa',
    'menu.lang.fi': 'Finska',
    'menu.lang.se': 'Svenska',
    'menu.lang.it': 'Italienska',
    'menu.lang.fr': 'Franska',
    'menu.lang.es': 'Spanska',
    'menu.lang.de': 'Tysk',
    'menu.lang.da': 'Danska',
    'menu.account': 'Byt konto',
    'menu.account.add': 'Dela konto',
    'menu.account.notHave': 'du har inte delade konton',
    'menu.logout': 'Logga ut',
    'menu.logout.alternatives': 'Alternativ',
    'menu.logout.modal.title': 'Är du säker på att du vill logga ut?',
    'menu.support': 'Kontakta för support',
    'menu.password': 'ändra lösenord',
    'menu.email': 'E-post',
    'menu.phone': 'Telefonnummer',
    'menu/settings': 'Inställningar',
    'menu/settings/profile': 'Redigera profil',
    'menu/settings/invite': 'Bjud in en vän',
    'menu/settings/plan': 'Prenumerationsplan',
    'menu/settings/password': 'Lösenord',
    'menu/settings/email': 'E-post',
    'menu/settings/phone': 'Telefon',
    'menu/settings/language': 'Språk',
    'menu/settings/quality': 'Kvitto kvalitet',
    'menu/settings/about': 'Om',
    'menu/settings/account': 'Byt konto',
    'menu.settings.account.free': 'Gratis konto',
    'menu.settings.account.business': 'Företagskonto',
    'menu.settings.account.add': 'Lägg till konto',

    'menu.plan.personal.storage-title': 'Lagring - upp till 10 Gb',
    'menu.plan.personal.storage-description':
      'Spara kvitton och använd dem från vilken enhet som helst',
    'menu.plan.family.storage-title': 'Lagring - upp till 20 Gb',
    'menu.plan.family.storage-description':
      'Spara kvitton och använd dem från vilken enhet som helst',

    'recognize.categoryName': 'känner igen namn',
    'recognize.categoryAmount': 'känner igen belopp',
    saveTo: 'Spara till..',
    'save.new': 'Spara och nytt',
    'save.close': 'Spara och stäng',

    'all.profiles': 'Alla profiler',
    'style.rotate': 'Rotera',
    'style.correction': 'Rättelse',
    'style.color': 'Färg',
    'style.intensity': 'Intensitet',
    'style.style': 'Ljusstyrka',
    'style.contrast': 'Kontrast',
    'move.to.receipts': 'Flytta till kvitton',
    'move.to.receipts.modal.title':
      'Denna faktura kommer att flyttas till kvitton',
    'move.to.invoices': 'Flytta till fakturor',
    'move.to.invoices.modal.title':
      'Detta kvitto kommer att flyttas till fakturor',
    'create.new.folder': 'Ny mapp',
    'share.modal.web.copy': 'Kopiera länk till Urklipp',
    'app.loading': 'Laddar',
    'receipt.quality': 'kvalitet på kvitto',

    'this.month': 'Den här månaden',
    'last.month': 'Förra månaden',
    'notifications.tryOpenReceiptLater': 'Försök att öppna kvittot senare',

    select: 'Välj',

    recognition: 'Erkännande',
    'recognition.none': 'Ingen',
    'recognition.fast': 'Snabb',
    'recognition.accurate': 'Exakt',
  },
  it: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Prova gratuita di 3 giorni',
    'app.buy': 'Sottoscrivi',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'crea il tuo account plus e controlla le tue ricevute e fatture',
    'menu.settings.account.personal': 'Account Plus',
    'menu.profile.plan.argument.2.family.description':
      'crea il tuo account premium e crea account separati per famiglia e lavoro',
    'menu.settings.account.family': 'Account Premium',
    'app.privacyPolicy': 'Politica sulla riservatezza',
    'app.licenseUserAgreement': "Accordo per l'utente della licenza",
    'app.gdpr': 'Il regolamento generale sulla protezione dei dati (GDPR)',
    'document.name': 'Nome del documento',
    unfilledDocument: 'Documento non compilato',
    'categoryList.no-documents': 'Ancora nessun documento',
    'new.document': 'Nuovo documento',
    'auth.h1': 'Benvenuto',
    'auth.p': 'Benvenuto nella tua ricevuta e nel raccoglitore per le fatture',
    'auth.social': 'O tramite i social media',
    'login.h1': 'Bentornato!',
    'login.email': 'Indirizzo email',
    'login.pass': 'Password',
    'login.forgot': 'Password dimenticata',
    'login.otp': 'Accedi tramite OTP',
    'login.footer': 'Non hai un account?',
    'login.footer.up': 'Iscriviti',
    'signUp.h1': 'Iscriviti',
    'signUp.email': 'La tua email',
    'signUp.pass': 'Password',
    'signUp.success':
      'Operazione effettuata con successo: controlla la posta elettronica per confermare il nuovo account',
    'signUp.footer': 'Hai già un account?',
    'signUp.footer.in': 'Registrati',
    'forgot.h1': 'Password dimenticata?',
    'forgot.p': "Inserisci l'indirizzo email associato a questo account",
    'forgot.success':
      'Operazione effettuata con successo: controlla la posta elettronica per reimpostare la password',
    'forgot.email': 'Inserisci il tuo indirizzo email',
    'reset.h1': 'Nuova password',
    'reset.p': 'Inserisci una nuova password',
    'reset.success':
      'Operazione effettuata con successo: controlla la posta elettronica per reimpostare la password',
    'reset.pass': 'Password',
    'reset.confirm': 'Conferma password',
    'otp.h1': 'Accedi tramite OTP',
    'otp.p': 'Invieremo una OTP al tuo numero di cellulare',
    'verify.h1': 'Verifica',
    'verify.p': 'Inserisci il numero di 4 cifre inviato su',
    'verify.receive': 'Non lo hai ricevuto?',
    'verify.reSend': 'Invia di nuovo ',
    'mailConfirm.text': 'Qualcosa è andato storto:',
    'mailConfirm.success': 'Successo: Email confermata',
    'mailConfirm.goTo': "Vai all'applicazione",
    'test.title': 'Imposta qualità',
    'test.quality': 'Qualità migliore',
    'test.recognition': 'Riconoscimento più veloce',
    'test.submit': 'imposta',
    'btn.login': 'Accedi',
    'btn.continue': 'Continua',
    'btn.sign': 'Iscriviti',
    'btn.submit': 'Invia',
    'btn.verify': 'Verifica ora',
    'sort.store': 'Negozio',
    'sort.madeBy': 'Fatto da',
    'sort.amount': 'Quantità',
    'sort.date': 'Data',
    'breadcrumbs.no.folders': 'Ancora nessuna cartella',
    cancel: 'Cancella',
    confirm: 'Conferma',
    create: 'Crea',
    next: 'Successivo',
    back: 'Precedente',
    save: 'Salva',
    apply: 'Applica',
    name: 'Nome',
    amount: 'Quantità',
    from: 'Da:',
    to: 'A:',
    receipts: 'Ricevute',
    invoices: 'Fatture',
    'new.folder': 'Nuova cartella',
    folder: 'Cartella:',
    home: 'Home',
    profile: 'Profilo',
    'new.receipt': 'Nuova ricevuta',
    'new.invoice': 'Nuova fattura',
    'receipt.name': 'Nome della ricevuta',
    'toast.share': 'Condividi link copiato negli appunti',
    'modal.share': 'Scegli il formato del link di condivisione',
    'modal.share.link': 'Link',
    'modal.share.pdf': 'File',
    'modal.share.download': 'Scarica',
    'preview.name': 'Nome:',
    'preview.amount': 'Quantità:',
    'preview.date': 'Data:',
    'preview.created': 'Creato da:',
    'preview.edit': 'Modifica',
    'preview.share': 'Condividi',
    'preview.delete': 'Elimina',
    'preview.close': 'Chiudi',
    'preview.print': 'Stampa',
    'categoryList.dont.1': 'nella categoria',
    'categoryList.dont.2': ' non hai ancora ricevute o categorie',
    'filter.original': 'Originale',
    'filter.bw': 'Bianco e nero',
    'filter.grey': 'Scala di grigi',
    unfilledReceipt: 'Ricevuta non compilata',
    unfilledInvoice: 'Fattura non compilata',
    tree: 'Albero delle categorie',
    CreateNewFolder: 'Crea una nuova cartella',
    alert: 'Sei sicuro di voler eliminare?',
    'set.date': 'imposta la data',
    'move.home': 'Sposta nella cartella Home',
    'move.parent': 'Sposta nella cartella principale',
    'add.item.additional.info-1': 'Puoi avere massimo ',
    'add.item.additional.info-2': ' account',
    'menu.profile': 'Modifica informazioni',
    'menu.profile.manage': 'Gestisci',
    'menu.quality': 'Qualità delle ricevute',
    'menu.recognition': 'Riconoscimento della ricevuta',
    'menu.terms': 'Termini e сondizioni',
    'menu.profile.info.currency': 'Valuta:',
    'menu.profile.enter-promo': 'Inserisci il codice promozionale',
    'menu.profile.redeem-promo': 'Riscatta',
    'menu.profile.redeem': 'Riscatta',

    'menu.profile.photo': 'Modifica la foto del profilo',
    'menu.profile.change.data': 'Puoi sempre modificare questi dati',
    'menu.profile.delete.account': "Elimina l'account",
    'menu.profile.delete.profile': 'Elimina il profilo',
    'menu.profile.your.mail': 'La tua email:',
    'menu.profile.add.mail': 'Aggiungi email',
    'menu.profile.new.mail': 'Nuova email',
    'menu.profile.new.account': 'Nuovo account',
    'menu.profile.account.empty': 'Aggiungi account',
    'menu.profile.invite': 'Invita ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'amici ',
    'menu.profile.have.yours': 'e hai il tuo per ',
    'menu.profile.free': 'GRATUITO',
    'menu.profile.invite.mail': 'Invita gli amici tramite e-mail',
    'menu.profile.invite.sms': 'Invita gli amici tramite SMS',
    'menu.profile.invite.share': 'Invia un link ai tuoi amici',
    'menu.profile.invite.gift': 'Regala un amico',
    'menu.profile.password.current': 'Password attuale',
    'menu.profile.password.new': 'Nuova password',
    'menu.profile.password.again': 'Inserisci ancora la nuova password',
    'menu.profile.password.forgot': 'Hai dimenticato la password?',
    'menu.profile.phone.your': 'Il tuo numero di telefono:',
    'menu.profile.phone.add': 'Aggiungi numero di telefono',
    'menu.profile.phone.new': 'Nuovo telefono',
    'menu.profile.first': 'Nome',
    'menu.profile.last': 'Cognome',
    'menu.profile.date': 'Data di nascita',
    'menu.profile.gender': 'Genere',
    'menu.profile.currency': 'Valuta',
    'menu.profile.male': 'Maschio',
    'menu.profile.female': 'Femmina',
    'menu.profile.remove.confirm1':
      'Sei sicuro di voler eliminare il tuo account con tutti i dati?',
    'menu.profile.remove.confirm2':
      "TUTTI I DATI DEL TUO ACCOUNT, IMPOSTAZIONI, DOCUMENTI CARICATI, CATEGORIE SARANNO CANCELLATI PERMANENTEMENTE PER SEMPRE!!! SEI D'ACCORDO?",
    'menu.invite': 'Invita un amico',
    'menu.plan': 'Piano di abbonamento',
    'menu.plan.title': 'Account Premium iReceipt',
    'menu.plan.tab.header.1': 'Caratteristiche',
    'menu.plan.tab.header.2': 'Base',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Salva le ricevute',
    'menu.plan.tab.body.2': 'Condividi le ricevute',
    'menu.plan.tab.body.3': 'Archiviazione nel cloud',
    'menu.plan.tab.body.4': 'Crea cartelle',
    'menu.plan.tab.body.5': 'Condividi cartelle',
    'menu.plan.tab.body.6': 'Chatta con gli altri',
    'menu.plan.tab.body.7': 'Spazio di archiviazione illimitato',
    'menu.profile.plan.title': 'VAI A PREMIUM',
    'menu.profile.plan.description':
      'Salva e condividi le tue ricevute, crea cartelle e condividi cartelle come PDF o come collegamento in PDF',
    'menu.profile.plan.business': 'Attività commerciale',
    'menu.profile.plan.argument.1.title': 'spazio di archiviazione illimitato',
    'menu.profile.plan.argument.1.description':
      'salva un numero illimitato di ricevute e accedi da qualsiasi dispositivo',
    'menu.profile.plan.argument.2.title': 'fino a ',
    'menu.profile.plan.argument.2.title-2': ' account',
    'menu.profile.plan.argument.2.business.description':
      "crea il tuo account aziendale e fai account separati per i membri dell'azienda e il lavoro",
    'menu.profile.plan.subtitle.1': 'Prova gratuita fino a ',
    'menu.profile.plan.subtitle.2': ' ricevute',
    'menu.profile.plan.proposals.1.period': 'Mensile',
    'menu.profile.plan.proposals.1.per-period': 'al mese',
    'menu.profile.plan.proposals.2.period': 'Annuale',
    'menu.profile.plan.proposals.2.per-period': 'per anno',
    'menu.profile.plan.proposals.best-value': 'miglior valore',
    'menu.profile.plan.proposals.info-1': 'Sono solo ',
    'menu.profile.plan.proposals.info-2': ' un mese',
    'menu.profile.plan.soon':
      'Altre funzionalità in arrivo per i nostri utenti premium',
    'menu.profile.plan.restore': 'Ripristina acquisti',
    'menu.profile.plan.bottom-info':
      "Il pagamento verrà addebitato sull'account Apple alla conferma dell'acquisto. L'abbonamento si rinnova automaticamente a meno che il rinnovo automatico non venga disattivato almeno 24 ore prima del termine del periodo in corso. L'account verrà addebitato per il rinnovo entro 24 ore prima della fine del periodo in corso e verrà identificato il costo del rinnovo. Gli abbonamenti possono essere gestiti dall'utente e il rinnovo automatico può essere disattivato accedendo alle Impostazioni dell'account dell'utente dopo l'acquisto.",
    'menu.profile.plan.coming.soon': 'Prossimamente...',
    'menu.profile.plan.coming.soon.alert':
      "Non appena il piano di abbonamento 'Business' diventa disponibile, riceverai una notifica push. Assicurati che le tue notifiche siano attive per non perdere questo importante aggiornamento",
    'menu.profile.plan.notify': 'Avvisami prima',
    'menu.profile.permission.viewing': 'Diritti di visualizzazione',
    'menu.profile.permission.editing': 'Diritti di modifica',
    'menu.profile.permission.deletion': 'Diritti di cancellazione',
    'menu.profile.permission.admin.view.info':
      'La concessione dei privilegi di amministratore può essere concessa a gruppi di partecipanti di età superiore a 18 anni. Un utente con questa autorizzazione potrà visualizzare ricevute e fatture',
    'menu.profile.permission.admin.edit.info':
      'La concessione dei privilegi di amministratore può essere concessa a gruppi di partecipanti di età superiore a 18 anni. Un utente con questa autorizzazione potrà modificare ricevute e fatture',
    'menu.profile.permission.admin.delete.info':
      'La concessione dei privilegi di amministratore può essere concessa a gruppi di partecipanti di età superiore a 18 anni. Un utente con questa autorizzazione potrà eliminare ricevute e fatture',
    'menu.free': 'Gratuito',
    'menu.monthly': 'mensile',
    'menu.yearly': 'annuale',
    'menu.about': 'Di',
    'menu.lang': 'Lingua',
    'menu.theme': "Tema dell'applicazione",
    'menu.theme.title': 'Scegli la tavolozza',
    'menu.theme.subtitle': "Goditi l'applicazione con i tuoi colori preferiti",
    'menu.lang.choose': 'Scegli la lingua:',
    'menu.lang.choose2': 'Scegli di riconoscere la lingua:',
    'menu.lang.uk': 'Ucraino',
    'menu.lang.en': 'Inglese',
    'menu.lang.no': 'Norvegese',
    'menu.lang.ru': 'Russo',
    'menu.lang.pt': 'Portoghese',
    'menu.lang.pl': 'Polacco',
    'menu.lang.fi': 'Finlandese',
    'menu.lang.se': 'Svedese',
    'menu.lang.it': 'Italiano',
    'menu.lang.fr': 'Francese',
    'menu.lang.es': 'Spagnolo',
    'menu.lang.de': 'Tedesco',
    'menu.lang.da': 'Danese',
    'menu.account': 'Cambia account',
    'menu.account.add': 'Condividi account',
    'menu.account.notHave': 'non hai account condivisi',
    'menu.logout': 'Disconnettersi',
    'menu.logout.alternatives': 'Opzioni alternative',
    'menu.logout.modal.title': 'Sei sicuro di voler uscire?',
    'menu.support': 'Contatta il supporto',
    'menu.password': 'Cambia la password',
    'menu.email': 'E-mail',
    'menu.phone': 'Numero di telefono',
    'menu/settings': 'Impostazioni',
    'menu/settings/profile': 'Modifica Profilo',
    'menu/settings/invite': 'Invita un amico',
    'menu/settings/plan': 'Piano di abbonamento',
    'menu/settings/password': 'Password',
    'menu/settings/email': 'E-mail',
    'menu/settings/phone': 'Telefono',
    'menu/settings/language': 'Lingua',
    'menu/settings/quality': 'Qualità delle ricevute',
    'menu/settings/about': 'Di',
    'menu/settings/account': 'Cambia account',
    'menu.settings.account.free': 'Account gratuito',
    'menu.settings.account.business': 'Account commerciale',
    'menu.settings.account.add': 'Aggiungi account',

    'menu.plan.personal.storage-title': 'Archiviazione - fino a 10 Gb',
    'menu.plan.personal.storage-description':
      'Salva le ricevute e accedi da qualsiasi dispositivo',
    'menu.plan.family.storage-title': 'Archiviazione - fino a 20 Gb',
    'menu.plan.family.storage-description':
      'Salva le ricevute e accedi da qualsiasi dispositivo',

    'recognize.categoryName': 'riconoscere il nome',
    'recognize.categoryAmount': "riconoscere l'importo",
    saveTo: 'Salva su..',
    'save.new': 'Salva e nuovo',
    'save.close': 'Salva e chiudi',

    'all.profiles': 'Tutti i profili',
    'style.rotate': 'Ruota',
    'style.correction': 'Correzione',
    'style.color': 'Colore',
    'style.intensity': 'Intensità',
    'style.style': 'Luminosità',
    'style.contrast': 'Contrasto',
    'move.to.receipts': 'Sposta in ricevute',
    'move.to.receipts.modal.title': 'Questa fattura sarà spostata in ricevute',
    'move.to.invoices': 'Sposta in fatture',
    'move.to.invoices.modal.title': 'Questa ricevuta sarà spostata in fatture',
    'create.new.folder': 'Crea una nuova cartella',
    'share.modal.web.copy': 'Copia il link negli appunti',
    'app.loading': 'Caricamento in corso',
    'receipt.quality': 'qualità della ricevuta',

    'this.month': 'Questo mese',
    'last.month': 'Lo scorso mese',
    'notifications.tryOpenReceiptLater':
      'Prova ad aprire la ricevuta più tardi',

    select: 'Selezionare',

    recognition: 'Riconoscimento',
    'recognition.none': 'Nessuno',
    'recognition.fast': 'Veloce',
    'recognition.accurate': 'Preciso',
  },
  fr: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Essai gratuit de 3 jours',
    'app.buy': "S'abonner",
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'créez votre compte plus et contrôlez vos reçus et factures',
    'menu.settings.account.personal': 'Compte Plus',
    'menu.profile.plan.argument.2.family.description':
      'créez votre compte premium et créez des comptes séparés pour la famille et le travail',
    'menu.settings.account.family': 'Compte Premium',
    'app.privacyPolicy': 'Politique de confidentialité',
    'app.licenseUserAgreement': "Contrat d'utilisation de la licence",
    'app.gdpr': 'Le Règlement Général sur la Protection des Données (RGPD)',
    unfilledDocument: 'Document non rempli',
    'document.name': 'Nom du document',
    'categoryList.no-documents': 'Pas encore de documents',
    'new.document': 'Nouveau document',
    'auth.h1': 'Bienvenue',
    'auth.p': 'Bienvenue dans votre portefeuille de reçus et de factures',
    'auth.social': 'Ou via les réseaux sociaux',
    'login.h1': 'Soyez à nouveau les bienvenus !',
    'login.email': 'Adresse e-mail',
    'login.pass': 'Mot de passe',
    'login.forgot': 'Mot de passe oublié',
    'login.otp': 'Connexion via OTP',
    'login.footer': 'Vous n’avez pas de compte ?',
    'login.footer.up': "S'inscrire",
    'signUp.h1': "S'inscrire",
    'signUp.email': 'Votre e-mail',
    'signUp.pass': 'Mot de passe',
    'signUp.success':
      'Bravo : consultez votre boîte de réception pour confirmer le nouveau compte',
    'signUp.footer': 'Vous avez déjà un compte ?',
    'signUp.footer.in': "S'identifier",
    'forgot.h1': 'Mot de passe oublié ?',
    'forgot.p': "Saisissez l'adresse e-mail associée à ce compte",
    'forgot.success':
      'Opération réussie : consultez votre boîte de réception pour réinitialiser le mot de passe',
    'forgot.email': 'Saisissez votre adresse e-mail',
    'reset.h1': 'Nouveau mot de passe',
    'reset.p': 'Entrez un nouveau mot de passe',
    'reset.success':
      'Opération réussie : consultez votre boîte de réception pour réinitialiser le mot de passe',
    'reset.pass': 'Mot de passe',
    'reset.confirm': 'Confirmez le mot de passe',
    'otp.h1': 'Connexion via OTP',
    'otp.p': 'Nous enverrons un OTP à votre numéro de téléphone mobile',
    'verify.h1': 'Verification',
    'verify.p': 'Entrez le numéro à 4 chiffres envoyé au',
    'verify.receive': 'Vous ne l’avez pas reçu ?',
    'verify.reSend': 'Renvoyer ',
    'mailConfirm.text': 'Un problème est survenu :',
    'mailConfirm.success': 'Opération réussie : E-mail confirmé',
    'mailConfirm.goTo': "Allez à l'application",
    'test.title': 'Paramétrez qualité',
    'test.quality': 'Meilleure qualité',
    'test.recognition': 'Reconnaissance plus rapide',
    'test.submit': 'Paramétrez',
    'btn.login': 'Connexion',
    'btn.continue': 'Continuer',
    'btn.sign': "S'inscrire",
    'btn.submit': 'Envoyez',
    'btn.verify': 'Vérifiez maintenant',
    'sort.store': 'Magasin',
    'sort.madeBy': 'Fait par',
    'sort.amount': 'Montant',
    'sort.date': 'Date',
    'breadcrumbs.no.folders': 'Aucun dossier n’existe à cet emplacement',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    create: 'Créer',
    next: 'Suivant',
    back: 'Retour',
    save: 'Sauvegarder',
    apply: 'Appliquer',
    name: 'Nom',
    amount: 'Montant',
    from: 'De :',
    to: 'À :',
    receipts: 'Reçus',
    invoices: 'Factures',
    'new.folder': 'Nouveau dossier',
    folder: 'Dossier :',
    home: 'Maison',
    profile: 'Profil',
    'new.receipt': 'Nouveau reçu',
    'new.invoice': 'Nouvelle facture',
    'receipt.name': 'Nom du reçu',
    'toast.share': 'Partager le lien copié dans le presse-papiers',
    'modal.share': 'Choisissez le format du lien de partage',
    'modal.share.link': 'Associez',
    'modal.share.pdf': 'Fichier',
    'modal.share.download': 'Télécharger',
    'preview.name': 'Nom :',
    'preview.amount': 'Montant :',
    'preview.date': 'Date :',
    'preview.created': 'Créé par :',
    'preview.edit': 'Rédiger',
    'preview.share': 'Partager',
    'preview.delete': 'Effacer',
    'preview.close': 'Fermer',
    'preview.print': 'Imprimer',
    'categoryList.dont.1': 'dans la Catégorie',
    'categoryList.dont.2': " vous n'avez pas encore de reçus et de catégories",
    'filter.original': 'Original',
    'filter.bw': 'N & B',
    'filter.grey': 'Niveaux de gris',
    unfilledReceipt: 'Reçu non rempli',
    unfilledInvoice: 'Facture non remplie',
    tree: 'Arborescence de catégories',
    CreateNewFolder: 'Créer un Nouveau Dossier',
    alert: 'Êtes-vous sûr de vouloir supprimer ?',
    'set.date': 'paramétrer la date',
    'move.home': "Déplacer vers le dossier d'accueil",
    'move.parent': 'Déplacer vers le dossier parent',
    'add.item.additional.info-1': 'Vous pouvez avoir un maximum de ',
    'add.item.additional.info-2': ' comptes',
    'menu.profile': 'Modifier les infos',
    'menu.profile.manage': 'Administrer',
    'menu.quality': 'Qualité des reçus',
    'menu.recognition': 'Reconnaissance de reçu',
    'menu.terms': 'Termes et conditions',
    'menu.profile.info.currency': 'Devise:',
    'menu.profile.enter-promo': 'Entrez le code promo',
    'menu.profile.redeem-promo': 'Racheter',
    'menu.profile.redeem': 'Racheter',

    'menu.profile.photo': 'Changer la photo de profil',
    'menu.profile.change.data': 'Vous pouvez toujours modifier ces données',
    'menu.profile.delete.account': 'Supprimer le compte',
    'menu.profile.delete.profile': 'Supprimer le profil',
    'menu.profile.your.mail': 'Votre e-mail :',
    'menu.profile.add.mail': 'Ajouter une adresse e-mail',
    'menu.profile.new.mail': 'Nouvelle Adresse e-mail',
    'menu.profile.new.account': 'Nouveau compte',
    'menu.profile.account.empty': 'Ajouter un compte',
    'menu.profile.invite': 'Inviter ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'amis ',
    'menu.profile.have.yours': 'et obtenez votre compte ',
    'menu.profile.free': 'GRATUITEMENT',
    'menu.profile.invite.mail': 'Inviter des amis par e-mail',
    'menu.profile.invite.sms': 'Inviter des amis par SMS',
    'menu.profile.invite.share': 'Envoyer un lien à vos amis',
    'menu.profile.invite.gift': 'Offrir un cadeau à un ami',
    'menu.profile.password.current': 'Mot de passe actuel',
    'menu.profile.password.new': 'Nouveau mot de passe',
    'menu.profile.password.again':
      'Saisissez à nouveau le nouveau mot de passe',
    'menu.profile.password.forgot': 'Mot de passe oublié ?',
    'menu.profile.phone.your': 'Votre numéro de téléphone :',
    'menu.profile.phone.add': 'Ajouter un numéro de téléphone',
    'menu.profile.phone.new': 'Nouveau numéro de téléphone',
    'menu.profile.first': 'Prénom',
    'menu.profile.last': 'Nom de famille',
    'menu.profile.date': 'Date de naissance',
    'menu.profile.gender': 'Sexe',
    'menu.profile.currency': 'Devise',
    'menu.profile.male': 'Homme',
    'menu.profile.female': 'Femme',
    'menu.invite': 'Inviter un ami',
    'menu.plan': 'Abonnement',
    'menu.plan.title': 'Compte Premium iReceipt',
    'menu.plan.tab.header.1': 'Caractéristiques',
    'menu.plan.tab.header.2': 'Basique',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Sauvegarder les reçus',
    'menu.plan.tab.body.2': 'Partager les reçus',
    'menu.plan.tab.body.3': 'Stockage en ligne',
    'menu.plan.tab.body.4': 'Créer des dossiers',
    'menu.plan.tab.body.5': 'Partager des dossiers',
    'menu.plan.tab.body.6': 'Discuter',
    'menu.plan.tab.body.7': 'Stockage illimité',
    'menu.profile.plan.title': 'PASSER AU PREMIUM',
    'menu.profile.plan.description':
      'Sauvegarder et partager vos reçus, créer des dossiers et partager des dossiers au format PDF ou sous forme de lien vers PDF',
    'menu.profile.plan.business': 'Professionnel',
    'menu.profile.plan.argument.1.title': 'stockage illimité',
    'menu.profile.plan.argument.1.description':
      "sauvegarder un nombre illimité de reçus et y avoir accès à partir de n'importe quel appareil",
    'menu.profile.plan.argument.2.title': "jusqu'à ",
    'menu.profile.plan.argument.2.title-2': ' comptes',
    'menu.profile.plan.argument.2.business.description':
      "créez votre compte d'entreprise et faites des comptes séparés pour les membres de l'entreprise et le travail",
    'menu.profile.plan.subtitle.1': "Essai gratuit jusqu'à ",
    'menu.profile.plan.subtitle.2': ' reçus',
    'menu.profile.plan.proposals.1.period': 'Mensuel',
    'menu.profile.plan.proposals.1.per-period': 'par mois',
    'menu.profile.plan.proposals.2.period': 'Annuel',
    'menu.profile.plan.proposals.2.per-period': 'par an',
    'menu.profile.plan.proposals.best-value': 'meilleur prix',
    'menu.profile.plan.proposals.info-1': "C'est seulement ",
    'menu.profile.plan.proposals.info-2': ' pendant un mois',
    'menu.profile.plan.soon':
      'Plus de fonctionnalités à venir pour nos utilisateurs premium',
    'menu.profile.plan.restore': 'Annuler achat',
    'menu.profile.plan.bottom-info':
      "Le paiement sera débité du compte Apple lors de la confirmation de l'achat. L'abonnement se renouvelle automatiquement sauf si le renouvellement automatique est désactivé au moins 24 heures avant la fin de la période en cours. Le compte sera facturé pour le renouvellement dans les 24 heures précédant la fin de la période en cours et déterminera le coût du renouvellement. Les abonnements peuvent être administrés par l'utilisateur et le renouvellement automatique peut être désactivé en accédant aux paramètres du compte de l'utilisateur après l'achat.",
    'menu.profile.plan.coming.soon': 'Bientôt disponible...',
    'menu.profile.plan.coming.soon.alert':
      "Dès que le plan d'abonnement 'Business' sera disponible, vous recevrez une notification push. Veuillez vous assurer que vos notifications sont activées pour ne pas manquer cette importante mise à jour",
    'menu.profile.plan.notify': 'Informez-moi en premier',
    'menu.profile.permission.viewing': 'Droits de visionnage',
    'menu.profile.permission.editing': "Droits d'édition",
    'menu.profile.permission.deletion': 'Droits de suppression',
    'menu.profile.permission.admin.view.info':
      "L'octroi de privilèges en tant qu'administrateur peut être accordé à des groupes de participants âgés de plus de 18 ans. Un utilisateur disposant de cette autorisation pourra consulter les reçus et les factures",
    'menu.profile.permission.admin.edit.info':
      "L'octroi de privilèges en tant qu'administrateur peut être accordé à des groupes de participants âgés de plus de 18 ans. Un utilisateur disposant de cette autorisation pourra modifier les reçus et les factures",
    'menu.profile.permission.admin.delete.info':
      "L'octroi de privilèges en tant qu'administrateur peut être accordé à des groupes de participants âgés de plus de 18 ans. Un utilisateur disposant de cette autorisation pourra supprimer les reçus et les factures",
    'menu.profile.remove.confirm1':
      'Êtes-vous sûr de vouloir supprimer votre compte avec toutes les données?',
    'menu.profile.remove.confirm2':
      "TOUTES LES DONNÉES DE VOTRE COMPTE, LES PARAMÈTRES, LES DOCUMENTS TÉLÉCHARGÉS, LES CATÉGORIES SERONT SUPPRIMÉS DE FAÇON PERMANENTE POUR TOUJOURS !!! ÊTES-VOUS D'ACCORD?",
    'menu.free': 'Gratuit',
    'menu.monthly': 'mensuel',
    'menu.yearly': 'annuel',
    'menu.about': 'À propos de',
    'menu.lang': 'Langue',
    'menu.theme': "Thème d'application",
    'menu.theme.title': 'Choisissez la palette',
    'menu.theme.subtitle':
      "Profitez de l'application avec vos couleurs préférées",
    'menu.lang.choose': 'Choisir la langue :',
    'menu.lang.choose2': 'Choisissez la langue de reconnaissance :',
    'menu.lang.uk': 'Ukrainien',
    'menu.lang.en': 'Anglais',
    'menu.lang.no': 'Norvégien',
    'menu.lang.ru': 'Russe',
    'menu.lang.pt': 'Portugais',
    'menu.lang.pl': 'Polonais',
    'menu.lang.fi': 'Finlandais',
    'menu.lang.se': 'Suédois',
    'menu.lang.it': 'Italien',
    'menu.lang.fr': 'Français',
    'menu.lang.es': 'Espanol',
    'menu.lang.de': 'Allemand',
    'menu.lang.da': 'Danois',
    'menu.account': 'Changer de compte',
    'menu.account.add': 'Partager le compte',
    'menu.account.notHave': "vous n'avez pas de comptes partagés",
    'menu.logout': 'Se déconnecter',
    'menu.logout.alternatives': 'Autres possibilités',
    'menu.logout.modal.title': 'Êtes-vous sûr de vouloir vous déconnecter ?',
    'menu.support': 'Contacter le service support',
    'menu.password': 'Changer le mot de passe',
    'menu.email': 'E-mail',
    'menu.phone': 'Numéro de téléphone',
    'menu/settings': 'Paramètres',
    'menu/settings/profile': 'Modifier le profil',
    'menu/settings/invite': 'Inviter un ami',
    'menu/settings/plan': 'Abonnement',
    'menu/settings/password': 'Mot de passe',
    'menu/settings/email': 'E-mail',
    'menu/settings/phone': 'Téléphone',
    'menu/settings/language': 'Langue',
    'menu/settings/quality': 'Qualité des reçus',
    'menu/settings/about': 'À propos de',
    'menu/settings/account': 'Changer de compte',
    'menu.settings.account.free': 'Compte gratuit',
    'menu.settings.account.business': 'Compte professionnel',
    'menu.settings.account.add': 'Ajouter un compte',

    'menu.plan.personal.storage-title': 'Stockage - jusqu’à 10 Go',
    'menu.plan.personal.storage-description':
      'Sauvegardez les reçus et accédez-y depuis n’importe quel appareil',
    'menu.plan.family.storage-title': 'Stockage - jusqu’à 20 Go',
    'menu.plan.family.storage-description':
      'Sauvegardez les reçus et accédez-y depuis n’importe quel appareil',

    'recognize.categoryName': 'reconnaître le nom',
    'recognize.categoryAmount': 'reconnaître le montant',
    saveTo: 'Sauvegarder dans..',
    'save.new': 'Sauvegarder et créer nouveau',
    'save.close': 'Sauvegarder et fermer',

    'all.profiles': 'Tous les profils',
    'style.rotate': 'Pivoter',
    'style.correction': 'Correction',
    'style.color': 'Couleur',
    'style.intensity': 'Intensité',
    'style.style': 'Luminosité',
    'style.contrast': 'Contraste',
    'move.to.receipts': 'Déplacer vers reçus',
    'move.to.receipts.modal.title':
      'Cette facture sera déplacée vers les reçus',
    'move.to.invoices': 'Déplacer vers factures',
    'move.to.invoices.modal.title': 'Ce reçu sera déplacé vers les factures',
    'create.new.folder': 'Créer un nouveau dossier',
    'share.modal.web.copy': 'Copier le lien vers le presse-papier',
    'app.loading': 'Chargement',
    'receipt.quality': 'qualité du reçu',

    'this.month': 'Ce mois-ci',
    'last.month': 'Le mois dernie',
    'notifications.tryOpenReceiptLater': "Essayez d'ouvrir le reçu plus tard",

    select: 'Sélectionner',

    recognition: 'Reconnaissance',
    'recognition.none': 'Rien',
    'recognition.fast': 'Rapide',
    'recognition.accurate': 'Précise',
  },
  es: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Prueba gratuita de 3 días',
    'app.buy': 'Suscribirse',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'crea tu cuenta plus y controla tus recibos y facturas',
    'menu.settings.account.personal': 'Cuenta Plus',
    'menu.profile.plan.argument.2.family.description':
      'crea tu cuenta premium y crea cuentas separadas para la familia y el trabajo',
    'menu.settings.account.family': 'Cuenta Premium',
    'app.privacyPolicy': 'Política de privacidad',
    'app.licenseUserAgreement': 'Acuerdo de licencia de usuario',
    'app.gdpr': 'El Reglamento General de Protección de Datos (RGPD)',
    unfilledDocument: 'Documento sin completar',
    'categoryList.no-documents': 'Aún no hay documentos',
    'new.document': 'Nuevo documento',
    'document.name': 'Nombre del documento',
    'auth.h1': 'Bienvenido',
    'auth.p': 'Te damos la bienvenida a tu organizador de recibos y facturas',
    'auth.social': 'O a través de las redes sociales.',
    'login.h1': '¡Bienvenido de nuevo!',
    'login.email': 'Dirección de correo electrónico',
    'login.pass': 'Contraseña',
    'login.forgot': '¿Has olvidado tu contraseña?',
    'login.otp': 'Iniciar sesión utilizando la contraseña de un solo uso.',
    'login.footer': '¿No tienes una cuenta?',
    'login.footer.up': 'Registrarse',
    'signUp.h1': 'Registrarse',
    'signUp.email': 'Tu correo electrónico',
    'signUp.pass': 'Contraseña',
    'signUp.success':
      '¡Éxito! Revisa tu correo electrónico para confirmar la nueva cuenta.',
    'signUp.footer': '¿Ya tienes una cuenta?',
    'signUp.footer.in': 'Iniciar sesión',
    'forgot.h1': '¿Has olvidado tu contraseña?',
    'forgot.p':
      'Introduce la dirección de correo electrónico asociada a esta cuenta.',
    'forgot.success':
      '¡Éxito! Revisa tu correo electrónico para restablecer la contraseña.',
    'forgot.email': 'Introduce tu dirección de correo electrónico.',
    'reset.h1': 'Nueva contraseña',
    'reset.p': 'Introduce una nueva contraseña.',
    'reset.success':
      '¡Éxito! Revisa tu correo electrónico para restablecer la contraseña.',
    'reset.pass': 'Contraseña',
    'reset.confirm': 'Confirmar contraseña',
    'otp.h1': 'Iniciar sesión utilizando la contraseña de un solo uso.',
    'otp.p': 'Enviaremos una contraseña de un solo uso a tu número de móvil.',
    'verify.h1': 'Verificación',
    'verify.p': 'Introduce el número de 4 dígitos enviado a',
    'verify.receive': '¿No lo has recibido?',
    'verify.reSend': 'Volver a enviar ',
    'mailConfirm.text': 'Algo salió mal:',
    'mailConfirm.success': '¡Éxito! Correo electrónico confirmado',
    'mailConfirm.goTo': 'Ir a la aplicación',
    'test.title': 'Determinar la calidad',
    'test.quality': 'Mejor calidad',
    'test.recognition': 'Reconocimiento más rápido',
    'test.submit': 'determinar',
    'btn.login': 'Inicio de sesión',
    'btn.continue': 'Continuar',
    'btn.sign': 'Registrarse',
    'btn.submit': 'Enviar',
    'btn.verify': 'Verificar ahora',
    'sort.store': 'Tienda',
    'sort.madeBy': 'Hecho por',
    'sort.amount': 'Importe',
    'sort.date': 'Fecha',
    'breadcrumbs.no.folders': 'Aún no hay carpetas aquí',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    create: 'Crear',
    next: 'Siguiente',
    back: 'Atrás',
    save: 'Guardar',
    apply: 'Aplicar',
    name: 'Nombre',
    amount: 'Importe',
    from: 'De:',
    to: 'Para:',
    receipts: 'Recibos',
    invoices: 'Facturas',
    'new.folder': 'Nueva carpeta',
    folder: 'Carpeta:',
    home: 'Inicio',
    profile: 'Perfil',
    'new.receipt': 'Nuevo recibo',
    'new.invoice': 'Nueva factura',
    'receipt.name': 'Nombre del recibo',
    'toast.share': 'Compartir enlace copiado en el portapapeles',
    'modal.share': 'Elegir el formato del enlace para compartir',
    'modal.share.link': 'Enlace',
    'modal.share.pdf': 'Archivo',
    'modal.share.download': 'Descargar',
    'preview.name': 'Nombre:',
    'preview.amount': 'Importe:',
    'preview.date': 'Fecha:',
    'preview.created': 'Creado por:',
    'preview.edit': 'Editar',
    'preview.share': 'Compartir',
    'preview.delete': 'Eliminar',
    'preview.close': 'Cerrar',
    'preview.print': 'Imprimir',
    'categoryList.dont.1': 'en categoría',
    'categoryList.dont.2': ' aún no tienes recibos ni categorías',
    'filter.original': 'Original',
    'filter.bw': 'Blanco y negro',
    'filter.grey': 'Escala de grises',
    unfilledReceipt: 'Recibo no rellenado',
    unfilledInvoice: 'Factura no rellenada',
    tree: 'Árbol de categorías',
    CreateNewFolder: 'Crear nueva carpeta',
    alert: '¿Estás seguro de que deseas eliminar esto?',
    'set.date': 'Fijar la fecha',
    'move.home': 'Mover a la carpeta de inicio',
    'move.parent': 'Mover a la carpeta principal',
    'add.item.additional.info-1': 'Puedes tener un máximo de ',
    'add.item.additional.info-2': ' cuentas',
    'menu.profile': 'Editar información',
    'menu.profile.manage': 'Administrar',
    'menu.quality': 'Calidad del recibo',
    'menu.recognition': 'Reconocimiento de recibos',
    'menu.terms': 'Términos y condiciones',
    'menu.profile.info.currency': 'Divisa:',
    'menu.profile.enter-promo': 'Introduce el código promocional',
    'menu.profile.redeem-promo': 'Canjear código promocional',
    'menu.profile.redeem': 'Canjear',

    'menu.profile.photo': 'Cambiar foto de perfil',
    'menu.profile.change.data': 'Siempre puedes cambiar estos datos',
    'menu.profile.delete.account': 'Eliminar cuenta',
    'menu.profile.delete.profile': 'Eliminar perfil',
    'menu.profile.your.mail': 'Tu correo electrónico:',
    'menu.profile.add.mail': 'Añadir correo electrónico',
    'menu.profile.new.mail': 'Nuevo correo electrónico',
    'menu.profile.new.account': 'Nueva cuenta',
    'menu.profile.account.empty': 'Añadir cuenta',
    'menu.profile.invite': 'Invita a ',
    'menu.profile.5premium': '5 amigos ',
    'menu.profile.friends': 'PREMIUM ',
    'menu.profile.have.yours': 'y obtén la tuya ',
    'menu.profile.free': 'GRATIS',
    'menu.profile.invite.mail': 'Invita a amigos por correo electrónico.',
    'menu.profile.invite.sms': 'Invita a amigos por SMS.',
    'menu.profile.invite.share': 'Envía un enlace a tus amigos',
    'menu.profile.invite.gift': 'Haz un regalo a un amigo',
    'menu.profile.password.current': 'Contraseña actual',
    'menu.profile.password.new': 'Nueva contraseña',
    'menu.profile.password.again': 'Introduce la nueva contraseña nuevamente.',
    'menu.profile.password.forgot': '¿Has olvidado tu contraseña?',
    'menu.profile.phone.your': 'Tu número de teléfono:',
    'menu.profile.phone.add': 'Añadir número de teléfono',
    'menu.profile.phone.new': 'Nuevo teléfono',
    'menu.profile.first': 'Nombre',
    'menu.profile.last': 'Apellido',
    'menu.profile.date': 'Fecha de nacimiento',
    'menu.profile.gender': 'Género',
    'menu.profile.currency': 'Divisa',
    'menu.profile.male': 'Hombre',
    'menu.profile.female': 'Mujer',
    'menu.profile.remove.confirm1':
      '¿Está seguro de que desea eliminar su cuenta con todos los datos?',
    'menu.profile.remove.confirm2':
      '¡¡¡TODOS LOS DATOS DE SU CUENTA, CONFIGURACIONES, DOCUMENTOS CARGADOS, CATEGORÍAS SE ELIMINARÁN PERMANENTEMENTE PARA SIEMPRE!!! ¿ESTÁS DE ACUERDO?',
    'menu.invite': 'Invita a un amigo.',
    'menu.plan': 'Plan de suscripción',
    'menu.plan.title': 'Cuenta iReceipt Premium',
    'menu.plan.tab.header.1': 'Funciones',
    'menu.plan.tab.header.2': 'Básicas',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Guardar recibos',
    'menu.plan.tab.body.2': 'Compartir recibos',
    'menu.plan.tab.body.3': 'Almacenamiento en la nube',
    'menu.plan.tab.body.4': 'Crear carpetas',
    'menu.plan.tab.body.5': 'Compartir carpetas',
    'menu.plan.tab.body.6': 'Chatear con otros',
    'menu.plan.tab.body.7': 'Almacenamiento ilimitado',
    'menu.profile.plan.title': 'HAZTE PREMIUM',
    'menu.profile.plan.description':
      'Guarda y comparte tus recibos, crea y comparte carpetas como PDF o como un enlace a un PDF',
    'menu.profile.plan.business': 'Comercial',
    'menu.profile.plan.argument.1.title': 'almacenamiento ilimitado',
    'menu.profile.plan.argument.1.description':
      'guarda un número ilimitado de recibos y accede a ellos desde cualquier dispositivo',
    'menu.profile.plan.argument.2.title': 'hasta ',
    'menu.profile.plan.argument.2.title-2': ' cuentas',

    'menu.profile.plan.argument.2.business.description':
      'crea tu cuenta de negocios y haz cuentas separadas para los miembros del negocio y el trabajo',
    'menu.profile.plan.subtitle.1': 'Prueba gratuita hasta ',
    'menu.profile.plan.subtitle.2': ' recibos',
    'menu.profile.plan.proposals.1.period': 'Mensual',
    'menu.profile.plan.proposals.1.per-period': 'por mes',
    'menu.profile.plan.proposals.2.period': 'Anual',
    'menu.profile.plan.proposals.2.per-period': 'por año',
    'menu.profile.plan.proposals.best-value': 'mejor valor',
    'menu.profile.plan.proposals.info-1': 'Eso es solo ',
    'menu.profile.plan.proposals.info-2': ' un mes',
    'menu.profile.plan.soon':
      'Próximamente más funciones para nuestros usuarios premium',
    'menu.profile.plan.restore': 'Restablecer lo comprado',
    'menu.profile.plan.bottom-info':
      'El pago se cargará a la cuenta de Apple al confirmar la compra. La suscripción se renueva automáticamente a menos que la renovación automática se desactive al menos 24 horas antes de que finalice el período actual. La renovación se cargará en la cuenta dentro de las 24 horas previas a la finalización del periodo actual, identificando el coste de la renovación. Las suscripciones pueden ser administradas por el usuario y la renovación automática puede desactivarse accediendo a la configuración de la cuenta del usuario después de la compra.',
    'menu.profile.plan.coming.soon': 'Próximamente...',
    'menu.profile.plan.coming.soon.alert':
      "Tan pronto como el plan de suscripción 'Negocios' esté disponible, recibirás una notificación push. Por favor, asegúrate de que tus notificaciones están activadas para no perderte esta importante actualización",
    'menu.profile.plan.notify': 'Notificarme primero',
    'menu.profile.permission.viewing': 'Derechos de visualización',
    'menu.profile.permission.editing': 'Derechos de edición',
    'menu.profile.permission.deletion': 'Derechos de eliminación',
    'menu.profile.permission.admin.view.info':
      'La concesión de privilegios de administrador puede otorgarse a grupos de participantes mayores de 18 años. Un usuario que cuente con este permiso podrá ver recibos y facturas.',
    'menu.profile.permission.admin.edit.info':
      'La concesión de privilegios de administrador puede otorgarse a grupos de participantes mayores de 18 años. Un usuario que cuente con este permiso podrá editar recibos y facturas.',
    'menu.profile.permission.admin.delete.info':
      'La concesión de privilegios de administrador puede otorgarse a grupos de participantes mayores de 18 años. Un usuario que cuente con este permiso podrá eliminar recibos y facturas.',
    'menu.free': 'Gratis',
    'menu.monthly': 'mensual',
    'menu.yearly': 'anual',
    'menu.about': 'Acerca de',
    'menu.lang': 'Idioma',
    'menu.theme': 'Tema de la aplicación',
    'menu.theme.title': 'Elige la paleta',
    'menu.theme.subtitle':
      'Disfruta de la aplicación con tus colores favoritos',
    'menu.lang.choose': 'Elegir idioma:',
    'menu.lang.choose2': 'Elegir reconocer idioma:',
    'menu.lang.uk': 'Ucraniano',
    'menu.lang.en': 'Inglés',
    'menu.lang.no': 'Noruego',
    'menu.lang.ru': 'Ruso',
    'menu.lang.pt': 'Portugués',
    'menu.lang.pl': 'Polaco',
    'menu.lang.fi': 'Finlandés',
    'menu.lang.se': 'Sueco',
    'menu.lang.it': 'Italiano',
    'menu.lang.fr': 'Francés',
    'menu.lang.es': 'Español',
    'menu.lang.de': 'Alemán',
    'menu.lang.da': 'Danés',
    'menu.account': 'Cambiar de cuenta',
    'menu.account.add': 'Compartir cuenta',
    'menu.account.notHave': 'no tienes cuentas compartidas',
    'menu.logout': 'Cerrar sesión',
    'menu.logout.alternatives': 'Opciones alternativas',
    'menu.logout.modal.title': '¿Estás seguro de que deseas cerrar la sesión?',
    'menu.support': 'Contacto para asistencia',
    'menu.password': 'Cambiar la contraseña',
    'menu.email': 'Correo electrónico',
    'menu.phone': 'Número de teléfono',
    'menu/settings': 'Ajustes',
    'menu/settings/profile': 'Editar perfil',
    'menu/settings/invite': 'Invitar a un amigo',
    'menu/settings/plan': 'Plan de suscripción',
    'menu/settings/password': 'Contraseña',
    'menu/settings/email': 'Correo electrónico',
    'menu/settings/phone': 'Teléfono',
    'menu/settings/language': 'Idioma',
    'menu/settings/quality': 'Calidad del recibo',
    'menu/settings/about': 'Acerca de',
    'menu/settings/account': 'Cambiar de cuenta',
    'menu.settings.account.free': 'Cuenta gratis',

    'menu.settings.account.business': 'Cuenta comercial',

    'menu.settings.account.add': 'Añadir cuenta',

    'menu.plan.personal.storage-title': 'Almacenamiento - hasta 10 GB',
    'menu.plan.personal.storage-description':
      'Guarda recibos y accede a ellos desde cualquier dispositivo',
    'menu.plan.family.storage-title': 'Almacenamiento - hasta 20 GB',
    'menu.plan.family.storage-description':
      'Guarda recibos y accede a ellos desde cualquier dispositivo',

    'recognize.categoryName': 'reconociendo el nombre',
    'recognize.categoryAmount': 'reconociendo el importe',
    saveTo: 'Guardar en..',
    'save.new': 'Guardar y nuevo',
    'save.close': 'Guardar y cerrar',

    'all.profiles': 'Todos los perfiles',
    'style.rotate': 'Girar',
    'style.correction': 'Corrección',
    'style.color': 'Color',
    'style.intensity': 'Intensidad',
    'style.style': 'Brillo',
    'style.contrast': 'Contraste',
    'move.to.receipts': 'Mover a recibos',
    'move.to.receipts.modal.title': 'Esta factura será movida a recibos',
    'move.to.invoices': 'Mover a facturas',
    'move.to.invoices.modal.title': 'Este recibo será movido a facturas',
    'create.new.folder': 'Crear carpeta nueva',
    'share.modal.web.copy': 'Copiar enlace al portapapeles',
    'app.loading': 'Cargando',
    'receipt.quality': 'calidad del recibo',

    'this.month': 'Este mes',
    'last.month': 'El mes pasado',
    'notifications.tryOpenReceiptLater': 'Intenta abrir el recibo más tarde',

    select: 'Seleccione',

    recognition: 'Reconocimiento',
    'recognition.none': 'Ninguno',
    'recognition.fast': 'Rápido',
    'recognition.accurate': 'Preciso',
  },
  de: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Kostenlose Testversion für 3 Tage',
    'app.buy': 'Abonnieren',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'erstellen Sie Ihr Plus-Konto und behalten Sie Ihre Quittungen und Rechnungen im Auge',
    'menu.settings.account.personal': 'Plus-Konto',
    'menu.profile.plan.argument.2.family.description':
      'erstellen Sie Ihr Premium-Konto und erstellen Sie separate Konten für Familie und Arbeit',
    'menu.settings.account.family': 'Premium-Konto',
    'app.privacyPolicy': 'Datenschutz-Bestimmungen',
    'app.licenseUserAgreement': 'Lizenz-Nutzungsvereinbarung',
    'app.gdpr': 'Die Datenschutz-Grundverordnung (DSGVO)',
    'document.name': 'Dokumentname',
    unfilledDocument: 'Ungefülltes Dokument',
    'categoryList.no-documents': 'Noch keine Dokumente',
    'new.document': 'Neues Dokument',
    'auth.h1': 'Herzlich willkommen',
    'auth.p': 'Willkommen in deiner Wallet für Belege & Rechnungen',
    'auth.social': 'Oder über soziale Medien',
    'login.h1': 'Willkommen zurück!',
    'login.email': 'E-Mail-Adresse',
    'login.pass': 'Passwort',
    'login.forgot': 'Passwort vergessen',
    'login.otp': 'Melden Sie sich über OTP an',
    'login.footer': 'Sie haben noch keinen Account?',
    'login.footer.up': 'Registrieren',
    'signUp.h1': 'Registrieren',
    'signUp.email': 'Ihre E-Mail',
    'signUp.pass': 'Passwort',
    'signUp.success':
      'Erfolg: Überprüfen Sie Ihren E-Mail-Eingang, um das neue Konto zu bestätigen',
    'signUp.footer': 'Sie haben bereits ein Konto?',
    'signUp.footer.in': 'Anmelden',
    'forgot.h1': 'Passwort vergessen?',
    'forgot.p': 'Geben Sie die mit diesem Konto verknüpfte E-Mail-Adresse ein',
    'forgot.success':
      'Erfolg: Überprüfen Sie den Posteingang Ihres E-Mail-Kontos, um das Passwort zurückzusetzen',
    'forgot.email': 'Geben sie ihre E-Mailadresse ein',
    'reset.h1': 'Neues Passwort',
    'reset.p': 'Neues Passwort eingeben',
    'reset.success':
      'Erfolg: Überprüfen Sie den Posteingang Ihres E-Mail-Kontos, um das Passwort zurückzusetzen',
    'reset.pass': 'Passwort',
    'reset.confirm': 'Kennwort bestätigen',
    'otp.h1': 'Anmelden via OTP',
    'otp.p': 'Wir senden ein OTP an Ihre Handynummer',
    'verify.h1': 'Überprüfung',
    'verify.p': 'Geben Sie die 4-stellige Nummer ein, die gesendet wurde',
    'verify.receive': 'Nicht erhalten?',
    'verify.reSend': 'Erneut senden ',
    'mailConfirm.text': 'Etwas ist schiefgelaufen:',
    'mailConfirm.success': 'Erfolg: E-Mail bestätigt',
    'mailConfirm.goTo': 'Zur App gehen',
    'test.title': 'Qualität einstellen',
    'test.quality': 'Bessere Qualität',
    'test.recognition': 'Schnellere Erkennung',
    'test.submit': 'einstellen',
    'btn.login': 'Anmeldung',
    'btn.continue': 'Fortsetzen',
    'btn.sign': 'Registrieren',
    'btn.submit': 'Einreichen',
    'btn.verify': 'Jetzt prüfen',
    'sort.store': 'Geschäft',
    'sort.madeBy': 'Erstellt von',
    'sort.amount': 'Betrag',
    'sort.date': 'Datum',
    'breadcrumbs.no.folders': 'Noch keine Ordner hier',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    create: 'Erstellen',
    next: 'Weiter',
    back: 'Zurück',
    save: 'Speichern',
    apply: 'Anwenden',
    name: 'Name',
    amount: 'Betrag',
    from: 'Von:',
    to: 'An:',
    receipts: 'Quittungen',
    invoices: 'Rechnungen',
    'new.folder': 'Neuer Ordner',
    folder: 'Mappe:',
    home: 'Home',
    profile: 'Profil',
    'new.receipt': 'Neuer Beleg',
    'new.invoice': 'Neue Rechnung',
    'receipt.name': 'Quittungsname',
    'toast.share': 'In die Zwischenablage kopierter Freigabelink',
    'modal.share': 'Freigabe-Link-Format wählen',
    'modal.share.link': 'Link',
    'modal.share.pdf': 'Datei',
    'modal.share.download': 'Herunterladen',
    'preview.name': 'Name:',
    'preview.amount': 'Betrag:',
    'preview.date': 'Datum:',
    'preview.created': 'Erstellt von:',
    'preview.edit': 'Bearbeiten',
    'preview.share': 'Teilen',
    'preview.delete': 'Löschen',
    'preview.close': 'Schließen',
    'preview.print': 'Drucken',
    'categoryList.dont.1': 'in der Kategorie',
    'categoryList.dont.2': ' Sie haben noch keine Belege und Kategorien',
    'filter.original': 'Original',
    'filter.bw': 'S/W',
    'filter.grey': 'Graustufen',
    unfilledReceipt: 'Nicht ausgefüllte Quittung',
    unfilledInvoice: 'Nicht ausgefüllte Rechnung',
    tree: 'Kategoriebaum',
    CreateNewFolder: 'Neuen Ordner erstellen',
    alert: 'Sind Sie sicher, dass Sie löschen möchten?',
    'set.date': 'Datum einstellen',
    'move.home': 'In den Home-Ordner verschieben',
    'move.parent': 'In übergeordneten Ordner verschieben',
    'add.item.additional.info-1': 'Die maximale Anzahl beträgt ',
    'add.item.additional.info-2': ' Konten',
    'menu.profile': 'Info bearbeiten',
    'menu.profile.manage': 'Verwalten',
    'menu.quality': 'Belegqualität',
    'menu.recognition': 'Empfangserkennung',
    'menu.terms': 'Geschäftsbedingungen',
    'menu.profile.info.currency': 'Währung:',
    'menu.profile.photo': 'Profilfoto ändern',
    'menu.profile.enter-promo': 'Promo-Code eingeben',
    'menu.profile.redeem-promo': 'Promo-Code einlösen',
    'menu.profile.redeem': 'Einlösen',

    'menu.profile.change.data': 'Sie können diese Daten jederzeit ändern',
    'menu.profile.delete.account': 'Konto löschen',
    'menu.profile.delete.profile': 'Profil löschen',
    'menu.profile.your.mail': 'Ihre E-Mail:',
    'menu.profile.add.mail': 'E-Mail hinzufügen',
    'menu.profile.new.mail': 'Neue E-Mail',
    'menu.profile.new.account': 'Neues Konto',
    'menu.profile.account.empty': 'Konto hinzufügen',
    'menu.profile.invite': 'Einladen ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'Freunde ',
    'menu.profile.have.yours': 'und bekommen Ihre ',
    'menu.profile.free': 'KOSTENLOS',
    'menu.profile.invite.mail': 'Laden Sie Freunde per E-Mail ein',
    'menu.profile.invite.sms': 'Laden Sie Freunde per SMS ein',
    'menu.profile.invite.share': 'Senden Sie einen Link an Ihre Freunde',
    'menu.profile.invite.gift': 'Einen Freund beschenken',
    'menu.profile.password.current': 'Jetziges Passwort',
    'menu.profile.password.new': 'Neues Passwort',
    'menu.profile.password.again': 'Geben Sie das neue Passwort erneut ein',
    'menu.profile.password.forgot': 'Passwort vergessen?',
    'menu.profile.phone.your': 'Ihre Telefonnummer:',
    'menu.profile.phone.add': 'Telefonnummer hinzufügen',
    'menu.profile.phone.new': 'Neue Telefonnummer',
    'menu.profile.first': 'Vorname',
    'menu.profile.last': 'Nachname',
    'menu.profile.date': 'Geburtsdatum',
    'menu.profile.gender': 'Geschlecht',
    'menu.profile.currency': 'Währung',
    'menu.profile.male': 'Männlich',
    'menu.profile.female': 'Weiblich',
    'menu.profile.remove.confirm1':
      'Sind Sie sicher, dass Sie Ihr Konto mit allen Daten löschen möchten?',
    'menu.profile.remove.confirm2':
      'ALLE IHRE KONTODATEN, EINSTELLUNGEN, HOCHGELADENEN DOKUMENTE, KATEGORIEN WERDEN FÜR IMMER GELÖSCHT!!! SIND SIE EINVERSTANDEN?',
    'menu.invite': 'Freund einladen',
    'menu.plan': 'Abonnementplan',
    'menu.plan.title': 'iReceipt Premium-Konto',
    'menu.plan.tab.header.1': 'Eigenschaften',
    'menu.plan.tab.header.2': 'Basic',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Quittungen speichern',
    'menu.plan.tab.body.2': 'Quittungen teilen',
    'menu.plan.tab.body.3': 'Cloud-Speicher',
    'menu.plan.tab.body.4': 'Ordner erstellen',
    'menu.plan.tab.body.5': 'Ordner freigeben',
    'menu.plan.tab.body.6': 'Mit anderen chatten',
    'menu.plan.tab.body.7': 'Unbegrenzter Speicherplatz',
    'menu.profile.plan.title': 'GO PREMIUM',
    'menu.profile.plan.description':
      'Speichern und teilen Sie Ihre Belege, erstellen Sie Ordner und teilen Sie Ordner als PDF oder als Link zu PDF',
    'menu.profile.plan.business': 'Business',
    'menu.profile.plan.argument.1.title': 'unbegrenzter Speicherplatz',
    'menu.profile.plan.argument.1.description':
      'Speichern Sie eine unbegrenzte Anzahl von Belegen und greifen Sie von jedem Gerät aus darauf zu',
    'menu.profile.plan.argument.2.title': 'bis zu ',
    'menu.profile.plan.argument.2.title-2': ' Konten',
    'menu.profile.plan.argument.2.business.description':
      'erstellen Sie Ihr Geschäftskonto und erstellen Sie separate Konten für Geschäftsmitglieder und Arbeit',
    'menu.profile.plan.subtitle.1': 'Kostenlose Testversion bis zu ',
    'menu.profile.plan.subtitle.2': ' Quittungen',
    'menu.profile.plan.proposals.1.period': 'Monatlich',
    'menu.profile.plan.proposals.1.per-period': 'pro Monat',
    'menu.profile.plan.proposals.2.period': 'Jährlich',
    'menu.profile.plan.proposals.2.per-period': 'pro Jahr',
    'menu.profile.plan.proposals.best-value': 'bester Wert',
    'menu.profile.plan.proposals.info-1': 'Das ist nur ',
    'menu.profile.plan.proposals.info-2': ' ein Monat',
    'menu.profile.plan.soon':
      'Weitere Funktionen für unsere Premium-Benutzer folgen in Kürze',
    'menu.profile.plan.restore': 'Gekauft wiederherstellen',
    'menu.profile.plan.bottom-info':
      'Die Zahlung wird Ihrem Apple-Konto bei Kaufbestätigung in Rechnung gestellt. Das Abonnement verlängert sich automatisch, es sei denn, die automatische Verlängerung wird spätestens 24 Stunden vor dem Ende des aktuellen Zeitraums deaktiviert. Die Kontoverlängerung wird binnen 24 Stunden vor dem Ende des aktuellen Zeitraums in Rechnung gestellt und die Kosten für die Verlängerung angegeben. Abonnements können vom Benutzer verwaltet und die automatische Verlängerung kann deaktiviert werden, indem Sie nach dem Kauf die Kontoeinstellungen aufrufen.',
    'menu.profile.plan.coming.soon': 'Kommt demnächst...',
    'menu.profile.plan.coming.soon.alert':
      "Sobald der 'Business'-Abonnementplan verfügbar ist, erhalten Sie eine Push-Benachrichtigung. Bitte stellen Sie sicher, dass Ihre Benachrichtigungen aktiviert sind, damit Sie dieses wichtige Update nicht verpassen",
    'menu.profile.plan.notify': 'Benachrichtigen Sie mich zuerst',
    'menu.profile.permission.viewing': 'Anzeigerechte',
    'menu.profile.permission.editing': 'Bearbeitungsrechte',
    'menu.profile.permission.deletion': 'Löschrechte',
    'menu.profile.permission.admin.view.info':
      'Teilnehmergruppen über 18 Jahre können Admin-Rechte gewährt werden. Ein Benutzer mit dieser Berechtigung kann Belege und Rechnungen anzeigen',
    'menu.profile.permission.admin.edit.info':
      'Teilnehmergruppen über 18 Jahre können Admin-Rechte gewährt werden. Ein Benutzer mit dieser Berechtigung kann Belege und Rechnungen bearbeiten',
    'menu.profile.permission.admin.delete.info':
      'Teilnehmergruppen über 18 Jahre können Admin-Rechte gewährt werden. Ein Benutzer mit dieser Berechtigung kann Belege und Rechnungen löschen',
    'menu.free': 'Kostenlos',
    'menu.monthly': 'monatlich',
    'menu.yearly': 'jährlich',
    'menu.about': 'Über',
    'menu.lang': 'Sprache',
    'menu.theme': 'Anwendungsthema',
    'menu.theme.title': 'Wählen Sie die Palette',
    'menu.theme.subtitle':
      'Genießen Sie die Anwendung mit Ihren Lieblingsfarben',
    'menu.lang.choose': 'Sprache wählen:',
    'menu.lang.choose2': 'Spracherkennung wählen:',
    'menu.lang.ru': 'Russisch',
    'menu.lang.uk': 'Ukrainisch',
    'menu.lang.en': 'Englisch',
    'menu.lang.no': 'Norwegisch',
    'menu.lang.pt': 'Portugiesisch',
    'menu.lang.pl': 'Polieren',
    'menu.lang.fi': 'Finnisch',
    'menu.lang.se': 'Schwedisch',
    'menu.lang.it': 'Italienisch',
    'menu.lang.fr': 'Französisch',
    'menu.lang.es': 'Spanisch',
    'menu.lang.de': 'Deutsche',
    'menu.lang.da': 'Dänisch',
    'menu.account': 'Account wechseln',
    'menu.account.add': 'Konto teilen',
    'menu.account.notHave': 'Sie haben keine freigegebenen Konten',
    'menu.logout': 'Abmelden',
    'menu.logout.alternatives': 'Weitere Optionen',
    'menu.logout.modal.title': 'Möchten Sie sich abmelden?',
    'menu.support': 'Kontakt zum Support',
    'menu.password': 'Passwort ändern',
    'menu.email': 'E-Mail',
    'menu.phone': 'Telefonnummer',
    'menu/settings': 'Einstellungen',
    'menu/settings/profile': 'Profil bearbeiten',
    'menu/settings/invite': 'Freund einladen',
    'menu/settings/plan': 'Abonnementplan',
    'menu/settings/password': 'Passwort',
    'menu/settings/email': 'E-Mail',
    'menu/settings/phone': 'Telefon',
    'menu/settings/language': 'Sprache',
    'menu/settings/quality': 'Belegqualität',
    'menu/settings/about': 'Über',
    'menu/settings/account': 'Konto wechseln',
    'menu.settings.account.free': 'Kostenloser Konto',
    'menu.settings.account.business': 'Geschäftskonto',
    'menu.settings.account.add': 'Konto hinzufügen',

    'menu.plan.personal.storage-title': 'Speicherplatz - bis zu 10 GB',
    'menu.plan.personal.storage-description':
      'Speichern Sie Belege und greifen Sie von jedem Gerät aus darauf zu',
    'menu.plan.family.storage-title': 'Speicherplatz - bis zu 20 GB',
    'menu.plan.family.storage-description':
      'Speichern Sie Belege und greifen Sie von jedem Gerät aus darauf zu',

    'recognize.categoryName': 'Name erkennen',
    'recognize.categoryAmount': 'Betrag erkennen',
    saveTo: 'Speichern unter..',
    'save.new': 'Speichern und Neu',
    'save.close': 'Speichern und schließen',

    'all.profiles': 'Alle Profile',
    'style.rotate': 'Drehen',
    'style.correction': 'Korrektur',
    'style.color': 'Farbe',
    'style.intensity': 'Intensität',
    'style.style': 'Helligkeit',
    'style.contrast': 'Kontrast',
    'move.to.receipts': 'Verschieben zu Quittungen',
    'move.to.receipts.modal.title':
      'Diese Rechnung wird zu Quittungen verschoben',
    'move.to.invoices': 'Verschieben zu Rechnungen',
    'move.to.invoices.modal.title':
      'Diese Quittung wird zu Rechnungen verschoben',
    'create.new.folder': 'Neuen Ordner erstellen',
    'share.modal.web.copy': 'Link in Zwischenablage kopieren',
    'app.loading': 'Wird geladen',
    'receipt.quality': 'Belegqualität',

    'this.month': 'Diesen Monat',
    'last.month': 'Letzten Monat',
    'notifications.tryOpenReceiptLater':
      'Versuchen Sie später, den Beleg zu öffnen',

    select: 'Wählen',

    recognition: 'Erkennung',
    'recognition.none': 'Keine',
    'recognition.fast': 'Schnelle',
    'recognition.accurate': 'Genaue',
  },
  da: {
    'menu.profile.plan.family': 'Premium',
    'app.trial-tree-days': 'Gratis prøveperiode i 3 dage',
    'app.buy': 'Abonner',
    'menu.profile.plan.personal': 'Plus',
    'menu.profile.plan.argument.2.personal.description':
      'opret din pluskonto og få kontrol over dine kvitteringer og fakturaer',
    'menu.settings.account.personal': 'Pluskonto',
    'menu.profile.plan.argument.2.family.description':
      'opret din premiumkonto og lav separate konti til familie og arbejde',
    'menu.settings.account.family': 'Premiumkonto',
    'app.privacyPolicy': 'Fortrolighedspolitik',
    'app.licenseUserAgreement': 'Licensbrugeraftale',
    'app.gdpr': 'Den generelle databeskyttelsesforordning (GDPR)',
    'document.name': 'Dokumentnavn',
    unfilledDocument: 'Ikke udfyldt dokument',
    'categoryList.no-documents': 'Ingen dokumenter endnu',
    'new.document': 'Nyt dokument',
    'auth.h1': 'Velkommen',
    'auth.p': 'Velkommen til din pung med kvitteringer og fakturaer',
    'auth.social': 'Eller via sociale medier',
    'login.h1': 'Velkommen tilbage!',
    'login.email': 'E-mailadresse',
    'login.pass': 'Kodeord',
    'login.forgot': 'Glemt kodeord',
    'login.otp': 'Login via engangskode',
    'login.footer': 'Har du ikke en konto?',
    'login.footer.up': 'Opret konto',
    'signUp.h1': 'Opret konto',
    'signUp.email': 'Din e-mail',
    'signUp.pass': 'Kodeord',
    'signUp.success': 'Gennemført: Tjek din e-mail for at bekræfte ny konto',
    'signUp.footer': 'Har du allerede en konto?',
    'signUp.footer.in': 'Log ind',
    'forgot.h1': 'Glemt kodeord?',
    'forgot.p': 'Indtast e-mailadressen, der er knyttet til denne konto',
    'forgot.success':
      'Gennemført: Tjek din e-mail for at nulstille adgangskoden',
    'forgot.email': 'Indtast din e-mailadresse',
    'reset.h1': 'Nyt kodeord',
    'reset.p': 'Indtast nyt kodeord',
    'reset.success':
      'Gennemført: Tjek din e-mail for at nulstille adgangskoden',
    'reset.pass': 'Kodeord',
    'reset.confirm': 'Bekræft kodeord',
    'otp.h1': 'Login via engangskode',
    'otp.p': 'Vi sender en engangskode til dit mobilnummer',
    'verify.h1': 'Bekræftelse',
    'verify.p': 'Indtast det 4-cifrede nummer, der er sendt til',
    'verify.receive': 'Har du ikke modtaget koden?',
    'verify.reSend': 'Send igen ',
    'mailConfirm.text': 'Noget gik galt:',
    'mailConfirm.success': 'Gennemført: E-mail bekræftet',
    'mailConfirm.goTo': 'Gå til applikation',
    'test.title': 'Indstil kvaliteten',
    'test.quality': 'Bedre kvalitet',
    'test.recognition': 'Hurtigere genkendelse',
    'test.submit': 'indstil',
    'btn.login': 'Login',
    'btn.continue': 'Forsæt',
    'btn.sign': 'Opret konto',
    'btn.submit': 'Indsend',
    'btn.verify': 'Bekræft nu',
    'sort.store': 'Butik',
    'sort.madeBy': 'Lavet af',
    'sort.amount': 'Beløb',
    'sort.date': 'Dato',
    'breadcrumbs.no.folders': 'Endnu ingen mapper her',
    cancel: 'Afbryd',
    confirm: 'Bekræft',
    create: 'Opret',
    next: 'Næste',
    back: 'Tilbage',
    save: 'Gem',
    apply: 'Anvend',
    name: 'Navn',
    amount: 'Beløb',
    from: 'Fra:',
    to: 'Til:',
    receipts: 'Kvitteringer',
    invoices: 'Fakturaer',
    'new.folder': 'Ny mappe',
    folder: 'Mappe:',
    home: 'Hjem',
    profile: 'Profil',
    'new.receipt': 'Ny kvittering',
    'new.invoice': 'Ny faktura',
    'receipt.name': 'Kvitteringsnavn',
    'toast.share': 'Delingslink kopieret til udklipsholderen',
    'modal.share': 'Vælg format for delingslink',
    'modal.share.link': 'Link',
    'modal.share.pdf': 'Fil',
    'modal.share.download': 'Download',
    'preview.name': 'Navn:',
    'preview.amount': 'Beløb:',
    'preview.date': 'Dato:',
    'preview.created': 'Oprettet af:',
    'preview.edit': 'Rediger',
    'preview.share': 'Del',
    'preview.delete': 'Slet',
    'preview.close': 'Luk',
    'preview.print': 'Print',
    'categoryList.dont.1': 'i kategori',
    'categoryList.dont.2':
      ' du har ikke nogen kvitteringer eller kategorier endnu',
    'filter.original': 'Original',
    'filter.bw': 'B & W',
    'filter.grey': 'Gråtone',
    unfilledReceipt: 'Tom kvittering',
    unfilledInvoice: 'Tom faktura',
    tree: 'Kategoritræ',
    CreateNewFolder: 'Opret ny mappe',
    alert: 'Er du sikker på, at du vil slette?',
    'set.date': 'indstil dato',
    'move.home': 'Gå til mappen Hjem',
    'move.parent': 'Gå til overordnet mappe',
    'add.item.additional.info-1': 'Du kan have maksimalt ',
    'add.item.additional.info-2': ' konti',
    'menu.profile': 'Rediger info',
    'menu.profile.manage': 'Administrer',
    'menu.quality': 'Kvitteringskvalitet',
    'menu.recognition': 'Kvitteringsanerkendelse',
    'menu.terms': 'Vilkår og betingelser',
    'menu.profile.info.currency': 'Valuta:',
    'menu.profile.enter-promo': 'Indtast promokode',
    'menu.profile.redeem-promo': 'Indløs promokode',
    'menu.profile.redeem': 'Indløs',

    'menu.profile.photo': 'Skift profilbillede',
    'menu.profile.change.data': 'Du kan altid ændre disse oplysninger',
    'menu.profile.delete.account': 'Slet konto',
    'menu.profile.delete.profile': 'Slet profil',
    'menu.profile.your.mail': 'Din e-mail:',
    'menu.profile.add.mail': 'Tilføj e-mail',
    'menu.profile.new.mail': 'Ny e-mail',
    'menu.profile.new.account': 'Ny konto',
    'menu.profile.account.empty': 'Tilføj konto',
    'menu.profile.invite': 'Inviter ',
    'menu.profile.5premium': '5 PREMIUM ',
    'menu.profile.friends': 'venner ',
    'menu.profile.have.yours': 'og få din ',
    'menu.profile.free': 'GRATIS',
    'menu.profile.invite.mail': 'Inviter venner via e-mail',
    'menu.profile.invite.sms': 'Inviter venner via SMS',
    'menu.profile.invite.share': 'Send et link til dine venner',
    'menu.profile.invite.gift': 'Giv en ven',
    'menu.profile.password.current': 'Nuværende kodeord',
    'menu.profile.password.new': 'Nyt kodeord',
    'menu.profile.password.again': 'Indtast nyt kodeord igen',
    'menu.profile.password.forgot': 'Glemt dit kodeord?',
    'menu.profile.phone.your': 'Dit telefonnummer:',
    'menu.profile.phone.add': 'Tilføj telefonnummer',
    'menu.profile.phone.new': 'Nyt telefonnummer',
    'menu.profile.first': 'Fornavn',
    'menu.profile.last': 'Efternavn',
    'menu.profile.date': 'Fødselsdato',
    'menu.profile.gender': 'Køn',
    'menu.profile.currency': 'Valuta',
    'menu.profile.male': 'Mand',
    'menu.profile.female': 'Kvinde',
    'menu.profile.remove.confirm1':
      'Er du sikker på, at du vil slette din konto med alle data?',
    'menu.profile.remove.confirm2':
      'ALLE DINE KONTODATA, INDSTILLINGER, UPLOADDE DOKUMENTER, KATEGORIER VIL BLI PERMANENT SLETTET FOR ALTID!!! ER DU ENIG?',
    'menu.invite': 'Inviter en ven',
    'menu.plan': 'Abonnementsplan',
    'menu.plan.title': 'iReceipt Premium-konto',
    'menu.plan.tab.header.1': 'Funktioner',
    'menu.plan.tab.header.2': 'Basic',
    'menu.plan.tab.header.3': 'Premium',
    'menu.plan.tab.body.1': 'Gem kvitteringer',
    'menu.plan.tab.body.2': 'Del kvitteringer',
    'menu.plan.tab.body.3': 'Lagring i skyen',
    'menu.plan.tab.body.4': 'Opret mapper',
    'menu.plan.tab.body.5': 'Del mapper',
    'menu.plan.tab.body.6': 'Chat med andre',
    'menu.plan.tab.body.7': 'Ubegrænset lagerplads',
    'menu.profile.plan.title': 'GO PREMIUM',
    'menu.profile.plan.description':
      'Gem og del dine kvitteringer, opret mapper og del mapper som en PDF eller som et link til PDF',
    'menu.profile.plan.business': 'Virksomhed',
    'menu.profile.plan.argument.1.title': 'ubegrænset lagerplads',
    'menu.profile.plan.argument.1.description':
      'gem et ubegrænset antal kvitteringer, og få adgang til dem fra alle enheder',
    'menu.profile.plan.argument.2.title': 'op til ',
    'menu.profile.plan.argument.2.title-2': ' konti',
    'menu.profile.plan.argument.2.business.description':
      'opret din virksomhedskonto og lav separate konti for virksomhedsmedlemmer og arbejde',
    'menu.profile.plan.subtitle.1': 'Gratis prøveversion op til ',
    'menu.profile.plan.subtitle.2': ' kvitteringer',
    'menu.profile.plan.proposals.1.period': 'Månedlig',
    'menu.profile.plan.proposals.1.per-period': 'om måneden',
    'menu.profile.plan.proposals.2.period': 'Årligt',
    'menu.profile.plan.proposals.2.per-period': 'Per år',
    'menu.profile.plan.proposals.best-value': 'mest værdi for pengene',
    'menu.profile.plan.proposals.info-1': 'Det er kun ',
    'menu.profile.plan.proposals.info-2': ' om måneden',
    'menu.profile.plan.soon':
      'Flere funktioner kommer snart til vores premium-brugere',
    'menu.profile.plan.restore': 'Gendan købt',
    'menu.profile.plan.bottom-info':
      'Betalingen opkræves på Apple-kontoen ved købsbekræftelse. Abonnementet fornyes automatisk, medmindre automatisk fornyelse er slået fra mindst 24 timer inden udløb af den nuværende periode. Konto vil blive debiteret for fornyelse inden for 24 timer inden udløb af den nuværende periode og identificere udgifterne ved fornyelsen. Abonnementer administreres muligvis af brugeren, og automatisk fornyelse kan slås fra ved at gå til brugerens kontoindstillinger efter køb.',
    'menu.profile.plan.coming.soon': 'Kommer snart ...',
    'menu.profile.plan.coming.soon.alert':
      "Så snart 'Business' abonnementsplanen bliver tilgængelig, vil du modtage en push-besked. Sørg for, at dine meddelelser er slået til, så du ikke går glip af denne vigtige opdatering",
    'menu.profile.plan.notify': 'Giv mig besked først',
    'menu.profile.permission.viewing': 'Visningsrettigheder',
    'menu.profile.permission.editing': 'Redigeringsrettigheder',
    'menu.profile.permission.deletion': 'Sletningsrettigheder',
    'menu.profile.permission.admin.view.info':
      'Tildeling af administratorrettigheder kan tildeles deltagergrupper over 18 år. En bruger med denne rettighed kan se kvitteringer og fakturaer',
    'menu.profile.permission.admin.edit.info':
      'Tildeling af administratorrettigheder kan tildeles deltagergrupper over 18 år. En bruger med denne rettighed kan redigere kvitteringer og fakturaer',
    'menu.profile.permission.admin.delete.info':
      'Tildeling af administratorrettigheder kan tildeles deltagergrupper over 18 år. En bruger med denne rettighed kan slette kvitteringer og fakturaer',
    'menu.free': 'Gratis',
    'menu.monthly': 'månedligt',
    'menu.yearly': 'årligt',
    'menu.about': 'Om',
    'menu.lang': 'Sprog',
    'menu.theme': 'Ansøgningstema',
    'menu.theme.title': 'Vælg paletten',
    'menu.theme.subtitle': 'Nyd applikationen med dine yndlingsfarver',
    'menu.lang.choose': 'Vælg sprog:',
    'menu.lang.choose2': 'Vælg genkend sprog:',
    'menu.lang.ru': 'Russisk',
    'menu.lang.uk': 'Ukrainsk',
    'menu.lang.en': 'Engelsk',
    'menu.lang.no': 'Norsk',
    'menu.lang.pt': 'Portugisisk',
    'menu.lang.pl': 'Polere',
    'menu.lang.fi': 'Finsk',
    'menu.lang.se': 'Svensk',
    'menu.lang.it': 'Italiensk',
    'menu.lang.fr': 'Fransk',
    'menu.lang.es': 'Spansk',
    'menu.lang.de': 'Tysk',
    'menu.lang.da': 'Dansk',
    'menu.account': 'Skift konto',
    'menu.account.add': 'Del konto',
    'menu.account.notHave': 'du har ikke delte konti',
    'menu.logout': 'Log ud',
    'menu.logout.alternatives': 'Andre muligheder',
    'menu.logout.modal.title': 'Er du sikker på, at du vil logge ud?',
    'menu.support': 'Kontakt support',
    'menu.password': 'Skift kodeord',
    'menu.email': 'E-mail',
    'menu.phone': 'Telefonnummer',
    'menu/settings': 'Indstillinger',
    'menu/settings/profile': 'Rediger profil',
    'menu/settings/invite': 'Inviter en ven',
    'menu/settings/plan': 'Abonnementsplan',
    'menu/settings/password': 'Kodeord',
    'menu/settings/email': 'E-mail',
    'menu/settings/phone': 'Telefon',
    'menu/settings/language': 'Sprog',
    'menu/settings/quality': 'Kvitteringskvalitet',
    'menu/settings/about': 'Om',
    'menu/settings/account': 'Skift konto',
    'menu.settings.account.free': 'Gratis konto',
    'menu.settings.account.business': 'Virksomhedskonto',
    'menu.settings.account.add': 'Tilføj konto',

    'menu.plan.personal.storage-title': 'Lagerplads - op til 10 GB',
    'menu.plan.personal.storage-description':
      'Gem kvitteringer og få adgang til dem fra alle enheder',
    'menu.plan.family.storage-title': 'Lagerplads - op til 20 GB',
    'menu.plan.family.storage-description':
      'Gem kvitteringer og få adgang til dem fra alle enheder',

    'recognize.categoryName': 'genkender navn',
    'recognize.categoryAmount': 'genkender beløb',
    saveTo: 'Gem i..',
    'save.new': 'Gem & Ny',
    'save.close': 'Gem & Luk',

    'all.profiles': 'Alle profiler',
    'style.rotate': 'Roter',
    'style.correction': 'Korrektion',
    'style.color': 'Farve',
    'style.intensity': 'Intensitet',
    'style.style': 'Lysstyrke',
    'style.contrast': 'Kontrast',
    'move.to.receipts': 'Flyt til kvitteringer',
    'move.to.receipts.modal.title':
      'Denne faktura vil blive flyttet til kvitteringer',
    'move.to.invoices': 'Flyt til fakturaer',
    'move.to.invoices.modal.title':
      'Denne kvittering vil blive flyttet til fakturaer',
    'create.new.folder': 'Opret ny mappe',
    'share.modal.web.copy': 'Kopier link til udklipsholder',
    'app.loading': 'Loader',
    'receipt.quality': 'Kvitteringskvalitet',

    'this.month': 'Denne måned',
    'last.month': 'Sidste måned',
    'notifications.tryOpenReceiptLater': 'Prøv at åbne kvitteringen senere',

    select: 'Vælg',

    recognition: 'Anerkendelse',
    'recognition.none': 'Ingen',
    'recognition.fast': 'Hurtig',
    'recognition.accurate': 'Nøjagtig',
  },
};
