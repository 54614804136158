import {
  CreatorType,
  LocalCategory,
  LocalReceipt,
  Maybe,
  ReceiptType,
} from 'core.types';
import { AvailableAccountShare } from 'gql/api-user/api-user.types';

export type ReceiptEmptyType = 'empty' | 'full' | 'all';

export type FilterItemPredicateDTO = {
  receipt: LocalReceipt;
  isEmpty: boolean;
  receiptEmptyType: ReceiptEmptyType;
  currentUserSharedAccount: Maybe<AvailableAccountShare>;
  userId: Maybe<number>;
  filters: {
    searchFilter: string;
    receiptType: ReceiptType;
    filterMadeBy: CreatorType;
    dateFrom: number;
    dateTo: number;
  };
};

export const shouldSkipUnderPermissions = (
  item: LocalCategory | LocalReceipt,
  currentUserSharedAccount: Maybe<AvailableAccountShare>,
  userId: Maybe<number>,
) => {
  if (
    Array.isArray((item as LocalCategory).children) &&
    item.parentId === null
  ) {
    return false;
  }

  return (
    userId !== item.createdById &&
    currentUserSharedAccount?.canViewGroup === false
  );
};

export const filterReceiptPredicate = ({
  receipt,
  isEmpty,
  receiptEmptyType,
  currentUserSharedAccount,
  userId,
  filters: { searchFilter, receiptType, filterMadeBy, dateFrom, dateTo },
}: FilterItemPredicateDTO) => {
  const { name, type, createdBy, receiptDate, deleted } = receipt;

  if (shouldSkipUnderPermissions(receipt, currentUserSharedAccount, userId)) {
    return false;
  }

  const extraInspection =
    receiptEmptyType === 'all' ||
    (isEmpty && receiptEmptyType === 'empty') ||
    (!isEmpty && receiptEmptyType === 'full');

  return (
    receiptDate >= dateFrom &&
    receiptDate <= dateTo &&
    !deleted &&
    String(name).toLowerCase().includes(searchFilter.toLowerCase()) &&
    type === receiptType &&
    (filterMadeBy.id === 'all' || createdBy === filterMadeBy.name) &&
    extraInspection
  );
};
