import {
  SegmentChangeEventDetail,
  SelectChangeEventDetail,
} from '@ionic/react';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { recognitionConfigAtom } from 'store/root-store';
import {
  RECOGNITION_LANGUAGES,
  RECOGNITION_TYPES,
} from '../../../../core.types';

export const useRecognitionLogic = () => {
  const intl = useIntl();
  const [recognitionConfig, setRecognitionConfig] = useRecoilState(
    recognitionConfigAtom,
  );
  const [recognitionType, setRecognitionType] = useState<RECOGNITION_TYPES>(
    recognitionConfig?.recognitionType,
  );
  const [recognitionLanguages, setRecognitionLanguages] = useState<string[]>(
    recognitionConfig.languages,
  );

  const recognitionSwitchOptions = useMemo(
    () =>
      Object.values(RECOGNITION_TYPES).map((value) => ({
        value,
        label: String(intl.messages[`recognition.${value}`]),
      })),
    [intl.messages],
  );
  const recognitionLanguageSelectOptions = useMemo(
    () =>
      Object.entries(RECOGNITION_LANGUAGES).map(([key, value]) => ({
        value,
        label: String(intl.messages[`menu.lang.${key}`]),
      })),
    [intl.messages],
  );

  const onRecognitionTypeChange = useCallback(
    ({ detail: { value } }: CustomEvent<SegmentChangeEventDetail>) => {
      if (value) {
        setRecognitionType(value as RECOGNITION_TYPES);
      }

      value === RECOGNITION_TYPES.none && setRecognitionLanguages([]);
    },
    [],
  );

  const onRecognitionLanguagesChange = useCallback(
    ({ detail: { value } }: CustomEvent<SelectChangeEventDetail>) => {
      setRecognitionLanguages(value);
    },
    [],
  );

  const onSaveButtonClick = () => {
    // TODO set config in application
    setRecognitionConfig({
      languages:
        recognitionType !== RECOGNITION_TYPES.none ? recognitionLanguages : [],
      recognitionType,
    });
  };

  return {
    recognitionSwitchOptions,
    recognitionType,
    onRecognitionTypeChange,
    recognitionLanguageSelectOptions,
    recognitionLanguages,
    onRecognitionLanguagesChange,
    onSaveButtonClick,
  } as const;
};
