import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { unlockBackSwipe } from '../../../../services';
import { SettingsIcon } from '../../settings-icon';
import Tappable from 'react-tappable';
import { APP_CSS_THEME_VARIABLES } from '../../../../color-theme.constants';
import './settings-menu.scss';

export const SettingsMenu = ({
  location: { pathname },
  menuRoute,
  path,
  isDesktop,
}: any) => {
  const intl = useIntl();
  const history = useHistory();
  const colorTextMain = `var(${APP_CSS_THEME_VARIABLES.textMain})`;

  return (
    <>
      {menuRoute.map((item: any, inx: number) => {
        const isActive = pathname.includes(item.to);
        const iconOpacity = isActive ? null : '0.2';

        const onLinkClick = async () => {
          await unlockBackSwipe();
          history.push(`${path}${item.to}`);
        };

        return item.type ? (
          <li
            className={cx('menu-line', {
              'menu-line--desktop-margin-top-bottom': isDesktop,
            })}
            key={inx}
          />
        ) : (
          <li
            key={inx}
            className={cx(`menu-item menu-item--${item.id}`, {
              'menu-item--active': isActive,
            })}
          >
            <Tappable
              component='div'
              onTap={onLinkClick}
              className={cx('nav-link', {
                'active-link': pathname.includes(item.to),
              })}
              disabled={!!item.disabled}
            >
              <div className={'nav-link-container'}>
                <SettingsIcon
                  color={item.iconColor}
                  name={item.iconName}
                  opacity={iconOpacity}
                />
                <span className='menu-text'>
                  {intl.messages[`menu.${item.id}`] as string}
                </span>
              </div>
              {!['account', 'logout'].includes(item.id) && (
                <SettingsIcon
                  name='arrow-right'
                  color={colorTextMain}
                  sizeType='custom'
                  opacity={iconOpacity}
                />
              )}
            </Tappable>
          </li>
        );
      })}
    </>
  );
};
