import cx from 'classnames';
import { AppButton } from 'components/app-button';
import { FormattedMessage } from 'react-intl';
import { AppIcon } from '../../../../components';
import { APP_CSS_THEME_VARIABLES } from '../../../../color-theme.constants';
import './AddButton.scss';

export const AddButton = ({
  label,
  onClick,
  className,
  withInfo = false,
  maxAccounts = 5,
}: any) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  return (
    <AppButton
      className={cx('add-item__button', { [className]: !!className })}
      onPress={onClick}
    >
      <AppIcon
        className='add-item__button-icon'
        name='add-icon'
        color={colorEmphasized}
      />

      <div className={'add-item__label'}>
        <div>{label}</div>

        {withInfo && (
          <div className='add-item__additional-info'>
            <FormattedMessage id={'add.item.additional.info-1'} />
            {maxAccounts}
            <FormattedMessage id={'add.item.additional.info-2'} />
          </div>
        )}
      </div>
    </AppButton>
  );
};
